import * as React from "react";

import "./Footer.scss";
import Icon from "../../../../components/Icon";

const Footer = () => {
    return(
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="social-links">
                            <a
                                href="https://www.facebook.com/"
                                target='_blank'
                                className="social-links__link"
                            >
                                <Icon
                                    name="facebook"
                                    width={13}
                                    height={22}
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/"
                                target='_blank'
                                className="social-links__link"
                            >
                                <Icon
                                    name="instagram"
                                    width={22}
                                    height={22}
                                />
                            </a>
                            <a
                                href="https://twitter.com/"
                                target='_blank'
                                className="social-links__link"
                            >
                                <Icon
                                    name="twitter"
                                    width={24}
                                    height={24}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <p>&copy;   Retroteam</p>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;