import * as React from 'react';

import { CardProps } from '../Card';
import Footer from '../Footer';
import './Details.scss';
import Avatar from '../../Avatar';
import Portal from '../../Portal';

import Column from '../../Column';

import cx from 'classnames'

const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

export interface DetailsProps extends CardProps {
  editable: boolean;
  onClose: () => void;
  boardUsers: any;
  width: number | undefined;
  columnObj: any;
  phase: any;
  boardUrl: string;
  isDiscussPhase: any;
  actionsColumn: any;
}

interface SettingsIsOpen {
  [key: string]: boolean
}

export interface DetailsState {
  settingsIsOpen: SettingsIsOpen
  listUsersIsOpened: boolean
  isExtendedView: boolean
}

export class Details extends React.Component<DetailsProps, DetailsState> {
  constructor(props: DetailsProps) {
    super(props);
    this.state = {
      settingsIsOpen: {},
      listUsersIsOpened: false,
      isExtendedView: false
    }
  }

  handleBlur = (e: React.FormEvent<any>) => {
    if (this.state.listUsersIsOpened) {
      return
    }

    const { innerText } = e.target as any;
    const { id, onUpdateText } = this.props;
    onUpdateText(id, innerText);
  };

  toggleSettings = (id: string) => {
    const { settingsIsOpen } = this.state;
    settingsIsOpen[id] = !settingsIsOpen[id];
    this.setState({ settingsIsOpen: { ...settingsIsOpen } });
  };

  closeSettings = (id: string) => {
    const { settingsIsOpen } = this.state;
    settingsIsOpen[id] = false;
    this.setState({ settingsIsOpen: { ...settingsIsOpen } });
  };

  onCardTextChanged = (e: any) => {
    this.setState({listUsersIsOpened: false})
    // if (this.props.card?.type !== 'actions') {
    //   return
    // }

    // const key = e.keyCode
    // if (e.shiftKey && key === 50) {
    //   this.setState({listUsersIsOpened: true})
    // } else {
    //   this.setState({listUsersIsOpened: false})
    // }
  }

  toggleListUsersForAssign = () => {
    this.setState({listUsersIsOpened: !this.state.listUsersIsOpened})
  }

  onClickUserInList = (e: any, user: any) => {
    const element = document.getElementById(`blockquote-${this.props.card.id}`)

    if (element) {
      const { innerText } = element
      const updatingText = innerText + '@' + user.email // used @ because of old logic to support @ inside text

      const { id, onUpdateText } = this.props
      onUpdateText(id, updatingText)

      // this.props.onClose()
    }

    this.setState({listUsersIsOpened: false})
  }

  onUnassignedUser = (e: any) => {
    const element = document.getElementById(`blockquote-${this.props.card.id}`)

    if (element) {
      const { innerText } = element
      const updatingText = innerText + '@---' // used @ because of old logic to support @ inside text

      const { id, onUpdateText } = this.props
      onUpdateText(id, updatingText)

      this.props.onClose()
    }

    this.setState({listUsersIsOpened: false})
  }

  onExpandView = () => {
    this.setState({isExtendedView: true})
  }

  render() {
    const {
      id,
      onRemove,
      onDownvote,
      onUpvote,
      votable,
      ownVotes,
      votes,
      showVotes,
      width,
      isBoardCompleted,
      isAdmin,
      owner,
      boardUsers
    } = this.props;

    const boardUsersWithEmail = boardUsers?.filter((u: any) => !!u.email)

    const isActionCard = this.props.card.type === 'actions';

    const ddProps: any = {};
    ddProps[id] = {
      isOpen: this.state.settingsIsOpen[id],
      close: () => this.closeSettings(id),
      align: 'right',
      closeOnInsideClick: true
    };

    const containerCSS = cx({'card-details-container': this.state.isExtendedView})
    const portalCSS = cx({'card-details-wrapper': this.state.isExtendedView})

    let cardDetailsStyle = {}
    if (!this.state.isExtendedView) {
      cardDetailsStyle = {
        width: `${width}px`
      }
    }

    const hasActionColunAndDiscussPhase = !!this.props.actionsColumn?.id && this.props.isDiscussPhase

    return (
      <Portal
        onClose={() => this.props.onClose()}
        verticallyAlignContent="start"
        className={portalCSS}
      >
        <div className={containerCSS}>
          {!isActionCard && hasActionColunAndDiscussPhase && !this.state.isExtendedView && (
            <button
              aria-label="Add Actions"
              className="start-button__start card-details-add-action-button"
              style={{ width: `${width}px` }}
              onClick={this.onExpandView}
              id="startBtn"
            >
              <h3 className="start-button__start-label">Add Actions</h3>
            </button>
          )}
          <div className="card_details__card" style={cardDetailsStyle}>
            {(isAdmin || (owner && !isBoardCompleted))  &&
              (
                <DropdownMenu
                  {...ddProps[id]}
                  className="card__settings"
                  toggle={(
                    <div
                      className="card__settings-icon"
                      onClick={() => this.toggleSettings(id)}
                    >
                      <span/><span/><span/>
                    </div>
                  )}
                >
                  <li
                    className='card__settings-item card__settings-item--only'
                    onClick={() => onRemove(id)}
                  >
                    Delete
                  </li>
                </DropdownMenu>
              )
            }
            <blockquote
              id={`blockquote-${this.props.card.id}`}
              className="card-details__card-text"
              contentEditable={this.props.editable}
              suppressContentEditableWarning={true}
              onKeyDown={this.onCardTextChanged}
              onBlur={this.handleBlur}
            >
              {this.props.card.text}
            </blockquote>

            <Footer
              votable={isActionCard ? false : votable}
              ownVotes={isActionCard ? 0 : ownVotes}
              onDownvote={() => onDownvote(id)}
              onUpvote={() => onUpvote(id)}
              votes={isActionCard ? null : showVotes || votable ? votes : null}
            >
              {this.props.isShowAuthor && !isActionCard && (
                <>
                  <Avatar
                    user={{
                      name: this.props.author.name || '',
                      photoUrl: this.props.author.photoUrl || undefined
                    }}
                    className="card__avatar"
                  />
                  <span className="card__author">{this.props.author.name}</span>
                </>
              )}
            </Footer>

            {this.props.isShowAuthor && isActionCard && (
              <div className='card__assignedUser u-pointer' onClick={this.toggleListUsersForAssign}>
                {this.props.card?.assignedUserId && (
                  <>
                    <Avatar
                      user={{
                        name: this.props.card.assignedUserName || '',
                        photoUrl: this.props.card.assignedUserPhotoUrl || undefined
                      }}
                      className="card__avatar"
                    />
                    <span className="card__author card__notAssigned--clickable">{this.props.card.assignedUserName}</span>
                    <span className='card__unassignUser' onClick={this.onUnassignedUser}>ⓧ</span>
                  </>
                )}
                {!this.props.card?.assignedUserId && (
                  <div className='card__notAssigned card__notAssigned--clickable'>not assigned</div>
                )}
              </div>
            )}
            
            {this.state.listUsersIsOpened && (
              <div className='card-details__userList'>
                {boardUsersWithEmail?.map((user: any) => {
                  return (
                    <div className='card-details__singleUserInLinst' key={`user-in-list-${user.id}`} onClick={(e) => this.onClickUserInList(e, user)}>
                      {user.name}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {this.state.isExtendedView && (
            <Column
              column={this.props.actionsColumn}
              key={this.props.actionsColumn?.id}
              boardUrl={this.props.boardUrl}
              boardId={this.props.boardId}
              phase={this.props.phase}
              isActive={true}
              hasPreviousColumn={false}
              hasNextColumn={false}
              onGoToPrevColumn={() => null}
              onGoToNextColumn={() => null}
              className="board__column"
              isCompactView={true}
              isBoardCompleted={this.props.isBoardCompleted}
              isDiscussPhase={false}
              actionsColumn={{}}
            />
          )}
        </div>
      </Portal>
    );
  }
}

export default Details;
