import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Raven from 'raven-js';
import firebase from 'firebase/app';
import 'firebase/analytics'
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import 'firebase/auth';
import 'firebase/firestore';

import { firebase as fbConfig } from './config';
import Router from './Router';
import configureStore from './store';
import './index.scss';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { sentry } from './config';


// types not available
const dnd = require('react-dnd');

const app = firebase.initializeApp(fbConfig);

export const analytics = firebase.analytics();

const db = firebase.firestore(app);

if(location.hostname === "localhost") {
  db.useEmulator("localhost", 8080);
}

const store = configureStore({});

if (Boolean(sentry.dsn)) {
  Raven.config(sentry.dsn as string).install();
}

window.addEventListener('unhandledrejection', (err: PromiseRejectionEvent) => {
  Raven.captureMessage(err.reason);
});

const renderLoadingScreen = () => {
  ReactDOM.render(<LoadingScreen />, document.getElementById(
    'root'
  ) as HTMLElement);
};

const rrfProps = {
  firebase,
  config: {
    users: 'users',
  },
  dispatch: store.dispatch,
  createFirestoreInstance
};

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <dnd.DndProvider backend={HTML5Backend}>
          <Router />
        </dnd.DndProvider>
      </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement
  );
};


renderLoadingScreen();


firebase
  .auth()
  .getRedirectResult()
  .then(() => renderApp())
  .catch((error: Error) => {
    Raven.captureMessage('Unable to authenticate', {
      extra: {
        reason: error
      }
    });
    renderApp();
  });
