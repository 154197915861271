import {StoreState, BoardUsers, Board} from '../../types';
import { getVal, getFirebase } from 'react-redux-firebase';
import * as Raven from 'raven-js';
import { FirebaseFirestore } from '@firebase/firestore-types';

import { OwnHeaderProps, StateHeaderProps } from './Header';
import { getPhaseConfiguration, getPhaseNames, getPhasesCount } from "../../constants/Retrospective";

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnHeaderProps
): StateHeaderProps => {
  const { fbState } = state;
  const firebase = getFirebase();
  const database: FirebaseFirestore = firebase.firestore();

  const board: Board = getVal(fbState, `data/boards/${ownProps.boardId}`);
  const auth = firebase.auth().currentUser ? firebase.auth().currentUser : null;

  // Get a list of all users that have been interacted with this board.
  const users: BoardUsers = board.users;

  const cards = getVal(fbState, `data/cards`, false);
  const hasCards: boolean = !!cards;

  const userLength: number = Object.keys(users) ? Object.keys(users).length : 0;

  const isBoardAdmin = !!auth && auth.uid === board.creatorUid;

  function onFocusCard(cardId: string) {
    const { focusedCardId } = board;

    database
      .collection('boards')
      .doc(ownProps.boardId)
      .update({ focusedCardId: focusedCardId !== cardId ? cardId : null })
      .catch((err: any) => {
        Raven.captureMessage('Could not focus card', {
          extra: {
            reason: err.message,
            uid: auth && auth.uid,
            boardId: ownProps.boardId,
            focusedCardId
          }
        });
      });
  }

  function onSwitchPhaseIndex(delta: number) {
    database
      .collection('boards')
      .doc(ownProps.boardId)
      .update({ guidedPhase: board.guidedPhase + delta })
      .catch((err: Error) => {
        Raven.captureMessage('Could not switch phase index', {
          extra: { reason: err.message, uid: auth && auth.uid, boardId: ownProps.boardId }
        });
      });

    database
      .collection('boards')
      .doc(ownProps.boardId)
      .update({ timerExpiration: null })
      .catch((err: Error) => {
          Raven.captureMessage('Could not switch phase index', {
            extra: { reason: err.message, uid: auth && auth.uid, boardId: ownProps.boardId }
          });
        });

    const newUsers: BoardUsers = {};

    Object.keys(users).map((uid: string) => {
      newUsers[uid] = {
          ...users[uid],
          ready: false
        }
    });

    database
      .collection('boards')
      .doc(ownProps.boardId)
      .update({ users: newUsers })
      .catch((err: Error) => {
        Raven.captureMessage('Could reset ready status for users', {
          extra: { reason: err.message, boardId: ownProps.boardId }
        });
      });

    // Always remove card selection if new phase is entered.
    if (board.focusedCardId) {
      onFocusCard(board.focusedCardId);
    }
  }

  const onSetTimer = (seconds: number) => {

    database
      .collection('boards')
      .doc(ownProps.boardId)
      .update({ timerExpiration: new Date(new Date().getTime() + seconds * 1000).toUTCString() })
      .catch((err: any) => {
        Raven.captureMessage('Could not set timer', {
          extra: {
            reason: err.message,
            uid: auth && auth.uid,
            boardId: ownProps.boardId,
            seconds
          }
        });
      });
  };

  const onPrevPhase = () => {
    onSwitchPhaseIndex(-1);
  };
  const onNextPhase = () => {
    onSwitchPhaseIndex(1);
  };

  let prevPhaseName: string | undefined = undefined;
  let nextPhaseName: string | undefined = undefined;
  let phaseNames: any = [];
  let totalPhases: number = 0;

  const phase = board.guidedPhase;

  if(board.mode === 'custom') {
    if(board.customConfig) {
      totalPhases = board.customConfig.length;
      prevPhaseName = phase > 0 ? board.customConfig[phase - 1].name : undefined;
      nextPhaseName = phase < totalPhases - 1 ? board.customConfig[phase + 1].name : undefined;
      board.customConfig.map((item: any) => {
        phaseNames.push({
          name: item.name,
          description: item.description
        })
      })
    }
  } else {
    totalPhases = getPhasesCount(board.mode);
    prevPhaseName = phase > 0 ? getPhaseConfiguration(board.mode, phase - 1).name : undefined;
    nextPhaseName = phase < totalPhases - 1 ? getPhaseConfiguration(board.mode, phase + 1).name : undefined;
    phaseNames = getPhaseNames(board.mode);
  }

  return {
    admin: isBoardAdmin,
    boardName: board.name,
    hasCards,
    prevPhaseName,
    nextPhaseName,
    phaseNames,
    totalPhases,
    onPrevPhase,
    onNextPhase,
    onSetTimer,
    userLength,
    user: auth ? auth.uid : null,
    users,
    loggedIn: Boolean(firebase.auth().currentUser),
  };
};
