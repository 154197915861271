import * as React from 'react';
import './Expand.scss';
import ReactTooltip from "react-tooltip";

export interface ExpandProps {
  columnId?: string
  svgClassName?: string;
}

export const Expand: React.FunctionComponent<ExpandProps> = ({
     svgClassName,
     columnId
 }) => {

  const expand = require('!svg-inline-loader!../../../assets/icon-20-expand.svg');

  return(
    <>
      <div
        className={svgClassName}
        dangerouslySetInnerHTML={{ __html: expand }}
        data-tip="View all cards"
        data-for={`expandTooltip${columnId}`}
      />
      <ReactTooltip
        id={`expandTooltip${columnId}`}
        place="left"
        effect="solid"
        isCapture={false}
      />
    </>
  )
};

export default Expand;
