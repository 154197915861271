import * as React from 'react';
import { Component } from 'react';
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

import Portal from "../../Portal";
import { Card as CardModel, CardGroup} from "../../../types";
import Card from '../../Card';
import './Expanded.scss';
import Input from "@material-ui/core/Input";

export interface ExpandedOwnProps {
  onClose: () => void;
  group: CardGroup;
  cards: CardModel[];
  ungroupAllCards: () => void;
  deleteGroupWithCards: () => void;
  votable: boolean;
  showVotes: boolean;
  isBoardCompleted: boolean;
  boardId: string;
  changeGroupName: (value: string) => void;
  isAdmin: boolean;
  columnObj: any;
  phase: any;
  boardUrl: string;
  isDiscussPhase: any;
  actionsColumn: any;
}

export interface ExpandedState {
  isMenuOpen: boolean;
  groupName: string;
}

class Expanded extends Component<ExpandedOwnProps, ExpandedState> {
  constructor(props: ExpandedOwnProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      groupName: this.props.group.name
    }
  }

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  };

  closeMenu = () => {
    this.setState({
      isMenuOpen: false
    })
  };

  changeGroupName = (e: any) => {
    const value: string = e.target.value;
    if(e.target.value.length <= 60) {
      this.setState({ groupName: value }, () => {
        if(value) {
          this.props.changeGroupName(value);
        }
      });
    }
  };


  render() {
    const {
      onClose,
      deleteGroupWithCards,
      ungroupAllCards,
      group,
      cards,
      votable,
      showVotes,
      isBoardCompleted,
      boardId,
      isAdmin
    } = this.props;

    const {
      isMenuOpen,
      groupName
    } = this.state;

    const toggleIcon = (
      <div
        className="card__settings-icon"
        onClick={this.toggleMenu}
      >
        <span />
        <span />
        <span />
      </div>
    );

    const ddMenuProps = {
      isOpen: isMenuOpen,
      close: this.closeMenu,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    const cardsTmp: CardModel[] = cards;
    if(group && group.cards) {
      cardsTmp.sort((a: CardModel, b: CardModel) => {
        if (a.id && b.id) {
          return group.cards[b.id].order - group.cards[a.id].order
        } else {
          return -1;
        }
      });
    }

    console.log('expend', this.props)

    return(
      <Portal
        onClose={onClose}
        verticallyAlignContent="start"
      >
        <div className="expanded">
          <div className="expanded-header">
            <div className="char-remaining">{groupName.length}/60</div>
            <Input
              className="expanded__title"
              type="text"
              value={groupName}
              onChange={this.changeGroupName}
              disableUnderline={true}
              placeholder='Group Name'
              disabled={!isAdmin}
              error={!groupName}
            />

            {isAdmin &&
              <DropdownMenu
                {...ddMenuProps}
                className='card__settings card-group__settings'
              >
               <li
                 className="card__settings-item card__settings-item--first"
                 onClick={deleteGroupWithCards}
               >
                 Delete
               </li>
               <li
                 className="card__settings-item card__settings-item--last"
                 onClick={ungroupAllCards}
               >
                 Ungroup
               </li>
              </DropdownMenu>
            }
          </div>
          <ul className="expanded-cards">
            {cardsTmp && cardsTmp.map((card: CardModel) =>
              <Card
                key={card.id}
                card={card}
                votable={votable}
                showVotes={showVotes}
                isBoardCompleted={isBoardCompleted}
                boardId={boardId}
                isGrouped={true}
                groupId={group.id}
                columnObj={this.props.columnObj}
                phase={this.props.phase}
                boardUrl={this.props.boardUrl}
                isDiscussPhase={this.props.isDiscussPhase}
                actionsColumn={this.props.actionsColumn}
              >
                {card.text}
              </Card>
              )}
          </ul>
        </div>
      </Portal>
    )
  }
}

export default Expanded;
