import * as cx from 'classnames';
import * as React from 'react';
import './Stack.scss';

import {Card as CardModel, CardGroup as CardGroupModel} from '../../types';
import CardGroup from '../CardGroup';
import Card from '../Card';
import Deferred from '../Deferred';

export interface StackProps {
  boardUrl: string;
  boardId: string;
  cards: CardModel[];
  groups: CardGroupModel[];
  isVotingAllowed: boolean;
  isVoteSummaryShown: boolean;
  className?: string;
  type?: string;
  id?: string;
  connectDropTarget?: any;
  isBoardCompleted: boolean;
  sorted: boolean;
  columnObj: any;
  phase: any;
  isDiscussPhase: any;
  actionsColumn: any;
}

class Stack extends React.Component<StackProps, {}> {

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    const stackList = document.getElementsByClassName('stack')
    for (let i = 0; i < stackList.length; i++) {
      const currEl = stackList[i] as HTMLElement

      if (window.innerWidth < 530) {
        currEl.style.gridTemplateColumns = 'auto'
      } else {
        currEl.style.gridTemplateColumns = 'repeat(2, 1fr)'
      }
    }
  }

  renderCard(card: CardModel) {
    return (
      <Card
        key={`card-${card.id}`}
        boardId={this.props.boardId}
        card={card}
        votable={this.props.isVotingAllowed}
        showVotes={this.props.isVoteSummaryShown}
        isBoardCompleted={this.props.isBoardCompleted}
        columnObj={this.props.columnObj}
        phase={this.props.phase}
        boardUrl={this.props.boardUrl}
        isDiscussPhase={this.props.isDiscussPhase}
        actionsColumn={this.props.actionsColumn}
      >
        <Deferred value={card.text} />
      </Card>
    );
  }

  renderGroup(group: CardGroupModel) {
    return (
      <CardGroup
        key={`group-${group.id}`}
        id={group.id}
        boardId={this.props.boardId}
        isBoardCompleted={this.props.isBoardCompleted}
        votable={this.props.isVotingAllowed}
        showVotes={this.props.isVoteSummaryShown}
        columnObj={this.props.columnObj}
        phase={this.props.phase}
        boardUrl={this.props.boardUrl}
        isDiscussPhase={this.props.isDiscussPhase}
        actionsColumn={this.props.actionsColumn}
      />
    )
  }

  sortData(groupCards: any[], sortByVotes: boolean) {
    const { cards } = this.props;
    const compareOrder = (a: any, b: any) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return 0;
      }
    };

    const getVotes = (a: any): number => {
      if(a.origin === 'card') {
        return a.votes
      } else {
        const t = Object.keys(a.cards).map((cardId: string) => {
          const tmpCard = cards.find((card: CardModel) => {
            return card.id === cardId
          });
          if(tmpCard) {
            return tmpCard.votes;
          } else {
            return 0
          }
        });
        return t.reduce((x: number, y: number) => x+y);
      }
    };

    if (sortByVotes) {
      return groupCards
        .sort((a, b) => {
          let aVotes = getVotes(a);
          let bVotes = getVotes(b);
          if (aVotes > bVotes) {
            return 1;
          } else if (aVotes < bVotes) {
            return -1;
          } else {
            return compareOrder(a, b);
          }
        })
        .reverse();
    } else {
      return groupCards.sort(compareOrder).reverse();
    }
  }

  render() {
    let { cards, groups, sorted } = this.props;
    const groupCards: any[] = [ ...cards, ...groups];
    let groupedCardsId = {};
    groups.map((group: CardGroupModel) => {
      groupedCardsId = { ...groupedCardsId, ...group.cards };
    });

    return (
      <div>
        <ul className={cx('stack', this.props.className)}>
          {this.sortData(groupCards, sorted).map((groupCard: any) => {
            if(groupCard.origin === 'card') {
              if(!groupedCardsId.hasOwnProperty(groupCard.id)) {
                return this.renderCard(groupCard)
              } else {
                return '';
              }
            } else {
              return this.renderGroup(groupCard);
            }
          })}
        </ul>
      </div>
    );
  }
}

export default Stack;
