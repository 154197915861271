import * as React from 'react';
import './VotesLeft.scss';

export interface VotesLeftProps {
  votes: number | string
}

const VotesLeft = ({ votes }: VotesLeftProps) => {
  return(
    <div
      className="votes-left"
    >
      <span className="votes-left__info">My votes <span className="votes-left__number">{votes}</span></span>
    </div>
  )
};

export default VotesLeft;
