import * as React from 'react';
import { Component } from 'react';
import * as cx from 'classnames';

import Portal from "../../Portal";
import Avatar from "../../Avatar";
import './UserListModal.scss';
import {OtherBoardUser} from "../../../types";

export interface UserListModalProps {
  onClose: () => void;
  users: OtherBoardUser
}

class UserListModal extends Component<UserListModalProps, {}> {

  render() {
    const { onClose, users } = this.props;
    return(
      <Portal
        onClose={onClose}
        showCloseButton={false}
      >
        <div className="modal__content-wrapper">
          <div className="modal__content all-users-content">
            <div className="all-users-header">
              <h2 className="all-users-header__title">Participants</h2>
              <div
                className="side-modal-close-wrapper"
                onClick={onClose}
              />
            </div>

            <div className="all-users-main">
              <ul className="all-users-list">
                {users && Object.keys(users).map((userId: string) =>
                  <li
                    key={userId}
                    className={cx("all-users-list__item", {"all-users-list__item--emailed": !!users[userId].email})}
                  >
                    <div
                      className="all-users-list__item-avatar"
                    >
                      <Avatar user={users[userId]} />
                    </div>
                    <div className={cx("all-users-list__item-info", {"all-users-list__item-info--emailed": !!users[userId].email})}>
                      <span
                        className="all-users-list__item-name"
                      >
                        {users[userId].name}
                      </span>

                      {!!users[userId].email
                        &&
                      <span className="all-users-list__item-email">{users[userId].email}</span>
                      }
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </Portal>
    )
  }
}

export default UserListModal;
