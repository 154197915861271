import * as React from 'react';

import './Logo.scss';

const Logo: React.SFC<any> = () => {

  return (
    <h1 className="header__logo">Retro<span className="header__logo-team">Team</span></h1>
  );
};

export default Logo;
