import { StoreState } from '../../types';
import { FirebaseFirestore } from '@firebase/firestore-types';
import { getVal, getFirebase, isLoaded } from 'react-redux-firebase';
import { FocusedCardProps, OwnFocusedCardProps } from './FocusedCard';
import Raven = require('raven-js');

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnFocusedCardProps
): FocusedCardProps => {
  const { fbState } = state;

  const database: FirebaseFirestore = getFirebase().firestore();
  const boardId = ownProps.boardId;
  const auth = getVal(fbState, 'auth', {});
  const boardConfig: any = getVal(
    fbState,
    `data/${boardId}`,
    {}
  );

  const isBoardAdmin =
    auth && isLoaded(boardConfig) ? auth.uid === boardConfig.creatorUid : false;

  function onClose() {
    database
      .collection('boards')
      .doc(boardId)
      .update({ focusedCardId: null })
      .catch((err: Error) => {
        Raven.captureMessage('Could not delete focus on', {
          extra: {
            reason: err.message,
            uid: auth.uid,
            boardId: boardId
          }
        });
      });
  }

  return {
    isAdmin: isBoardAdmin,
    onClose,
    ...ownProps
  };
};
