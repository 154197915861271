import * as React from 'react';
import './LoadingScreen.scss';
// import LoadingIndicator from '../LoadingIndicator';

export interface LoadingScreenProps {
  status?: string | JSX.Element;
}

export const LoadingScreen: React.SFC<LoadingScreenProps> = props => (
  <div className="loading-screen">
    <h1 className="loading-screen-logo">Retro<span className="loading-screen-logo__team">Team</span></h1>
    <div className="loading-screen__progress-bar">
      <div className="loading-screen__current-progress" />
    </div>
    <p className="loading-screen__message">
      Loading data. Please wait.
    </p>
    <div className="loading-screen__message">
      {props.status && (
        <span>
          {props.status}
        </span>
      )}
    </div>
  </div>
);

export default LoadingScreen;
