import * as React from "react";

import "./Features.scss";

const Features = () => {
    return(
        <div id="features" className="features">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="features__title">WHY RETROTEAM</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <div className="feature">
                            <div className="feature-image">
                                <div className="feature-image__container">
                                    icon
                                </div>
                            </div>
                            <div className="feature-description">
                                <h5 className="feature-description__title">FEATURE NO1</h5>
                                <span className="feature-description__text">Cras mattis consectetur purus sit amet fermentum.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="feature">
                            <div className="feature-image">
                                <div className="feature-image__container">
                                    icon
                                </div>
                            </div>
                            <div className="feature-description">
                                <h5 className="feature-description__title">FEATURE NO2</h5>
                                <span className="feature-description__text">Maecenas faucibus mollis interdum.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="feature">
                            <div className="feature-image">
                                <div className="feature-image__container">
                                    icon
                                </div>
                            </div>
                            <div className="feature-description">
                                <h5 className="feature-description__title">FEATURE NO3</h5>
                                <span className="feature-description__text">Morbi leo risus, porta ac consectetur ac vestibulum.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="feature">
                            <div className="feature-image">
                                <div className="feature-image__container">
                                    icon
                                </div>
                            </div>
                            <div className="feature-description">
                                <h5 className="feature-description__title">FEATURE NO4</h5>
                                <span className="feature-description__text">Donec id elit non mi porta gravida at eget metus.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="feature">
                            <div className="feature-image">
                                <div className="feature-image__container">
                                    icon
                                </div>
                            </div>
                            <div className="feature-description">
                                <h5 className="feature-description__title">FEATURE NO5</h5>
                                <span className="feature-description__text">Vivamus sagittis lacus vel augue laoreet rutrum.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="feature">
                            <div className="feature-image">
                                <div className="feature-image__container">
                                    icon
                                </div>
                            </div>
                            <div className="feature-description">
                                <h5 className="feature-description__title">FEATURE NO6</h5>
                                <span className="feature-description__text">Faucibus dolor auctor. Nullam id dolor id nibh tricies.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Features;