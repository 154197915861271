import { getFirebase, getVal, isEmpty } from 'react-redux-firebase';
import {
  Board,
  StoreState,
  Teams,
} from '../../types';
import {BoardGuardProps, BoardGuardStateProps } from './BoardGuard';

export const mapStateToProps = (
  state: StoreState,
  ownProps: BoardGuardProps
): BoardGuardStateProps => {

  const { fbState } = state;
  const teams: Teams = getVal(fbState, `data/teams`, undefined);
  const boardId: string = ownProps.match.params.id;
  const board: Board = getVal(fbState, `data/boards/${boardId}`);
  const isBoardEmpty: boolean | undefined = isEmpty(board);
  const uid: string | undefined = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : undefined;

  function addPresence() {
    if(uid) {
      if(board && (!board.secure || board.users && board.users[uid])) {
        const firebase = getFirebase();
        firebase
          .firestore()
          .collection('boards')
          .doc(boardId)
          .update({ [`users.${uid}.presence`]: true })
          .catch((e) => {
            console.log(e);
          })
      }
    }
  }

  function removePresence(currentBoardId: string) {
    if(uid) {
      if(board && (!board.secure || board.users && board.users[uid])) {
        const firebase = getFirebase();
        firebase
          .firestore()
          .collection('boards')
          .doc(currentBoardId)
          .update({ [`users.${uid}.presence`]: false })
          .catch((e) => {
            console.log(e);
          })
      }
    }
  }

  return {
    uid,
    board,
    isBoardEmpty,
    teams,
    addPresence,
    removePresence
  }
};
