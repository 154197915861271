import * as React from 'react';
import { Component } from 'react';

import './ColumnName.scss';

export interface ColumnNameState {
  columnName: string;
}

export interface ColumnNameProps {
  title: string;
  count: number;
  isAdmin?: boolean;
  isCustomBoard?: boolean;
  changeCustomColumn?: (id: string, value: string) => void;
  id?: string
}

class ColumnName extends Component <ColumnNameProps, ColumnNameState> {

  constructor(props: ColumnNameProps) {
    super(props);
    this.state = {
      columnName: ''
    }
  }

  componentDidMount() {
    this.setState({
      columnName: this.props.title
    });
  }

  onChange = (e: any) => {
    const { changeCustomColumn, id } = this.props;
    this.setState({ columnName: e.target.value });
    if(e.target.value && changeCustomColumn && id) {
      changeCustomColumn(id, e.target.value);
    }

  };

  onBlur = (e: any) => {
    if (!this.state.columnName) {
      this.setState({ columnName: this.props.title });
    }
  }

  render() {
    const {
      title,
      count,
      isAdmin,
      isCustomBoard
    } = this.props;

    const { columnName } = this.state;

    return (
      <div className="column-name-wrapper">
        {isAdmin && isCustomBoard ? (
          <input
            type="text"
            className="column-name column-name--input"
            value={columnName}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        ) :
        (
          <h1 className="column-name">
            {title}
          </h1>
        )
        }
        {count !== 0 && (
          <span className="column-name__count">{count}</span>
        )}
      </div>
    );
  }
}

export default ColumnName;
