import * as React from 'react';
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

import './UserMenu.scss';
import {BoardUsers, ModalType} from '../../types';
import UserList from "../UserList";
import SignOutModal from "../Modal/variant/SignOutModal";
import {Link} from "react-router-dom";

export interface UserMenuProps {
  onSignOut: () => void;
  completed?: boolean;
  onOpenModal: (modal: ModalType) => void;
  onChangeBoardName?: (boardName: string) => void;
  onSetTimer?: (seconds: number) => void;
  boardName?: string;
  admin?: boolean;
  isBoard?: boolean;
  currentUserId: string;
  users: BoardUsers;
}

export interface UserMenuState {
  isOpen: boolean;
  isSignOutOpen: boolean;
}

export class UserMenu extends React.Component<UserMenuProps, UserMenuState> {
  constructor(props: UserMenuProps) {
    super(props);
    this.state = {
      isOpen: false,
      isSignOutOpen: false
    };
  }

  closeUserMenu = () => {
    this.setState({ isOpen: false });
  };

  toggleUserMenu = () => {
    this.setState(state => ({ ...state, isOpen: !state.isOpen }));
  };

  closeSignOut = () => {
    this.setState({ isSignOutOpen: false })
  };

  toggleSignOut = () => {
    this.setState({ isSignOutOpen: !this.state.isSignOutOpen });
  };

  render() {
    const { isOpen, isSignOutOpen } = this.state;
    const {
      onSignOut,
      currentUserId,
      users
    } = this.props;

    const toggleIcon = (
      <div
        className="user-menu__toggle-button"
        onClick={this.toggleUserMenu}
      >
        <UserList
          currentUserId={currentUserId}
          users={users}
        />
      </div>
    );

    const ddMenuProps = {
      isOpen,
      close: this.closeUserMenu,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: false
    };

    return (
      <DropdownMenu
        {...ddMenuProps}
        className="header__toggle-btn"
      >
        <li
          key="user-menu__account"
          className="header__menu-item"
        >
          <Link
            to="/account"
            className="header__menu-link"
          >
            Account
          </Link>
        </li>
        <li
          key="user-menu__logout"
          className="header__menu-item"
          onClick={this.toggleSignOut}
        >
          Sign Out
        </li>

        {isSignOutOpen && (
          <SignOutModal
            onClose={this.closeSignOut}
            onSignOut={onSignOut}
          />
        )}
      </DropdownMenu>
    );
  }
}

export default UserMenu;
