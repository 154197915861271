import { getFirebase, getVal } from 'react-redux-firebase';
import * as Raven from 'raven-js';

import { Card, StoreState } from '../../types';
import { OwnAddCardProps, StateAddCardProps } from './AddCard';
import { ColumnType } from '../../constants/Retrospective';

export const mapStateToProps = (
  store: StoreState,
  ownProps: OwnAddCardProps
): StateAddCardProps => {
  const firebase = getFirebase();

  // TODO: Currently the third parameter, timestamp, is needed for testing.
  // Can this be done by mocking the `Date.now` method in Jest?
  function onAddCard(
    type: string,
    theme: ColumnType,
    value: string,
    timestamp?: string
  ) {
    if (value.length === 0) {
      return;
    }
    const { currentUser: user } = getFirebase().auth();
    if (!user) {
      return;
    }

    const cards = getVal(store.fbState, `data/cards`, {});
    let order = -1;
    for(let k in cards) {
      if(cards[k] && cards[k].order > order) {
        order = cards[k].order;
      }
    }
    order++;

    const authorName = user.displayName;
    const authorImage = user.photoURL;
    const card: Card = {
      authorUid: user.uid,
      author: authorName,
      photoUrl: authorImage,
      text: value,
      type,
      theme,
      votes: 0,
      order: order,
      timestamp: timestamp || new Date().toJSON(),
      userVotes: {
        [user.uid]: 0
      }
    };

    firebase
      .firestore()
      .collection('boards')
      .doc(ownProps.boardId)
      .collection('cards')
      .add(card)
      .catch((err: Error) => {
        Raven.captureMessage('Could not create new card', {
          extra: {
            reason: err.message,
            uid: user.uid,
            boardId: ownProps.boardId
          }
        });
      });
  }

  return {
    onAdd: onAddCard
  };
};
