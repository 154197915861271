import * as React from 'react';
import { Component } from 'react';

import './DeleteModal.scss';
import Portal from '../../Portal';

export interface SwitchAccountModalProps {
  onClose: () => void;
  onSignOut: () => void;
}

class SwitchAccountModal extends Component<SwitchAccountModalProps, {}> {
  render() {
    return (
      <Portal
        onClose={this.props.onClose}
        showCloseButton={false}
      >
        <div className="modal__content-wrapper">
          <div className="modal__content delete-modal">
            <div
              className="modal__close-button"
              onClick={this.props.onClose}
            />
            <div className="delete-modal__message">
              <h2 className="delete-modal__title">Sign out and log-in via another method?</h2>
            </div>
            <div className="delete-modal-button__buttons">
              <button
                className="delete-modal-button delete-modal-button__danger"
                onClick={this.props.onSignOut}
              >
                SIGN OUT
              </button>
              <button
                className="delete-modal-button delete-modal-button__cancel"
                onClick={this.props.onClose}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default SwitchAccountModal;
