import * as React from "react";
import { Component } from 'react';
import { connect } from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import * as cx from 'classnames';

import './TeamInviteUsers.scss'

import { mapStateToProps } from "./TeamInviteUsers.container";
import {getFirebase} from "react-redux-firebase";
import {analytics} from "../../index";

export interface TeamInviteUsersOwnProps {
  teamId: string;
  isCreator: boolean;
}

export interface TeamInviteUsersStateProps {
  sendInviteMails: (emails: string, hasAdminRights: boolean) => void;
}

export interface TeamInviteUsersState {
  emails: string;
  message: string;
  hasAdminRights: boolean;
  error: boolean;
}

export type TeamInviteUsersProps = TeamInviteUsersOwnProps & TeamInviteUsersStateProps;

class TeamInviteUsers extends Component<TeamInviteUsersProps, TeamInviteUsersState> {
  constructor(props: TeamInviteUsersProps) {
    super(props);
    this.state = {
      emails: '',
      message: '',
      hasAdminRights: false,
      error: false
    }
  }

  onToggleAdminRight = () => {
    this.setState({ hasAdminRights: !this.state.hasAdminRights });
  };

  onChangeEmails = (e: any) => {
    this.setState({ emails: e.target.value });
  };

  validation = (email: string): boolean => {
    const pattern = new RegExp(/^([\w-+.]+@([\w-]+\.)+[\w-]{2,20})$/);
    return pattern.test(email);
  };

  onBlurEmails = async (e: any) => {
    const emails: string = e.target.value;
    try {
      let isValid: boolean = true;
      await emails.split(',').map((email: string, index: number) => {
        const emailTrim = email.trim();
        if(!this.validation(emailTrim)) {
          isValid = false
        }
      });

      if(!isValid) {
        this.setState({ error: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  onFocusEmails = () => {
    this.setState({ error: false });
  };

  onKeyUpTextArea = (e: any) => {
    const target = e.target;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`
  };

  onSendMail = async (e: any) => {
    const target = e.target;
    const { emails, error, hasAdminRights } = this.state;
    target.disabled = true;
    if(!error ) {
      try {
        let isValid: boolean = true;
        const emailArray: string[] = [];
        await emails.split(',').map((email: string, index: number) => {
          const emailTrim = email.trim();
          if(!this.validation(emailTrim)) {
            isValid = false;
          } else {
            emailArray.push(emailTrim);
          }
        });

        if(!isValid) {
          target.disabled = false;
          this.setState({ error: true });
        } else {
          const user = getFirebase().auth().currentUser;
          if(user) {
            this.setState({ message: 'Please wait...' }, () => {
              const { teamId } = this.props;
              fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendInvites` , {
                method: 'POST',
                body: JSON.stringify({ userIds: emailArray, uid: user.uid, hasAdmin: hasAdminRights, teamId: teamId })
              })
                .then(res => res.json())
                .then((data) => {
                  if(data.body) {
                    const body = JSON.parse(data.body);
                    if(body.success) {
                      analytics.logEvent('invite_to_team');
                      this.setState({ message: 'Email invitation is successfully sent', emails: '' });
                      target.disabled = false;
                    } else {
                      this.setState({ message: body.error });
                      target.disabled = false;
                    }
                  } else {
                    this.setState({ message: 'Failed to sent invitations' });
                    target.disabled = false;
                  }
                })
                .catch((e) => {
                  console.log(e);
                  this.setState({ message: 'Failed to sent invitations' });
                  target.disabled = false;
                })
            });
          }
        }
      } catch (e) {
        target.disabled = false;
        console.log(e);
      }
    } else {
      target.disabled = false;
    }
  };

  render() {
    const { hasAdminRights, message, emails, error } = this.state;
    const { isCreator } = this.props;
    return(
      <div className="side-modal__box team-invite">
        <h3 className="side-modal-box__title">INVITE BY EMAIL</h3>
        {isCreator &&
          <FormGroup>
            <FormControlLabel
              label="Admin"
              labelPlacement='start'
              className="settings__checkbox team-invite__checkbox"
              control={
                <Switch
                  size="small"
                  checked={hasAdminRights}
                  onChange={this.onToggleAdminRight}
                />
              }
            />
          </FormGroup>
        }
        <div className="team-invite-input">
          <textarea
            placeholder="Enter emails separated by comma (,)"
            className={cx("team-invite-input__textarea", {"team-invite-input__textarea--error": error })}
            value={emails}
            onChange={this.onChangeEmails}
            onFocus={this.onFocusEmails}
            onBlur={this.onBlurEmails}
            onKeyUp={this.onKeyUpTextArea}
          />
        </div>
        <button
          className="teams-button--primary"
          onClick={this.onSendMail}
        >
          SEND
        </button>
        <div className="team-invite__message">{!!message ? message : error ? 'Invalid emails input' : 'Invited users will get an email with signup link'}</div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(TeamInviteUsers);
