import * as React from "react";
import { Component } from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import * as cx from 'classnames';
import './PersonalInfo.scss';
import {AccountData} from "../Account";
import InputAdornment from "@material-ui/core/InputAdornment";

export interface PersonalInfoState {
  name: string;
  email: string;
  nameError: boolean;
  emailError: boolean;
  isLoadedName: boolean;
  isLoadedEmail: boolean;
  isSubmited: boolean;
  nameFocused: boolean;
  emailFocused: boolean;
}

export interface PersonalInfoOwnProps {
  userName: string;
  userEmail: string;
  onChangeData: (username: AccountData) => void;
  isAnonymous: boolean;
}

class PersonalInfo extends Component<PersonalInfoOwnProps, PersonalInfoState> {
  constructor(props: PersonalInfoOwnProps) {
    super(props);
    this.state = {
      name: this.props.userName,
      email: this.props.userEmail,
      nameError: false,
      emailError: false,
      isLoadedName: false,
      isLoadedEmail: false,
      isSubmited: false,
      nameFocused: false,
      emailFocused: false
    }
  }

  static getDerivedStateFromProps(nextProps: PersonalInfoOwnProps, prevState: PersonalInfoState) {
    if(!prevState.isLoadedName && nextProps.userName ) {
      return {
        name: nextProps.userName,
        isLoadedName: true
      }
    } else if(!prevState.isLoadedEmail && nextProps.userEmail) {
      return {
        email: nextProps.userEmail,
        isLoadedEmail: true
      }
    } else {
      return true;
    }
  }

  validation = (email: string): boolean => {
    const pattern = new RegExp(/^([\w-+.]+@([\w-]+\.)+[\w-]{2,20})$/);
    return pattern.test(email);
  };

  onBlurName = (e: any) => {
    if(!e.target.value) {
      this.setState({
        nameError: true,
      });
    }

    this.setState({
      nameFocused: false
    });
  };

  onFocusName = () => {
    this.setState({
      nameError: false,
      isSubmited: false,
      nameFocused: true
    });
  };

  onBlurEmail = () => {
    const { email } = this.state;
    if(!this.validation(email)) {
      this.setState({
        emailError: true,
      });
    }
    this.setState({
      emailFocused: false
    });
  };

  onFocusEmail = () => {
    this.setState({
      emailError: false,
      isSubmited: false,
      emailFocused: true
    });
  };

  onChangeName = (e: any) => {
    const limit = process.env.REACT_APP_USERNAME_LIMIT ? process.env.REACT_APP_USERNAME_LIMIT : 40;
    if(e.target.value.length <= limit) {
      this.setState({ name: e.target.value })
    }
  };

  onChangeEmail = (e: any) => {
    this.setState({ email: e.target.value });
  };

  onSubmit = async (e: any) => {
    e.preventDefault();
    e.target.disabled = true;
    const el = e.target;
    const { name, email } = this.state;
    const { isAnonymous } = this.props;
    let isValid: boolean = true;
    if(!this.validation(email) && !isAnonymous) {
      isValid = false;
      this.setState({ emailError: true });
    }
    if(!name) {
      isValid = false;
      this.setState({ nameError: true });
    }

    if(isValid) {
      await this.props.onChangeData({
        username: name,
        email: email
      });
      this.setState({ isSubmited: true });
      el.disabled = false;
    } else {
      e.target.disabled = false;
    }
  };

  clearName = () => {
    this.setState({ name: '' });
  };

  clearEmail = () => {
    this.setState({ email: '' });
  };

  render() {
    const {
      name,
      email,
      nameError,
      emailError,
      isSubmited,
      nameFocused,
      emailFocused
    } = this.state;

    const { isAnonymous } = this.props;

    return(
      <div className="account-tabs-content-container">
        <div className="account-tabs-content">
          <h2 className="account-content__title">Account</h2>
          <div className="account-content">
            <div className="char-remaining">{name.length} / {process.env.REACT_APP_USERNAME_LIMIT}</div>
            <TextField
              label="Your Name"
              value={name}
              error={nameError}
              helperText={nameError && 'Please enter your name'}
              onChange={this.onChangeName}
              onBlur={this.onBlurName}
              onFocus={this.onFocusName}
              className="account__input account__input--first"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {name && (
                      <div
                        className={cx('account__input-clear', {
                          'account__input-clear--focused': nameFocused
                        })}
                        onClick={this.clearName}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {!isAnonymous &&
              <TextField
                label="Email"
                value={email}
                error={emailError}
                helperText={emailError && 'Invalid email format'}
                onChange={this.onChangeEmail}
                onBlur={this.onBlurEmail}
                onFocus={this.onFocusEmail}
                className="account__input account__input--last"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {email && (
                        <div
                          className={cx('account__input-clear', {
                            'account__input-clear--focused': emailFocused
                          })}
                          onClick={this.clearEmail}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            }
          </div>
          <button
            className="account__button"
            onClick={this.onSubmit}
            id="accountButton"
          >
            SAVE
          </button>
          <div
            className={cx(`account-update-message`, {'account-update-message--active': isSubmited})}
          >
            Settings saved
          </div>
        </div>
      </div>
    )
  }
}

export default PersonalInfo;
