import * as React from "react";

export const icons = {
  sort_arrow_up: (props) => (
    <svg
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.6"
      >
        <path
          d="M3.4001155,5.20015401 C3.73142256,5.64189675 4.26622999,5.64502668 4.5998845,5.20015401 L6.4001155,2.79984599 C6.73142256,2.35810325 6.54902482,2 6.00922203,2 L1.99077797,2 C1.44358641,2 1.26622999,2.35497332 1.5998845,2.79984599 L3.4001155,5.20015401 Z"
          fill={props.fill}
          transform="translate(4.000000, 4.000000) scale(1, -1) translate(-4.000000, -4.000000) "
        />
      </g>
    </svg>
  ),

  sort_arrow_down: (props) => (
    <svg
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.6"
      >
        <path
          d="M3.4001155,5.20015401 C3.73142256,5.64189675 4.26622999,5.64502668 4.5998845,5.20015401 L6.4001155,2.79984599 C6.73142256,2.35810325 6.54902482,2 6.00922203,2 L1.99077797,2 C1.44358641,2 1.26622999,2.35497332 1.5998845,2.79984599 L3.4001155,5.20015401 Z"
          fill={props.fill}
        />
      </g>
    </svg>
  ),
};

export function sortArray(arr, sortKey = null, order = "asc") {
  const sign = order === "asc" ? 1 : -1;

  return arr.sort((a, b) => {
    let elementA = a;
    let elementB = b;

    if (
      (typeof elementA === "object" && !Array.isArray(elementA)) ||
      (typeof elementB === "object" && !Array.isArray(elementB))
    ) {
      elementA = a[sortKey];
      elementB = b[sortKey];
    }

    if (elementA > elementB) return 1 * sign;
    else if (elementA < elementB) return -1 * sign;
    else return 0;
  });
}
