import * as React from 'react';
import classNames = require('classnames');
import './MinusCircle.scss';
import Circle from '../Circle';

export interface MinusCircleProps {
  svgClassName?: string;
  circleClassName?: string;
  minusLineClassName?: string;
}

export const MinusCircle: React.FunctionComponent<MinusCircleProps> = ({
   svgClassName,
   circleClassName,
   minusLineClassName
 }) => (
  <Circle svgClassName={svgClassName} circleClassName={circleClassName}>
    <line
      className={classNames('plus-circle__plus-line', minusLineClassName)}
      x1="9"
      x2="23"
      y1="16"
      y2="16"
    />
  </Circle>
);

export default MinusCircle;
