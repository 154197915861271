import * as React from "react";
import { Component } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase, isLoaded, ReduxFirestoreQueries } from "react-redux-firebase";

import './TeamManager.scss';
import SideModal from "../../../../components/SideModal";
import TeamCreate from "../../../../components/SideModal/variant/TeamSides/TeamCreate";
import { Teams } from "../../../../types";
import { mapStateToProps } from "./TeamManager.container";
import TeamList from "../../../../components/TeamList";
import InfoModal from "../../../../components/Modal/variant/InfoModal";

interface TeamBasicInfo {
  name: string | undefined;
}

export interface TeamManagerState {
  isOpenSide: boolean;
  openedSide: boolean;
  removedFromTeam: TeamBasicInfo;
}

export interface TeamManagerStateProps {
  teams: Teams;
  createTeam: (name: string) => void;
  leaveTeam: (teamId: string, userId: string) => void;
  toggleAdmin: (teamId: string, userId: string) => void;
}

export interface TeamManagerOwnProps {
  openLoading: (status?: string) => Promise<void>;
  closeLoading: () => void;
  openModal: () => void;
  paymentScreen: (e: any) => void;
  onCreateTeam: () => void;
  userUID: string;
  createdTeam: boolean;
  openSide: boolean;
}

export type TeamManagerProps = TeamManagerStateProps & TeamManagerOwnProps;

class TeamManager extends Component<TeamManagerProps, TeamManagerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpenSide: false,
      openedSide: false,
      removedFromTeam: { name: undefined },
    }
  }

  onScroll = () => {
    const tabs = document.getElementById('tabs');
    if(tabs) {
      if(window.pageYOffset >= 89) {
        if(!tabs.classList.contains('account-tabs--fixed')) {
          tabs.classList.add('account-tabs--fixed');
        }
      } else {
        if(tabs.classList.contains('account-tabs--fixed')) {
          tabs.classList.remove('account-tabs--fixed');
        }
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.add('account-portal');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.remove('account-portal');
    }
  }

  componentWillReceiveProps (newProps: TeamManagerStateProps) {
    if(newProps.teams !== this.props.teams) {
      if (this.props.teams) {
        const oldTeams = Object.keys(this.props.teams)
        const removedTeam = oldTeams.reduce((acc: any, curr: any) => {
          if(!newProps.teams || !newProps.teams[curr]) {
            return this.props.teams[curr]
          }

          return acc
        }, {})

        if (removedTeam.name) {
          const portal = document.getElementById('portal');
          if(portal) {
            portal.classList.add('account-portal--active');
          }
          this.setState({ removedFromTeam: removedTeam })
        }
      }
    }
  }

  openSide = () => {
    this.setState({ isOpenSide: true });
  };

  closeSide = () => {
    this.setState({ isOpenSide: false });
  };

  closeRemovedFromTeam = () => {
    this.setState({ removedFromTeam: { name: undefined } }, () => {
      const portal = document.getElementById('portal');
      if(portal) {
        portal.classList.remove('account-portal--active');
      }
    })
  }

  componentDidUpdate(prevProps: Readonly<TeamManagerProps>, prevState: Readonly<TeamManagerState>): void {
    const { openSide, createdTeam } = this.props;
    if(openSide && !this.state.isOpenSide && !this.state.openedSide && !createdTeam) {
      this.setState({ isOpenSide: true, openedSide: true })
    }
  }

  render() {
    const {  isOpenSide, removedFromTeam } = this.state;
    const { teams, createTeam } = this.props;
    const plusIcon = require('!svg-inline-loader!../../../../assets/icon-14-plus.svg');

    return (
      <div
        className='teams'
      >
        <div className="teams-heading">
          <h2
            className="account-content__title"
          >
            Team management
          </h2>
        </div>
        {removedFromTeam.name &&
          <InfoModal
            onClose={this.closeRemovedFromTeam}
            onSubmit={this.closeRemovedFromTeam}
            description={`You have been removed from team ${removedFromTeam.name}.`}
            title="You have been removed"
            buttonText="OK"
            classNames="account-modal"
          />
        }

        <div className="teams-content">
          {isLoaded(teams) ?
            teams ?
              <TeamList
                teams={teams}
                openLoading={this.props.openLoading}
                closeLoading={this.props.closeLoading}
                paymentScreen={this.props.paymentScreen}
                leaveTeam={this.props.leaveTeam}
                toggleAdmin={this.props.toggleAdmin}
                userUID={this.props.userUID}
              />
              :
              <div className="teams-content__empty">
                <p>There is no team associated with your account.</p>
                <p>Go ahead and create your first one.</p>
              </div>
             :
            <div className="teams-content__empty">
              <p>Loading teams</p>
            </div>
          }


        </div>
        <div className="teams-create">
          <button
            className="teams-create__button"
            onClick={this.openSide}
          >
            <span
              className="teams-create__icon"
              dangerouslySetInnerHTML={{ __html: plusIcon }}
            />
            Create team
          </button>
        </div>

        <SideModal
          title="Create team"
          isOpen={isOpenSide}
          onClose={this.closeSide}
          className="teams-sidebar"
        >
          <TeamCreate
            createTeam={createTeam}
          />
        </SideModal>
      </div>
    )
  }
}

function firestoreConnector(): ReduxFirestoreQueries {
  const user = getFirebase().auth().currentUser;
  let uid: string = '';
  if(user) {
    uid = user.uid;
  }

  return [
    {
      collection: 'teams',
      where: [['usersArray', 'array-contains', uid]],
      orderBy: ['lastUpdatedAt', 'desc'],
      storeAs: 'teams'
    }
  ]
}

// @ts-ignore
export default compose<any, any, any>(firestoreConnect(firestoreConnector),connect(mapStateToProps))(TeamManager);
// @ts-ignore
