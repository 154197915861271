import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {firestoreConnect, ReduxFirestoreQueries} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { getFirebase } from 'react-redux-firebase';
import * as cx from 'classnames';

import {UserBoard, UserTeams} from '../../types';
import { mapStateToProps } from './NewBoard.container';
import './NewBoard.scss';
import { RetroMode } from '../../constants/mode';
import { DEFAULT_RETRO_MODE } from '../../constants/Retrospective';
import StartButton from '../../components/StartButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import HeaderDashboard from '../../components/HeaderDashboard/HeaderDashboard';
import { Link } from 'react-router-dom';
import CardBoard from '../../components/CardBoard';
import moment = require('moment');
import PlanExceededModal from "../../components/Modal/variant/PlanExceededModal";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import TeamNamesList from "../../components/TeamNamesList";
import CreateTeamLink from "./components/CreateTeamLink";
import { analytics } from "../../index";

let now = moment().format('MMMM Do YYYY');

export type OwnNewBoardProps = RouteComponentProps<{}>;

interface description {
  title: string;
  message: string;
}

interface AvailableModes {
  [key: string]: {
    label: string;
    structure: string[];
    descriptions: description[];
  };
}

export interface StateNewBoardProps {
  uid: string | null;
  boards: UserBoard[];
  userTeams: UserTeams | undefined;
}

export type NewBoardProps = OwnNewBoardProps & StateNewBoardProps;

export interface NewBoardState {
  boardName: string;
  email: string;
  secure: 'public' | 'private' | 'team';
  errorEmail: boolean;
  errorCustom: boolean[];
  errorBoardName: boolean;
  isOpenSide: boolean;
  selectedMode: RetroMode;
  availableModes: AvailableModes,
  customColumn: string;
  errorCustomColumn: boolean;
  planExceeded: '' | 'boards' | 'time';
  addAction: boolean;
  isLoading: boolean;
  loadingStatus: string;
  teamId: string | undefined;
}

export class NewBoard extends Component<NewBoardProps, NewBoardState> {

  constructor(props: NewBoardProps) {
    super(props);

    this.state = {
      boardName: `Retrospective ${now}`,
      email: '',
      secure: 'public',
      errorEmail: false,
      errorBoardName: false,
      selectedMode: DEFAULT_RETRO_MODE,
      isOpenSide: false,
      availableModes: {},
      customColumn: '',
      errorCustomColumn: false,
      errorCustom: [],
      planExceeded: '',
      addAction: false,
      isLoading: false,
      loadingStatus: '',
      teamId: undefined
    };
  }

  componentDidMount() {
    const availableModes: AvailableModes = {
      wellImproveAction: {
        label: 'Went Well, Improve, Action',
        structure: [
          'What went well',
          "What didn't go well",
          'What needs to change'
        ],
        descriptions: [
          {
            title: 'Went Well',
            message:
              'Celebrate and focus on success of what people are proud of'
          },
          {
            title: 'Improve',
            message: 'Identify areas for improvement and gaps in processes'
          },
          {
            title: 'Action',
            message:
              'List out forward-looking perspective and encourage the team to problem solve'
          }
        ]
      },
      lean: {
        label: 'Lean Coffee',
        structure: ['Lean Coffee'],
        descriptions: [
          { title: 'Lean Coffee', message: 'is a structured, but agenda-less meeting. Participants gather, build an agenda, and begin talking.' },
          { title: 'When to use', message: 'Have an open ended conversation that is entirely owned and directed by the team' },
        ]
      },
      positiveNegative: {
        label: 'Simple Retro (Delta+)',
        structure: ['Plus', 'Delta', 'Actions'],
        descriptions: [
          { title: 'Pros', message: 'Argument in support of a decision' },
          { title: 'Cons', message: 'Argument against a decision' },
          {
            title: 'Action',
            message:
              'Create action Items the team can complete in order to improve'
          }
        ]
      },
      startStopContinue: {
        label: 'Start, Stop, Continue',
        structure: ['Start', 'Stop', 'Continue'],
        descriptions: [
          {
            title: 'Start',
            message:
              'Ideas that the team should be working on but are not doing. Ideas that are innovative and could address current problems.'
          },
          {
            title: 'Stop',
            message:
              'Ideas that are not delivering expected results and are not productive.'
          },
          {
            title: 'Continue',
            message:
              'Ideas that are creating value and should not be dismissed and bring positive results. '
          },
          {
            title: 'Action',
            message:
              'Create action Items the team can complete in order to improve'
          }
        ]
      },
      madSadGlad: {
        label: 'Mad, Sad, Glad',
        structure: ['Mad', 'Sad', 'Glad', 'Actions'],
        descriptions: [
          {
            title: 'Mad',
            message: 'Things that are stopping you from performing at your best'
          },
          {
            title: 'Sad',
            message: 'Things that disappoint you or you wish you could improve'
          },
          {
            title: 'Glad',
            message:
              'Things that makes you happy when you think about this project, what do you enjoy about the project'
          },
          {
            title: 'Action',
            message: 'List the things that can be done to keep you excited'
          }
        ]
      },
      kalm: {
        label: 'KALM (Keep, Add, Less, More)',
        structure: ['Keep', 'Add', 'Less', 'More'],
        descriptions: [
          {
            title: 'Keep',
            message:
              'Things that provide value to your team that you want to keep doing'
          },
          {
            title: 'Add',
            message:
              'New ideas, practices, or something that can increase value to your team'
          },
          {
            title: 'Less',
            message:
              "Things that doesn't provide enough value you would rather do less of"
          },
          {
            title: 'More',
            message: 'Things that can increase value if you do more of'
          },
          {
            title: 'Action',
            message:
              'Create action Items the team can complete in order to improve'
          }
        ]
      },
      plusDelta: {
        label: 'Plus & Delta',
        structure: ['Plus', 'Delta', 'Actions'],
        descriptions: [
          {
            title: 'Thumbs Up',
            message: 'Things you like and positive experiences'
          },
          {
            title: 'Thumbs Down',
            message: 'Things you dislike that could be better'
          },
          {
            title: 'New Idea',
            message: 'New things you want to explore that might increase value'
          },
          {
            title: 'Action',
            message:
              'Create action Items the team can complete in order to improve'
          }
        ]
      },
      fourL: {
        label: '4L (Liked, Learned, Lacked, Longed for)',
        structure: ['Liked', 'Learned', 'Lacked', 'Longed for'],
        descriptions: [
          { title: 'Liked', message: 'What did the team really enjoy, what went better than expected? Emphasize the positive.' },
          { title: 'Learned', message: 'What new things did the team learn?' },
          {
            title: 'Lacked',
            message: 'What things could the team have done better?'
          },
          {
            title: 'Longed For',
            message:
              'What things did the team desire to have that were unavailable?'
          },
          {
            title: 'Action',
            message:
              'Create action Items the team can complete in order to improve'
          }
        ]
      },
      custom: {
        label: 'Custom',
        structure: [],
        descriptions: []
      }
    };

    let displayName = '';
    const currentUser = getFirebase().auth().currentUser;
    if(currentUser) {
      if(currentUser.displayName) {
        displayName = currentUser.displayName;
      }
    }

    if(this.props.location.search === '?upgradePlan=true') {
      this.setState({ planExceeded: 'boards' });
    }

    this.setState({
      availableModes: availableModes,
      email: displayName
    })
  }

  componentDidUpdate() {
    const { secure } = this.state;
    const portal = document.querySelector('#portal.active');
    if (portal) {
      portal.addEventListener('click', () => {
        this.setState({ isOpenSide: false }, () => {
          portal.classList.remove('active');
          const {
            customColumn,
            availableModes,
            selectedMode
          } = this.state;
          if(selectedMode === 'custom' && customColumn) {
            availableModes.custom.structure.push(customColumn);
            availableModes.custom.descriptions.push({ title: customColumn, message: ''});
            this.setState({ customColumn: '', availableModes: { ...availableModes }});
          }
        });
      });
    }

    if(this.props.location.search.includes('?teamId=') && secure !== 'team') {
      const teamId: string = this.props.location.search.slice(8);
      const { userTeams } = this.props;
      if(!!userTeams && !!userTeams[teamId]) {
        this.setState({ secure: 'team', teamId: teamId })
      }
    }
  }

  handleChangeEmail = (e: any) => {
    const email = (e.target as HTMLInputElement).value;
    const limit = process.env.REACT_APP_USERNAME_LIMIT ? process.env.REACT_APP_USERNAME_LIMIT : 40;
    if(email.length <= limit) {
      this.setState(state => ({
        ...state,
        email,
        errorEmail: /^\s/.test(email)
      }));
    }
  };

  handleBlurEmail = () => {
    const { email } = this.state;
    if (!email) {
      this.setState({ errorEmail: true });
    }
  };

  closePlanExceed = () => {
    this.setState({ planExceeded: '' });
  };

  handleCreateBoard = (e: any) => {
    const el = e;
    el.disabled = true;

    const createBoard = (custom?: any) => {
      const { uid } = this.props;
      const { selectedMode, secure, boardName, email, addAction, teamId } = this.state;
      if(uid && ((secure === 'team' && !!teamId) || secure === 'public' || secure === 'private')) {
        this.setState({ isLoading: true, loadingStatus: 'Creating Board'}, () => {
          this.onCloseSide();
          const team = secure === 'team' ? teamId : undefined;
          fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createBoard`, {
            method: 'POST',
            body: JSON.stringify({
              uid: uid,
              secure: secure !== 'public',
              mode: selectedMode,
              boardName: boardName,
              email: email,
              addAction: addAction,
              teamId: team,
              custom: custom
            })
          })
            .then((data) => data.json())
            .then((res) => {
              if(res.body) {
                const body = JSON.parse(res.body);
                if(res.statusCode === 200) {
                  location.hash = `/board/${body.boardId}`;
                  analytics.logEvent('create_retro', { mode: selectedMode, secure })
                } else if(res.statusCode === 400) {
                  this.setState({ loadingStatus: '', isLoading: false });
                } else if(res.statusCode === 401) {

                } else if(res.statusCode === 403) {
                  this.setState({ loadingStatus: '', isLoading: false }, () => {
                    if(body.reason === 'time') {
                      this.setState({ planExceeded: 'time' });
                    } else {
                      this.setState({ planExceeded: 'boards' });
                    }
                    el.disabled = false;
                  });
                } else {
                  this.setState({ loadingStatus: '', isLoading: false });
                }
              } else {
                this.setState({ loadingStatus: '', isLoading: false });
              }
            })
            .catch((e)=> {
              this.setState({ isLoading: false, loadingStatus: ''});
              console.log(e);
            });
        });
      }
    };

    const {
      errorBoardName,
      errorCustom,
      selectedMode,
      availableModes,
      customColumn,
    } = this.state;

    const isValidInputs = errorCustom.indexOf(true) === -1;
    let custom: any = {
      label: 'Custom',
      structure: [],
      descriptions: []
    };
    const description = [];
    const structure: string[] = [];

    description.push(...availableModes.custom.descriptions);
    structure.push(...availableModes.custom.structure);

    if(customColumn) {
      description.push({ title: customColumn, message: '' });
      structure.push(customColumn);
    }

    custom.structure = structure;
    custom.descriptions = description;

    if(selectedMode === 'custom') {
      if(!errorBoardName) {
        if(!availableModes.custom.structure.length) {
          if(!customColumn) {
            this.setState({ errorCustomColumn: true });
            el.disabled = false;
          } else {
            createBoard(custom);
          }
        } else {
          if(isValidInputs) {
            createBoard(custom);
          } else {
            el.disabled = false;
          }
        }
      } else {
        el.disabled = false;
      }
    } else {
      if(!errorBoardName) {
        createBoard();
      } else {
        el.disabled = false;
      }
    }

  };

  handleSelectMode = (mode: RetroMode) => {
    const portal = document.getElementById('portal');
    if (portal) {
      portal.classList.add('active');
    }
    this.setState({ selectedMode: mode, isOpenSide: true });
  };

  onCloseSide = () => {
    const portal = document.getElementById('portal');
    if (portal) {
      portal.classList.remove('active');
    }
    const {
      customColumn,
      availableModes,
      selectedMode
    } = this.state;
    if(selectedMode === 'custom' && customColumn) {
      availableModes.custom.structure.push(customColumn);
      availableModes.custom.descriptions.push({ title: customColumn, message: ''});
      this.setState({ customColumn: '', availableModes: { ...availableModes }});
    }
    this.setState({ isOpenSide: false });
  };

  onSecureBoard = (e: any) => {
    this.setState({ secure: e.target.value });
  };

  handleChangeCustomTemplate = (value: string, index: number) => {
    const { availableModes } = this.state;
    const { custom } = availableModes;
    let { structure, descriptions } = availableModes.custom;
    structure[index] = value;
    descriptions[index].title = value;

    this.setState({
      availableModes: {
        ...availableModes,
        custom: {
          ...custom,
          structure: [ ...structure ],
          descriptions: [ ...descriptions ]
        }
      }
    })
  };

  deleteCustomType = (index: number) => {
    const { availableModes, errorCustom } = this.state;
    if(availableModes) {
      const { custom } = availableModes;
      const { structure, descriptions } = custom;
      errorCustom.splice(index, 1);
      structure.splice(index, 1);
      descriptions.splice(index, 1);
      this.setState({
        availableModes: {
          ...availableModes,
          custom: {
            ...custom,
            structure: [ ...structure ],
            descriptions: [ ...descriptions ]
          }
        },
        errorCustom: errorCustom
      })
    }
  };

  handleBlurCustom = (e: any, index: number) => {
    if(!e.target.value) {
      const { errorCustom } = this.state;
      errorCustom[index] = true;
      this.setState({ errorCustom: errorCustom });
    }
  };

  handleFocusCustom = (e: any, index: number) => {
    const { errorCustom } = this.state;
    errorCustom[index] = false;
    this.setState({ errorCustom: errorCustom });
  };

  changeNewColumnType = (e: any) => {
    this.setState({
      customColumn: e.target.value,
    });
  };

  focusNewColumnType = () => {
    this.setState({
      errorCustomColumn: false
    })
  };

  submitNewColumnType = () => {
    const { customColumn, errorCustom } = this.state;
    if(customColumn) {
      const { availableModes } = this.state;
      const { custom } = availableModes;
      let { structure, descriptions } = custom;
      if(structure.length < 5) {
        structure.push(customColumn);
        descriptions.push({
          title: customColumn,
          message: ''
        });
        errorCustom.push(false);
        this.setState({
          availableModes: {
            ...availableModes,
            custom: {
              ...custom,
              structure: [ ...structure ],
              descriptions: [ ...descriptions ]
            }
          },
          customColumn: '',
          errorCustom: errorCustom
        })
      }
    } else {
      this.setState({ errorCustomColumn: true });
    }
  };

  clearNewColumnType = () => {
    const { customColumn } = this.state;
    if(customColumn) {
      this.setState({ customColumn: '' });
    }
  };

  handleChangeBoardName = (e: any) => {
    const limit = process.env.REACT_APP_BOARD_NAME_LIMIT ? process.env.REACT_APP_BOARD_NAME_LIMIT : 66;
    if(e.target.value.length <= limit) {
      this.setState({
        boardName: e.target.value,
        errorBoardName: /^\s/.test(e.target.value)
      });
    }
  };

  handleFocusBoardName = () => {
    this.setState({
      errorBoardName: false
    })
  };

  handleBlurBoardName = () => {
    const { boardName } = this.state;
    if (!boardName) {
      this.setState({ errorBoardName: true });
    }
  };

  handleAddAction = () => {
    const { addAction } = this.state;
    this.setState({ addAction: !addAction })
  };

  openLoading = async () => {
    this.setState({ isLoading: true, loadingStatus: '' });
  };

  closeLoading = async () => {
    this.setState({ isLoading: false, loadingStatus: '' });
  };

  selectTeam = (teamId: string) => {
    this.setState({ teamId: teamId });
  };

  render() {
    const {
      isOpenSide,
      selectedMode,
      boardName,
      errorBoardName,
      availableModes,
      customColumn,
      errorCustomColumn,
      planExceeded,
      addAction,
      errorCustom,
      isLoading,
      loadingStatus,
      secure,
      teamId
    } = this.state;
    const { boards, userTeams } = this.props;

    let isAnonymous: boolean = false;
    if(getFirebase().auth().currentUser) {
      isAnonymous = getFirebase().auth().currentUser!.isAnonymous;
    }

    const deleteIcon = require('!svg-inline-loader!../../assets/icon-16-20-gray-delete.svg');

    const message: string = planExceeded === 'time'
      ?
      'Your plan has been exceeded. Upgrade to add more boards.'
      :
      'You reached maximum of boards. Upgrade to add more boards.';

    if(isLoading) {
      return(
        <LoadingScreen
          status={loadingStatus}
        />
      )
    } else {
      return (
        <div className="new-board">

          <HeaderDashboard />

          {!!availableModes[selectedMode] && (
            <aside className={`new-board-side ${isOpenSide && 'show'}`}>
              <div className="new-board-side__container">
                <div className="new-board-side__box">
                <span
                  onClick={this.onCloseSide}
                  className="new-board-side__close"
                />
                  <h4 className="new-board-side__title">
                    {availableModes[selectedMode].label}
                  </h4>
                </div>

                <div className="new-board-side__box">
                  <div className="char-remaining">{boardName.length} / {process.env.REACT_APP_BOARD_NAME_LIMIT}</div>
                  <TextField
                    className="new-board-side__input"
                    label="Board Name"
                    value={boardName}
                    onBlur={this.handleBlurBoardName}
                    onChange={this.handleChangeBoardName}
                    onFocus={this.handleFocusBoardName}
                    helperText={errorBoardName && "Please enter board name"}
                    error={errorBoardName}
                  />
                  {isAnonymous && (
                    <>
                      <div className="char-remaining char-remaining--last">{this.state.email ? this.state.email.length : 0} / {process.env.REACT_APP_USERNAME_LIMIT}</div>
                      <TextField
                        className="new-board-side__input"
                        type="text"
                        label="Your Name"
                        onChange={this.handleChangeEmail}
                        onBlur={this.handleBlurEmail}
                        value={this.state.email}
                      />
                    </>
                  )}
                </div>

                <div className="new-board-side__box">
                  <h5 className="new-board-side__structure">
                    Structure
                    {selectedMode === 'custom' && (
                      <button
                        className="participant__button participant__button--add"
                        disabled={availableModes[selectedMode].structure.length >= 5}
                        onClick={this.submitNewColumnType}
                      />
                    )}
                  </h5>
                  <ul className="new-board-side__list">
                    {selectedMode !== 'custom' && availableModes[selectedMode].descriptions.map(
                      (item, index) => (
                        <li
                          key={index}
                          className={
                            index + 1 !==
                            availableModes[selectedMode].descriptions.length
                              ? 'new-board-side__list-item'
                              : ''
                          }
                        >
                          <b>{item.title}</b>
                          <br />
                          <span>{item.message}</span>
                        </li>
                      )
                    )}

                    {selectedMode === 'custom' && availableModes[selectedMode].descriptions.map((item, index) => (
                      <li
                        key={index}
                        className="new-board-side__custom new-board-side__custom--saved"
                      >
                        <TextField
                          type="text"
                          className="custom-retro__input"
                          value={item.title}
                          error={errorCustom[index]}
                          onBlur={(e: any) => this.handleBlurCustom(e, index)}
                          onFocus={(e: any) => this.handleFocusCustom(e, index)}
                          onChange={(e: any) => this.handleChangeCustomTemplate(e.target.value, index)}
                          placeholder="Add column name"
                          variant="outlined"
                        />

                        <div
                          className={cx('custom-retro__delete', {'custom-retro__delete--disabled': !item.title })}
                          dangerouslySetInnerHTML={{ __html: deleteIcon }}
                          onClick={() => this.deleteCustomType(index)}
                        />
                      </li>
                    ))}

                    {selectedMode === 'custom' && availableModes[selectedMode].structure.length < 6 && (
                      <li className="new-board-side__custom">
                        <TextField
                          type="text"
                          className="custom-retro__input"
                          placeholder="Add column name"
                          variant="outlined"
                          error={errorCustomColumn}
                          value={customColumn}
                          onChange={this.changeNewColumnType}
                          onKeyDown={(e: any) => {
                            if(e.keyCode === 13) {
                              this.submitNewColumnType();
                            }
                          }}
                          onFocus={this.focusNewColumnType}
                        />

                        <div
                          className={cx('custom-retro__delete', {'custom-retro__delete--disabled': !customColumn })}
                          dangerouslySetInnerHTML={{ __html: deleteIcon }}
                          onClick={this.clearNewColumnType}
                        />
                      </li>
                    )}

                    {selectedMode === 'custom'
                    &&
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleAddAction}
                          checked={addAction}
                          name="Action Column"
                          className="custom-retro__checkbox"
                        />
                      }
                      label='Add Action Column'
                    />
                    }
                  </ul>
                </div>

                <div className="new-board-side-box-container">
                  <div className="new-board-side__box">
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="secure"
                        value={this.state.secure}
                        onChange={this.onSecureBoard}
                      >
                        <div className="new-board-radio-wrapper">
                          <FormControlLabel
                            className="new-board__radio-label"
                            value="public"
                            control={<Radio />}
                            label="Public"
                          />
                          <div className="new-board-side__info-icon" />
                          <div className="secure-info">
                            <div className="secure-info-wrapper">
                              <h5 className="secure-info__title">Public</h5>
                              <div className="secure-info__description">
                                Anyone with a link can view
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="new-board-radio-wrapper">
                          <FormControlLabel
                            className="new-board__radio-label"
                            value="private"
                            control={<Radio />}
                            label="Private"
                          />
                          <div className="new-board-side__info-icon" />
                          <div className="secure-info secure-info__private">
                            <div className="secure-info-wrapper">
                              <h5 className="secure-info__title">Private</h5>
                              <div className="secure-info__description">
                                Encrypted and Only invited users can view link
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="new-board-radio-wrapper">
                          <FormControlLabel
                            className="new-board__radio-label"
                            value="team"
                            control={<Radio />}
                            label="Team"
                          />
                          <div className="new-board-side__info-icon" />
                          <div className="secure-info secure-info__private">
                            <div className="secure-info-wrapper">
                              <h5 className="secure-info__title">Team</h5>
                              <div className="secure-info__description">
                                Only team members can view link
                              </div>
                            </div>
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    {secure === 'team' && (!userTeams || !Object.keys(userTeams).length) && !isAnonymous &&
                      <CreateTeamLink />
                    }
                    {secure === 'team' && (!userTeams || !Object.keys(userTeams).length) && isAnonymous &&
                      <div className='new-board__team-creatiion--error'>
                        You need to sign up with an email/social to create teams.
                      </div>
                    }
                  </div>

                  {secure === 'team' && !!userTeams && !!Object.keys(userTeams).length &&
                    <TeamNamesList
                      userTeams={userTeams}
                      selectTeam={this.selectTeam}
                      selectedTeam={(teamId && !!userTeams) ? userTeams[teamId].name : undefined}
                    />
                  }
                </div>

                <StartButton onStart={this.handleCreateBoard} />
              </div>
            </aside>
          )}

          {
            !!planExceeded
            &&
            <PlanExceededModal
              uid={this.props.uid}
              type={planExceeded}
              message={message}
              onClose={this.closePlanExceed}
              openLoading={this.openLoading}
              closeLoading={this.closeLoading}
            />
          }

          <div className="container">
            {!!boards && !!boards.length && (
              <>
                <div className="row recent-boards">
                  <div className="col-sm-12">
                    <h2 className="recent-boards__title">Recent Boards</h2>
                    <Link className="recent-boards__link" to="/dashboard">
                      See all
                    </Link>
                  </div>
                </div>

                <div className="row">
                  {boards.map((item: UserBoard) => (
                    <div key={item.id} className="col-sm-6 col-md-4">
                      <CardBoard
                        board={item}
                        openLoading={this.openLoading}
                        closeLoading={this.closeLoading}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="row">
              <div className="col-sm-12">
                <h2 className="new-board-main__title">
                  Create new retrospective
                </h2>
              </div>
            </div>
            <div className="row">
              {Object.keys(availableModes).map((item: RetroMode) => (
                <div key={item} className="col-sm-6 col-md-4">
                  <div
                    className="new-board-mode"
                    onClick={() => this.handleSelectMode(item)}
                  >
                    <h5 className="new-board-mode__name">
                      <span>{availableModes[item].label}</span>
                    </h5>
                    <div className="new-board-mode-feature">
                      <ul className="new-board-mode__features">
                        {availableModes[item].structure.map(
                          (x: string, index: any) => (
                            <li key={index}>{x}</li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
}

function firebaseConnector(): ReduxFirestoreQueries {
  const firebase = getFirebase();
  const auth = firebase.auth();
  let uid: string | undefined = undefined;
  if (auth.currentUser) {
    uid = auth.currentUser.uid;
    return [
      {
        collection: 'users',
        doc: uid,
        subcollections: [{ collection: 'userBoards', doc: 'userBoards' }],
        storeAs: 'userBoards'
      },
      {
        collection: 'users',
        doc: uid,
        subcollections: [{ collection: 'userTeams', doc: 'userTeams' }],
        storeAs: 'userTeams'
      }
    ];
  } else {
    return []
  }
}

export default compose<any, any, any>(
  firestoreConnect(firebaseConnector),
  connect(mapStateToProps)
)(NewBoard);
