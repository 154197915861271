import * as React from "react";
import { Component } from "react";
import * as cx from 'classnames';

import {BoardUsers} from "../../../types";
import Avatar from "../../Avatar";
import './ParticipantsSideModal.scss';
import {UserPresence} from "../../../routes/Board";
import ReactTooltip from "react-tooltip";

export interface ParticipantsUser {
  id: string;
  name: string;
  photoUrl?: string;
  ready?: boolean
}

export interface ParticipantsSideModalProps {
  activeUsers: BoardUsers;
  uid: string;
  isAdmin: boolean;
  isPrivateBoard: boolean;
  pendingUsers: ParticipantsUser[];
  onChangeUserAcception: (uid: string, accept: boolean) => void;
  presence: UserPresence
}

class ParticipantsSideModal extends Component<ParticipantsSideModalProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      activeUsers,
      isAdmin,
      isPrivateBoard,
      uid,
      pendingUsers,
      presence
    } = this.props;
    let activeUsersArray: any = [];
    let sortedArray: any = [];
    if(activeUsers) {
      Object.keys(activeUsers).map((item) => {
        activeUsersArray.push({
          ...activeUsers[item],
          id: item
        })
      });
    }

    sortedArray.push(activeUsersArray.find((obj: any) => obj.id === uid));
    sortedArray.push(...activeUsersArray.filter((obj: any) => obj.id !== uid));

    const readyIcon = require('!svg-inline-loader!../../../assets/icon-5-3-checked.svg');

    return(
      <div className="participants-container">
        <div className="participants-box">
          {isAdmin && isPrivateBoard && (
            <h4 className="participants-box__title">ACTIVE ({activeUsersArray.length})</h4>
          )}
          <ul className="participants-list">
            {sortedArray.map((user: ParticipantsUser) =>
              (user
                &&
                <li
                  key={user.id}
                  className="participant"
                >
                  <div className="participant-info">
                    <div
                      className='participant-avatar'
                    >
                      <div
                        className={cx(`participant-avatar-container`, {'participant-avatar-container--active': !!presence && presence[user.id]})}
                        data-for={`avatar${user.id}`}
                        data-tip={!!presence && presence[user.id] ? 'Online' : 'Away'}
                      >
                        <Avatar
                          user={{
                            name: user.name,
                            photoUrl: user.photoUrl ? user.photoUrl : ''
                          }}
                        />
                        <ReactTooltip
                          id={`avatar${user.id}`}
                          place="bottom"
                          effect="solid"
                          isCapture={false}
                        />
                      </div>
                      {user.ready &&
                        <div className="participant-avatar-ready">
                          <div
                            className="participant-avatar-ready--checked"
                            dangerouslySetInnerHTML={{ __html: readyIcon }}
                          />
                        </div>
                      }
                    </div>

                    <div
                      className="participant__name"
                    >
                      {user.name}
                    </div>
                  </div>

                  {isAdmin && (
                    <button
                      className="participant__button"
                      disabled={uid === user.id}
                      onClick={() => this.props.onChangeUserAcception(user.id, false)}
                    />
                  )}
                </li>
              )
            )}
          </ul>
        </div>

        {isAdmin && !!pendingUsers.length && (
          <div className="participants-box participant-box--last">
            <h4 className="participants-box__title">PENDING ({pendingUsers.length})</h4>
            <ul className="participants-list">
              {pendingUsers.map((user: ParticipantsUser) =>
                <li
                  key={user.id}
                  className="participant"
                >
                  <div className="participant-info">
                    <Avatar
                      user={{
                        name: user.name,
                        photoUrl: user.photoUrl ? user.photoUrl : ''
                      }}
                    />
                    <div
                      className="participant__name"
                    >
                      {user.name}
                    </div>
                  </div>

                  {isAdmin && (
                    <button
                      className="participant__button participant__button--add"
                      onClick={() => this.props.onChangeUserAcception(user.id, true)}
                    />
                  )}

                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default ParticipantsSideModal;
