import * as React from 'react';
import './LockBanner.scss'

const LockBanner = (props: {}) => {
  return(
    <div className="lock-banner">
      <div className="lock-banner__content">
        Board is locked by Administrator
      </div>
    </div>
  )
};

export default LockBanner;
