import * as React from 'react';
import Button from '../../Button';

import './MembershipRequestModal.scss';

export interface MembershipRequestModalProps {
  onDeny?: () => void;
  onAccept?: () => void;
  member: {
    photo?: string;
    name: string;
  };
}

const MembershipRequestModal: React.SFC<MembershipRequestModalProps> = ({
  onDeny,
  onAccept,
  member,
}) => (
  <div className="membership-request-container">
    <div className="membership-request">
      <h5 className="membership-request__title">Grant access</h5>
      <div
        className="membership-request__close"
        onClick={onDeny}
      />
      <div className="membership-request__request-message">
        {member.photo && (
          <img
            src={member.photo}
            className="membership-request__member-photo"
          />
        )}
        <div className="membership-request__prompt">
          <span className="membership-request__member-name">{member.name}</span> wants to join this retrospective
        </div>
      </div>

      <div className="membership-request-modal__action-area">
        <Button onClick={onAccept} primary>
          Grant
        </Button>
      </div>
    </div>
  </div>
);

export default MembershipRequestModal;
