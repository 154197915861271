import { getFirebase } from 'react-redux-firebase';

import { StoreState } from '../../types';
import { LoginBoardProps } from './LoginBoard';

export function mapStateToProps(
  state: StoreState,
  ownProps: LoginBoardProps
): LoginBoardProps {
  const firebase = getFirebase() as any;

  let uid: string | null = null;
  const auth = firebase.auth();

  if (auth.currentUser) {
    uid = auth.currentUser.uid;
  }

  function subscribeAnonymousUser(uid: string, name: string) {
    console.log('ownProps.match.params', ownProps.match.params)
    if(uid) {
      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/subscribeToFree`, {
        method: 'POST',
        body: JSON.stringify({
          uid: uid,
          name: name,
          email: ''
        })
      })
        .then((data) => data.json())
        .then((res) => {
          if(res) {
            const body = JSON.parse(res.body);
            if(body.success) {
              location.hash =`/board/${(ownProps.match.params as any).id}`;
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      }
  }

  function onLogin(email: string) {
    if (!uid) {
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          firebase
            .auth()
            .currentUser
            .updateProfile({
              displayName: email
            })
            .then(() => {
              const userId: string = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : '';
              if (userId) {
                firebase
                  .firestore()
                  .collection('users')
                  .doc(userId)
                  .set({ name: email, uid: userId })
                  .then(() => {
                    subscribeAnonymousUser(userId!, email);
                  });
              }
            })
        });
    }
  }

  return {
    ...ownProps,
    uid,
    onLogin
  };
}
