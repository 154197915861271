import * as React from 'react';
import { Component } from 'react';
import { connect } from "react-redux";

const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;
const moment = require('moment');

import './CardBoard.scss';
import { UserBoard } from '../../types';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar';
import DeleteModal from "../Modal/variant/DeleteModal";
import { mapStateToProps } from './CardBoard.container';
import UserListModal from "../Modal/variant/UserListModal";
import ReactTooltip from "react-tooltip";
import {getFirebase} from "react-redux-firebase";

export interface CardBoardOwnProps {
  board: UserBoard;
  archiveBoard?: (boardId: string | undefined, isOwnBoard: boolean) => void;
  unarchiveBoard?: (boardId: string | undefined, isOwnBoard: boolean) => void;
  openLoading: () => Promise<void>;
  closeLoading: () => Promise<void>;
}

export interface CardBoardStateProps {
  toggleBoardSecure: (secure: boolean) => void;
}

export interface CardBoardState {
  isOpenDetails: boolean;
  showDelete: boolean;
  showUserList: boolean;
  updateTimes: any;
  timeText: string
}

export type CardBoardProps = CardBoardOwnProps & CardBoardStateProps;

class CardBoard extends Component<CardBoardProps, CardBoardState> {

  constructor(props: any) {
    super(props);
    this.state = {
      isOpenDetails: false,
      showDelete: false,
      showUserList: false,
      updateTimes: 0,
      timeText: ''
    };
  }

  componentDidMount() {
    this.onUpdateTime(this.props.board);
    const interval = setInterval(() => {
      this.onUpdateTime(this.props.board);
    }, 30000);
    this.setState({ updateTimes: interval });
  }

  componentWillUnmount() {
    clearInterval(this.state.updateTimes);
  }

  onUpdateTime = (board: UserBoard) => {
      const lastUpdated: number = board.lastUpdatedAt;
      const currentDate: number = new Date().getTime();
      let diffDate = Math.abs(lastUpdated - currentDate);

      let timeText: string = '';
      if(Math.ceil(diffDate / 1000) < 60) {
        diffDate = Math.ceil(diffDate / 1000);
        timeText = `${diffDate} seconds ago`;
      } else if(Math.ceil(diffDate / (1000)) < 60 * 60) {
        diffDate = Math.ceil(diffDate / (1000 * 60));
        timeText = `${diffDate} minutes ago`;
      } else if(Math.ceil(diffDate / (1000)) < 60 * 60 * 24) {
        diffDate = Math.ceil(diffDate / (1000 * 60 * 60));
        timeText = `${diffDate} hours ago`;
      } else if(moment(currentDate).diff(moment(lastUpdated), 'months', true) < 1) {
        diffDate = Math.ceil(diffDate / (1000 * 60 * 60 * 24));
        timeText = `${diffDate} days ago`;
      } else if(new Date(lastUpdated).getFullYear() === new Date(currentDate).getFullYear() && new Date(currentDate).getMonth() - new Date(lastUpdated).getMonth() <= 3 && new Date(currentDate).getMonth() > new Date(lastUpdated).getMonth()) {
        let monthDiff = new Date(currentDate).getMonth() - new Date(lastUpdated).getMonth();
        if(monthDiff === 1) {
          timeText = `${monthDiff} month ago`;
        } else {
          timeText = `${monthDiff} months ago`;
        }
      } else {
        timeText = moment(lastUpdated).format('MMM Do YYYY');
      }
      this.setState({ timeText: timeText });
  };

  onClose = () => {
    this.setState({ isOpenDetails: false });
  };

  onToggle = () => {
    this.setState({ isOpenDetails: !this.state.isOpenDetails });
  };

  onOpenDelete = () => {
    this.setState({ showDelete: true });
  };

  onCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  onOpenUserList = () => {
    this.setState({ showUserList: true });
  };

  onCloseUserList = () => {
    this.setState({ showUserList: false });
  };

  render() {
    const {
      board,
      archiveBoard,
      unarchiveBoard,
      openLoading,
      closeLoading
    } = this.props;
    const { showDelete, timeText, showUserList } = this.state;
    const auth = getFirebase().auth();
    let currentUserId = ''
    if(auth.currentUser) {
      currentUserId = auth.currentUser.uid;
    }

    let usersIds: any = [];
    if (board.users) {
      usersIds = Object.keys(board.users);
    }

    const toggleIcon = (
      <div className="card-dropdown__icon" onClick={this.onToggle}>
        <span>...</span>
      </div>
    );

    const ddMenuProps = {
      isOpen: this.state.isOpenDetails,
      close: this.onClose,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    const privateIcon = require(`!svg-inline-loader!../../assets/icon-10-12-lock.svg`);
    const publicIcon = require(`!svg-inline-loader!../../assets/icon-12-public.svg`);

    let secureIcon;
    if(!board.secure) {
      secureIcon = require('!svg-inline-loader!../../assets/icon-12-15-private.svg');
    } else {
      secureIcon = require('!svg-inline-loader!../../assets/icon-12-16-open-lock.svg');
    }

    const plusIcon = require('!svg-inline-loader!../../assets/icon-14-plus.svg');

    const uid: string = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : '';

    const isCreator: boolean = uid === board.creatorUid;

    return (
      <div className="dashboard-board">
        <div className="dashboard-board-container">
          {isCreator ?
            <DropdownMenu {...ddMenuProps} className="card-dropdown">
              {!board.teamId && (
                <li
                  className="dashboard-board__user-list"
                  onClick={() => this.props.toggleBoardSecure(board.secure)}
                >
                  Make {board.secure ? 'public' : 'private'}
                  <span
                    className="dashboard-board__user-list-icon"
                    dangerouslySetInnerHTML={{ __html: secureIcon }}
                  />
                </li>
              )}

              {unarchiveBoard && archiveBoard ?
                <li
                  className="dashboard-board__archive-button"
                  onClick={() =>
                    board.archived
                      ? unarchiveBoard(board.id, isCreator)
                      : archiveBoard(board.id, isCreator)
                  }
                >
                  {board.archived ? 'Unarchive' : 'Archive'}
                </li>
                :
                ''
              }

              <Link to={`/board/${board.id}/action-items`} className="dashboard-board__user-list">
                <li>Action Items</li>
              </Link>

              <li
                className="dashboard-board__delete-button"
                onClick={this.onOpenDelete}
              >
                DELETE
              </li>
            </DropdownMenu>
            :
            ''
          }
          <Link to={`/board/${board.id}`} className="dashboard-board__link">
            <div className="dashboard-board-top">
              <h5 className="dashboard-board__header">
                {board.name}
              </h5>
              <div className="dashboard-board__secure">
                {board.secure !== undefined
                  ? board.secure ?
                    <>
                      <span
                        className="dashboard-board__secure-icon dashboard-board__secure-icon--private"
                        dangerouslySetInnerHTML={{ __html: privateIcon }}
                      />
                      {!!board.teamId ? board.teamName : 'Private'}
                    </>
                    :
                    <>
                      <span
                        className="dashboard-board__secure-icon"
                        dangerouslySetInnerHTML={{ __html: publicIcon }}
                      />
                      Public
                    </>
                  : ''}
              </div>
              {board.secure !== undefined ? !board.secure && <div className="dashboard-board__author">{board.users[board.creatorUid].name}</div> : ''}
            </div>
          </Link>
          <div className="dashboard-board-bottom">
            <div className="dashboard-board__footer">
              <div
                className="dashboard-board__footer-left"
                onClick={this.onOpenUserList}
              >
                {usersIds.map(
                  (item: string, index: number) =>
                    index < 3 && (
                      <span
                        key={item}
                      >
                        <span
                          className={`dashboard-board__avatar ${index === 0 && 'dashboard-board__avatar--first'}`}
                          data-tip={board.users[item].name}
                          data-for={`userTooltip${index}${board.id}`}
                        >
                          <Avatar user={board.users[item]} />
                        </span>
                        {currentUserId !== item && (
                          <ReactTooltip
                            id={`userTooltip${index}${board.id}`}
                            place="bottom"
                            effect="solid"
                            isCapture={false}
                            delayShow={500}
                          />
                        )}
                      </span>
                    )
                )}
              {usersIds.length > 3
                &&
              <>
                <span
                  className="dashboard-board__users-more"
                  data-tip="All Participants"
                  data-for={`allUsersTooltip${board.id}`}
                  dangerouslySetInnerHTML={{__html: plusIcon}}
                />
                <ReactTooltip
                  id={`allUsersTooltip${board.id}`}
                  place="bottom"
                  effect="solid"
                  isCapture={false}
                  delayShow={500}
                />
              </>
              }
              </div>
              {board.secure !== undefined ? <div className="dashboard-board__footer-right"> {board.lastUpdatedAt && timeText} </div> : ''}
            </div>
          </div>
        </div>
        {showDelete &&
          <DeleteModal
            onClose={this.onCloseDelete}
            boardId={board.id}
            openLoading={openLoading}
            closeLoading={closeLoading}
          />
        }
        {showUserList &&
          <UserListModal
            onClose={this.onCloseUserList}
            users={board.users}
          />
        }
      </div>
    );
  }
}

export default connect<CardBoardStateProps, null, CardBoardOwnProps>(mapStateToProps)(CardBoard);
