import * as React from "react";
import {Component, MouseEvent, SyntheticEvent} from 'react';
import { getFirebase } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import moment = require("moment");
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

import { Team } from "../../types";
import './TeamCard.scss'

export interface TeamCardProps {
  team: Team;
  teamId: string;
  openSide: (team: Team) => void;
  openModal: (e: MouseEvent<HTMLLIElement>, team: Team, modal: 'delete' | 'leave') => void;
}

export interface TeamCardState {
  isOpenDD: boolean
}

class TeamCard extends Component<TeamCardProps, TeamCardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpenDD: false
    }
  }

  closeDD = () => {
    this.setState({ isOpenDD: false });
  };

  toggleDD = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.setState({ isOpenDD: !this.state.isOpenDD });
  };


  render() {
    const { team, openModal, openSide } = this.props;
    const { isOpenDD } = this.state;
    const dateFormat = moment.unix(team.lastUpdatedAt / 1000).format( "M/D/YY");
    const toggleIcon = (
      <div
        className="card__settings-icon"
        onClick={this.toggleDD}
      >
        <span />
        <span />
        <span />
      </div>
    );

    const ddMenuProps = {
      isOpen: isOpenDD,
      close: this.closeDD,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };
    const user = getFirebase().auth().currentUser;
    let uid: string = '';
    if(user) {
      uid = user.uid
    }

    const isAdmin: boolean = team.admins.includes(uid);

    return(
      <div
        onClick={() => openSide(team)}
        className='team-card'
      >
        <DropdownMenu {...ddMenuProps} className="team-card-options">
          <li
            className="team-card-options__item"
            onClick={() => openSide(team)}
          >
            Edit
          </li>
          {isAdmin ?
            <>
              <li
                className="team-card-options__item"
                onClick={(e) => openModal(e, team, 'delete')}
              >
                Delete
              </li>
              <li
                className="team-card-options__item"
              >
                <Link
                  className='dd-item-ignore'
                  to={`/new?teamId=${team.id}`}
                >
                  Create Retro
                </Link>
              </li>
            </>
          :
            <li
              className="team-card-options__item"
              onClick={(e) => openModal(e, team, 'leave')}
            >
              Leave Team
            </li>
          }
        </DropdownMenu>
        <h5 className="team-card__title">{team.name}</h5>
        <div className="team-card__activity">Last Activity {dateFormat}</div>
      </div>
    )
  }
}


export default TeamCard;
