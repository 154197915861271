import * as React from 'react';
import { Component } from 'react';
import './ActionItems.scss';
import Avatar from 'components/Avatar';
import { getResidualUsername } from 'util/emojis';
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;
import cx from 'classnames'

export interface SingleActionItemState {
  settingsIsOpen: boolean;
  hasOverflow: boolean;
}

export interface SingleActionItemStateProps {
  card: any;
  getCards: any;
  onMarkCardAsDone: (boardId: string, cardId: string) => any;
  onMarkCardAsUndone: (boardId: string, cardId: string) => any;
  onDelteCard: (boardId: string, cardId: string) => any;
  onItemClicked: (card: any) => any;
}

export type SingleActionItemInnerProps = SingleActionItemStateProps;

class SingleActionItem extends Component<SingleActionItemInnerProps, SingleActionItemState> {
  content: HTMLElement | null;

  constructor(props: any) {
    super(props);
    this.state = {
      settingsIsOpen: false,
      hasOverflow: false,
    };
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          hasOverflow: this.contentHasOverflowingContent()
        }),
      0
    );
  }

  componentWillReceiveProps (newProps: SingleActionItemStateProps) {
    if(newProps?.card?.text !== this.props.card?.text) {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            hasOverflow: this.contentHasOverflowingContent()
          }),
        0
      );
    }
  }

  closeSettings = () => {
    this.setState({ settingsIsOpen: false });
  };

  toggleSettings = () => {
    this.setState({ settingsIsOpen: !this.state.settingsIsOpen });
  };

  onMarkAsDone = async () => {
    const card = this.props.card
    const isSuccess = await this.props.onMarkCardAsDone(card.boardId, card.id)
    if (isSuccess) {
      this.props.getCards()
    }
  }

  onMarkCardAsUndone = async () => {
    const card = this.props.card
    const isSuccess = await this.props.onMarkCardAsUndone(card.boardId, card.id)
    if (isSuccess) {
      this.props.getCards()
    }
  }

  onDeleteClicked = async () => {
    const card = this.props.card
    const isSuccess = await this.props.onDelteCard(card.boardId, card.id)
    if (isSuccess) {
      this.props.getCards()
    }
  }


  contentHasOverflowingContent = () => {
    if (!this.content) {
      return false;
    }
    return (
      this.content.offsetHeight < this.content.scrollHeight ||
      this.content.offsetWidth < this.content.scrollWidth
    );
  };

  render() {
    const card = this.props.card

    const toggleIcon = (
      <div
        className="card__settings-icon"
        onClick={this.toggleSettings}
      >
        <span />
        <span />
        <span />
      </div>
    );

    const ddMenuProps = {
      isOpen: this.state.settingsIsOpen,
      close: this.closeSettings,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    const { hasOverflow } = this.state

    return (
      <div className='action-item-single-card'>
        <DropdownMenu {...ddMenuProps} className="action-item-single-card__settings">
          {card?.status !== 'done' && (
            <li
              className="action-item-single-card__settings-item action-item-single-card__settings-item--first"
              onClick={this.onMarkAsDone}
            >
              Done
            </li>
          )}
          {card?.status === 'done' && (
            <li
              className="action-item-single-card__settings-item action-item-single-card__settings-item--first"
              onClick={this.onMarkCardAsUndone}
            >
              Undone
            </li>
          )}
          <li
            className={cx('action-item-single-card__settings-item')}
            onClick={() => this.props.onItemClicked(card)}
          >
            Edit
          </li>
          <li
            className="action-item-single-card__settings-item action-item-single-card__settings-item--last"
            onClick={this.onDeleteClicked}
          >
            Delete
          </li>
        </DropdownMenu>
        <div className='action-item-single-card__text' onClick={() => this.props.onItemClicked(card)}>
          <h3>{card.boardName}</h3>
          <p className={`action-card__content ${hasOverflow ? 'action-card__content--overflow' : ''}`} ref={content => {this.content = content;}}>
            {card.text}
            {hasOverflow && (
              <span className="action-card__more-content-indicator">See more</span>
            )}
          </p>
        </div>
        <div className='action-item-single-card__footer'>
          {card?.status === 'done' && (
            <div className='action-item-single-card__done'>Done</div>
          )}
          {card?.status !== 'done' && (
            <div className='action-item-single-card__empty'>Open</div>
          )}
          <div>
            {card?.assignedUserId && (
              <>
                <Avatar
                  user={{
                    name: card?.assignedUserName || '',
                    photoUrl: card?.assignedUserPhotoUrl || undefined
                  }}
                  className="card__avatar"
                />
                <span className="card__author">
                  {getResidualUsername(card?.assignedUserName || '')}
                </span>
              </>
            )}
            {!card?.assignedUserId && (
              <div className='card__notAssigned'>not assigned</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SingleActionItem;
