import * as React from 'react';
import { Component } from 'react';
import { connect } from "react-redux";
import { DropTarget, DragSource } from 'react-dnd';
import * as cx from 'classnames';
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

import './CardGroup.scss';
import { mapDispatchToProps, mapStateToProps } from "./CardGroup.container";
import {Card, CardGroup as CardGroupModel, OtherBoardUser} from '../../types/index';
import Avatar from "../Avatar";
import Expanded from "./Expanded";
import Dot from "../Card/Dot";
import { getFirebase } from "react-redux-firebase";
import ReactTooltip from "react-tooltip";
import UserListModal from "../Modal/variant/UserListModal";

export interface CardGroupState {
  isMenuOpen: boolean;
  expanded: boolean;
  showUsers: boolean;
}

export interface CardGroupOwnProps {
  id: string;
  boardId: string;
  isBoardCompleted: boolean;
  votable: boolean;
  showVotes: boolean;
  columnObj: any;
  phase: any;
  boardUrl: string;
  isDiscussPhase: any;
  actionsColumn: any;
}

export interface CardGroupStateProps {
  group: CardGroupModel;
  cards: Card[];
  isAdmin: boolean;
  showAuthor?: boolean;
  isShowAuthor?: boolean;
  // disableUpVotes: boolean;
  // ownVotes: number | undefined;
  // votes: number | undefined;
  ungroupAllCards: () => void;
  addGroupToCard: (sourceGroupId: string, targetCardId: string) => void;
  addGroupToGroup: (sourceGroupId: string, targetGroupId: string) => void;
  deleteGroupWithCards: () => void;
  changeGroupName: (value: string) => void;
  // onUpVote: () => void;
  // onDownVote: () => void;
}

export interface CardGroupDNDProps {
  isDragging: boolean,
  connectDragSource: (el: any) => any,
  dragSource: any,
  connectDropTarget: (el: any) => any,
  isOver: boolean,
  canDrop: boolean
}

export interface CardGroupDispatchProps {
  onEditMode: (active: boolean) => void;
}

export type CardGroupProps = CardGroupOwnProps & CardGroupStateProps & CardGroupDNDProps & CardGroupDispatchProps;

const GroupTarget: any = {
  hover(props: CardGroupProps, monitor: any, component: any) {
    if(props.isAdmin) {
      const source = monitor.getItem();
      const target = props.group;
      if(source && target && source.column && target.type && source.id && target.id && source.column === target.type && source.id !== target.id) {
        const pointer = monitor.getClientOffset();
        const targetDOM = document.getElementById(`group-card-${target.id}`);
        if(targetDOM) {
          const targetRect = targetDOM.getBoundingClientRect();
          const pointerY = pointer.y - targetRect.top;
          const groupIndicator = document.getElementById(`group-card-group-${target.id}`);
          if(pointerY > 0 && pointerY <= targetRect.height) {
            if(groupIndicator) {
              groupIndicator.style.display = 'block';
            }
          } else {
            if(groupIndicator) {
              groupIndicator.style.display = 'none';
            }
          }
        }
      }
    }
  },

  drop(props: CardGroupProps) {
    if(props.isAdmin) {
      return {
        id: props.id,
        column: props.group.type,
        type: 'group'
      }
    } else {
      return null;
    }
  }
};

const GroupSource: any = {
  beginDrag(props: CardGroupProps) {
    if(props.isAdmin) {
      return {
        id: props.id,
        column: props.group.type,
        type: 'group'
      }
    } else {
      return null;
    }
  },

  endDrag(props: CardGroupProps, monitor: any, component: any) {
    if(props.isAdmin) {
      const source = monitor.getItem();
      const target = monitor.getDropResult();
      if(target && source && target.column && source.column && target.id && source.id && target.id !== source.id && target.column === source.column) {
        if(target.type === 'card') {
          //group in card
          props.addGroupToCard(source.id, target.id);
        } else if(target.type === 'group') {
          //group in group
          props.addGroupToGroup(source.id, target.id);
        }
      }
    }
  },

  canDrag(props: CardGroupProps, monitor: any) {
    return props.isAdmin;
  }
};

class CardGroup extends Component<CardGroupProps, CardGroupState> {

  constructor(props: CardGroupProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      expanded: false,
      showUsers: false
    }
  }

  onCloseEditMode = () => {
    this.setState({ expanded: false });
    this.props.onEditMode(false);
  };

  closeMenu = () => {
    this.setState({
      isMenuOpen: false
    })
  };

  openEditMode = () => {
    this.props.onEditMode(true);
    this.setState({
      expanded: !this.state.expanded
    })
  };

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  };

  handleShowUsers = () => {
    const { showUsers } = this.state;
    this.setState({
      showUsers: !showUsers
    })
  };

  onCloseShowUsers = () => {
    this.setState({
      showUsers: false
    })
  };

  render() {
    const {
      group,
      cards,
      connectDropTarget,
      connectDragSource,
      ungroupAllCards,
      deleteGroupWithCards,
      isOver,
      isAdmin,
      isBoardCompleted,
      votable,
      showVotes,
      boardId,
      changeGroupName,
      isShowAuthor,
    } = this.props;
    const { isMenuOpen } = this.state;

    const toggleIcon = (
      <div
        className="card__settings-icon"
        onClick={this.toggleMenu}
      >
        <span />
        <span />
        <span />
      </div>
    );

    let modalUsers: OtherBoardUser = {};
    let users: any = [];
    let votesGroup: number = 0;
    let ownVotesGroup: number = 0;
    const uid: string = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : '';
    let isParticipant: boolean = false;
    cards.map((card: Card) => {
      if (card.author) {
        if(card.votes) {
          votesGroup += card.votes;
        }
        if(card.userVotes[uid]) {
          ownVotesGroup += card.userVotes[uid];
        }
        if(users) {
          if(!users.some((a: any) => a.id === card.authorUid)) {
            modalUsers = {
              ...modalUsers,
              [card.authorUid]: {
                name: card.author || '',
                photoUrl: card.photoUrl || ''
              }
            };
            users.push({
              id: card.authorUid,
              name: card.author || '',
              photoUrl: card.photoUrl || ''
            })
          }
          if(users.some((b: any) => b.id === uid)) {
            isParticipant = true;
          }
        } else {
          users.push({
            id: card.authorUid,
            name: card.author || '',
            photoUrl: card.photoUrl || ''
          })
        }
      }
    });

    const ddMenuProps = {
      isOpen: isMenuOpen,
      close: this.closeMenu,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    const isVotable: boolean = (isBoardCompleted ? false : votable);

    const plusIcon = require('!svg-inline-loader!../../assets/icon-14-plus.svg');

    return connectDropTarget(
      connectDragSource(
        <li
          className='group-card'
          id={`group-card-${group.id}`}
        >
          <div className="group-card__shadow"/>
          <div
            className="group-card-wrapper"
          >
            {isOver &&
            <div
              className="group-card-group"
              id={`group-card-group-${group.id}`}
            >
              <div className="group-card-group__text">GROUP</div>
            </div>
            }

            {cards &&
            <span className="card-stack__count">
            {cards.length}
          </span>
            }
            {(isParticipant || isAdmin) &&
            <DropdownMenu
              {...ddMenuProps}
              className='card__settings card-group__settings'
            >
              <li
                className='card__settings-item card__settings-item--first'
                onClick={this.openEditMode}
              >
                Edit
              </li>
              {isAdmin &&
              <>
                <li
                  className='card__settings-item'
                  onClick={deleteGroupWithCards}
                >
                  Delete
                </li>
                <li
                  className='card__settings-item card__settings-item--last'
                  onClick={ungroupAllCards}
                >
                  Ungroup
                </li>
              </>
              }
            </DropdownMenu>
            }

            {!!group &&
            <div
              className="group-card__text"
              onClick={this.openEditMode}
            >
            <span>
              {group.name}
            </span>
            </div>
            }

            {(isShowAuthor || showVotes || votable) &&
            <div className={cx(`group-card__footer`, {'group-card__foter--multiuser': !!users && users.length > 1})}>
              <div
                className='group-card-avatars'
                onClick={this.handleShowUsers}
              >
                {users && isShowAuthor &&
                users.map((user: any, index: number) => {
                    if(index < 3) {
                      return (
                        <div
                          key={user.id}
                          className='group-card-avatar-container'
                          data-tip={users.length > 1 && user.name}
                          data-for={users.length > 1 && `groupUser-${user.id}`}
                        >
                          <Avatar
                            user={{
                              name: user.name || '',
                              photoUrl: user.photoUrl
                            }}
                            className={cx(`group-card__avatar`, {'group-card__avatar--seconds': index >= 1})}
                          />
                          {
                            users.length > 1
                            &&
                            <span>
                              <ReactTooltip
                                id={`groupUser-${user.id}`}
                                place="bottom"
                                effect="solid"
                                isCapture={false}
                                delayShow={500}
                              />
                         </span>
                          }
                          <div className="group-card__author-name">{user.name}</div>
                        </div>
                      )
                    } else if(index === 3) {
                      return (
                        <>
                          <span
                            className="dashboard-board__users-more"
                            data-tip="All Participants"
                            data-for={`allUsersTooltip${index}`}
                            dangerouslySetInnerHTML={{__html: plusIcon}}
                          />
                            <ReactTooltip
                              id={`allUsersTooltip${index}`}
                              place="bottom"
                              effect="solid"
                              isCapture={false}
                              delayShow={500}
                            />
                        </>
                      )
                    } else {
                      return '';
                    }
                  }
                )
                }
              </div>
              {isVotable && <Dot size='large'>{ownVotesGroup}</Dot>}
              {showVotes && group.type !== 'actions' && !isVotable && <Dot size='large'>{votesGroup}</Dot>}
            </div>
            }

            {this.state.showUsers &&
              <UserListModal
                onClose={this.onCloseShowUsers}
                users={modalUsers}
              />
            }

            {this.state.expanded &&
              <Expanded
                {...this.props}
                onClose={this.onCloseEditMode}
                group={group}
                cards={cards}
                ungroupAllCards={ungroupAllCards}
                deleteGroupWithCards={deleteGroupWithCards}
                votable={votable}
                showVotes={showVotes}
                isBoardCompleted={isBoardCompleted}
                boardId={boardId}
                changeGroupName={changeGroupName}
                isAdmin={isAdmin}
              />
            }
          </div>
        </li>
      ))
  }
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(
  DropTarget<CardGroupProps>('card', GroupTarget, (connect: any, monitor: any) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(
    DragSource<CardGroupProps>('card', GroupSource, (connect: any, monitor: any) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
      dragSource: monitor.getItem(),
      isBeingDragged: monitor.isDragg
    }))(CardGroup)
  ));
