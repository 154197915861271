import * as React from 'react';
import { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import * as cx from 'classnames';

import './TimerSideModal.scss';

export interface TimerSideModalState {
  minutes: number | string;
  showCustom: boolean;
}

export interface TimerSideModalProps {
  onTimeUpdate: (minutes: any) => void;
}

class TimerSideModal extends Component<
  TimerSideModalProps,
  TimerSideModalState
> {
  constructor(props: TimerSideModalProps) {
    super(props);
    this.state = {
      minutes: '',
      showCustom: false
    };
  }

  updateTimer = (minutes: number) => {
    this.setState({ minutes: minutes, showCustom: false });
  };

  showCustom = () => {
    if(!this.state.showCustom) {
      this.setState({ minutes: '', showCustom: true });
    }
  };

  onChangeCustom = (e: any) => {
    const value = parseInt(e.target.value);
    if (!value) {
      this.setState({ minutes: '' });
      return true;
    } else if(value >= 0 && value < 61) {
      this.setState({ minutes: value });
      return true;
    } else {
      return false;
    }
  };

  onKeyDown = (e: any) => {
    if (e.keyCode === 13 && this.state.minutes) {
      this.onSubmitTimer();
    }
  };

  onSubmitTimer = () => {
    if (this.state.minutes) {
      this.props.onTimeUpdate(this.state.minutes);
    }
  };

  render() {
    const { showCustom, minutes } = this.state;

    return (
      <div className="timer-wrapper">
        <div className="timer-options side-modal__box">
          <button
            className={cx('timer-options__item', {'timer-options__item--active': minutes === 5 && !showCustom})}
            onClick={() => this.updateTimer(5)}
          >
            5 minutes
          </button>
          <button
            className={cx('timer-options__item', 'timer-options__item--middle', {'timer-options__item--active': minutes === 10 && !showCustom})}
            onClick={() => this.updateTimer(10)}
          >
            10 minutes
          </button>
          <button
            className={cx('timer-options__item', {'timer-options__item--active': minutes === 15 && !showCustom})}
            onClick={() => this.updateTimer(15)}
          >
            15 minutes
          </button>
          <button
            className={cx('timer-options__item', {'timer-options__item--active': minutes === 20 && !showCustom})}
            onClick={() => this.updateTimer(20)}
          >
            20 minutes
          </button>
          <button
            className={cx('timer-options__item', 'timer-options__item--middle', {'timer-options__item--active': minutes === 25 && !showCustom})}
            onClick={() => this.updateTimer(25)}
          >
            25 minutes
          </button>
          <button
            className={cx('timer-options__item', {'timer-options__item--active': showCustom})}
            onClick={this.showCustom}
          >
            Custom
          </button>
        </div>

        {showCustom && (
          <div className="timer-custom-time side-modal__box">
            <TextField
              value={minutes}
              label="Custom time (Max 60 Minutes)"
              type="number"
              className="side-modal__input"
              onChange={this.onChangeCustom}
              onKeyDown={this.onKeyDown}
            />
          </div>
        )}
        <div className="side-start-button">
          <button className="side-start" onClick={this.onSubmitTimer}>
            START TIMER
          </button>
        </div>
      </div>
    );
  }
}

export default TimerSideModal;
