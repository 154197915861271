import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import * as cx from 'classnames';
import './Card.scss';
import { DragSource, DropTarget } from 'react-dnd';
import { BoardProp, Card as TCard, DragAndDropProps } from '../../types';
import Details from './Details';
import Footer from './Footer';
import { mapDispatchToProps, mapStateToProps } from './Card.container';
import { throttle } from 'lodash';
import { connect } from 'react-redux';
import Avatar from '../Avatar';
import { getResidualUsername } from '../../util/emojis';
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

export interface OwnCardProps extends BoardProp {
  card: TCard;
  votable: boolean;
  showVotes: boolean;
  children?: React.ReactNode;
  isBoardCompleted: boolean;
  isGrouped?: boolean;
  groupId?: string;
  columnObj: any;
  phase: any;
  boardUrl: string;
  isDiscussPhase: any;
  actionsColumn: any;
}

export interface StateCardProps {
  id: string;
  author: { name: string | null | undefined; photoUrl?: string | null };
  votes: number | null;
  owner: boolean;
  isAdmin: boolean;
  isFocused: boolean;
  onRemove: (id: string) => void;
  onDownvote: (id: string) => void;
  onUpvote: (id: string) => void;
  groupCards: (cardSourceId: string, cardTargetId: string) => void;
  addCardToGroup: (sourceCardId: string, targetGroupId: string) => void;
  onUpdateText: (key: string, text: string) => void;
  ownVotes: number;
  canVote: boolean;
  boardUsers: any;
  isShowAuthor?: boolean;
  reorderInGroup: (sourceCardId: string, targetCardId: string, groupId: string) => void;
  onUngroup: (cardId: string, groupId: string) => void;
}

export interface DispatchCardProps {
  onEditMode: (active: boolean) => void;
}

export type CardProps = OwnCardProps &
  StateCardProps &
  DispatchCardProps &
  DragAndDropProps;

export interface CardState {
  expanded: boolean;
  hasOverflow: boolean;
  settingsIsOpen: boolean;
}

const cardTarget = {
  hover(props: CardProps, monitor: any, component: any) {
    if (props.isAdmin) {
      const source = monitor.getItem();
      const target = props.card;
      if(source && target && source.column && target.type && source.id && target.id && source.column === target.type && source.id !== target.id) {
        const pointer = monitor.getClientOffset();
        const targetDOM = document.getElementById(`card-${target.id}`);
        if(targetDOM) {
          const targetRect = targetDOM.getBoundingClientRect();
          const pointerY = pointer.y - targetRect.top;
          const groupIndicator = document.getElementById(`card-group-${target.id}`);
          if(pointerY > 0 && pointerY <= targetRect.height) {
            if(!props.isGrouped) {
              if(groupIndicator) {
                groupIndicator.style.display = 'block';
              }
            } else {
              if(source.id && target.id && props.groupId) {
                if(pointerY <= targetRect.height / 2) {
                  props.reorderInGroup(source.id, target.id, props.groupId);
                }
              }
            }
          } else {
            if(groupIndicator) {
              groupIndicator.style.display = 'none';
            }
          }
        }
      }
    }
  },

  drop(props: CardProps) {
    if (props.isAdmin && !props.isGrouped) {
      return { type: 'card', id: props.id, column: props.card.type };
    }
    return {};
  }
};

const cardSource: any = {
  beginDrag(props: CardProps) {
    if (props.isAdmin) {
      return {
        id: props.id,
        column: props.card.type,
        type: 'card'
      };
    }
    return {};
  },

  endDrag(props: CardProps, monitor: any) {
    if (props.isAdmin && !props.isGrouped) {
      const source = monitor.getItem();
      const target = monitor.getDropResult();
      if(target && source && target.column && source.column && target.id && source.id && target.id !== source.id && target.column === source.column) {
        if(target.type === 'card') {
          //card in card
          props.groupCards(source.id, target.id);
        } else if(target.type === 'group') {
          //card in group
          props.addCardToGroup(source.id, target.id);
        }
      }
    }
  },

  canDrag(props: CardProps, monitor: any) {
    return props.isAdmin;
  }
};

const defaultProps: Partial<CardProps> = {

  // Add some default props for drag and drop.
  // This simplifies testing, in real application dnd-support is added from outside
  // the Card component.
  isDragging: false,
  connectDragSource: (el: any) => el,
  dragSource: null,
  connectDropTarget: (el: any) => el,
  isOver: false,
  canDrop: false
};

const animateScroll = throttle(
  (target: HTMLElement, start: number, finish: number) => {
    const maxIterations = 30;

    let iteration = 0;
    let currentPos = start;

    const stepOffset = (finish - start) / maxIterations;

    const scroll = () => {
      currentPos = currentPos + stepOffset;

      target.scrollTop = currentPos;
      iteration++;

      if (iteration >= maxIterations) {
        return;
      }

      requestAnimationFrame(scroll);
    };

    scroll();
  }
);

export class Card extends Component<CardProps, CardState> {
  static defaultProps: Partial<OwnCardProps> = defaultProps;

  content: HTMLElement | null;

  state: CardState = {
    expanded: false,
    hasOverflow: false,
    settingsIsOpen: false
  };

  element: any;

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          hasOverflow: this.contentHasOverflowingContent()
        }),
      0
    );
  }
  componentDidUpdate(prevProps: CardProps) {
    const hasOverflow = this.contentHasOverflowingContent();
    if (hasOverflow !== this.state.hasOverflow) {
      this.setState({ ...this.state, hasOverflow });
    }

    if (!prevProps.isFocused && this.props.isFocused) {
      const oldScrollTop = this.element.parentElement.parentElement.scrollTop;
      const newScrollTop = this.element.offsetTop - 16;

      animateScroll(
        this.element.parentElement.parentElement,
        oldScrollTop,
        newScrollTop
      );
    }
  }

  contentHasOverflowingContent = () => {
    if (!this.content) {
      return false;
    }
    return (
      this.content.offsetHeight < this.content.scrollHeight ||
      this.content.offsetWidth < this.content.scrollWidth
    );
  };

  expand = () => {
    this.setState({ ...this.state, expanded: true });
    this.props.onEditMode(true);
  };

  onDetailsCloseListener = () => {
    this.setState({ ...this.state, expanded: false });
    this.props.onEditMode(false);
  };

  toggleSettings = () => {
    this.setState({ settingsIsOpen: !this.state.settingsIsOpen });
  };

  closeSettings = () => {
    this.setState({ settingsIsOpen: false });
  };

  render() {
    const {
      id,
      isOver,
      isDragging,
      onDownvote,
      onUpvote,
      owner,
      votes,
      isAdmin,
      votable,
      showVotes,
      ownVotes,
      card,
      connectDropTarget,
      connectDragSource,
      isShowAuthor,
      author,
      isBoardCompleted,
      onRemove,
      canVote,
      isGrouped,
      groupId,
      onUngroup
    } = this.props;

    const isActionCard = card.type === 'actions';

    const elem = document.getElementById(`card-container-${id}`);
    let width = undefined;
    if (elem) {
      width = elem.getBoundingClientRect().width;
    }

    const toggleIcon = (
      <div
        className="card__settings-icon"
        onClick={this.toggleSettings}
      >
        <span />
        <span />
        <span />
      </div>
    );

    const ddMenuProps = {
      isOpen: this.state.settingsIsOpen,
      close: this.closeSettings,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    return connectDropTarget(
      connectDragSource(
        <li
          key={id}
          id={`card-${id}`}
          className="card__root"
          ref={li => {
            this.element = li;
          }}
        >
          <div
            id={`card-container-${id}`}
            className={classNames('card', {
              'card--disabled': isDragging && isAdmin,
            })}
          >

            {(isAdmin || (owner && !isBoardCompleted))  &&
              (
                <DropdownMenu {...ddMenuProps} className="card__settings">
                  <li
                    className="card__settings-item card__settings-item--first"
                    onClick={this.expand}
                  >
                    Edit
                  </li>
                  <li
                    className={cx('card__settings-item', {'card__settings-item--last': !isGrouped})}
                    onClick={() => onRemove(id)}
                  >
                    Delete
                  </li>
                  {isGrouped && groupId &&
                    <li
                      className="card__settings-item card__settings-item--last"
                      onClick={() => onUngroup(id, groupId)}
                    >
                      Ungroup
                    </li>
                  }
                </DropdownMenu>
              )
            }
            {isOver &&  !isGrouped  && (
              <div id={`card-group-${id}`} className="card-group">
                <div className="card-group__text">
                  GROUP
                </div>
              </div>
            )}
            <div className="card__selection-area">
              <blockquote
                className={`card__content ${
                  this.state.hasOverflow ? 'card__content--overflow' : ''
                }`}
                onClick={this.expand}
                ref={content => {
                  this.content = content;
                }}
              >
                {card.text}

                {this.state.hasOverflow && (
                  <span className="card__more-content-indicator">See more</span>
                )}
              </blockquote>
            </div>
            {!isActionCard && (isShowAuthor || showVotes || votable) && (
              <Footer
                votable={isBoardCompleted ? false : votable}
                ownVotes={ownVotes}
                onDownvote={() => onDownvote(id)}
                onUpvote={() => onUpvote(id)}
                votes={showVotes || votable ? votes : null}
                disableUpVotes={canVote}
              >
                {isShowAuthor && (
                  <>
                    <Avatar
                      user={{
                        name: author.name || '',
                        photoUrl: author.photoUrl || undefined
                      }}
                      className="card__avatar"
                    />
                    <span className="card__author">
                      {getResidualUsername(author.name || '')}
                    </span>
                  </>
                )}
              </Footer>
            )}
            {isActionCard && (
              <div className='card__assignedUser' onClick={this.expand}>
                {card?.assignedUserId && (
                  <>
                    <Avatar
                      user={{
                        name: card?.assignedUserName || '',
                        photoUrl: card?.assignedUserPhotoUrl || undefined
                      }}
                      className="card__avatar"
                    />
                    <span className="card__author card__notAssigned--clickable">
                      {getResidualUsername(card?.assignedUserName || '')}
                    </span>
                  </>
                )}
                {!card?.assignedUserId && (
                  <div className='card__notAssigned card__notAssigned--clickable'>not assigned</div>
                )}
              </div>
            )}
          </div>

          {this.state.expanded && (
            <Details
              {...this.props}
              onClose={this.onDetailsCloseListener}
              editable={isAdmin || (!isBoardCompleted && owner)}
              width={width}
              isBoardCompleted={isBoardCompleted}
            />
          )}
        </li>
      )
    );
  }
}

export default connect<StateCardProps, DispatchCardProps, OwnCardProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  DropTarget<CardProps>('card', cardTarget, (connect: any, monitor: any) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(
    DragSource<CardProps>('card', cardSource, (connect: any, monitor: any) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
      dragSource: monitor.getItem(),
      isBeingDragged: monitor.isDragg
    }))(Card)
  )
);
