import * as React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import {firestoreConnect, getFirebase, isLoaded, ReduxFirestoreQueries} from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { User } from '@firebase/auth-types';
import { RouteComponentProps } from 'react-router';

import { mapStateToProps } from './BoardGuard.container';
import { Board, Teams } from "../../types";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import PlanExceededModal from "../../components/Modal/variant/PlanExceededModal";
import './BoardGuard.scss';

export interface BoardGuardProps extends RouteComponentProps<{ id: string }> {
  component: React.ComponentType<any>;
}

export interface BoardGuardStateProps {
  uid: string | undefined;
  board: Board;
  isBoardEmpty: boolean | undefined;
  teams: Teams;
  addPresence: () => void;
  removePresence: (currentBoardId: string) => void;
}

export interface BoardGuardState {
  // wait until member is added synchronously on the database, otherwise
  // can't proceed, because user won't have permissions to read data

  planExceeded: '' | 'timeUsers' | 'users';
  isAddingMember: boolean;
  isAddedPresence: boolean;
  isAuthenticated: boolean | undefined;
  isAnonymous: boolean | undefined;
  isUserActive: boolean | undefined;
  isMember: boolean | undefined;
  isLoading: boolean;
  isForeignTeam: boolean;
  isInvalidBoard: boolean | undefined;
  isSecuredBoard: boolean | undefined;
  isBoardDeleted: boolean | undefined;
  isPaymentInProgress: boolean;
}

export type BoardGuardPropsMain = BoardGuardProps & BoardGuardStateProps;

export class BoardGuard extends React.PureComponent<
  BoardGuardPropsMain,
  BoardGuardState
> {
  currentBoardId: string;
  interval: number | undefined;

  constructor(props: BoardGuardPropsMain) {
    super(props);
    this.state = {
      isLoading: true,
      isForeignTeam: false,
      isInvalidBoard: undefined,
      isBoardDeleted: undefined,
      isAuthenticated: undefined,
      isAnonymous: undefined,
      isUserActive: undefined,
      isMember: undefined,
      isSecuredBoard: undefined,
      isAddingMember: false,
      isAddedPresence: false,
      planExceeded: '',
      isPaymentInProgress: false
    };
    this.interval = undefined;
  }

  addMember() {
    const { board } = this.props;
    if(board.creatorUid) {
      this.setState( { isAddingMember: true, isLoading: true }, () => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getPlanInfo`, {
          method: 'POST',
          body: JSON.stringify({ uid: board.creatorUid })
        })
          .then(res => res.json())
          .then((data) => {
            if(data.body) {
              const body = JSON.parse(data.body);
              const date: number = new Date().getTime();
              if(date > body.current_period_end * 1000) {
                this.setState({
                  planExceeded: 'timeUsers',
                  isAddingMember: false,
                  isMember: false
                })
              } else {
                if(board.users) {
                  if(Object.keys(board.users).length >= body.userLimit) {
                    this.setState({
                      planExceeded: 'users',
                      isAddingMember: false,
                      isMember: false
                    })
                  } else {
                    const { uid } = this.props;
                    const currentUser = getFirebase().auth().currentUser;
                    if(uid && currentUser) {
                      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/addMember`, {
                        method: 'POST',
                        body: JSON.stringify({
                          boardId: this.props.match.params.id,
                          uid: uid
                        })
                      })
                        .then(res => res.json())
                        .then((response) => {
                          const body = JSON.parse(response.body);
                          if(body.success) {
                            this.setState({ isAddingMember: false, isMember: true, isLoading: false });
                          } else {
                            location.hash = '/new';
                          }
                        })
                        .catch((err: Error) => {
                          this.setState({ isAddingMember: false, isMember: false, isLoading: false });
                          console.log(err);
                        });
                    }
                  }
                }
              }
            }
          })
          .catch((err: Error) => {
            this.setState({ isAddingMember: false, isMember: false, isLoading: false });
            console.log(err);
          })
      })
    }
  }

  addApplicant() {
    const { board } = this.props;
    if(board.creatorUid) {
      this.setState( { isAddingMember: true, isLoading: true }, () => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getPlanInfo`, {
          method: 'POST',
          body: JSON.stringify({ uid: board.creatorUid })
        })
          .then(res => res.json())
          .then((data) => {
            if(data.body) {
              const body = JSON.parse(data.body);
              const date: number = new Date().getTime();
              if(date > body.current_period_end * 1000) {
                this.setState({
                  planExceeded: 'timeUsers',
                  isAddingMember: false,
                  isLoading: false
                })
              } else {
                if(board.users) {
                  if(Object.keys(board.users).length >= body.userLimit) {
                    this.setState({
                      planExceeded: 'users',
                      isAddingMember: false,
                      isLoading: false
                    })
                  } else {
                    const { uid } = this.props;
                    const currentUser = getFirebase().auth().currentUser;
                    if(uid && currentUser) {
                      const boardRef = getFirebase().firestore().collection('boards').doc(this.props.match.params.id);
                      const userInfo = {
                        name: currentUser.displayName ? currentUser.displayName : '',
                        email: currentUser.email ? currentUser.email : '',
                        photoUrl: currentUser.photoURL ? currentUser.photoURL : ''
                      };
                      const userUpdate = {};
                      userUpdate[`applicants.${uid}`] = userInfo;
                      boardRef
                        .update(userUpdate)
                        .then(() => {
                          this.setState({ isAddingMember: false, isLoading: false })
                        })
                        .catch((e) => {
                          console.log(e);
                        })
                    }
                  }
                }
              }
            }
          })
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<BoardGuardPropsMain>, prevState: Readonly<BoardGuardState>, snapshot?: any): void {
    const { board, teams, uid } = this.props;
    const { isLoading, isBoardDeleted, isAddedPresence } = this.state;
    const boardId = this.props.match.params.id;

    if(prevState.isAuthenticated && this.state.isAuthenticated === false) {
      this.props.history.push('/');
    } else if((!prevProps.uid && !this.props.uid) || (prevState.isUserActive === undefined && this.state.isUserActive === false)) {
      this.props.history.push(`/join/${this.props.match.params.id}`);
    }

    if(prevProps.isBoardEmpty === false && this.props.isBoardEmpty) {
      this.setState({ isBoardDeleted: true });
    } else if(isLoaded(board) && prevProps.isBoardEmpty && this.props.isBoardEmpty && !isBoardDeleted) {
      this.setState({ isInvalidBoard: true });
    } else if(isLoaded(board) && isLoaded(teams) && isLoading && this.state.isAuthenticated && uid) {
      this.setState({
        isLoading: false,
        isInvalidBoard: false,
        isBoardDeleted: false,
        isSecuredBoard: board.secure,
        isMember: !!board.users[uid]
      }, () => {
          const teamId = board.teamId
          if (teamId) {
            const { isAnonymous } = this.state

            if (isAnonymous) {
              this.setState({ isForeignTeam: true })
              return
            }

            const isUserInsideTeam = Object.keys(teams).some(t => t === teamId)
            if (!isUserInsideTeam) {
              this.setState({ isForeignTeam: true })
              return
            }
          }

          if(board.secure) {
            if(!this.state.isMember && !this.state.isAddingMember && (!board.applicants || (board.applicants && !board.applicants[uid]))) {
              this.addApplicant();
            } else if(!this.state.isMember && !this.state.isAddingMember && !!board.applicants && !!board.applicants[uid] &&
              !!board.applicants[uid].isAuthorized) {
              this.addMember();
            }
          } else {
            if(!this.state.isMember && !this.state.isAddingMember) {
              this.addMember();
            }
          }
      });
    }

    if(
      uid && !!board && board.secure && !this.state.isMember && ! this.state.isAddingMember &&
      !!board.applicants && !!board.applicants[uid] && board.applicants[uid].isAuthorized && !!board.users && !board.users[uid]
    ) {
      this.addMember();
    }

    if(this.state.isMember && !isAddedPresence && uid && !!board && !!board.users && !!board.users[uid] && !board.users[uid].presence) {
      this.setState({ isAddedPresence: true }, () => {
        this.props.addPresence();
      })
    }

    if (boardId !== prevProps.match.params.id) {
      this.setState(
        {
          isInvalidBoard: undefined,
          isSecuredBoard: undefined,
          isMember: undefined,
          isBoardDeleted: undefined,
          isAuthenticated: !!uid,
          isAddingMember: false,
          planExceeded: '',
          isLoading: true,
          isAddedPresence: false
        }
      )
    }

    if(!this.interval && this.state.isPaymentInProgress) {
      this.interval = window.setInterval(this.checkUser, 5000);
    } else if(!!this.interval && !this.state.isPaymentInProgress) {
      clearInterval(this.interval);
    }

  }

  componentDidMount(): void {
    this.currentBoardId = this.props.match.params.id;
    this.checkUser();

    getFirebase().auth()
      .onAuthStateChanged((user: User) => {
        this.setState({ isAuthenticated: user !== null, isAnonymous: user?.isAnonymous });
        }, () => {
        this.setState({ isAuthenticated: false });
        this.props.history.push('/');
        })
  }

  checkUser = () => {
    const { uid } = this.props;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/isActiveUser`, {
      method: 'POST',
      body: JSON.stringify({ uid: uid })
    })
      .then(res => res.json())
      .then((data) => {
        if(data) {
          if(data) {
            const body = JSON.parse(data.body);
            if(body.value === 'payment-in-progress') {
              this.setState({ isPaymentInProgress: true });
            } else if(body.value !== true) {
              this.setState({ isUserActive: false, isPaymentInProgress: false });
            } else {
              this.setState({ isUserActive: true, isPaymentInProgress: false });
            }
          }
        } else {
          this.setState({ isUserActive: false, isPaymentInProgress: false });
        }
      });
  };

  openLoading = async () => {
    this.setState({ isLoading: true });
  };

  closeLoading = async () => {
    this.setState({ isLoading: false });
  };

  render() {
    const {
      isLoading,
      isForeignTeam,
      isBoardDeleted,
      isInvalidBoard,
      isMember,
      planExceeded,
      isAddingMember,
      isAuthenticated,
      isPaymentInProgress
    } = this.state;

    const { uid, board } = this.props;

    const url = window.location.href;

    if (isForeignTeam) {
      return (
        <div className='board-guard__not-team-part'>
          <div>
            <p className='board-guard__not-team-part-header'>You are not part of the team. <br/> You need to be part of the team to see this board.</p>
            <a href="/" className="board-guard__denied-link">
              Return to homepage
            </a>
          </div>
        </div>
      )
    }

    if(isPaymentInProgress) {
      return(<LoadingScreen status='Payment in progress' />);
    } else if(isAuthenticated === undefined || (isLoading && isBoardDeleted === undefined && isInvalidBoard === undefined) || isAddingMember) {
      let status: string = '';
      if(isAuthenticated === undefined) {
        status = 'Authentication in progress';
      } else if(isAddingMember) {
        status = 'Registering as board member';
      }
      return(
        <LoadingScreen status={status} />
      )
    } else if(isBoardDeleted) {
      const DeniedMessage = (
        <>
          <p>Board Deleted by the board administrator</p>
          <a href="/" className="board-guard__denied-link">
            Return to homepage
          </a>
        </>
      );

      return <LoadingScreen status={DeniedMessage} />;
    } else if(isInvalidBoard) {
      return (
        <Redirect
          to={{
            pathname: '/new',
            state: { referrer: url }
          }}
        />
      );
    } else if(planExceeded) {
      const message: string = planExceeded === 'timeUsers'
          ?
          'Your plan has been exceeded. Upgrade to add more boards.'
          :
          'Board has maximum number of users';
      return(
        <PlanExceededModal
          uid={uid}
          onClose={() => this.props.history.push('')}
          message={message}
          type={planExceeded}
          openLoading={this.openLoading}
          closeLoading={this.closeLoading}
        />
      );
    } else if(board && board.secure && board.applicants && !!uid && board.applicants[uid] && !board.applicants[uid].isAuthorized && board.applicants[uid].isAuthorized !== false) {
      const status = 'Waiting for board admin approval';
      return (<LoadingScreen status={status} />);
    } else if(board && board.applicants && !!uid && board.applicants[uid] && board.applicants[uid].isAuthorized === false) {
      const DeniedMessage = (
        <>
          <p>Access denied by the board administrator</p>
          <a href="/" className="board-guard__denied-link">
            Return to homepage
          </a>
        </>
      );
      return (<LoadingScreen status={DeniedMessage} />);
    } else if(!!uid && isMember && board && board.users && board.users[uid]) {
      const WrappedComponent = this.props.component;
      return (<WrappedComponent {...this.props} board={board} />)
    }

    return <LoadingScreen />;
  }
}

function firestoreConnector(props: any): ReduxFirestoreQueries {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const user = auth.currentUser;

  let uid: string = '';
  if(user) {
    uid = user.uid;
  }
  if (auth.currentUser) {
    return [
      {
        collection: 'boards',
        doc: props.match.params.id
      },
      {
        collection: 'teams',
        where: [['usersArray', 'array-contains', uid]],
        orderBy: ['lastUpdatedAt', 'desc'],
        storeAs: 'teams'
      }
    ];
  } else {
    return []
  }
}

export default compose<any, any, any>(
  firestoreConnect(firestoreConnector),
  connect(mapStateToProps)
)(BoardGuard);
