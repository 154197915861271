import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {firestoreConnect, ReduxFirestoreQueries} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
const Div100vh: any = require('react-div-100vh').default;
const { toast } = require('react-toastify');

import { Board as BoardType } from "../../types";

import './Board.scss';

import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps
} from './Board.container';
import {
  BoardCards,
  BoardUsers,
  // Card,
  ModalType
} from '../../types';
import Header from '../../components/Header';
import ColumnView from '../../components/ColumnView';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import MembershipRequestModal from '../../components/Modal/variant/MembershipRequestModal';
import {getPhaseConfiguration, IndexedPhaseConfiguration} from '../../constants/Retrospective';
import Timer from '../../components/Timer';
import SideModal from "../../components/SideModal";
import ShareSideModal from "../../components/SideModal/variant/ShareSideModal";
import SetingsSideModal from "../../components/SideModal/variant/SetingsSideModal";
import LockBanner from "../../components/LockBanner";
import ParticipantsSideModal, {ParticipantsUser} from "../../components/SideModal/variant/ParticipantsSideModal";
import VotesLeft from "../../components/VotesLeft";
import {analytics} from "../../index";

export interface UserPresence {
  [uid: string]: boolean
}

export interface BoardProps extends RouteComponentProps<{ id: string }> {
  board: BoardType
  cards: BoardCards;
  users: BoardUsers;
  boardSelector: string;
  isBoardAdmin: boolean;
  uid: string; // ID of the current user
  setupCompleted: boolean;
  userReadyState: boolean | undefined;
  userVotes: number | undefined;
  presence: UserPresence;
  pendingUsers: ParticipantsUser[]
  onChangeUserAcception: (uid: string, accept: boolean) => void;
  onToggleReadyState: () => void;
  onChangeBoardName: (boardName: string) => void;
  onDeleteTimer: () => void;
  onToggleShowAuthor: () => void;
  onLimitVotes: (limit: number) => void;
  onToggleMarkAsDone: () => void;
  toggleLockBoard: () => void;
  changeUserName: (username: string) => void;
  onSignOut: () => void;
  removePresence: (currentBoardId: string) => void;
  toggleBoardSecurity: () => void;

  // Added by mergeProps
  onRegisterCurrentUser: () => void;

  username?: string;
  email?: string;
  timerExpiration?: string;
  isAnonymous: boolean;

  waitingUsers: {
    uid: string;
    name: string;
    image: string;
  }[];
  acceptUser: (uid: string, accept: boolean) => void;

  [key: string]: any;
}

export interface BoardState {
  showModal?: ModalType;
  isLoading: boolean;
}

export class Board extends React.Component<BoardProps, BoardState> {
  private readonly currentBoardId: string;
  constructor(props: BoardProps) {
    super(props);
    this.state = {
      isLoading: false
    };
    this.currentBoardId = props.match.params.id;
  }

  toggleTimeoutRef: any;

  componentDidMount() {
    const { onRegisterCurrentUser } = this.props;
    onRegisterCurrentUser();
    // @ts-ignore
    analytics.logEvent('active_board');

    this.toggleWidget('none', 500)
  }

  toggleWidget = (display: string, nextTryInMs: number) => {
    const d = document.querySelectorAll('helpspace-widget')
    if (d?.length > 0) {
      // @ts-ignore: Unreachable code error
      d[0]?.style?.display = display;
    } else if (nextTryInMs < 10000) {
      this.toggleTimeoutRef = setTimeout(() => this.toggleWidget('none', nextTryInMs + 500), nextTryInMs)
    }
  };

  componentDidUpdate(prevProps: Readonly<BoardProps>, prevState: Readonly<BoardState>, snapshot?: any): void {
    if (
      prevProps.board && this.props.board &&
      prevProps.board.guidedPhase !== this.props.board.guidedPhase
    ) {
      if (
        prevProps.board.guidedPhase > 0 ||
        this.props.board.guidedPhase > 0
      ) {
        let phase: IndexedPhaseConfiguration = {
          index: 0,
          activities: [],
          columns: [],
          description: "",
          name: ""
        };
        if(this.props.board.mode === 'custom') {
          if(this.props.board.customConfig){
            phase = {
              index: this.props.board.guidedPhase,
              ...this.props.board.customConfig[this.props.board.guidedPhase]
            }
          }
        } else {
          phase = getPhaseConfiguration(
            this.props.board.mode,
            this.props.board.guidedPhase
          );

        }
        toast(
          `Phase ${phase.index + 1} ${
            phase.name
          }: ${phase.activities.map(a => a.description).join(', ')}`,
          { className: 'toast--info' }
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.toggleTimeoutRef) {
      clearTimeout(this.toggleTimeoutRef)
    }

    this.toggleWidget('block', 30000)
    this.props.removePresence(this.currentBoardId);
  }

  handleCloseModal = () => {
    this.setState({
      showModal: undefined
    });
  };

  handleOpenModal = (modal: ModalType) => {
    this.setState({
      showModal: modal
    });
  };

  openLoading = async () => {
    this.setState({ isLoading: true });
  };

  closeLoading = async () => {
    this.setState({ isLoading: false });
  };

  render() {
    const {
      isBoardAdmin,
      board,
      setupCompleted,
      waitingUsers,
      timerExpiration,
      onDeleteTimer,
      acceptUser,
      onLimitVotes,
      userReadyState,
      onToggleReadyState,
      onToggleMarkAsDone,
      toggleLockBoard,
      pendingUsers,
      onChangeUserAcception,
      userVotes,
      presence,
      boardSelector,
      toggleBoardSecurity
    } = this.props;

    const { isLoading } = this.state;

    const configLoaded = board && Object.keys(board).length > 0;
    if (!configLoaded || !setupCompleted || isLoading) {
      return <LoadingScreen />;
    }

    let waitingUser;
    if (isBoardAdmin && waitingUsers && waitingUsers.length > 0) {
      const user = waitingUsers[0];
      waitingUser = (
        <MembershipRequestModal
          onAccept={() => {
            acceptUser(user.uid, true);
          }}
          onDeny={() => {
            acceptUser(user.uid, false);
          }}
          member={user}
        />
      );
    }

    const showDelete = !waitingUser && this.state.showModal === 'delete';
    const showSettings = !waitingUser && this.state.showModal === 'settings';
    const showShareDialog = !waitingUser && this.state.showModal === 'share';
    const showParticipants = !waitingUser && this.state.showModal === 'participants';
    const onDeleteTimerAuthorized = isBoardAdmin ? onDeleteTimer : undefined;
    let voteMsg: number | string = '';
    if(board.limitVotes) {
      if(userVotes) {
        voteMsg = board.limitVotes - userVotes >= 0 ? board.limitVotes - userVotes : 0;
      } else {
        voteMsg = board.limitVotes;
      }
    } else {
      voteMsg = 'Unlimited'
    }

    return (
      <>
        {waitingUser}
        <Div100vh className="board-page">
          <Header
            boardId={this.props.match.params.id}
            board={board}
            onSignOut={this.props.onSignOut}
            onOpenModal={this.handleOpenModal}
            onChangeBoardName={this.props.onChangeBoardName}
            showDelete={showDelete}
            onCloseModal={this.handleCloseModal}
            markAsDoneDisabled={board.markAsDoneDisabled}
            isReady={userReadyState}
            toggleReadyState={onToggleReadyState}
            openLoading={this.openLoading}
            closeLoading={this.closeLoading}
          />

          {board.guidedPhase === 1 &&
            <VotesLeft
              votes={voteMsg}
            />
          }

          <ColumnView
            board={board}
            boardId={this.props.match.params.id}
            className="board-page__column-view"
            boardUrl={boardSelector}
          />

          {!board.completed && (
            <Timer
              timerExpiration={timerExpiration}
              onDeleteTimer={onDeleteTimerAuthorized}
            />
          )}


          {isBoardAdmin && (
            <SideModal
              title='Settings'
              isOpen={showSettings}
              onClose={this.handleCloseModal}
            >
              <SetingsSideModal
                onClose={this.handleCloseModal}
                boardName={board.name}
                isTeam={!!board.teamId}
                username={this.props.username}
                onChangeBoardName={this.props.onChangeBoardName}
                onChangeUsername={this.props.changeUserName}
                onToggleShowAuthor={this.props.onToggleShowAuthor}
                isShowAuthor={board.showAuthor}
                isBoardCompleted={board.completed}
                onLimitVotes={onLimitVotes}
                limitVotes={board.limitVotes}
                onToggleMarkAsDone={onToggleMarkAsDone}
                markAsDoneDisabled={board.markAsDoneDisabled}
                toggleLockBoard={toggleLockBoard}
                boardSecurity={board.secure}
                toggleBoardSecurity={toggleBoardSecurity}
              />
            </SideModal>
          )}

          <SideModal
            onClose={this.handleCloseModal}
            isOpen={showShareDialog}
            title="Invite Link"
          >
            <ShareSideModal
              boardName={board.name}
            />
          </SideModal>

          <SideModal
            title="Participants"
            onClose={this.handleCloseModal}
            isOpen={showParticipants}
            className="side-modal--participant"
          >
            <ParticipantsSideModal
              activeUsers={this.props.users}
              uid={this.props.uid}
              isAdmin={isBoardAdmin}
              isPrivateBoard={board.secure}
              pendingUsers={pendingUsers}
              onChangeUserAcception={onChangeUserAcception}
              presence={presence}
            />
          </SideModal>

          {board.completed && (
            <LockBanner />
          )}

        </Div100vh>
      </>
    );
  }
}

function firestoreConnector(props: RouteComponentProps<{ id: string }>): ReduxFirestoreQueries {
  return [
    {
      collection: 'boards',
      doc: props.match.params.id,
      subcollections: [{ collection: 'cards' }],
      storeAs: 'cards'
    },
    {
      collection: 'boards',
      doc: props.match.params.id,
      subcollections: [{ collection: 'cardGroups' }],
      storeAs: 'cardGroups'
    }
  ];
}

export default compose<any, any, any>(
  firestoreConnect(firestoreConnector),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(Board) as React.ComponentClass<any>;
