import { getVal, getFirebase } from 'react-redux-firebase';
import { User } from "@firebase/auth-types";
import { FirebaseFirestore } from '@firebase/firestore-types';
import * as Raven from 'raven-js';

import {
  Board,
  Card,
  CardGroup,
  StoreState,
} from '../../types';
import { OwnCardProps, StateCardProps } from './Card';
import { AnyAction, Dispatch } from 'redux';
import { EDIT_STATUS } from '../../actions';

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnCardProps
): StateCardProps => {
  const user: User | null = getFirebase().auth().currentUser;
  const uid: string = user ? user.uid : '';
  const board: Board = getVal(state.fbState, `data/boards/${ownProps.boardId}`);
  const isShowAuthor = board.showAuthor;
  const admin = board.creatorUid === uid;
  const cards: { [key: string]: Card } = getVal(state.fbState, `data/cards`, {});
  const database: FirebaseFirestore = getFirebase().firestore();
  const { boardId } = ownProps;

  let boardUsers : any = [] 
  if (board?.users) {
    const userKeys = Object.keys(board.users)
    boardUsers = userKeys.map((key: any) => {
      const singleUser = board.users[key]
      return { id: key, ...singleUser }
    })
  }

  let limitVotes = board.limitVotes;
  let currentVotes = board.users[uid].votes || 0;

  limitVotes = limitVotes ? limitVotes : undefined;

  async function onRemoveCard(key: string) {
    if(ownProps.isGrouped && ownProps.groupId) {
      const cardsInGroup = getVal(state.fbState, `data/cardGroups/${ownProps.groupId}/cards`, {});
      let updatedCards = {};
      await Object.keys(cardsInGroup).map((id: string) => {
        if(id !== key) {
          updatedCards[id] = {
            ...cardsInGroup[id]
          }
        }
      });
      database
        .collection('boards')
        .doc(boardId)
        .collection('cardGroups')
        .doc(ownProps.groupId)
        .update({ cards: updatedCards })
        .then(() => {
          database
            .collection('boards')
            .doc(boardId)
            .collection('cards')
            .doc(key)
            .delete()
            .then(() => {
              if(Object.keys(cardsInGroup).length === 2) {
                database
                  .collection('boards')
                  .doc(boardId)
                  .collection('cardGroups')
                  .doc(ownProps.groupId)
                  .delete()
                  .catch((err: Error) => {
                    Raven.captureMessage('Could not remove card', {
                      extra: {
                        reason: err.message,
                        uid: uid,
                        boardId: ownProps.boardId,
                      }
                    });
                  });
              }
            })
            .catch((err: Error) => {
              Raven.captureMessage('Could not remove card', {
                extra: {
                  reason: err.message,
                  uid: uid,
                  boardId: ownProps.boardId,
                  cardId: key
                }
              });
            });
        })
        .catch((err: Error) => {
          Raven.captureMessage('Could not remove card', {
            extra: {
              reason: err.message,
              uid: uid,
              boardId: ownProps.boardId,
              cardId: key
            }
          });
        });
    } else {
      database
        .collection('boards')
        .doc(boardId)
        .collection('cards')
        .doc(key)
        .delete()
        .catch((err: Error) => {
          Raven.captureMessage('Could not remove card', {
            extra: {
              reason: err.message,
              uid: uid,
              boardId: ownProps.boardId,
              cardId: key
            }
          });
        });
    }
  }

  function onUpdateVoteForCard(key: string, inc: number) {
    // Don't do anything in case we should downvote a card that has no
    // votes at all.

    if (
      ((inc < 0 && cards[key].votes === 0) || (inc < 0 && currentVotes <= 0)) ||
      (inc > 0 && limitVotes && limitVotes <= currentVotes)
    ) {
      return;
    }

    const newUserVote = (board.users[uid].votes || 0) + inc;
    const newCardVote = (cards[key].votes || 0) + inc;
    const newUserCardVote = (cards[key].userVotes[uid] || 0) + inc;
    database
      .collection('boards')
      .doc(boardId)
      .update({
        [`users.${uid}.votes`]: newUserVote
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not update votes on card', {
          extra: {
            reason: err.message,
            uid: uid,
            boardId: ownProps.boardId,
            cardId: key
          }
        });
      });

    database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(key)
      .update({
        votes: newCardVote
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not update votes on card', {
          extra: {
            reason: err.message,
            uid: uid,
            boardId: ownProps.boardId,
            cardId: key
          }
        });
      });

    database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(key)
      .update({
        [`userVotes.${uid}`]: newUserCardVote
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not update userVotes on card', {
          extra: {
            reason: err.message,
            uid: uid,
            boardId: ownProps.boardId,
            cardId: key
          }
        });
      });
  }

  async function getUserFromEmail(userEmail: any) {
    try {
      const assignedUserRes = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getUserFromEmail`, {
        method: 'POST',
        body: JSON.stringify({ userEmail: userEmail })
      })
  
      if (assignedUserRes) {
        const resJSON = await assignedUserRes.json()

        if (resJSON) {
          if(resJSON.body) {
            const body = JSON.parse(resJSON.body);
            return body.user
          }
        }
      }
  
    } catch (e) {
      return {}
    }
  }

  async function sendUserEmailForAssignment(userEmail: any, boardId: any) {
    try {
      const board = await database.collection('boards').doc(boardId).get()
      if (!board.exists || !board.data()) {
        return false
      }

      const data = {
        userId: uid,
        assignedEmail: userEmail,
        boardName: board.data()?.name,
        boardLink: `/#/board/${boardId}/action-items`
      }

      const assignedUserRes = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendEmailForAssignedItem`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
  
      if (assignedUserRes) {
        const resJSON = await assignedUserRes.json()

        if (resJSON) {
          if(resJSON.body) {
            return true
          }
        }
      }

      return false
  
    } catch (e) {
      return {}
    }
  }

  async function onUpdateCardText(key: string, text: string) {
    let updatingText = text
    const card = cards[key]
    let assignedUserId = card.assignedUserId
    let assignedUserEmail = card.assignedUserEmail
    let assignedUserName = card.assignedUserName
    let assignedUserPhotoUrl = card.assignedUserPhotoUrl

    if (card.type === 'actions') {
      let assignedEmail = ''
      let newText = text
      const indexOfAt = text.indexOf('@')
      if (indexOfAt > 0) {
        const textAfterAt = text.substring(indexOfAt + 1)
        const indexOfSpace = textAfterAt.indexOf(' ')
        if (indexOfSpace > 0) {
          const textBeforeSpace = textAfterAt.substring(0, indexOfSpace)
          assignedEmail = textBeforeSpace
        } else {
          assignedEmail = textAfterAt
        }
      }
  
      if (assignedEmail) {
        newText = text.replace(` @${assignedEmail}`, '')
        newText = newText.replace(`@${assignedEmail} `, '')
        newText = newText.replace(`@${assignedEmail}`, '')

        // check if email exist
        let assignedUser = await getUserFromEmail(assignedEmail.trim())
        if (!assignedUser?.uid) {
          assignedUser = {
            uid: null,
            email: null,
            name: null,
            photoUrl: null,
          }
        }

        updatingText = newText
        assignedUserId = assignedUser.uid
        assignedUserEmail = assignedUser.email
        assignedUserName = assignedUser.name
        assignedUserPhotoUrl = assignedUser.photoUrl

        if (assignedUser?.uid) {
          if (assignedUser.uid !== card.assignedUserId || updatingText !== card.text) {
            sendUserEmailForAssignment(assignedUserEmail, boardId)
          }
        }
      } else if (assignedUserEmail && updatingText !== card.text) {
        sendUserEmailForAssignment(assignedUserEmail, boardId)
      }
    }

    database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(key)
      .update({
        text: updatingText,
        assignedUserId: assignedUserId || null,
        assignedUserEmail: assignedUserId ? assignedUserEmail : null,
        assignedUserName: assignedUserId ? assignedUserName : null,
        assignedUserPhotoUrl: assignedUserId? assignedUserPhotoUrl : null
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not update text on card', {
          extra: {
            reason: err.message,
            uid: uid,
            boardId: ownProps.boardId,
            cardId: key
          }
        });
      });
  }

  function groupCards(cardSourceId: string, cardTargetId: string) {
    if (
      cards[cardSourceId].type === cards[cardTargetId].type &&
      cardSourceId !== cardTargetId
    ) {

      const cardGroup: CardGroup = {
        name: 'New Group',
        votes: 0,
        order: cards[cardTargetId].order,
        theme: cards[cardTargetId].theme,
        type: cards[cardTargetId].type,
        timestamp: new Date().toJSON(),
        cards: {
          [cardTargetId]: {
            order: 0
          },
          [cardSourceId]: {
            order: 1
          }
        },
        userVotes: {}
      };

      if(cardGroup) {
        database
          .collection('boards')
          .doc(boardId)
          .collection('cardGroups')
          .add(cardGroup)
          .catch((err: Error) => {
            Raven.captureMessage('Could not group cards', {
              extra: {
                reason: err.message,
                uid: uid,
                boardId: ownProps.boardId,
              }
            });
          });
      }
    }
  }

  function addCardToGroup(sourceCardId: string, targetGroupId: string) {
    const group: CardGroup = getVal(state.fbState, `data/cardGroups/${targetGroupId}`, {});
    if(group && group.cards) {
      const order = Object.keys(group.cards).length;
      database
        .collection('boards')
        .doc(boardId)
        .collection('cardGroups')
        .doc(targetGroupId)
        .update({ [`cards.${sourceCardId}.order`]: order })
        .catch((err: Error) => {
          Raven.captureMessage('Could not group cards', {
            extra: {
              reason: err.message,
              uid: uid,
              boardId: ownProps.boardId,
            }
          });
        });
    }
  }

  function reorderInGroup(sourceCardId: string, targetCardId: string, groupId: string) {
    if(sourceCardId && targetCardId && groupId) {
      const sourceOrder = getVal(state.fbState, `data/cardGroups/${groupId}/cards/${sourceCardId}/order`, undefined);
      const targetOrder = getVal(state.fbState, `data/cardGroups/${groupId}/cards/${targetCardId}/order`, undefined);
      if(sourceOrder !== targetOrder) {
        database
          .collection('boards')
          .doc(boardId)
          .collection('cardGroups')
          .doc(groupId)
          .update({
            [`cards.${sourceCardId}.order`]: targetOrder
          })
          .then(() => {
            database
              .collection('boards')
              .doc(boardId)
              .collection('cardGroups')
              .doc(groupId)
              .update({
                [`cards.${targetCardId}.order`]: sourceOrder
              })
              .catch((err: Error) => {
                Raven.captureMessage('Could not reorder cards', {
                  extra: {
                    reason: err.message,
                    uid: uid,
                    boardId: ownProps.boardId,
                  }
                });
              });
          })
          .catch((err: Error) => {
            Raven.captureMessage('Could not reorder cards', {
              extra: {
                reason: err.message,
                uid: uid,
                boardId: ownProps.boardId,
              }
            });
          });
      }
    }
  }

  async function onUngroup(cardId: string, groupId: string) {
    const cardOrder = getVal(state.fbState, `data/cardGroups/${groupId}/cards/${cardId}/order`, undefined);
    const cardsInGroup = getVal(state.fbState, `data/cardGroups/${groupId}/cards`, undefined);
    if(cardOrder !== undefined && cardsInGroup) {
      if(Object.keys(cardsInGroup).length === 2) {
        database
          .collection('boards')
          .doc(boardId)
          .collection('cardGroups')
          .doc(groupId)
          .delete()
          .catch((err: Error) => {
            Raven.captureMessage('Could not ungroup', {
              extra: {
                reason: err.message,
                uid: uid,
                boardId: ownProps.boardId,
              }
            });
          });
      } else {
        let newCards: {[key:string]: { order: number }} = {};
        await Object.keys(cardsInGroup).map((cardItem: string) => {
          if(cardItem !== cardId) {
            newCards[cardItem] = {
              order: cardsInGroup[cardItem].order - 1
            }
          }
        });

        database
          .collection('boards')
          .doc(boardId)
          .collection('cardGroups')
          .doc(groupId)
          .update({ cards: newCards })
          .catch((err: Error) => {
            Raven.captureMessage('Could not ungroup card', {
              extra: {
                reason: err.message,
                uid: uid,
                boardId: ownProps.boardId,
              }
            });
          });
      }
    }
  }

  const focusedCardId: string | undefined= board.focusedCardId;

  return {
    id: ownProps.card.id || '',
    author: {
      name: ownProps.card.author || '',
      photoUrl: ownProps.card.photoUrl || ''
    },
    isAdmin: admin,
    isFocused: focusedCardId == ownProps.card.id,
    owner: uid === ownProps.card.authorUid,
    ownVotes: ownProps.card.userVotes[uid],
    votes: ownProps.card.votes,
    canVote: !limitVotes || currentVotes >= limitVotes,
    boardUsers: boardUsers,

    onRemove: onRemoveCard,
    onUpvote: (key: string) => onUpdateVoteForCard(key, +1),
    onDownvote: (key: string) => onUpdateVoteForCard(key, -1),
    onUpdateText: onUpdateCardText,
    groupCards: groupCards,
    addCardToGroup: addCardToGroup,
    onUngroup,
    reorderInGroup,
    isShowAuthor,
  };
};

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    onEditMode: (active: boolean) => {
      dispatch({ type: EDIT_STATUS, isActive: active });
    }
  };
}
