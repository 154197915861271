import * as React from 'react';
import { Component } from 'react';
import { connect } from "react-redux";
import { groupBy, sortBy } from 'lodash';

import './ExportSideModal.scss';
import { Link } from "react-router-dom";
import { mapStateToProps } from './ExportSideModal.container';
import {BoardCards, BoardGroups, Card, CardGroup} from "../../../../types";
import { ColumnConfiguration } from "../../../../constants/Retrospective";
import moment = require("moment");

export interface ExportSideModalStateProps {
  cards: BoardCards | undefined;
  columns: ColumnConfiguration[];
  cardGroups: BoardGroups | undefined;
  printingCards: BoardCards | undefined;
  retroName: string;
  createdAt: number;
}

export interface ExportSideModalOwnProps {
  boardId: string;
}

export type ExportSideModalProps = ExportSideModalStateProps & ExportSideModalOwnProps;

class ExportSideModal extends Component<ExportSideModalProps, {}> {

  static sortCards(cards: BoardCards): { [columnName: string]: Card[] } {
    if(cards) {
      const cardsArray = Object.keys(cards).map(key => cards[key]);
      const groups = groupBy(cardsArray, 'type') as any;
      for (let key in groups) {
        const sorted = sortBy(groups[key], ['votes', 'timestamp']);
        sorted.reverse();
        groups[key] = sorted;
      }
      return groups;
    } else {
      return {};
    }
  }

  static sortGroups(groups: BoardGroups): { [columnName: string]: CardGroup[] } {
    if(groups) {
      const groupArray = Object.keys(groups).map(key => groups[key]);
      const groupedGroups = groupBy(groupArray, 'type') as any;
      for(let key in groupedGroups) {
        const sorted = sortBy(groupedGroups[key], ['order']);
        sorted.reverse();
        groupedGroups[key] = sorted;
      }

      return groupedGroups;
    } else {
      return {};
    }
  }

  renderCardItem(card: Card, index: number) {
    return(
      <div
        key={index}
        className="export-column-card"
      >
        <h4 className="export-column-card__title">{card.author}</h4>
        <div className="export-column-card__description">{card.text}</div>
        <div className="export-column-card__votes">{card.votes} votes</div>
      </div>
    )
  }

  renderGroupItem(group: CardGroup, index: number) {
    const { cards } = this.props;
    return(
      <div
        key={index}
        className="export-column-group"
      >
        <h3 className="export-column-group__title">{group.name} <div className="export-column-header__count">{Object.keys(group.cards).length}</div></h3>
        <div className="export-column-group-cards">
          {Object.keys(group.cards).map((cardId: string, index: number) => {
            const card: Card = {
              id: cardId,
              ...cards![cardId]
            };
            return(this.renderCardItem(card, index));
          })}
        </div>
      </div>
    )
  }

  render() {
    const {
      boardId,
      cards,
      columns,
      retroName,
      createdAt,
      printingCards,
      cardGroups
    } = this.props;

    if(!cards || Object.keys(cards).length === 0) {
      return(
        <div
          className="side-modal__box"
        >
          <div className="export__title">Board has no cards</div>
        </div>
      )
    } else {
      const creationDate: string = moment(createdAt).format('dddd, MMMM D, YYYY');
      let cardSorted: { [columnName: string]: Card[] } = printingCards ? ExportSideModal.sortCards(printingCards) : {};
      let groupSorted: { [columnName: string]: CardGroup[] } = cardGroups ? ExportSideModal.sortGroups(cardGroups): {};
      const exportIcon = require('!svg-inline-loader!../../../../assets/icon-20-export.svg');
      return(
        <div>
          <div className="side-modal__box">
            <h3 className="export__title">Retrospective results</h3>
            <div className="export__info">{retroName} &bull; {creationDate}</div>
            <Link
              to={`/print/${boardId}`}
              className="export-button"
            >
              <span
                className="export-button__icon"
                dangerouslySetInnerHTML={{ __html: exportIcon }}
              />
              Export to PDF
            </Link>
          </div>
          {columns.map((column, index) => {

            if((cardSorted && cardSorted[column.id]) || (groupSorted && groupSorted[column.id])) {
              let totalCards: number = !!cardSorted && !!cardSorted[column.id] && !!cardSorted[column.id].length ? cardSorted[column.id].length : 0;
              if(!!groupSorted && !!groupSorted[column.id]) {
                groupSorted[column.id].map((group: CardGroup) => {
                  if(group.cards) {
                    totalCards += Object.keys(group.cards).length;
                  }
                })
              }
              return (
                <div
                  key={index}
                  className="side-modal__box"
                >
                  <div className="export-column">
                    <div className="export-column-header">
                      <h2 className="export-column-header__title">{column.name}</h2>
                      <div className="export-column-header__count">{totalCards}</div>
                    </div>
                    <div className="export-column-main">
                      {!!cardSorted && !!cardSorted[column.id] && cardSorted[column.id].map((card: Card, index: number) => this.renderCardItem(card, index))}
                      {!!groupSorted && !!groupSorted[column.id] && groupSorted[column.id].map((group: CardGroup, index: number) => this.renderGroupItem(group, index))}
                    </div>
                  </div>
                </div>
                )
              } else {
              return false;
              }
            }
          )}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps)(ExportSideModal);
