import * as cx from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './AddCard.scss';
import { BoardProp } from '../../types';
import { mapStateToProps } from './AddCard.container';
import { Column, ColumnType, getTheme } from '../../constants/Retrospective';
import Input from '../Input/Input';
export type AddCardTheme = 'light' | 'dark' | 'mint';

export interface OwnAddCardProps extends BoardProp {
  column: Column;
  disabled?: boolean;
}

export interface StateAddCardProps {
  /** Callback function on add of card. */
  onAdd: (
    type: string,
    theme: ColumnType,
    text: string,
    timestamp?: string
  ) => void;
}

export type AddCardProps = OwnAddCardProps & StateAddCardProps;

export interface AddCardState {
  /** The current card text. */
  text: string;
  isTyping: boolean;
  isInputFocused: boolean;
}

const initialState: AddCardState = {
  text: '',
  isTyping: false,
  isInputFocused: false
};

export class AddCard extends Component<AddCardProps, AddCardState> {
  state: AddCardState = initialState;

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value) {
      if (!this.state.isTyping) {
        this.setState({ isTyping: true });
      }
    } else {
      this.setState({ isTyping: false });
    }
    this.setState({ text: (event.target as HTMLInputElement).value });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      // Enter-Key
      this.handleAdd();
    }
  };

  handleAdd = async () => {
    const { column, onAdd } = this.props;
    const { text } = this.state;

    if (text.length > 0) {
      onAdd(column.id, column.type, text);
      this.setState(() => ({ text: '', isTyping: false }));
    } else {
      const theme = getTheme(column.type);
      const input = document.getElementById(`${theme}Input`);
      if (input) {
        input.focus();
      }
    }
  };

  focusInput = () => {
    this.setState({ isInputFocused: true, isTyping: !!this.state.text });
  };

  blurInput = () => {
    this.setState({ isInputFocused: false, isTyping: false });
  };

  render() {
    const { column, disabled } = this.props;
    const { text, isTyping, isInputFocused } = this.state;

    return (
      <>
        <div className={cx('add-card', `add-card--theme-light`)}>
          <Input
            id={`${column.id}Input`}
            showUnderline={false}
            placeholder={`Add ${column.name} card`}
            value={text}
            onChange={(e: any) => this.handleChange(e)}
            onKeyDown={(e: any) => this.handleKeyDown(e)}
            onBlur={this.blurInput}
            onFocus={this.focusInput}
            disabled={disabled}
          />

          {!disabled && (
            <button
              type="button"
              className={`add-card__button ${isInputFocused && 'add-card__button--focused'}`}
              onClick={() => this.handleAdd()}
              aria-label={`Submit ${column.name} card`}
              disabled={disabled}
            />
          )}
        </div>
        <small
          className={`add-card__hint ${isTyping ? 'add-card__hint--show' : ''}`}
        >
          Hit <strong>Enter</strong> to add new card.
        </small>
      </>
    );
  }
}
export default connect(mapStateToProps)(AddCard as any);
