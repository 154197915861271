import * as React from 'react';
import * as cx from 'classnames';

import './PhaseMenu.scss';
import ReactTooltip from "react-tooltip";

export interface PhaseMenuProps {
  admin: boolean;
  guidedPhase: number;
  onPrevPhase: () => void;
  onNextPhase: () => void;
  prevPhaseName: string | undefined;
  nextPhaseName: string | undefined;
  phaseNames: any;
  totalPhases: number;
}

export class PhaseMenu extends React.Component<PhaseMenuProps, {}> {
  render() {
    const { admin, guidedPhase, onPrevPhase, onNextPhase, prevPhaseName, nextPhaseName, phaseNames, totalPhases } = this.props;

    return (
      <div className={cx('phase-menu')}>
        <span className="phase-menu__navigation">
          {phaseNames.map((item: any, index: number) =>
            <span key={index}>
              <span
                className={`phase-menu__current-phase-name ${index === phaseNames.length - 1 ? 'phase-menu__phase-phase-name--last' : index === 0 && 'phase-menu__phase-phase-name--first'} ${index === guidedPhase && 'phase-menu__phase-phase-name--active'}`}
                data-tip={item.description}
                data-for={`phase-name-description-${index}`}
              >
                <span
                  className={`phase-menu__phase-index ${guidedPhase === index ? 'phase-menu__phase-index--active' : ''}`}
                >
                  {index+1}
                </span>
                {item.name}
              </span>
              <ReactTooltip
                id={`phase-name-description-${index}`}
                place="bottom"
                effect="solid"
                delayShow={500}
              />
            </span>
          )}

          {admin && (
            <span className="phase-menu__dash" />
          )}

          {admin && (
              <button
                className="phase-menu__phase-button phase-menu__phase-button--previous"
                aria-label="Go to previous phase"
                disabled={guidedPhase <= 0}
                onClick={onPrevPhase}
                data-tip={
                  prevPhaseName
                    ? `Back to previous phase ${prevPhaseName.toUpperCase()}`
                    : undefined
                }
                data-for="prev-phase-button"
              >
                {prevPhaseName && (
                  <ReactTooltip
                    id="prev-phase-button"
                    place="bottom"
                    effect="solid"
                    delayShow={500}
                  />
                )}
              </button>
          )}
          {admin && (
            <button
              className="phase-menu__phase-button phase-menu__phase-button--next"
              aria-label="Go to next phase"
              disabled={guidedPhase >= totalPhases - 1}
              onClick={onNextPhase}
              data-tip={
                nextPhaseName
                  ? `Move to next phase ${nextPhaseName.toUpperCase()}`
                  : undefined
              }
              data-for="next-phase-button"
            >
              {nextPhaseName && (
                <ReactTooltip
                  id="next-phase-button"
                  place="bottom"
                  effect="solid"
                  delayShow={500}
                />
              )}
            </button>
          )}

        </span>
      </div>
    );
  }
}

export default PhaseMenu;
