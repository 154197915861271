import * as classNames from 'classnames';
import * as React from 'react';

import './Column.scss';
import {Card as CardModel, CardGroup} from '../../types';
import {
  ColumnConfiguration,
  IndexedPhaseConfiguration
} from '../../constants/Retrospective';
import { mapStateToProps } from './Column.container';
import FocusedCardComponent from './FocusedCardComponent';
import StackComponent from './StackComponent';
import ColumnHeader from './ColumnHeader';
import {
  CSSTransition,
  TransitionGroup } from 'react-transition-group';
import { Icon } from '../Icon';
import { connect } from 'react-redux';
import { ColumnOverview } from '../ColumnOverview';
import Action from '../Action';
import AddCard from '../AddCard';

export interface OwnColumnProps {
  boardUrl: string;
  boardId: string;

  /** The unique column type. */
  column: ColumnConfiguration;
  phase: IndexedPhaseConfiguration;

  isActive: boolean;

  hasNextColumn?: boolean;
  hasPreviousColumn?: boolean;
  onGoToNextColumn?: () => void;
  onGoToPrevColumn?: () => void;
  isCompactView: boolean;
  isBoardCompleted: boolean;
  isDiscussPhase: any;
  actionsColumn: any;

  className?: string;
}

export interface StateColumnProps {
  theme: string;
  cards: CardModel[];
  groups: CardGroup[];
  focused?: CardModel;
  isHidden: boolean;
  isExtended: boolean;
  isAdmin: boolean;
  isCustomBoard: boolean,
  changeCustomColumn: (id: string, value: string) => void
}

export type ColumnProps = OwnColumnProps & StateColumnProps;

export interface ColumnState {
  showOverview: boolean;
}

export class Column extends React.Component<ColumnProps, ColumnState> {
  state: ColumnState = {
    showOverview: false
  };

  toggleOverview = () => {
    this.setState({ showOverview: !this.state.showOverview });
  };

  render() {
    const {
      isAdmin,
      isActive,
      isHidden,
      isExtended,
      className,
      focused,
      column,
      phase,
      // theme,
      boardUrl,
      boardId,
      cards,
      groups,
      isCompactView,
      isCustomBoard
    } = this.props;

    const cardsCount = cards.length;

    return (
      <>
        {this.state.showOverview && (
          <ColumnOverview
            boardUrl={boardUrl}
            boardId={boardId}
            column={column.name}
            sorted={column.sorted}
            cardsCount={cardsCount}
            groups={groups}
            cards={cards}
            isVotingEnabled={column.voting.enabled}
            isVoteSummaryShown={column.voting.displayed && column.name !== 'Action'}
            toggleOverview={this.toggleOverview}
            isBoardCompleted={this.props.isBoardCompleted}
            phase={phase}
            columnObj={column}
            isDiscussPhase={this.props.isDiscussPhase}
            actionsColumn={this.props.actionsColumn}
          />
        )}
        <div
          className={classNames('column', className, {
            ['column--inactive']:
              !isActive && (focused ? focused.type !== column.id : true),
            ['column--hidden']: isHidden
          })}
          style={{ flex: isExtended ? phase.columns.length - 1 : 1 }}
        >
          {!focused && this.props.hasPreviousColumn && (
            <button
              className={classNames(
                'column__navigation',
                'column__navigation-left'
              )}
              type="button"
              onClick={this.props.onGoToPrevColumn}
              disabled={!isActive}
            >
              <Icon name="chevron-left" width={32} height={32} />
            </button>
          )}

          {!focused && this.props.hasNextColumn && (
            <button
              className={classNames(
                'column__navigation',
                'column__navigation-right'
              )}
              type="button"
              onClick={this.props.onGoToNextColumn}
              disabled={!isActive}
            >
              <Icon name="chevron-right" width={32} height={32} />
            </button>
          )}

          <ColumnHeader
            id={column.id}
            title={column.name}
            count={cardsCount}
            onToggleOverview={this.toggleOverview}
            isAdmin={isAdmin}
            isCustomBoard={isCustomBoard}
            changeCustomColumn={this.props.changeCustomColumn}
          />

          {this.props.isBoardCompleted ? (
            <>
              {isAdmin && (
                <Action theme="light">
                  <AddCard
                    boardId={boardId}
                    column={column}
                  />
                </Action>
              )}
            </>
          ) : (
            <Action theme="light">
              <AddCard
                boardId={boardId}
                column={column}
              />
            </Action>
          )}

          {!this.state.showOverview &&
            <TransitionGroup
              enter={true}
              exit={false}
              className="column__content"
            >
              <CSSTransition timeout={{ enter: 600 }}>
                <>
                  {focused && column.focus.align === 'left' && (
                    <FocusedCardComponent
                      key={focused.id}
                      boardId={boardId}
                      focused={focused}
                      className="component--large"
                      showVotes={column.voting.displayed}
                    />
                  )}

                  {!isExtended && (
                    <StackComponent
                      boardUrl={boardUrl}
                      boardId={boardId}
                      cards={cards}
                      groups={groups}
                      isVotingAllowed={column.voting.enabled && column.name !== 'Action'}
                      isVoteSummaryShown={column.voting.displayed && column.name !== 'Action'}
                      column={column}
                      isActive={isActive || !isCompactView}
                      className={classNames('column__stack-component', {
                        ['column__stack-component--hidden']: Boolean(focused)
                      })}
                      isBoardCompleted={this.props.isBoardCompleted}
                      isDiscussPhase={this.props.isDiscussPhase}
                      actionsColumn={this.props.actionsColumn}
                    />
                  )}

                  {focused && column.focus.align === 'right' && (
                    <FocusedCardComponent
                      key={focused.id}
                      boardId={boardId}
                      focused={focused}
                      className="component--large"
                      showVotes={column.voting.displayed}
                    />
                  )}
                </>
              </CSSTransition>
            </TransitionGroup>
          }
        </div>
      </>
    );
  }
}

export default connect<StateColumnProps, null, OwnColumnProps>(mapStateToProps)(Column);
