import * as React from 'react';
import PlusCircle from "../Icon/PlusCircle";
import * as cx from 'classnames';
import MinusCircle from "../Icon/MinusCircle";

export interface AddRemoveCircleInterface {
  onClick: () => void;
  isPlus: boolean;
  disableUpVotes: boolean;
  disableDownVotes: boolean
}

const AddRemoveCircle: React.FunctionComponent<AddRemoveCircleInterface> = ({ onClick, isPlus, disableUpVotes, disableDownVotes }) => {

  return(
    <>
      {isPlus ?
        <button
          className={cx(`add-dot`, {'add-dot--disabled': disableUpVotes, 'add-dot--clickable': !disableUpVotes})}
          onClick={onClick}
        >
          <PlusCircle
            svgClassName="add-dot__plus-circle"
            circleClassName="add-dot__plus-circle-icon-circle"
            plusLineClassName="add-dot__plus-circle-icon-plus-line"
          />
        </button>
        :
        <button
          className={cx(`add-dot`, {'add-dot--disabled': disableDownVotes, 'add-dot--clickable': !disableDownVotes})}
          onClick={onClick}
        >
          <MinusCircle
            svgClassName="add-dot__plus-circle"
            circleClassName="add-dot__plus-circle-icon-circle"
            minusLineClassName="add-dot__plus-circle-icon-plus-line"
          />
        </button>
      }
    </>
  )
};

export default AddRemoveCircle;
