import * as React from 'react';
import { Component } from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import * as cx from 'classnames';
import './ShareSideModal.scss';
import {getFirebase} from "react-redux-firebase";
import {analytics} from "../../../index";

export interface ShareSideModalState {
  showCopyInfo: boolean;
  emails: string;
  message: string;
  error: boolean;
}

export interface ShareSideModalProps {
  boardName: string;
}

class ShareSideModal extends Component<ShareSideModalProps, ShareSideModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showCopyInfo: false,
      emails: '',
      message: '',
      error: false
    };
  }

  validation = (email: string): boolean => {
    const pattern = new RegExp(/^([\w-+.]+@([\w-]+\.)+[\w-]{2,20})$/);
    return pattern.test(email);
  };

  onSendMail = async (e: any) => {
    const target = e.target;
    const { boardName } = this.props;
    const { emails, error } = this.state;
    target.disabled = true;
    let userName = '';
    const auth = getFirebase().auth();
    if(auth.currentUser) {
      userName = auth.currentUser.displayName || '';
    }

    if(!error ) {
      try {
        let isValid: boolean = true;
        const emailArray: string[] = [];
        await emails.split(',').map((email: string, index: number) => {
          const emailTrim = email.trim();
          if(!this.validation(emailTrim)) {
            isValid = false;
          } else {
            emailArray.push(emailTrim);
          }
        });

        if(!isValid) {
          target.disabled = false;
          this.setState({ error: true });
        } else {
          const data = {
            email: emailArray.join(', '),
            type: 'invite',
            callerName: userName,
            boardName: boardName,
            boardLink: window.location.href
          };

          this.setState({ message: 'Please wait...' }, () => {
            fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendMail`, {
              method: 'POST',
              body: JSON.stringify(data)
            })
              .then(res => res.json())
              .then((data) => {
                if(data.body) {
                  const body = JSON.parse(data.body);
                  if(body.success) {
                    analytics.logEvent('invite_to_board');
                    this.setState({ message: 'Email invitation is successfully sent', emails: '' });
                    target.disabled = false;
                  } else {
                    this.setState({ message: body.error });
                    target.disabled = false;
                  }
                } else {
                  this.setState({ message: 'Failed to sent invitations' });
                  target.disabled = false;
                }
              })
              .catch((e) => {
                console.log(e);
                this.setState({ message: 'Failed to sent invitations' });
                target.disabled = false;
              })
          });
        }
      } catch (e) {
        target.disabled = false;
        console.log(e);
      }
    } else {
      target.disabled = false;
    }
  };

  displayCopyMessage = () => {
    this.setState({ ...this.state, showCopyInfo: true }, () => {
      setTimeout(() => {
        this.setState({ showCopyInfo: false });
      }, 3000)
    });
  };

  onChangeEmails = (e: any) => {
    this.setState({ emails: e.target.value });
  };

  onFocusEmails = () => {
    this.setState({ error: false });
  };

  onBlurEmails = async (e: any) => {
    const emails: string = e.target.value;
    try {
      let isValid: boolean = true;
      await emails.split(',').map((email: string, index: number) => {
        const emailTrim = email.trim();
        if(!this.validation(emailTrim)) {
          isValid = false
        }
      });

      if(!isValid) {
        this.setState({ error: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  onKeyUpTextArea = (e: any) => {
    const target = e.target;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`
  };

  render() {
    const { emails, showCopyInfo, message, error } = this.state;

    const link = window.location.href.replace('board', 'join');
    const shareIcon = require(`!svg-inline-loader!../../../assets/icon-19-12-share.svg`);

    return (
      <div className="side-share">
        <div className="side-share-link-wrapper">
          <h5 className="side-share-link__title">GET SHAREABLE LINK</h5>
          <span className="side-share__link">
            <label className="share-modal__text">{link}</label>
            <CopyToClipboard text={link} onCopy={this.displayCopyMessage}>
              <span
                className="side-share__icon"
                dangerouslySetInnerHTML={{ __html: shareIcon }}
              />
            </CopyToClipboard>
          </span>
          <CopyToClipboard text={link} onCopy={this.displayCopyMessage}>
            <button type="button" className="side-share__invite-button">
              Copy invite URL
            </button>
          </CopyToClipboard>
          <div
            className={cx('side-share__copy-text', {
              'side-share__copy-text--hidden': !showCopyInfo
            })}
          >
            URL copied to clipboard
          </div>
        </div>

        <div className="side-share-link-wrapper">
          <h5 className="side-share-link__title">INVITE BY EMAIL</h5>
          <textarea
            placeholder="Enter emails separated by comma (,)"
            className={cx("side-share-input__textarea", {"side-share-input__textarea--error": error })}
            value={emails}
            onChange={this.onChangeEmails}
            onFocus={this.onFocusEmails}
            onBlur={this.onBlurEmails}
            onKeyUp={this.onKeyUpTextArea}
          />
          <button
            className="side-share__invite-button"
            onClick={this.onSendMail}
            id="emailSendBtn"
          >
            SEND
          </button>
          <div className="side-share__message">{!!message ? message : error ? 'Invalid emails input' : 'Invited users will get an email with join link'}</div>
        </div>
      </div>
    );
  }
}

export default ShareSideModal;
