import * as React from 'react';

import './StartButton.scss';

export interface StartButtonProps {
  onStart: (e?: any) => void;
}

export class StartButton extends React.Component<
  StartButtonProps,
  {}
> {
  constructor(props: StartButtonProps) {
    super(props);
  }

  render() {
    const { onStart } = this.props;

    return (
      <div className="start-button">
        <button
          aria-label="Start session"
          className="start-button__start"
          onClick={onStart}
          id="startBtn"
        >
          <h3 className="start-button__start-label">Start Retrospective</h3>
        </button>
      </div>
    );
  }
}

export default StartButton;
