import * as React from 'react';

import './MenuGeneral.scss';
import { ModalType } from '../../types';
import TimerButton from '../TimerButton';
import DeleteModal from '../Modal/variant/DeleteModal';
import * as cx from 'classnames';
import ReactTooltip from "react-tooltip";
import SideModal from "../SideModal";
import ExportSideModal from "../SideModal/variant/ExportSideModal";

export interface MenuGeneralProps {
  admin: boolean;
  completed: boolean;
  hasCards: boolean;
  onOpenModal: (modal: ModalType) => void;
  onSetTimer: (second: number) => void;
  showDelete?: boolean;
  onCloseModal?: () => void;
  markAsDoneDisabled: boolean | undefined;
  isReady: boolean | undefined;
  mobileVersion: boolean | undefined;
  toggleReadyState: () => void;
  boardId: string;
  openLoading: () => Promise<void>;
  closeLoading: () => Promise<void>;
}

export interface MenuGeneralState {
  isPrintOpen: boolean
}

class MenuGeneral extends React.Component<MenuGeneralProps, MenuGeneralState> {
  constructor(props: MenuGeneralProps) {
    super(props);
    this.state = {
      isPrintOpen: false
    }
  }

  openExport = () => {
    const { hasCards } = this.props;
    if(hasCards) {
      this.setState({ isPrintOpen: true })
    }
  };

  closeExport = () => {
    this.setState({ isPrintOpen: false });
  };

  render() {

    const {
      admin,
      completed,
      onOpenModal,
      onSetTimer,
      showDelete,
      onCloseModal,
      markAsDoneDisabled,
      isReady,
      toggleReadyState,
      boardId,
      openLoading,
      closeLoading,
      mobileVersion,
    } = this.props;

    const { isPrintOpen } = this.state;

    const shareIcon = require(`!svg-inline-loader!../../assets/icon-19-12-share.svg`);
    const deleteIcon = require(`!svg-inline-loader!../../assets/icon-16-19-delete.svg`);
    const settingsIcon = require(`!svg-inline-loader!../../assets/icon-20-settings.svg`);
    const readyIcon = require(`!svg-inline-loader!../../assets/icon-16-ready.svg`);
    const exportIcon = require(`!svg-inline-loader!../../assets/icon-20-export.svg`);

    const generalMenuCSS = cx({"general-menu": !mobileVersion, "general-menu--mobile": mobileVersion})

    return (
      <div className={generalMenuCSS}>
        <div className="general-menu--centered">

          {!completed && !markAsDoneDisabled && (
            <>
              <span
                className={cx("general-menu-item__button general-menu-item__button--ready", {'general-menu-item__button--ready-active': isReady})}
                onClick={toggleReadyState}
                dangerouslySetInnerHTML={{ __html: readyIcon }}
                data-tip='Mark when you are done'
                data-for='readyTooltip'
              />
              <ReactTooltip
                id='readyTooltip'
                place="bottom"
                effect="solid"
                isCapture={false}
              />
            </>
          )}

          <span
            id="shareLinkBtn"
            className="general-menu-item__button general-menu-item__button--share"
            onClick={() => onOpenModal('share')}
            dangerouslySetInnerHTML={{__html: shareIcon}}
            data-tip="Invite Link"
            data-for="shareTooltip"
          />
          <ReactTooltip
            id="shareTooltip"
            place="bottom"
            effect="solid"
            isCapture={false}
          />

          {admin && (
            <>
              {!completed && (
                <TimerButton onSetTimer={onSetTimer} />
              )}
              <span
                className="general-menu-item__button general-menu-item__button--delete"
                onClick={() => onOpenModal('delete')}
                dangerouslySetInnerHTML={{__html: deleteIcon}}
                data-tip="Delete"
                data-for="deleteTooltip"
              />
              <ReactTooltip
                id="deleteTooltip"
                place="bottom"
                effect="solid"
                isCapture={false}
              />
            </>
          )}

          <span
            className="general-menu-item__button general-menu-item__button--export"
            dangerouslySetInnerHTML={{ __html: exportIcon }}
            onClick={this.openExport}
            data-tip="Export"
            data-for="exportTooltip"
          />
          <ReactTooltip
            id="exportTooltip"
            place="bottom"
            effect="solid"
            isCapture={false}
          />

          {admin && (
            <>
              <span
                className="general-menu-item__button general-menu-item__button--settings"
                dangerouslySetInnerHTML={{__html: settingsIcon}}
                onClick={() => this.props.onOpenModal('settings')}
                data-tip="Settings"
                data-for="settingsTooltip"
              />
              <ReactTooltip
                id="settingsTooltip"
                place="bottom"
                effect="solid"
                isCapture={false}
              />
            </>
          )}

          {showDelete && (
            <DeleteModal
              onClose={() => (onCloseModal ? onCloseModal() : '')}
              boardId={boardId}
              openLoading={openLoading}
              closeLoading={closeLoading}
            />
          )}

          <SideModal
            title="Meeting summary"
            isOpen={isPrintOpen}
            onClose={this.closeExport}
          >
            <ExportSideModal
              boardId={boardId}
            />
          </SideModal>

        </div>
      </div>
    );
  }
}

export default MenuGeneral;
