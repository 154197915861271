import {Board, BoardCards, BoardGroups, Card, CardGroup, StoreState} from '../../types';
import { getVal, getFirebase } from 'react-redux-firebase';
import { get, debounce } from 'lodash';
import { FirebaseFirestore } from '@firebase/firestore-types';

import { OwnColumnProps, StateColumnProps } from './Column';
import {ColumnConfiguration, getTheme, PhaseConfiguration} from '../../constants/Retrospective';
import { Key } from 'ts-keycode-enum';
import Raven = require('raven-js');

function sortCards(cards: Card[], sortByVotes: boolean): Card[] {
  const compareOrder = (a: Card, b: Card) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  };

  if (sortByVotes) {
    return cards
      .sort((a, b) => {
        if (a.votes > b.votes) {
          return 1;
        } else if (a.votes < b.votes) {
          return -1;
        } else {
          return compareOrder(a, b);
        }
      })
      .reverse();
  } else {
    return cards.sort(compareOrder).reverse();
  }
}

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnColumnProps
): StateColumnProps => {
  const user = getVal(state.fbState, 'auth', undefined);
  const uid: string = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : '';
  const board: Board = getVal(state.fbState, `data/${ownProps.boardUrl}`);
  const isAdmin: boolean = board.creatorUid === uid;
  const database: FirebaseFirestore = getFirebase().firestore();

  const boardCards: BoardCards | undefined = getVal(state.fbState, `data/cards`, {});

  const boardGroups: BoardGroups | null = getVal(state.fbState, `data/cardGroups`, {});

  const isCustomBoard: boolean = board.mode === 'custom';

  const focusedCardId: string = board.focusedCardId || '';

  if (!focusedCardId) {
    window.onkeydown = () => {};
  }

  let focusTarget = undefined;

  const focusedCard = boardCards ? boardCards[focusedCardId] : undefined;
  let focused: Card | undefined = undefined;
  if (focusedCard) {
    if (focusedCard.type === ownProps.column.id) {
      focused = focusedCard;
      focused.id = focusedCardId;
    }
    focusTarget = get(
      ownProps.phase.columns.find(column => column.id === focusedCard.type),
      'focus.column'
    );
  }

  const showAsFocusTarget = focusedCard
    ? ownProps.column.id === focusTarget
    : false;
  const isFocusOrigin = focusedCard
    ? focusedCard.type === ownProps.column.id
    : false;

  const isHidden = focusedCard ? !isFocusOrigin && !showAsFocusTarget : false;
  const isExtended = focusedCard
    ? isFocusOrigin && ownProps.column.id !== focusTarget
    : false;

  let cards: Card[] = boardCards ? Object.keys(boardCards)
    .map(key => {
      return { id: key, ...boardCards[key], origin: 'card' };
    })
    .filter(card => card.type === ownProps.column.id) : [];

  let groups: CardGroup[] = boardGroups ?Object.keys(boardGroups)
    .map(key => {
      return {id: key, ...boardGroups[key], origin: 'group'}
    })
    .filter((group) => group.type === ownProps.column.id) : [];


  let cardsWithFocused: Card[] = boardCards ? Object.keys(boardCards)
    .map(key => {
      return { id: key, ...boardCards[key] };
    })
    .filter(card => card.type === ownProps.column.id)
    .filter(
      card =>
        focused && card.id === (focused as Card).id
    ) : [];
  cardsWithFocused = sortCards(cardsWithFocused, ownProps.column.sorted);

  function onFocusCard(cardId: string | null) {

    database
      .collection('boards')
      .doc(ownProps.boardId)
      .update({ focusedCardId: cardId})
      .catch((err: Error) => {
        Raven.captureMessage('Could not set focus on card', {
          extra: {
            reason: err.message,
            uid: user.uid,
            boardId: ownProps.boardUrl,
            cardId
          }
        });
      });
  }

  if (focused && focused.type === ownProps.column.id) {
    const isFocusedStacked = cards.length !== cardsWithFocused.length;
    const stackToSearch = isFocusedStacked ? cardsWithFocused : cards;

    let index = stackToSearch.findIndex((card: Card) => {
      return card.id === (focused as Card).id;
    });

    if (state.app.keyboardNavigationEnabled) {
      window.onkeydown = (ev: KeyboardEvent) => {
        if (isAdmin) {
          // on escape
          if (ev.keyCode === Key.Escape) {
            if (document.getElementById('portal')!.children.length === 0) {
              onFocusCard(null);
              ev.preventDefault();
            }
          }

          // on arrow up
          if (ev.keyCode === Key.UpArrow) {
            if (index > 0) {
              onFocusCard(cards[index - 1].id as string);
              ev.preventDefault();
            }
          }

          // on arrow down
          if (ev.keyCode === Key.DownArrow) {
            if (index < cards.length - 1) {
              onFocusCard(cards[index + (isFocusedStacked ? 0 : 1)]
                .id as string);
              ev.preventDefault();
            }
          }
        }
      };
    } else {
      window.onkeydown = () => {};
    }
  }

  const theme = getTheme(ownProps.column.type);

  const changeCustomColumn = debounce( async(id: string, value: string ) => {
    const customConfig: PhaseConfiguration[] = getVal(state.fbState, `data/boards/${ownProps.boardId}/customConfig`, []);
    if(customConfig) {
      const updateConfig: PhaseConfiguration[] = [];
      await customConfig.map(async (phaseConfig: PhaseConfiguration, index: number) => {
        let newColumn: ColumnConfiguration[] = [];
        await phaseConfig.columns.map((column: ColumnConfiguration, index: number) => {
          if(column.id === id) {
            newColumn.push({
              ...column,
              name: value
            })
          } else {
            newColumn.push(column);
          }
        });

        updateConfig.push({
          ...phaseConfig,
          columns: newColumn
        });
      });

      if(updateConfig.length) {
        database
          .collection('boards')
          .doc(ownProps.boardId)
          .update({ customConfig: updateConfig })
      }
    }
  }, 2000);

  return {
    theme,
    cards,
    groups,
    focused,
    isHidden,
    isExtended,
    isAdmin,
    isCustomBoard,
    changeCustomColumn
  };
};
