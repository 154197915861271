import cx from 'classnames';
import { getFirebase } from "react-redux-firebase";
import SideModal from 'components/SideModal';
import * as React from 'react';
import { Component } from 'react';
import { analytics } from "../../index";
import { Link } from "react-router-dom";
import './ActionItems.scss';
import ActionItemsCreate from './ActionItemsCreate';
import SingleActionItem from './SingleActionItem'
import { onPayment } from 'analytics';

const INNER_TABS = {
  ALL: 'All',
  OPEN: 'Open',
  COMPLETED: 'Done',
  ASSIGNED_TO_ME: 'Assigned to me',
}

const MAX_DISPLAYED_ITEMS = 6

export interface ActionItemsState {
  isLoading: boolean;
  canSeePage: boolean;
  innerTab: string;
  isOpenSide: boolean;
  clickedCard: any;
  cards: any,
  visibleCards: any,
  currentPage: number;
  currentPageInput: any;
}

export interface ActionItemsStateProps {
  activeUserUid: string;
  isAnonymous: boolean;
  getCards: any;
  cards: any;
  liveUpdate: boolean;
  onMarkCardAsDone: (boardId: string, cardId: string) => any;
  onMarkCardAsUndone: (boardId: string, cardId: string) => any;
  onDelteCard: (boardId: string, cardId: string) => any;
  onUpdateCard: (card: any) => any;
}

export type ActionItemsInnerProps = ActionItemsStateProps;

class ActionItems extends Component<ActionItemsInnerProps, ActionItemsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      canSeePage: true,
      innerTab: INNER_TABS.ALL,
      isOpenSide: false,
      clickedCard: {},
      cards: [],
      visibleCards: [],
      currentPage: 1,
      currentPageInput: 1,
    };
  }

  componentDidMount() {
    // @ts-ignore
    analytics.logEvent('Action Items');
    this.checkCanUserSeePage()
    this.fetchCards();
  }

  checkCanUserSeePage = async () => {
    const auth = getFirebase().auth().currentUser;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/canUserSeeActionItemsPage`, {
      method: 'POST',
      body: JSON.stringify({
        uid: auth?.uid
      })
    })
      .then((response) => response.json())
      .then((res) => {
        const body = JSON.parse(res.body);
        if(body?.stripeInfoData) {
          const amount = body?.stripeInfoData?.planAmount
          if (!amount || amount === 0) {
            this.setState({canSeePage: false})
          }
        } else {
          this.setState({canSeePage: false})
        }

        this.setState({isLoading: false})
      })
      .catch(() => {
        this.setState({canSeePage: false, isLoading: false})
      })
  }

  fetchCards = async () => {
    if (!this.props.liveUpdate) {
      const res = await this.props.getCards()
      this.setState({cards: res})
      this.getVisibleCards(res, this.state.innerTab, true)
    }
  }

  getVisibleCards = (cards: any, tab: string, updateState: boolean) => {
    let newVisibleCards = cards
    if(INNER_TABS.OPEN === tab) {
      newVisibleCards = cards?.filter((card: any) => card?.status !== 'done')
    }

    if(INNER_TABS.COMPLETED === tab) {
      newVisibleCards = cards?.filter((card: any) => card?.status === 'done')
    }

    if(INNER_TABS.ASSIGNED_TO_ME === tab) {
      newVisibleCards = cards?.filter((card: any) => card?.assignedUserId === this.props.activeUserUid)
    }

    if (!this.props.liveUpdate && updateState) {
      this.setState({visibleCards: newVisibleCards, currentPage: 1, currentPageInput: 1,})
    }

    return newVisibleCards
  }

  onTabChanged = (tab: string) => {
    this.setState({innerTab: tab})
    this.getVisibleCards(this.state.cards, tab, true)
  }

  onCreateNewAction = () => {
    this.setState({isOpenSide: true})
  }

  closeSide = () => {
    this.setState({isOpenSide: false})
  }

  onItemClicked = (card: any) => {
    this.setState({clickedCard: card})
    this.setState({isOpenSide: true})
  }

  onUpdatingCard = async (card: any) => {
    const isSuccess = await this.props.onUpdateCard(card)
    if (isSuccess) {
      this.fetchCards()
      this.setState({isOpenSide: false, clickedCard: {}})
    }

    return isSuccess
  }

  paymentScreen = (e: any) => {
    const auth = getFirebase().auth().currentUser;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`, {
      method: 'POST',
      body: JSON.stringify({
        uid: auth?.uid
      })
    })
      .then((response) => response.json())
      .then((res) => {
        const body = JSON.parse(res.body);
        if(body.session && body.session.url) {
          onPayment();
          window.location.href = body.session.url;
        }
      })
      .catch((e) => {
        console.log('e', e)
      })
  };

  onPageNumberChange = (e: any) => {
    const value = e.target.value
    const newPage = parseInt(value)

    if (value === '') {
      this.setState({ currentPageInput: '' })
      return
    }

    if (!Number.isNaN(newPage)) {
      this.updatePage(newPage)
    }
  }

  onInputBlur = () => {
    if (this.state.currentPageInput === '') {
      this.setState({ currentPageInput: this.state.currentPage })
    }
  }

  updatePage = (newPage: number) => {
    let cards = this.state.visibleCards

    if (this.props.liveUpdate) {
      cards = this.getVisibleCards(this.props.cards, this.state.innerTab, true)
    }

    const rest = cards.length % MAX_DISPLAYED_ITEMS
    let maxPage = parseInt("" + cards.length / MAX_DISPLAYED_ITEMS)
    if (rest > 0) {
      maxPage++
    }

    if (newPage < 1) {
      this.setState({ currentPage: 1, currentPageInput: 1 })
    } else if (newPage > maxPage) {
      this.setState({ currentPage: maxPage, currentPageInput: maxPage })
    } else {
      this.setState({ currentPage: newPage, currentPageInput: newPage })
    }
  }

  render() {
    const plusIcon = require('!svg-inline-loader!../../assets/icon-14-plus.svg');
    let cards = this.state.visibleCards
    const canCreateNewOne = false

    if (this.props.liveUpdate) {
      cards = this.getVisibleCards(this.props.cards, this.state.innerTab, true)
    }

    const showOnlyForPaid = process.env.REACT_APP_SHOW_ACTION_ITEMS_ONLY_TO_PAID_USERS == 'true' || process.env.REACT_APP_SHOW_ACTION_ITEMS_ONLY_TO_PAID_USERS == 'TRUE'

    const { currentPage, currentPageInput } = this.state
    const rest = cards.length % MAX_DISPLAYED_ITEMS
    let maxPage = parseInt("" + cards.length / MAX_DISPLAYED_ITEMS)
    if (rest > 0) {
      maxPage++
    }

    let firstFilterIndex = 0
    let lastFilterIndex = MAX_DISPLAYED_ITEMS

    if (currentPage > 1) {
      firstFilterIndex = (currentPage - 1) * MAX_DISPLAYED_ITEMS
      lastFilterIndex = firstFilterIndex + MAX_DISPLAYED_ITEMS
    }

    const displayedData = cards.slice(firstFilterIndex, lastFilterIndex)

    if(!this.state.canSeePage && showOnlyForPaid) {
      return (
        <div className="action-items-unauthorized">
          Please{' '}
          <span onClick={this.paymentScreen}>
            subscribe
          </span>
          {' '}to view this page.
        </div>
      )
    }

    if(this.state.isLoading) {
      return(
        <div className='action-items-checking'>Checking your account...</div>
        );
    } else {
      return (
        <div className="container dashboard-action-items">
          <div className='action-items-sidebar'>
            {canCreateNewOne && (
              <button
                className="action-items__button"
                onClick={this.onCreateNewAction}
              >
                <span dangerouslySetInnerHTML={{ __html: plusIcon }} />
                New Action Item
              </button>
            )}
            <ul className='action-items-sidebar-options'>
              {Object.values(INNER_TABS).map(tab => {
                const allCards = this.props.liveUpdate ? this.props.cards : this.state.cards
                const cardsForTab = this.getVisibleCards(allCards, tab, false)?.length || 0
                const css = cx('action-items-sidebar-options__item', {'action-items-sidebar-options__item--active': tab === this.state.innerTab})

                return (
                  <li key={tab} className={css} onClick={() => this.onTabChanged(tab)}>
                    {tab} <span style={{color: '#FA6400'}}>({cardsForTab})</span>
                  </li>
                )
              })}
            </ul>
          </div>
          {displayedData.length === 0 && (
            <div style={{textAlign: 'center'}}>You can create new action items via the <Link className='action-items-no-items-link' to='/new'>Retrospective board</Link>.</div>
          )}
          {displayedData.length > 0 && (
            <div>
              <div className='action-items-container'>
                {displayedData.map((card: any) => {
                  return (
                    <SingleActionItem 
                      key={card.id}
                      card={card}
                      getCards={this.fetchCards}
                      onMarkCardAsDone={this.props.onMarkCardAsDone}
                      onMarkCardAsUndone={this.props.onMarkCardAsUndone}
                      onDelteCard={this.props.onDelteCard}
                      onItemClicked={this.onItemClicked} 
                    />
                  )
                })}
              </div>
              <div style={{marginTop: '24px', marginBottom: '50px'}} className='DataTable__navigation'>
                <button disabled={currentPage === 1} onClick={() => this.updatePage(currentPage - 1)}>Prev</button>
                <input pattern="[0-9]+" value={currentPageInput} onChange={this.onPageNumberChange} onBlur={this.onInputBlur} />
                <div>/ {maxPage}</div>
                <button disabled={currentPage === maxPage} onClick={() => this.updatePage(currentPage + 1)}>Next</button>
              </div>
            </div>
          )}
          <SideModal
            title="Action Item"
            isOpen={this.state.isOpenSide}
            onClose={this.closeSide}
            className="item-actions-sidebar"
          >
            <ActionItemsCreate isAnonymous={this.props.isAnonymous} clickedCard={this.state.clickedCard} onUpdate={this.onUpdatingCard} />
          </SideModal>
        </div>
      );
    }

  }
}

export default ActionItems;
