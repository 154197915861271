import * as React from 'react';
import './BackButton.scss';

const BackButton: React.SFC<any> = () => {
  const onClick = () => {
    window.location.replace('#/dashboard');
  };

  return (
    <div onClick={onClick} className="header__back-button">
      Back
    </div>
  );
};

export default BackButton;
