import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { getFirebase, firestoreConnect, ReduxFirestoreQueries } from "react-redux-firebase";
import { compose } from "redux";
import { Link } from "react-router-dom";
import * as cx from 'classnames';
import { DocumentSnapshot } from '@firebase/firestore-types';
import moment = require("moment");

import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import PersonalInfo from "./AccountSettings/PersonalInfo";
import DeactivateDelete from "./AccountSettings/DeactivateDelete";
import { mapStateToProps } from "./Account.container";
import './Account.scss';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import TeamManager from "./AccountSettings/TeamManager";
import PlanExceededModal from "../../components/Modal/variant/PlanExceededModal";
import InfoModal from "../../components/Modal/variant/InfoModal";
import {analytics} from "../../index";
import { onPayment } from "../../analytics";

export interface AccountState {
  activeTab: 'account' | 'deactivation' | 'teams';
  isLoading: boolean;
  isCreatingSession: boolean;
  isFreePlan: boolean;
  isOpenModal: boolean;
  loadingStatus: string;
  teamInvite: 'join' | 'declined' | '';
  teamInvitedBy?: string;
  teamName?: string;
  teamId: string;
  createdTeam: boolean;
}

export interface AccountStateProps {
  uid: string;
  name: string;
  email: string;
  createdAt: string | undefined;
  onChangeData: (data: AccountData ) => void;
  onDeactivateUser: () => Promise<void>;
  onDeleteUser: () => Promise<void>;
  isAnonymous: boolean;
}

export type AccountProps = RouteComponentProps & AccountStateProps;

export interface AccountData {
  username: string;
  email: string;
}

class Account extends Component<AccountProps, AccountState> {
  constructor(props: AccountProps) {
    super(props);
    this.state = {
      activeTab: 'account',
      isLoading: false,
      isCreatingSession: false,
      isFreePlan: false,
      isOpenModal: false,
      loadingStatus: '',
      teamInvite: '',
      teamId: '',
      createdTeam: false
    }
  }

  paymentScreen = (e: any) => {
   e.preventDefault();
     this.openLoading()
       .then(() => {
         this.setState({ isCreatingSession: true }, () => {
           fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`, {
             method: 'POST',
             body: JSON.stringify({
               uid: this.props.uid
             })
           })
             .then((response) => response.json())
             .then((res) => {
               const body = JSON.parse(res.body);
               if(body.session && body.session.url) {
                 onPayment();
                 window.location.replace(body.session.url);
               } else {
                 this.closeLoading();
               }
             })
             .catch(() => {
               this.closeLoading();
             })
         });
       })
   };

  closeTeamInvite = () => {
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.remove('account-portal--active');
    }
    this.setState({ teamInvite: '' })
  };

  openDeclineTeamUser = () => {
    this.setState({ isLoading: false, teamInvite: 'declined' }, () => {
      const portal = document.getElementById('portal');
      if(portal) {
        portal.classList.add('account-portal--active');
      }
    })
  };

  componentDidMount() {
    const { uid } = this.props;
    const user = getFirebase().auth().currentUser;

    if(uid && user && user.email) {
      // @ts-ignore
      analytics.logEvent('settings');
      const { location } = this.props;
      if(location.search && location.search.slice(0, 6) === '?team=') {
        const teamId: string = location.search.slice(6);
        this.setState({ activeTab: 'teams', isLoading: true }, () => {
          const firestore = getFirebase().firestore();
          firestore
            .collection('teams')
            .doc(teamId)
            .collection('pendingUsers')
            .doc('pendingUsers')
            .get()
            .then((snap: DocumentSnapshot) => {
              if(snap.exists && snap.data() && snap.data()![user.email!]) {
                const pendingUser = snap.data()![user.email!];
                this.setState({
                  isLoading: false,
                  teamInvite: 'join',
                  teamInvitedBy: pendingUser!.invitedBy,
                  teamName: pendingUser!.teamName,
                  teamId: teamId,
                  }, () => {
                    const portal = document.getElementById('portal');
                    if(portal) {
                      portal.classList.add('account-portal--active');
                    }
                  });
              } else {
                this.openDeclineTeamUser();
              }
            })
            .catch((e) => {
              console.log(e);
              this.openDeclineTeamUser();
            });
        })
      }

      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getPlanInfo`, {
        method: 'POST',
        body: JSON.stringify({ uid: uid})
      })
        .then(response => response.json())
        .then((res) => {
          if(res.body) {
            const data = JSON.parse(res.body);
            if(data && data.amount === 0) {
              this.setState({ isFreePlan: true });
            }
          }
        })
    }

    if(!!this.props.location.state && this.props.location.state === 'team') {
      this.setState({ activeTab: 'teams', })
    }

    const portalForm = document.getElementById('customerPortal');
    if(portalForm) {
      portalForm.addEventListener('submit', (e) => {
        this.paymentScreen(e);
      })
    }

    window.addEventListener('scroll', this.onScroll);
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.add('account-portal');
    }
  }

  onScroll = () => {
    const tabs = document.getElementById('tabs');
    if(tabs) {
      if(window.pageYOffset >= 89) {
        if(!tabs.classList.contains('account-tabs--fixed')) {
          tabs.classList.add('account-tabs--fixed');
        }
      } else {
        if(tabs.classList.contains('account-tabs--fixed')) {
          tabs.classList.remove('account-tabs--fixed');
        }
      }
    }
  };

  componentDidUpdate(prevProps: AccountStateProps, prevState: AccountState) {
    const { isFreePlan, isLoading } = this.state;

    if(prevState.isLoading && !isLoading) {
      const portal = document.getElementById('portal');
      if(portal) {
        portal.classList.remove('account-portal--active');
      }
      const portalForm = document.getElementById('customerPortal');
      if(portalForm) {
        portalForm.addEventListener('submit', (e) => {
          this.paymentScreen(e);
        })
      }
    }

    if(isFreePlan) {
      const portalForm1 = document.getElementById('customerPortal1');
      if(portalForm1) {
        portalForm1.addEventListener('submit', (e) => {
          this.paymentScreen(e);
        })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.remove('account-portal');
    }
  }

  openLoading = async (status: string = '') => {
    this.setState({ isLoading: true, loadingStatus: status });
  };

  closeLoading = () => {
    this.setState({ isLoading: false, loadingStatus: '' });
  };

  onCreateTeam = () => {
    this.setState({ createdTeam: true })
  };

  toggleTabs = (value: 'account' | 'deactivation' | 'teams') => {
    this.setState({ activeTab: value });
  };

  reloadPage = () => {
    window.location.reload();
  };

  openModal = () => {
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.add('account-portal--active');
    }
    this.setState({ isOpenModal: true });
  };

  closeModal = () => {
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.remove('account-portal--active');
    }
    this.setState({ isOpenModal: false });
  };

  onJoinTeam = (teamId: string) => {
    const { uid } = this.props;
    if(uid) {
      this.setState({ isLoading: true, loadingStatus: 'Registering as a team member' }, () => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/addTeamMember`, {
          method: 'POST',
          body: JSON.stringify({ uid: uid, teamId: teamId })
        })
          .then(res => res.json())
          .then((data) => {
            if(data && data.body) {
              this.setState({ isLoading: false, loadingStatus: '' })
            }
          })
      })
    }
  };

  render() {
    const {
      activeTab,
      isLoading,
      isCreatingSession,
      isFreePlan,
      isOpenModal,
      loadingStatus,
      teamInvite,
      teamInvitedBy,
      teamName,
      teamId,
      createdTeam
    } = this.state;

    const {
      uid,
      name,
      email,
      onChangeData,
      isAnonymous,
      createdAt,
      onDeactivateUser,
      onDeleteUser
    } = this.props;

    const now = moment(new Date().getTime());
    const created = moment(createdAt ? parseInt(createdAt) : now);
    const openTeam: boolean = this.props.location.state ? this.props.location.state === 'team' : false;

    const DeniedMessage = (
      <>
        <p>Redirecting to Payment and Subscription Page</p>
        <Link
          to='/account'
          className="board-guard__denied-link"
          onClick={this.reloadPage}
        >
          Cancel
        </Link>
      </>
    );

    return(
      <>
        {isLoading ?
          <LoadingScreen
            status={isCreatingSession ? DeniedMessage : loadingStatus}
          />
          :
          <div className="account">
            <HeaderDashboard
              isAccount={true}
            />
            <div className="container account-main">
              <div className="account-container">
                <div className="account-tabs-container">
                  <div className="account-tabs" id="tabs">
                    <ul
                      className="account-tabs-options"
                    >
                      <li
                        className={cx("account-tabs-options__item", {
                          "account-tabs-options__item--active": activeTab === 'account'
                        })}
                        onClick={() => this.toggleTabs('account')}
                      >
                        Account
                      </li>
                      <li
                        className={cx("account-tabs-options__item", {
                          "account-tabs-options__item--active": activeTab === 'deactivation'
                        })}
                        onClick={() => this.toggleTabs('deactivation')}
                      >
                        Deactivation and Deletion
                      </li>
                      {!isAnonymous &&
                        <form
                          method="POST"
                          action={`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`}
                          id="customerPortal"
                        >
                          <button
                            className="account-tabs-options__item account-tabs-options__item--button"
                            type="submit"
                            onClick={onPayment}
                          >
                            Payments Management
                          </button>
                        </form>
                      }
                      {!isAnonymous &&
                        <li
                          className={cx("account-tabs-options__item", {
                            "account-tabs-options__item--active": activeTab === 'teams'
                          })}
                          onClick={() => this.toggleTabs('teams')}
                        >
                          Team management
                        </li>
                      }
                    </ul>
                  </div>
                </div>
                {activeTab === 'account' &&
                  <PersonalInfo
                    userName={name}
                    userEmail={email}
                    onChangeData={onChangeData}
                    isAnonymous={isAnonymous}
                  />
                }
                {activeTab === 'deactivation' &&
                  <DeactivateDelete
                    onDeactivateUser={onDeactivateUser}
                    onDeleteUser={onDeleteUser}
                    isAnonymous={isAnonymous}
                    openLoading={this.openLoading}
                    closeLoading={this.closeLoading}
                  />
                }

                {activeTab === 'teams' && !isAnonymous &&
                  <TeamManager
                    paymentScreen={this.paymentScreen}
                    openLoading={this.openLoading}
                    closeLoading={this.closeLoading}
                    onCreateTeam={this.onCreateTeam}
                    createdTeam={createdTeam}
                    openModal={this.openModal}
                    openSide={openTeam}
                    userUID={uid}
                  />
                }
                {isAnonymous && activeTab !== 'teams' && (
                  <div
                    className="account-anonymous-container"
                  >
                    <div
                      className="account-anonymous"
                    >
                      <div className="account-anonymous__text">
                        Your data will be lost in {60 - now.diff(created, 'days')} days. Connect your email or social network account to keep your data remaining!
                      </div>
                        <Link
                          to="/register"
                          className="account-anonymous__link"
                        >
                          SIGN UP
                        </Link>
                    </div>
                  </div>
                )}

                {!isAnonymous && isFreePlan && activeTab !== 'teams' &&
                  <div
                    className="account-anonymous-container"
                  >
                    <div
                      className="account-anonymous"
                    >
                      <div className="account-anonymous__text">
                        Upgrade to paid plan to create more than 10 boards, invite more than 10 users, and create multiple teams
                      </div>
                      <form
                        method="POST"
                        action={`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`}
                        id="customerPortal1"
                      >
                        <button
                          className="account-anonymous__link account-tabs-options__item--button"
                          type='submit'
                          onClick={onPayment}
                        >
                          UPGRADE
                        </button>
                      </form>
                    </div>
                  </div>
                }
              </div>
            </div>

            {isOpenModal &&
              <PlanExceededModal
                uid={uid}
                onClose={this.closeModal}
                message='You reached maximum of teams. Upgrade to add more teams.'
                type='teams'
                openLoading={this.openLoading}
                closeLoading={this.closeLoading}
              />
            }

            {teamInvite === 'join'
              &&
            <InfoModal
              onClose={this.closeTeamInvite}
              onSubmit={() => this.onJoinTeam(teamId)}
              description={`You are invited by ${teamInvitedBy || ''} to join ${teamName}`}
              title="Join the team"
              buttonText="JOIN"
              classNames="account-modal"
            />
            }
            {teamInvite === 'declined'
              &&
            <InfoModal
              onClose={this.closeTeamInvite}
              onSubmit={this.closeTeamInvite}
              description="You don't have permissions to join this team"
              title="Access denied"
              classNames="account-modal"
            />
            }
          </div>
        }
      </>
    )
  }
}

function firebaseConnector(): ReduxFirestoreQueries {
  const firebase = getFirebase();
  const auth = firebase.auth();
  let uid = '';
  if (auth.currentUser) {
    uid = auth.currentUser.uid;
  }
  return [
    {
      collection: 'users',
      doc: uid
    }
  ];
}

export default compose<any, any, any>(firestoreConnect(firebaseConnector),connect(mapStateToProps))(Account);
