import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { getFirebase } from "react-redux-firebase";
import firebase from "firebase/app";
import "firebase/auth";
import { StyledFirebaseAuth } from "react-firebaseui";
import { Redirect, RouteComponentProps, StaticContext } from "react-router";
import { UserCredential } from "@firebase/auth-types";
import * as firebaseui from "firebaseui";
const picture = require("../../assets/image-dashboard.png");

import { Link } from "react-router-dom";
import { mapStateToProps } from "./Register.container";
import Plans from "./components/Plans";
import "./Register.scss";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import UserNameBox from "./components/UserNameBox";

export interface RegisterStateProps {
  uid: string;
  hasBoards: boolean;
  isAnonymous: boolean;
  addUserToDb: (authResult: any) => void;
  subscribeToFreePlan: (uid: string, name: string, email?: string) => void;
  upgradeAnonymousUser: () => void;
}

export type LocationState = {
  upgradePlan?: boolean;
};

export type RegisterProps = RegisterStateProps &
  RouteComponentProps<{}, StaticContext, LocationState>;

export interface RegisterState {
  authFinished: boolean;
  isLoaded: boolean;
  hasActivePlan: undefined | boolean | "payment-in-progress";
  subscribingProgress: boolean;
  showUsernameBox: boolean;
  authResult: UserCredential | undefined;
  isAnonymousCheckCompleted: boolean;
  successUrl: string;
  isJoinTeam: boolean;
}

class Register extends Component<RegisterProps, RegisterState> {
  interval: number | undefined;
  constructor(props: RegisterProps) {
    super(props);
    this.state = {
      authFinished: false,
      isLoaded: false,
      hasActivePlan: undefined,
      subscribingProgress: false,
      showUsernameBox: false,
      authResult: undefined,
      isAnonymousCheckCompleted: false,
      successUrl: process.env.REACT_APP_HOST || "",
      isJoinTeam: false,
    };
    this.interval = undefined;
  }

  componentDidMount() {
    this.checkUser();
  }

  checkUser = () => {
    const { uid } = this.props;
    if (uid) {
      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/isActiveUser`, {
        method: "POST",
        body: JSON.stringify({ uid: uid }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const body = JSON.parse(data.body);
            if (body) {
              this.setState({
                isLoaded: true,
                hasActivePlan: body.value,
              });
            }
          }
        });
    } else {
      this.setState({
        isLoaded: true,
      });
    }

    let successUrl: string = process.env.REACT_APP_HOST || "";
    const search = this.props.location.search;
    let isJoinTeam: boolean = false;
    if (search) {
      if (search.slice(0, 6) === "?team=") {
        isJoinTeam = true;
        successUrl += `#/account${search}`;
      }
    }

    this.setState({ successUrl: successUrl, isJoinTeam: isJoinTeam });
  };

  componentDidUpdate(
    prevProps: Readonly<RegisterProps>,
    prevState: Readonly<RegisterState>
  ) {
    const currentUser = getFirebase().auth().currentUser
      ? getFirebase().auth().currentUser
      : null;

    if (!prevProps.uid && this.props.uid && !this.props.isAnonymous) {
      this.setState({ isLoaded: false }, () => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/isActiveUser`, {
          method: "POST",
          body: JSON.stringify({ uid: this.props.uid }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              const body = JSON.parse(data.body);
              if (body) {
                this.setState({
                  isLoaded: true,
                  hasActivePlan: body.value,
                });
              }
            } else {
              this.setState({
                isLoaded: true,
              });
            }
          });
      });
    }

    if (
      !prevProps.uid &&
      !this.props.isAnonymous &&
      !!this.props.uid &&
      this.state.hasActivePlan !== true &&
      this.state.authFinished &&
      process.env.REACT_APP_HAS_PAYMENT === "false" &&
      !this.state.subscribingProgress
    ) {
      if (currentUser) {
        const email = currentUser.email ? currentUser.email : "";
        this.setState({ subscribingProgress: true });
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/isActiveUser`, {
          method: "POST",
          body: JSON.stringify({ uid: this.props.uid }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              const body = JSON.parse(data.body);
              if (!body.value) {
                this.props.subscribeToFreePlan(
                  currentUser!.uid,
                  currentUser!.displayName || "",
                  email
                );
              } else {
                this.setState({
                  hasActivePlan: body.value,
                  subscribingProgress: false,
                });
              }
            }
          });
      }
    }

    if (
      currentUser &&
      this.props.isAnonymous &&
      !this.state.showUsernameBox &&
      this.state.isLoaded &&
      !this.state.isAnonymousCheckCompleted
    ) {
      this.setState({ isLoaded: false });
      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/isActiveUser`, {
        method: "POST",
        body: JSON.stringify({ uid: currentUser.uid }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            const body = JSON.parse(data.body);
            if (!body.value) {
              this.setState({
                showUsernameBox: true,
                authFinished: true,
                isLoaded: true,
                isAnonymousCheckCompleted: true,
              });
            } else {
              this.setState({
                showUsernameBox: false,
                authFinished: false,
                isLoaded: true,
                isAnonymousCheckCompleted: true,
              });
            }
          }
        });
    }

    if (!this.interval && this.state.hasActivePlan === "payment-in-progress") {
      this.interval = window.setInterval(this.checkUser, 5000);
    } else if (
      this.interval &&
      this.state.hasActivePlan !== "payment-in-progress"
    ) {
      clearInterval(this.interval);
    }
  }

  completeAuth = (authResult: UserCredential) => {
    if (authResult.user) {
      if (!authResult.user.isAnonymous) {
        this.setState({ authFinished: true });
      } else {
        this.setState({
          showUsernameBox: true,
          authFinished: true,
          authResult: authResult,
        });
      }
    }
  };

  loading = () => {
    this.setState({ isLoaded: false });
  };

  onSwitchAccount = () => {
    firebase.auth().signOut();

    this.setState({ authFinished: false });
  };

  render() {
    const {
      authFinished,
      isLoaded,
      hasActivePlan,
      subscribingProgress,
      showUsernameBox,
      authResult,
      successUrl,
      isJoinTeam,
    } = this.state;
    const { uid, hasBoards, isAnonymous, subscribeToFreePlan } = this.props;

    let upgradePlan: boolean =
      this.props.location.search === "?upgradePlan=true";

    const uiConfig: any = isAnonymous
      ? {
          signInFlow: "popup",
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            //firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
          ],
          autoUpgradeAnonymousUsers: true,
          callbacks: {
            signInSuccessWithAuthResult: () => {
              this.props.upgradeAnonymousUser();
              return false;
            },
            signInFailure: async (error: any) => {
              if (error.code != "firebaseui/anonymous-upgrade-merge-conflict") {
                await Promise.resolve();
              }
              const cred = error.credential;
              firebase.auth().signInWithCredential(cred);
            },
          },
          signInSuccessUrl: `${process.env.REACT_APP_HOST}/#/new${
            upgradePlan ? "?upgradePlan=true" : ""
          }`,
        }
      : {
          signInFlow: "popup",
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            //firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
          ],
          callbacks: {
            signInSuccessWithAuthResult: (authResult: UserCredential) => {
              this.completeAuth(authResult);
              if (authResult.user) {
                if (!authResult.user.isAnonymous) {
                  this.props.addUserToDb(authResult);
                }
              }
              return false;
            },
          },
          signInSuccessUrl: successUrl,
        };

    const authElement = document.getElementById("firebaseui_container");

    // const showPlan: boolean = !!uid && hasActivePlan !== true && authFinished;

    if (hasActivePlan === "payment-in-progress") {
      return <LoadingScreen status="Payment in progress" />;
    }

    if (subscribingProgress) {
      return <LoadingScreen />;
    }

    if (!isLoaded && !authElement) {
      return <LoadingScreen />;
    } else if (!uid || (uid && isAnonymous)) {
      return (
        <div className="register">
          <div className="register-container">
            <Link to="/" className="register-container__logoContainer">
              <h1 className="register-container__logo">
                Retro<span>Team</span>
              </h1>
            </Link>
            {!authFinished && (
              <div className="auth-container">
                <div className="auth-providers">
                  <h2 className="auth-providers__title">Sign In</h2>
                  <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebase.auth()}
                  />
                </div>
                <div className="auth-image-container">
                  <h4 className="auth-image__title">
                    Easy retrospectives for your team
                  </h4>
                  <img src={picture} alt="RetroTeam" className="auth__image" />
                </div>
              </div>
            )}
          </div>
          {showUsernameBox && (
            <UserNameBox
              onSubmit={this.props.addUserToDb}
              authResult={authResult}
              loading={this.loading}
            />
          )}
        </div>
      );
    } else if (
      !!uid &&
      !isAnonymous &&
      hasActivePlan !== true &&
      process.env.REACT_APP_HAS_PAYMENT === "true"
    ) {
      return (
        <Plans
          subscribeToFreePlan={subscribeToFreePlan}
          param={this.props.location.search}
          isJoinTeam={isJoinTeam}
          onSwitchAccount={this.onSwitchAccount}
        />
      );
    } else {
      if (isJoinTeam) {
        return <Redirect to={`/account${this.props.location.search}`} />;
      } else {
        if (hasBoards) {
          return <Redirect to="/dashboard" />;
        } else {
          return <Redirect to="/new" />;
        }
      }
    }
  }
}

export default connect(mapStateToProps)(Register);
