import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect, getFirebase, ReduxFirestoreQueries } from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Banner from "../../components/Banner";
import Boards from '../../components/Boards';
import HeaderDashboard from '../../components/HeaderDashboard';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import SubNavigation from '../../components/SubNavigation';
import { analytics } from "../../index";
import { UserBoard } from '../../types';
import ActionItems from "../../components/ActionItems/ActionItems";
import { mapStateToProps } from './Dashboard.container';
import './Dashboard.scss';

export interface DashboardState {
  isFilterByTime: boolean;
  isMyBoards: boolean;
  showArchived: boolean;
  isAllBoards: boolean;
  isSharedBoards: boolean;
  isTeamBoards: boolean;
  isActionItems: boolean;
  isFinishLoad: boolean;
  isLoading: boolean;
  totalActionItems: number;
}

export interface DashboardOwnProps {
  search: string;
  searchInput: string;
  onSearch: (value: string) => Promise<any>;
}

export interface DashboardStateProps {
  activeUserUid: string;
  isAnonymous: boolean;
  createdAt: string | undefined;
  ownBoards: UserBoard[];
  sharedBoards: UserBoard[];
  teamBoards: UserBoard[];
  archivedBoards: UserBoard[];
  searchBoards: UserBoard[];
  archiveBoard: (boardId: string | undefined, isOwnBoard: boolean) => void;
  unarchiveBoard: (boardId: string | undefined, isOwnBoard: boolean) => void;
  fetchCardForBoards: (boards: UserBoard[], type: string) => any;
  onMarkCardAsDone: (boardId: string, cardId: string) => any;
  onMarkCardAsUndone: (boardId: string, cardId: string) => any;
  onDelteCard: (boardId: string, cardId: string) => any;
  onUpdateCard: (card: any) => any;
}

export type DashboardInnerProps = DashboardOwnProps & DashboardStateProps;

class Dashboard extends Component<DashboardInnerProps, DashboardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isFilterByTime: true,
      isMyBoards: false,
      isSharedBoards: false,
      isTeamBoards: false,
      isAllBoards: true,
      isActionItems: false,
      showArchived: false,
      isFinishLoad: true,
      isLoading: false,
      totalActionItems: 0,
    };
  }

  componentDidMount() {
    // @ts-ignore
    analytics.logEvent('dashboard');
    this.getCards()
    // in case that data is not available yet, make sure to fetch good data
    setTimeout(this.getCards, 500)
    setTimeout(this.getCards, 5000)
  }

  changeFilter = (value: boolean) => {
    this.setState({ isFilterByTime: value });
  };

  changeAllBoards = () => {
    if(!this.state.isAllBoards) {
      this.setState({ isAllBoards: true, isMyBoards: false, isSharedBoards: false, isTeamBoards: false, isActionItems: false });
    }
  };

  changeMyBoards = () => {
    if(!this.state.isMyBoards) {
      this.setState({ isMyBoards: true, isAllBoards: false, isSharedBoards: false, isTeamBoards: false, isActionItems: false });
    }
  };

  changeSharedBoards = () => {
    if(!this.state.isSharedBoards) {
      this.setState({ isSharedBoards: true, isAllBoards: false, isMyBoards: false, isTeamBoards: false, isActionItems: false });
    }
  };

  changeTeamBoards = () => {
    if(!this.state.isTeamBoards) {
      this.setState({ isSharedBoards: false, isAllBoards: false, isMyBoards: false, isTeamBoards: true, isActionItems: false });
    }
  };

  onActionItems = () => {
    if(!this.state.isActionItems) {
      this.setState({ isSharedBoards: false, isAllBoards: false, isMyBoards: false, isTeamBoards: false, isActionItems: true });
    }
  };

  showArchive = () => {
    this.setState({ showArchived: !this.state.showArchived });
  };

  openLoading = async () => {
    this.setState({ isLoading: true });
  };

  closeLoading = async () => {
    this.setState({ isLoading: false });
  };

  getCards = async () => {
    const {
      ownBoards,
      sharedBoards,
      teamBoards,
      fetchCardForBoards
    } = this.props;

    const allBoards: UserBoard[] = [];
    for (let i = 0; i < ownBoards.length; i++) {
      allBoards.push({ ...ownBoards[i] });
    }
    for (let i = 0; i < sharedBoards.length; i++) {
      allBoards.push({ ...sharedBoards[i] });
    }
    for (let i = 0; i < teamBoards.length; i++ ) {
      allBoards.push({ ...teamBoards[i] })
    }

    const res = await fetchCardForBoards(allBoards, 'actions')

    this.setState({totalActionItems: res?.length || 0})

    return res
  }

  render() {
    const {
      ownBoards,
      sharedBoards,
      teamBoards,
      archiveBoard,
      unarchiveBoard,
      archivedBoards,
      onSearch,
      searchInput,
      searchBoards,
      isAnonymous,
      createdAt
    } = this.props;

    const {
      isMyBoards,
      isSharedBoards,
      isTeamBoards,
      isFilterByTime,
      showArchived,
      isAllBoards,
      isActionItems,
      isFinishLoad,
      totalActionItems,
      isLoading
    } = this.state;

    const allBoards: UserBoard[] = [];
    for (let i = 0; i < ownBoards.length; i++) {
      allBoards.push({ ...ownBoards[i] });
    }
    for (let i = 0; i < sharedBoards.length; i++) {
      allBoards.push({ ...sharedBoards[i] });
    }
    for (let i = 0; i < teamBoards.length; i++ ) {
      allBoards.push({ ...teamBoards[i] })
    }

    if(isLoading) {
      return(
        <LoadingScreen />
        );
    } else {
      return (
        <div className="dashboard">
          {isAnonymous
            &&
          <Banner
            createdAt={createdAt}
          />
          }
          <HeaderDashboard />

          <SubNavigation
            isFilterByTime={isFilterByTime}
            changeFilter={this.changeFilter}
            changeAllBoards={this.changeAllBoards}
            changeMyBoards={this.changeMyBoards}
            changeSharedBoards={this.changeSharedBoards}
            changeTeamBoards={this.changeTeamBoards}
            onActionItems={this.onActionItems}
            searchQuery={searchInput}
            onSearch={onSearch}
            isSearching={!!searchInput.length}
            isAllBoards={isAllBoards}
            isMyBoards={isMyBoards}
            isSharedBoards={isSharedBoards}
            isTeamBoards={isTeamBoards}
            isActionItems={isActionItems}
            totalActionItems={totalActionItems}
            totalAllBoards={allBoards.length}
            totalMyBoards={ownBoards.length}
            totalSharedBoards={sharedBoards.length}
            totalTeamBoards={teamBoards.length}
          />
          {isFinishLoad && (
            <>
              {searchInput ? (
                <div className="search">
                  <Boards
                    boards={searchBoards}
                    isFilterByTime={isFilterByTime}
                    archiveBoard={archiveBoard}
                    unarchiveBoard={unarchiveBoard}
                    openLoading={this.openLoading}
                    closeLoading={this.closeLoading}
                  />
                </div>
              ) : (
                <>
                  {isAllBoards ? (
                    <Boards
                      boards={allBoards}
                      isFilterByTime={isFilterByTime}
                      archiveBoard={archiveBoard}
                      unarchiveBoard={unarchiveBoard}
                      openLoading={this.openLoading}
                      closeLoading={this.closeLoading}
                    />
                  ) : (
                    <>
                      {isMyBoards ? (
                        <Boards
                          boards={ownBoards}
                          isFilterByTime={isFilterByTime}
                          archiveBoard={archiveBoard}
                          unarchiveBoard={unarchiveBoard}
                          openLoading={this.openLoading}
                          closeLoading={this.closeLoading}
                        />
                      ) :  isSharedBoards ? (
                        <Boards
                          boards={sharedBoards}
                          isFilterByTime={isFilterByTime}
                          archiveBoard={archiveBoard}
                          unarchiveBoard={unarchiveBoard}
                          openLoading={this.openLoading}
                          closeLoading={this.closeLoading}
                        />
                      ) : isTeamBoards ? (
                        <Boards
                          boards={teamBoards}
                          isFilterByTime={isFilterByTime}
                          archiveBoard={archiveBoard}
                          unarchiveBoard={unarchiveBoard}
                          openLoading={this.openLoading}
                          closeLoading={this.closeLoading}
                        />
                      ) : (
                        <ActionItems 
                          isAnonymous={isAnonymous}
                          getCards={this.getCards}
                          cards={[]}
                          liveUpdate={false}
                          onMarkCardAsDone={this.props.onMarkCardAsDone}
                          onMarkCardAsUndone={this.props.onMarkCardAsUndone}
                          onDelteCard={this.props.onDelteCard}
                          onUpdateCard={this.props.onUpdateCard}
                          activeUserUid={this.props.activeUserUid}
                        />
                      )
                      }
                    </>
                  )}
                  {!isActionItems && (
                    <div className="dashboard__archived">
                      <button
                        className={`dashboard__archived-button ${archivedBoards.length && 'dashboard__archived-button--available'} ${showArchived ? 'active' : ''}`}
                        onClick={this.showArchive}
                      >
                        Archived ({archivedBoards.length})
                      </button>
                      {showArchived && (
                        <Boards
                          boards={archivedBoards}
                          isFilterByTime={isFilterByTime}
                          archiveBoard={archiveBoard}
                          unarchiveBoard={unarchiveBoard}
                          hidePagination={true}
                          openLoading={this.openLoading}
                          closeLoading={this.closeLoading}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      );
    }

  }
}

function firebaseConnector(props: RouteComponentProps<{ id: string }>): ReduxFirestoreQueries {
  const firebase = getFirebase();
  const auth = firebase.auth();
  let uid = '';
  if (auth.currentUser) {
    uid = auth.currentUser.uid;
  }
  if(uid) {
    return[
      {
        collection: 'users',
        doc: uid,
        subcollections: [ { collection: 'userBoards', doc: 'userBoards' }],
        storeAs: 'userBoards'
      },
    ]
  } else {
    return []
  }
}

export default compose<any, any, any>(
  firestoreConnect(firebaseConnector),
  connect(mapStateToProps)
)(Dashboard) as React.ComponentClass<any>;
