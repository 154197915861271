import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';

import { StoreState } from '../types';
import { app } from './app';

export * from './app';

const rootReducer = combineReducers<StoreState, any>({
  fbState: firestoreReducer,
  app
});

export default rootReducer;
