import * as React from 'react';
import { Component } from 'react';
import * as cx from 'classnames';
import { getFirebase } from "react-redux-firebase";
import firebase from 'firebase/app';
import 'firebase/auth';
import './Plans.scss';
import { loadStripe } from "@stripe/stripe-js";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";

import HeaderDashboard from '../../../components/HeaderDashboard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export interface PlanProps {
  subscribeToFreePlan: (uid: string, name: string, email?: string) => void;
  onSwitchAccount: () => void;
  param: string;
  isJoinTeam: boolean
}

export interface PlansState {
  plans: any;
  activePlan: string;
  freePlanId: string;
  isLoaded: boolean;
  status: string;
}

const FREE_PLAN_PRICE = 0
const TEAM_PLAN_PRICE = 200
const LARGE_PLAN_PRICE = 5000

const FREE_PLAN_ID = process.env.REACT_APP_FREE_PLAN_ID
const TEAM_PLAN_ID = process.env.REACT_APP_TEAM_PLAN_ID
const LARGE_PLAN_ID = process.env.REACT_APP_LARGE_PLAN_ID

const getPlanDetails = (plan: any) => {
  let title: any

  switch (plan?.amount) {
    case FREE_PLAN_PRICE: {
      title = 'Free'
      break
    }
    case TEAM_PLAN_PRICE: {
      title = 'Team'
      break
    }
    case LARGE_PLAN_PRICE: {
      title = 'Large'
      break
    }
  }

  return {
    title,
    price: `$${plan.amount / 100}/per ${plan?.interval === 'month' ? 'mo' : 'year'}`,
    teamsLimit: plan?.metadata?.teamLimit,
    userPerBoardLimit: plan?.metadata?.userLimit,
  }
}

class Plans extends Component<PlanProps, PlansState> {
  constructor(props: PlanProps) {
    super(props);
    this.state = {
      plans: [],
      activePlan: '',
      freePlanId: '',
      isLoaded: false,
      status: ''
    }
  }

  componentDidMount() {
    const { isLoaded } = this.state;
    const firebase = getFirebase();
    const auth = firebase.auth();
    let uid: string = '';
    if (auth.currentUser) {
      uid = auth.currentUser.uid;
    }

    if(uid && !isLoaded) {
      this.setState({ isLoaded: false }, () => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getPlans`, {
          method: 'GET',
        })
          .then((response) => response.json())
          .then((data) => {
            if(data.body) {
              const { param } = this.props;
              const newData = JSON.parse(data.body);

              console.log('newData', newData)

              const planListId = [FREE_PLAN_ID, TEAM_PLAN_ID,LARGE_PLAN_ID]
              console.log('planListId', planListId)
              const activePlans = newData.plans.data.filter((p: any) => planListId.includes(p.id))

              console.log('activePlans', activePlans)

              const freePlan = activePlans.filter(
                (info: any) => info.amount === 0
              );

              console.log('freePlan', freePlan)
              const sortedPlans = activePlans.sort((a: any, b: any) => {
                return parseInt(a.amount) - parseInt(b.amount);
              });

              console.log('sortedPlans', sortedPlans)

              if(param) {
                const indicator = param.slice(0, 7);
                if(indicator === '?price=') {
                  const priceId = param.slice(7);
                  const targetPlan = activePlans.find((plan: any) => plan.id === priceId);
                  const user = firebase.auth().currentUser ? firebase.auth().currentUser : undefined;
                  if(!!targetPlan && user) {
                    const name: string = user.displayName || '';
                    const email: string = user.email || '';
                    if(targetPlan.amount === 0) {
                      this.props.subscribeToFreePlan(user.uid, name, email);
                    } else {
                      this.setState( {status: 'Loading payment page' }, () => {
                        const { isJoinTeam } = this.props;
                        const successUrl: string = isJoinTeam ? `${window.location.origin}/#/account${this.props.param}` : `${window.location.origin}/#/new?session_id={CHECKOUT_SESSION_ID}`
                        const body: any = {
                          success_url: successUrl,
                          cancel_url: window.location.href,
                          active_plan: priceId,
                          client_reference_id: user.uid,
                          customer_email: email
                        };

                        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/checkout`, {
                          method: 'POST',
                          body: JSON.stringify(body)
                        })
                          .then((res) => res.json())
                          .then(async (nres) => {
                            const body = JSON.parse(nres.body);
                            const stripe = await stripePromise;
                            if (stripe) {
                              const {error} = await stripe.redirectToCheckout({
                                sessionId: body.sessionData.id
                              });
                              console.log(error);
                            }
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      });
                    }
                  } else {
                    this.setState({
                      plans: sortedPlans,
                      activePlan: sortedPlans[0].id,
                      freePlanId: freePlan[0] ? freePlan[0].id : '',
                      isLoaded: true,
                      status: ''
                    });
                  }
                } else {
                  this.setState({
                    plans: sortedPlans,
                    activePlan: sortedPlans[0].id,
                    freePlanId: freePlan[0] ? freePlan[0].id : '',
                    isLoaded: true,
                    status: ''
                  });
                }
              } else {
                this.setState({
                  plans: sortedPlans,
                  activePlan: sortedPlans[0].id,
                  freePlanId: freePlan[0] ? freePlan[0].id : '',
                  isLoaded: true,
                  status: ''
                });
              }
            }
          })
          .catch((e) => {
            console.log(e)
          })

      });
    }
  }

  onSubmit = (e: any) => {
    e.preventDefault();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser ? firebase.auth().currentUser : undefined;
    if(user) {
      const name = user.displayName || '';
      const email = user.email || '';
      const { freePlanId, activePlan } = this.state;
      if(freePlanId === activePlan) {
        this.setState({ isLoaded: false, status: 'Payment in progress'}, () => {
          this.props.subscribeToFreePlan(user.uid, name, email);
        });
      } else {
        this.setState({ isLoaded: false, status: 'Redirecting to payment checkout' }, () => {
          const { isJoinTeam } = this.props;
          const successUrl: string = isJoinTeam ? `${window.location.origin}/#/account${this.props.param}` : `${window.location.origin}/#/new?session_id={CHECKOUT_SESSION_ID}`;
          const body: any = {
            success_url: successUrl,
            cancel_url: window.location.href,
            active_plan: activePlan,
            client_reference_id: user.uid,
            customer_email: email
          };

          fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/checkout`, {
            method: 'POST',
            body: JSON.stringify(body)
          })
            .then((res) => res.json())
            .then(async (nres) => {
              const body = JSON.parse(nres.body);
              const stripe = await stripePromise;
              if (stripe) {
                const {error} = await stripe.redirectToCheckout({
                  sessionId: body.sessionData.id
                });
                console.log(error);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        });
      }
    }
  };

  onChangePlan = (id: string) => {
    this.setState({ activePlan: id });
  };

  onSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.hash = '/';
      })
  }

  render() {
    const { activePlan, plans, isLoaded, status } = this.state;
    if(!isLoaded) {
      return(
        <LoadingScreen status={status} />
      );
    } else {
      return(
        <div className="plans">
          <div className="plans-container">
            <HeaderDashboard onSwitchAccount={this.props.onSwitchAccount} silver dontShowAccount showEmail />
            <div className='register-plans'>
              <div className='register-plans__header'>
                Choose Plan
              </div>
              <div className='payment-plans'>
                <div className='payments-plans-container'>
                  {plans.map((plan: any, index: number) => {
                    const planDetails = getPlanDetails(plan)
                    const isRecomended = planDetails.title === 'Team'
                    const isSelected = plan.id === activePlan

                    const singlePlanCSS = cx('single-plan', {'single-plan--recomend': isRecomended})
                    const planContentCSS = cx('single-plan__content', {'single-plan__content--selected': isSelected})
                    const buttonCSS = cx('single-plan__button', {'single-plan__button--selected': isSelected})
                    const recomendCSS = cx('single-plan__recomend', {'single-plan__recomend--selected': isSelected})

                    return (
                      <div key={plan.id} className={singlePlanCSS}>
                        <div className={planContentCSS}>
                          <div className='single-plan__title'>{planDetails.title}</div>
                          <div className='single-plan__price'>{planDetails.price}</div>
                          <div className='single-plan__singleLimit'>{planDetails.teamsLimit} {planDetails.teamsLimit === '1' ? ' team' : ' teams'}</div>
                          <div className='single-plan__singleLimit'>{planDetails.userPerBoardLimit} users per board</div>
                        </div>
                        <button onClick={() => this.onChangePlan(plan.id)} className={buttonCSS}>Choose</button>
                        {isRecomended && <div className={recomendCSS}>Recommended</div>}
                      </div>
                    )
                  })}
                </div>
                <button
                  className="plan__submit"
                  onClick={this.onSubmit}
                >
                  Continue
                </button>
              </div>
              <div className='register-plans__nav'>
                <div onClick={this.props.onSwitchAccount}>Switch Account</div>
                <div onClick={this.onSignOut}>Sign out</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Plans;
