import * as React from "react";
import { Component } from "react";
import { getFirebase } from "react-redux-firebase";

import "./Landing.scss";
import HeaderLanding from "./subComponents/HeaderLanding";
import About from "./subComponents/About";
import Features from "./subComponents/Featrues";
import Description from "./subComponents/Description";
import Review from "./subComponents/Review";
import Contact from "./subComponents/Contact";
import Footer from "./subComponents/Footer";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Pricing from "./subComponents/Pricing";

export interface LandingState {
  isLoaded: boolean;
  plans: any;
}

class Landing extends Component<{}, LandingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoaded: false,
      plans: {},
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_TESTING === "true") {
      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getPlans`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.body) {
            const body = JSON.parse(data.body);
            this.setState({
              isLoaded: true,
              plans: body.plans.data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    if (process.env.REACT_APP_TESTING === "true") {
      const { isLoaded } = this.state;

      if (isLoaded) {
        const { plans } = this.state;
        return (
          <>
            <HeaderLanding />
            <About />
            <Features />
            <Pricing plans={plans} />
            <Description />
            <Review />
            <Contact />
            <Footer />
          </>
        );
      } else {
        return <LoadingScreen />;
      }
    } else {
      const user = getFirebase().auth().currentUser;
      if (user) {
        return <LoadingScreen />;
      } else {
        location.href = "http://retroteam.app/";
        return <LoadingScreen />;
      }
    }
  }
}

export default Landing;
