import * as React from 'react';
import { Component } from 'react';
import Icon from '../../../../components/Icon';
import './HeaderLanding.scss';
import { Link } from "react-router-dom";


class HeaderLanding extends Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('scroll', (e: any) => {
      const border = document.getElementById('headerBorder');
      if (border) {
        if (window.pageYOffset <= 100) {
          border.style.width = `${window.pageYOffset}%`;
        } else {
          border.style.width = '100%';
        }
      }
    });
  }

  scrollTo = (id: string) => {
    const elem = document.getElementById(id);
    if (elem) {
      window.scroll({
        top: elem.offsetTop - 88,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  render() {
    return (
      <header className="landing-header">
        <Icon className="landing__logo" name="logo" />
        <nav className="landing-navigation">
          <span
            className="landing-navigation__link"
            onClick={() => this.scrollTo('intro')}
          >
            About
          </span>
          <span
            className="landing-navigation__link"
            onClick={() => this.scrollTo('features')}
          >
            Why Retroteam?
          </span>
          <span
            className="landing-navigation__link"
            onClick={() => this.scrollTo('description')}
          >
            How it works?
          </span>
          <span
            className="landing-navigation__link"
            onClick={() => this.scrollTo('review')}
          >
            Others about us
          </span>
          <span
            className="landing-navigation__link"
            onClick={() => this.scrollTo('contact')}
          >
            Contact
          </span>
          <Link
            to='/register'
            className="landing-navigation__button"
          >
            Log in
          </Link>
        </nav>
        <div id="headerBorder" className="landing-header__border" />
      </header>
    );
  }
}

export default HeaderLanding;
