import * as React from "react";
import Portal from "../../Portal";
import { Team } from "../../../types";
import { getFirebase } from "react-redux-firebase";

export interface DeleteTeamModalProps {
  onClose: () => void;
  team: Team;
  openLoading: (status?: string) => Promise<void>;
  closeLoading: () => void;
}

const DeleteTeamModal = (props: DeleteTeamModalProps) => {

  function onDelete() {
    const { team, openLoading, closeLoading } = props;
    const user = getFirebase().auth().currentUser;
    let uid: string = '';
    if(user) {
      uid = user.uid;
    }

    if(team.admins.includes(uid) && team.id) {
      const teamRef = getFirebase().firestore().collection('teams').doc(team.id);
      openLoading('Deleting team')
        .then(() => {
          teamRef
            .delete()
            .then(() => {
              closeLoading();
            })
            .catch((e) => {
              console.log(e);
              closeLoading();
            })
        });
    }
  }

  return(
    <Portal
      onClose={props.onClose}
      showCloseButton={false}
    >
      <div className="modal__content-wrapper">
        <div className="modal__content delete-modal">
          <div
            className="modal__close-button"
            onClick={props.onClose}
          />
          <div className="delete-modal__message">
            <h2 className="delete-modal__title">Are you sure you want to delete team?</h2>
            <p className="delete-modal__warning">
              This action cannot be undone.
            </p>
          </div>
          <div className="delete-modal-button__buttons">
            <button
              className="delete-modal-button delete-modal-button__danger"
              onClick={onDelete}
            >
              DELETE
            </button>
            <button
              className="delete-modal-button delete-modal-button__cancel"
              onClick={props.onClose}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </Portal>
  )
};

export default DeleteTeamModal;
//@ts-ignore
