import * as React from 'react';
import { Component } from 'react';
// import { getFirebase } from "react-redux-firebase";
import { Link } from 'react-router-dom';
import { analytics } from "../../index";
// import TextField from '@material-ui/core/TextField';
import cx from 'classnames'
import './ActionItems.scss';

export interface ActionItemsCreateState {
  boardId: string;
  boardName: string;
  boardNameError: string;
  description: string;
  descriptionError: string;
  assignEmail: string,
  assignName: string,
  assignEmailError: string,
  createError: string,
  listUsersIsOpened: boolean,
  isDone: boolean,
  listMarkAsDoneOpened: boolean,
}

export interface ActionItemsCreateStateProps {
  isAnonymous: boolean;
  clickedCard: any;
  onUpdate: (card: any) => any;
}

export type ActionItemsCreateInnerProps = ActionItemsCreateStateProps;

class ActionItemsCreate extends Component<ActionItemsCreateInnerProps, ActionItemsCreateState> {
  constructor(props: any) {
    super(props);
    this.state = {
      boardId: "",
      boardName: "",
      boardNameError: "",
      description: this.props.clickedCard?.text || '',
      descriptionError: "",
      assignEmail: "",
      assignName: "",
      assignEmailError: "",
      createError: "",
      listUsersIsOpened: false,
      isDone: false,
      listMarkAsDoneOpened: false,
    };
  }

  listener = (event: any) => {
    // Do nothing if clicking ref's element or descendent elements
    if (!this.selectRef || this.selectRef.contains(event.target)) {
      if (!this.selectRef2 || this.selectRef2.contains(event.target)) {
        return;
      }

      return;
    }

    if (!this.selectRef2 || this.selectRef2.contains(event.target)) {
      if (!this.selectRef || this.selectRef.contains(event.target)) {
        return;
      }

      return;
    }

    this.onBlurAssignUser();
  };

  componentDidMount() {
    // @ts-ignore
    analytics.logEvent('Action Items Create Pressed');
    
    document.addEventListener("mousedown", this.listener);
    document.addEventListener("touchstart", this.listener);
  }

  componentDidUpdate(prevProps: ActionItemsCreateInnerProps) {
		if (this.props.clickedCard?.text !== prevProps.clickedCard?.text) {
			this.setState({
				description: this.props.clickedCard?.text || '',
			})
		}

		if (this.props.clickedCard?.boardName !== prevProps.clickedCard?.boardName) {
			this.setState({
				boardName: this.props.clickedCard?.boardName || '',
				boardId: this.props.clickedCard?.boardId || '',
			})
		}

		if (this.props.clickedCard?.assignedUserEmail !== prevProps.clickedCard?.assignedUserEmail) {
			this.setState({
				assignEmail: this.props.clickedCard?.assignedUserEmail || '',
        assignName: this.props.clickedCard?.assignedUserName || ''
			})
		}

    if (this.props.clickedCard?.status !== prevProps.clickedCard?.status) {
      this.setState({isDone: this.props.clickedCard?.status === 'done'})
    }
	}

  onNameChanged = (e: any) => {
    const value = e.target.value;
    this.setState({ boardName: value, boardNameError: '' })
  }

  onDescriptionChanged = (e: any) => {
    const value = e.target.value;
    this.setState({ description: value, descriptionError: '' })
  }

  validation = (email: string): boolean => {
    const pattern = new RegExp(/^([\w-+.]+@([\w-]+\.)+[\w-]{2,20})$/);
    return pattern.test(email);
  };

  getUserFromEmail = async (userEmail: any) => {
    try {
      const assignedUserRes = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getUserFromEmail`, {
        method: 'POST',
        body: JSON.stringify({ userEmail: userEmail })
      })
  
      if (assignedUserRes) {
        const resJSON = await assignedUserRes.json()

        if (resJSON) {
          if(resJSON.body) {
            const body = JSON.parse(resJSON.body);
            return body.user
          }
        }
      }
  
    } catch (e) {
      console.log('e', e)
      return {}
    }
  }

  onUpdate = async () => {
    const { boardName, description, assignEmail } = this.state
    let error = false
    let userUid = null
    let userName = null
    let userPhotoUrl = null

    if (!boardName) {
      this.setState({boardNameError: 'Please enter action boardName'})
      error = true
    }

    if (!description) {
      this.setState({descriptionError: 'Please enter description'})
      error = true
    }

    if (!!assignEmail) {
      // check if user exist
      const user = await this.getUserFromEmail(assignEmail)
      if (!user?.uid) {
        this.setState({assignEmailError: 'User with this email doesn\'t exist'})
        error = true
      } else {
        userUid = user?.uid
        userName = user?.name
        userPhotoUrl = user?.photoUrl
      }
    }

    if (error) {
      return
    }
    
    const newCard = {
      ...this.props.clickedCard,
      text: description,
      assignedUserId: userUid,
      assignedUserEmail: assignEmail || null,
      assignedUserName: userName,
      assignedUserPhotoUrl: userPhotoUrl,
      status: this.state.isDone ? 'done' : 'active'
    }

    const isSuccess = await this.props.onUpdate(newCard)
    if (!isSuccess) {
      this.setState({createError: 'Please try again later.'})
    }

    this.setState({listUsersIsOpened: false})
  }

  onToggleAssignUser = () => {
    this.setState({listUsersIsOpened: !this.state.listUsersIsOpened})
  }

  onToggleMarkAsDone = () => {
    this.setState({listMarkAsDoneOpened: !this.state.listMarkAsDoneOpened})
  }

  onBlurAssignUser = () => {
    this.setState({listUsersIsOpened: false})
    this.setState({listMarkAsDoneOpened: false})
  }

  onClickDone = (e: any) => {
    this.setState({isDone: true, listMarkAsDoneOpened: false})
  }

  onClickUndone = (e: any) => {
    this.setState({isDone: false, listMarkAsDoneOpened: false})
  }

  onClickUserInList = (e: any, user: any) => {
    this.setState({assignEmail: user.email || null, assignName: user.name || null, listUsersIsOpened: false})
  }

  selectRef: any;
  selectRef2: any;
  
  render() {
    const boardUsers = this.props.clickedCard?.boardUsers || []

    const boardUsersWithEmail = boardUsers?.filter((u: any) => !!u.email)

    return (
      <div className="action-items-create">
        <div className="side-modal__box team-create-box" onClick={this.onBlurAssignUser}>
          <div className="side-modal-input-container">
            {/* <TextField
              label="Board Name"
              className='side-modal__input settings__input'
              disabled
              value={this.state.boardName}
              onChange={this.onNameChanged}
              error={!!this.state.boardNameError}
              helperText={this.state.boardNameError}
            /> */}
            <label className='action-side-modal__boardLabel'>Board Name</label>
            <Link className='action-side-modal__boardLink' to={`/board/${this.state.boardId}`}>{this.state.boardName}</Link>
          </div>
        </div>
        <div className="side-modal__box team-create-box" onClick={this.onBlurAssignUser}>
          <h3 className="side-modal-box__title">DESCRIPTION</h3>
          <div className="side-modal-input-container">
            <div className="team-invite-input">
              <textarea
                placeholder="Enter description"
                className={cx("team-invite-input__textarea", {"team-invite-input__textarea--error": this.state.descriptionError })}
                value={this.state.description}
                onChange={this.onDescriptionChanged}
              />
              {!!this.state.descriptionError && <div className='action-items-error'>{this.state.descriptionError}</div>}
            </div>
          </div>
        </div>
        <div className="side-modal__box side-modal__assignUserContainer side-modal__assignUserContainer--first team-create-box">
          <h3 className="side-modal-box__title">Mark as done/undone</h3>
          <div ref={(rs: any) => (this.selectRef2 = rs)}>
            <div className='side-modal__assignedUser' onClick={this.onToggleMarkAsDone}>
              {this.state.isDone ? 'Done' : 'Undone'}
            </div>
            {this.state.listMarkAsDoneOpened && (
              <div className='side-modal__userList'>
                <div className='side-modal__singleUserInLinst' onClick={this.onClickDone}>
                  Done
                </div>
                <div className='side-modal__singleUserInLinst' onClick={this.onClickUndone}>
                  Undone
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="side-modal__box side-modal__assignUserContainer team-create-box">
          <h3 className="side-modal-box__title">ASSIGN USER</h3>
          {/* <TextField
            label="User email"
            className='side-modal__input settings__input'
            value={this.state.assignEmail}
            onChange={this.onAssignEmailChanged}
            error={!!this.state.assignEmailError}
            helperText={this.state.assignEmailError}
            onFocus={this.onFocusAssignUser}
          /> */}
          <div ref={(rs: any) => (this.selectRef = rs)}>
            <div className='side-modal__assignedUser' onClick={this.onToggleAssignUser}>
              {!!this.state.assignName ? this.state.assignName : 'Select user'}
            </div>
            {this.state.listUsersIsOpened && (
              <div className='side-modal__userList'>
                <div className='side-modal__singleUserInLinst side-modal__singleUserInLinst--notAssigned' key={'user-in-list-not-assigned'} onClick={(e) => this.onClickUserInList(e, {})}>
                  Not assigned
                </div>
                {boardUsersWithEmail?.map((user: any) => {
                  return (
                    <div className='side-modal__singleUserInLinst' key={`user-in-list-${user.id}`} onClick={(e) => this.onClickUserInList(e, user)}>
                      {user.name}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
        <div className='action-items-create-button-container'>
          {!!this.state.createError && <div className='action-items-error'>{this.state.createError}</div>}
          <button
            className="teams-button--primary action-items-create-button"
            onClick={this.onUpdate}
          >
            Update
          </button>
        </div>
      </div>
    );

  }
}

export default ActionItemsCreate;
