import { getFirebase, getVal } from "react-redux-firebase";
import { debounce } from 'lodash';
import { FirebaseFirestore } from '@firebase/firestore-types';
import { AnyAction, Dispatch } from "redux";
import { EDIT_STATUS } from "../../actions";
import * as Raven from "raven-js";

import {Board, Card, CardGroup, StoreState} from "../../types";
import { CardGroupOwnProps, CardGroupStateProps } from "./CardGroup";

export const mapStateToProps = (
  state: StoreState,
  ownProps: CardGroupOwnProps
): CardGroupStateProps => {

  const uid: string = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : '';
  const boardId = ownProps.boardId;
  const board: Board = getVal(state.fbState, `data/boards/${boardId}`);
  const currentGroup: CardGroup = getVal(state.fbState, `data/cardGroups/${ownProps.id}`);
  // const ownVotes = currentGroup.userVotes[uid];
  // const votes = currentGroup.votes;
  const database: FirebaseFirestore = getFirebase().firestore();

  const admin: boolean = board.creatorUid === uid;

  // let limitVotes = board.limitVotes;

  // let currentVotes: number = board.users[uid].votes || 0;

  const isShowAuthor: boolean = board.showAuthor;

  const group: CardGroup = {
    ...currentGroup,
    id: ownProps.id
  };

  const cards: Card[] = Object.keys(group.cards).map((cardId: string) => {
    const card: Card = getVal(state.fbState, `data/cards/${cardId}`, {});
    return {
      ...card,
      id: cardId,
      order: group.cards[cardId].order
    }
  });

  cards.sort((a: Card, b: Card) => {
    return a.order - b.order;
  });

  function deleteGroupWithCards() {
    if(group && group.cards) {
      const cardTmp = group.cards;
      database
        .collection('boards')
        .doc(boardId)
        .collection('cardGroups')
        .doc(ownProps.id)
        .delete()
        .then(() => {
          Object.keys(cardTmp).map((cardId: string) => {
            database
              .collection('boards')
              .doc(boardId)
              .collection('cards')
              .doc(cardId)
              .delete()
              .catch((err: Error) => {
                Raven.captureMessage('Could not delete cards', {
                  extra: {
                    reason: err.message,
                    uid: uid,
                    boardId: ownProps.boardId,
                  }
                });
              });
          });
        })
        .catch((err: Error) => {
          Raven.captureMessage('Could not delete group', {
            extra: {
              reason: err.message,
              uid: uid,
              boardId: ownProps.boardId,
            }
          });
        });
    }
  }

  function ungroupAllCards() {
    database
      .collection('boards')
      .doc(boardId)
      .collection('cardGroups')
      .doc(ownProps.id)
      .delete()
      .catch((err: Error) => {
        Raven.captureMessage('Could not ungroup cards', {
          extra: {
            reason: err.message,
            uid: uid,
            boardId: ownProps.boardId,
          }
        });
      });
  }

  function addGroupToCard(sourceGroupId: string, targetCardId: string) {
    const order = Object.keys(group.cards).length;
    if(sourceGroupId && targetCardId) {
      database
        .collection('boards')
        .doc(boardId)
        .collection('cardGroups')
        .doc(sourceGroupId)
        .update({[`cards.${targetCardId}.order`]: order})
        .catch((err: Error) => {
          Raven.captureMessage('Could not group card', {
            extra: {
              reason: err.message,
              uid: uid,
              boardId: ownProps.boardId,
            }
          });
        });
    }
  }

  async function addGroupToGroup(sourceGroupId: string, targetGroupId: string) {
    if(sourceGroupId && targetGroupId) {
      const sourceGroup: CardGroup = getVal(state.fbState, `data/cardGroups/${sourceGroupId}`, undefined);
      const targetGroup: CardGroup = getVal(state.fbState, `data/cardGroups/${targetGroupId}`, undefined);
      const order = Object.keys(targetGroup.cards).length;
      let cardsTmp = {
        ...targetGroup.cards
      };
      if(sourceGroup && sourceGroup.cards) {
        await Object.keys(sourceGroup.cards).map((cardId: string, index: number) => {
          const card = {
            [cardId]: {
              order: order + index
            }
          };
          cardsTmp = {
            ...cardsTmp,
            ...card
          }
        });
        database
          .collection('boards')
          .doc(boardId)
          .collection('cardGroups')
          .doc(targetGroupId)
          .update({ cards: cardsTmp })
          .then(() => {
            database
              .collection('boards')
              .doc(boardId)
              .collection('cardGroups')
              .doc(sourceGroupId)
              .delete()
              .catch((err: Error) => {
                Raven.captureMessage('Could not join groups', {
                  extra: {
                    reason: err.message,
                    uid: uid,
                    boardId: ownProps.boardId,
                  }
                });
              });
          })
          .catch((err: Error) => {
            Raven.captureMessage('Could not join groups', {
              extra: {
                reason: err.message,
                uid: uid,
                boardId: ownProps.boardId,
              }
            });
          });
      }
    }
  }

  const changeGroupName = debounce((value: string) => {
    if(ownProps.id) {
      database
        .collection('boards')
        .doc(boardId)
        .collection('cardGroups')
        .doc(ownProps.id)
        .update({ name: value })
        .catch((err: Error) => {
          Raven.captureMessage('Could not update name of group', {
            extra: {
              reason: err.message,
              uid: uid,
              boardId: ownProps.boardId,
            }
          });
        });
      }
    }, 2000);

  // function onUpdateVoteForGroup(inc: number) {
  //   const groupVotes = group.votes;
  //
  //   if (
  //     (inc < 0 && groupVotes && groupVotes === 0) ||
  //     (inc > 0 && limitVotes && limitVotes <= currentVotes)
  //   ) {
  //     return;
  //   }
  //
  //   database
  //     .collection('boards')
  //     .doc(boardId)
  //     .collection('cardGroups')
  //     .doc(ownProps.id)
  //     .update({ votes: (groupVotes || 0) + inc })
  //     .then(() => {
  //       database
  //         .collection('boards')
  //         .doc(boardId)
  //         .collection('cardGroups')
  //         .doc(ownProps.id)
  //         .update({ [`userVotes.${uid}`]: (groupVotes || 0) + inc })
  //         .then(() => {
  //           database
  //             .collection('boards')
  //             .doc(boardId)
  //             .update({ [`users.${uid}.votes`]: (groupVotes || 0) + inc })
  //             .catch((err: Error) => {
  //               Raven.captureMessage('Could not update votes on card', {
  //                 extra: {
  //                   reason: err.message,
  //                   uid: uid,
  //                   boardId: boardId,
  //                   cardId: ownProps.id
  //                 }
  //               });
  //             });
  //         })
  //         .catch((err: Error) => {
  //           Raven.captureMessage('Could not update votes on card', {
  //             extra: {
  //               reason: err.message,
  //               uid: uid,
  //               boardId: boardId,
  //               cardId: ownProps.id
  //             }
  //           });
  //         });
  //     })
  //     .catch((err: Error) => {
  //       Raven.captureMessage('Could not update votes on card', {
  //         extra: {
  //           reason: err.message,
  //           uid: uid,
  //           boardId: boardId,
  //           cardId: ownProps.id
  //         }
  //       });
  //     });
  // }

  return {
    group,
    cards,
    isAdmin: admin,
    isShowAuthor,
    // ownVotes,
    // votes,
    // disableUpVotes: !limitVotes || currentVotes >= limitVotes,
    ungroupAllCards,
    addGroupToCard,
    deleteGroupWithCards,
    addGroupToGroup,
    changeGroupName,
    // onUpVote: () => onUpdateVoteForGroup(+1),
    // onDownVote: () => onUpdateVoteForGroup( -1)
  }
};

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    onEditMode: (active: boolean) => {
      dispatch({type: EDIT_STATUS, isActive: active});
    }
  }
}
