import * as React from 'react';
import { Component } from 'react';

import './About.scss';
import {Link} from "react-router-dom";

class About extends Component<{}, {}> {

  render() {
    return (
      <div id="intro" className="landing-intro">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="landing-intro__title">
                EASY RETROSPECTIVES FOR YOUR TEAM
              </h1>
            </div>
            <div className="col-sm-8 m-auto landing-intro__text">
              <p>
                Donec id elit non mi porta gravida at eget metus. Praesent
                commodo cursus magna, vel scelerisque nisl consectetur et.
                Maecenas faucibus mollis interdum.{' '}
              </p>
            </div>
            <div className="col-sm-12">
              <Link
                to='/register'
                className="landing-intro__button m-auto"
              >
                Get started
              </Link>
            </div>
            <div className="col-sm-12">
              <img
                src="https://i.imgur.com/dNmJygU.png"
                alt="placeholder image"
                className="landing-intro__snapshot m-auto"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
