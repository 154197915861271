import { StoreState } from "../../types";
import {getFirebase, getVal} from "react-redux-firebase";
import {AccountData, AccountStateProps} from "./Account";
import {analytics} from "../../index";

export const mapStateToProps = (
  state: StoreState,
  ownProps: any
): AccountStateProps => {
  const firebase = getFirebase();
  const auth = getFirebase().auth();
  let uid: string = auth.currentUser ? auth.currentUser.uid : '';
  let name: string = getVal(state.fbState, `data/users/${uid}/name`, '');
  let email: string = getVal(state.fbState, `data/users/${uid}/email`, '');
  const isAnonymous: boolean = firebase.auth().currentUser ? firebase.auth().currentUser!.isAnonymous : false;
  const createdAt: string | undefined = getVal(state.fbState, `auth/createdAt`, undefined);

  const onChangeData = (data: AccountData) => {
    const { username, email } = data;
    const user = firebase.auth().currentUser;
    if (user) {
      if(email) {
        user
          .updateEmail(email)
          .catch((e: any) => {
            console.log(e)
          });
      }
      user
        .updateProfile({
          displayName: username,
          photoURL: user.photoURL,
        })
        .then(() => {
          firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .update({
              name: username,
              email: email ? email : ''
            })
            .catch((err: Error) => {
              console.log(err)
            });
        });
    }
  };

  const onDeactivateUser = async () => {

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/disableUser`, {
      method: 'POST',
      body: JSON.stringify({ uid: uid })
    }).then(response => response.json())
      .then(() => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            location.hash = '/';
          })
      })
      .catch((e) => {
        console.log(e);
      })
  };

  const onDeleteUser = async () => {
    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/deleteUser`, {
      method: 'POST',
      body: JSON.stringify({ uid: uid })
    })
      .then(response => response.json())
      .then(() => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            location.hash = '/';
            analytics.logEvent('user_deleted', { uid, name, email })
          });
      })
  };

  return {
    uid,
    name,
    email,
    isAnonymous,
    createdAt,
    onChangeData,
    onDeactivateUser,
    onDeleteUser
  }
};
