import * as React from 'react';
import './TimerButton.scss';
import SideModal from "../SideModal";
import TimerSideModal from "../SideModal/variant/TimerSideModal";
import ReactTooltip from "react-tooltip";

export interface TimerButtonProps {
  onSetTimer: (seconds: number) => void;
}

export interface TimerButtonState {
  isOpen: boolean;
  customTime: number | string;
}

class TimerButton extends React.Component<TimerButtonProps, TimerButtonState> {
  constructor(props: TimerButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
      customTime: ''
    };
  }

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onTimeUpdate = (minutes: any) => {
    if (minutes !== '0' && minutes !== 0 && minutes < 61) {
      this.setState({ isOpen: false });
      this.props.onSetTimer(minutes * 60);
    }
  };

  render() {
    const {
      isOpen,
    } = this.state;

    const timerIcon = require(`!svg-inline-loader!../../assets/icon-18-timer.svg`);

    return (
      <>
        <span
          className="general-menu-item__button general-menu-item__button--timer"
          onClick={this.onToggle}
          dangerouslySetInnerHTML={{__html: timerIcon}}
          data-tip="Timer"
          data-for="timerTooltip"
        />
        <ReactTooltip
          id="timerTooltip"
          place="bottom"
          effect="solid"
          isCapture={false}
        />

        <SideModal
          title='Timer'
          isOpen={isOpen}
          onClose={this.onClose}
          className='side-modal__timer'
        >
          <TimerSideModal
            onTimeUpdate={this.onTimeUpdate}
          />
        </SideModal>
      </>
    );
  }
}

export default TimerButton;
