import * as React from 'react';
import { Component } from 'react';
import { Link } from "react-router-dom";
import { sortArray } from '../DataTable/icons';
import moment = require("moment");
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;
import './style.scss';

export interface NotificationCenterState {
  unreadNotifications: any;
  groupedUnreadNotifications: any;
  readNotifications: any;
  isOpenUnreadSetings: any;
  isOpenReadSetings: any;
}

export interface NotificationCenterStateProps {
  notifications: any;
  userId: any;
  onClose: any;
}

export type NotificationCenterInnerProps = NotificationCenterStateProps;

class NotificationCenter extends Component<NotificationCenterInnerProps, NotificationCenterState> {
  constructor(props: any) {
    super(props);
    this.state = {
      unreadNotifications: [],
      groupedUnreadNotifications: [],
      readNotifications: [],
      isOpenUnreadSetings: false,
      isOpenReadSetings: false,
    };
  }

  componentDidMount() {
    this.populateInnerNotifications()
  }

  componentDidUpdate(prevProps: NotificationCenterStateProps) {
		if (prevProps.notifications !== this.props.notifications) {
			this.populateInnerNotifications()
		}
	}

  populateInnerNotifications = () => {
    let columnKey : any
    columnKey = 'createdAt'

    const forwardedNotifications = this.props.notifications || []
    const allNotifications = sortArray(forwardedNotifications, columnKey, 'asc')
    const unreadNotifications = allNotifications.filter((n: any) => n.status === 'unread')
    const groupedUnreadNotifications = this.getGroupedByDay(unreadNotifications)

    const readNotifications = allNotifications.filter((n: any) => n.status === 'read')

    this.setState({unreadNotifications, groupedUnreadNotifications, readNotifications})
  }

  getGroupedByDay = (notifications: any) => {
    let todayMidnight = moment().toDate()
    todayMidnight.setHours(0)
    todayMidnight.setMinutes(0)
    todayMidnight.setSeconds(0)
    todayMidnight.setMilliseconds(0)

    const today = moment(todayMidnight).add(1, 'days')

    const groupedNotifications = notifications.reduce((acc: any, curr: any) => {
      const currentDay: any = moment(curr.createdAt)
      const differenceInDays = today.diff(currentDay, 'days')

      const notificationListForDifference = acc[differenceInDays] || []

      return {...acc, [differenceInDays]: [curr, ...notificationListForDifference]}
    }, {})

    return groupedNotifications
  }

  getTitleForGroup = (dayDifference: any, singleNotification: any) => {
    if (+dayDifference === 0) {
      return 'Today'
    }

    if (+dayDifference === 1) {
      return 'Yesterday'
    }

    if (+dayDifference > 6) {
      return `${dayDifference} days ago`
    }

    return moment(singleNotification.createdAt).format('dddd')
  }

  renderNotificationList = (notifications: any, showTime: boolean) => {
    return notifications.map((notification: any) => {
      let createdAt = moment(notification.createdAt).format('MMM Do YYYY')
      if (showTime) {
        createdAt = moment(notification.createdAt).format('h:mmA')
      }

      return (
        <Link onClick={this.props.onClose} className='single-notification-link' to={notification.link} key={notification.id}>
          <div className='single-notification'>
            <div className='single-notification-header'>
              <div className='single-notification-title'>{notification.title}</div>
              <div className='single-notification-time'>{createdAt}</div>
            </div>
            <div>{notification.text}</div>
          </div>
        </Link>
      )
    })
  }

  onToggleUnreadSettings = () => {
    this.setState({isOpenUnreadSetings: !this.state.isOpenUnreadSetings})
  }

  closeUnreadSettings = () => {
    this.setState({isOpenUnreadSetings: false})
  }

  deleteNotifications = (notifications: any) => {
    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/deleteNotifications`, {
      method: 'POST',
      body: JSON.stringify({
        user_id: this.props.userId,
        notifications: notifications
      })
    })
    .then(res => res.json())
    .catch((e) => {
      console.log(e);
    })
  }

  onDeleteUnreadNotifications = () => {
    if (this.state.unreadNotifications?.length > 0) {
      const notificationsForDelete = this.state.unreadNotifications.map((n: any) => n.id)
      this.deleteNotifications(notificationsForDelete)
    }
  }

  onToggleReadSettings = () => {
    this.setState({isOpenReadSetings: !this.state.isOpenReadSetings})
  }

  closeReadSettings = () => {
    this.setState({isOpenReadSetings: false})
  }

  onDeleteReadNotiifications = () => {
    if (this.state.readNotifications?.length > 0) {
      const notificationsForDelete = this.state.readNotifications.map((n: any) => n.id)
      this.deleteNotifications(notificationsForDelete)
    }
  }

  render() {
    const { groupedUnreadNotifications, readNotifications } = this.state

    const groupedKeys = Object.keys(groupedUnreadNotifications)

    const toggleIcon = (
      <div className="notification-settings-dropdown__icon" onClick={this.onToggleUnreadSettings}>
        <span>...</span>
      </div>
    );

    const ddMenuProps1 = {
      isOpen: this.state.isOpenUnreadSetings,
      close: this.closeUnreadSettings,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    const toggleIcon2 = (
      <div className="notification-settings-dropdown__icon" onClick={this.onToggleReadSettings}>
        <span>...</span>
      </div>
    );

    const ddMenuProps2 = {
      isOpen: this.state.isOpenReadSetings,
      close: this.closeReadSettings,
      toggle: toggleIcon2,
      align: 'right',
      closeOnInsideClick: true
    };

    return (
      <div className="action-items-create">
        <div className='notification-content'>
          {groupedKeys?.length > 0 && (
            <>
              <div className='notification-settings-container'>
                <div className='notification-label'>Unread</div>
                <DropdownMenu {...ddMenuProps1} className="notification-settings">
                  <li className="dashboard-board__user-list" onClick={this.onDeleteUnreadNotifications}>
                    Delete
                  </li>
                </DropdownMenu>
              </div>
              {groupedKeys.map((singleKey: any) => {
                const title = this.getTitleForGroup(singleKey, groupedUnreadNotifications[singleKey][0])
    
                return (
                  <div key={`unread-${singleKey}`}>
                    <div className='notification-group-title'>{title}</div>
                    {this.renderNotificationList(groupedUnreadNotifications[singleKey], true)}
                  </div>
                )
              })}
            </>
          )}
          {readNotifications?.length > 0 && (
            <>
              <div className='notification-settings-container'>
                <div className='notification-label'>Read</div>
                <DropdownMenu {...ddMenuProps2} className="notification-settings">
                  <li className="dashboard-board__user-list" onClick={this.onDeleteReadNotiifications}>
                    Delete
                  </li>
                </DropdownMenu>
              </div>
              {this.renderNotificationList(readNotifications, false)}
            </>
          )}
        </div>
      </div>
    );

  }
}

export default NotificationCenter;
