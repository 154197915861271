import * as React from "react";
import { Component } from "react";
import { FormControl, FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import { Modal } from "../../../components/Modal";
import { getFirebase } from "react-redux-firebase";

export interface DeactivateDeleteState {
  buttonChecked: 'deactivate' | 'delete';
  isModalOpen: boolean;
}

export interface DeactivateDeleteOwnProps {
  onDeactivateUser: () => Promise<void>;
  onDeleteUser: () => Promise<void>;
  isAnonymous: boolean;
  openLoading: () => Promise<void>;
  closeLoading: () => void;
}

class DeactivateDelete extends Component<DeactivateDeleteOwnProps, DeactivateDeleteState> {
  constructor(props: DeactivateDeleteOwnProps) {
    super(props);
    this.state = {
      buttonChecked: 'deactivate',
      isModalOpen: false,
    }
  }

  handleChange = (e: any) => {
    this.setState({ buttonChecked: e.target.value });
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  onDeactivate = () => {
    this.props.openLoading()
      .then(() => {
        this.props.onDeactivateUser()
          .catch(() => {
            this.props.closeLoading();
          })
      });
  };

  onDelete = () => {
    this.props.openLoading()
      .then(() => {
        this.props.onDeleteUser()
          .catch(() => {
            this.props.closeLoading();
          })
      })
  }

  render() {
    const { buttonChecked, isModalOpen } = this.state;
    const { isAnonymous } = this.props;

    let provider: string = '';
    let usedLogin: string = '';
    const user = getFirebase().auth().currentUser;
    if(!isAnonymous) {
      if(user) {
        if(user.providerData && user.providerData[0]) {
          provider = user.providerData[0].providerId;
        }
      }
    } else {
      provider = 'Anonymous';
    }

    switch (provider) {
      case 'google.com':
        usedLogin = 'Gmail';
        break;
      case 'twitter.com':
        usedLogin = 'Twitter';
        break;
      case 'github.com':
        usedLogin = 'Github';
        break;
      case 'Anonymous':
        usedLogin = 'Anonymous';
        break;
      default:
        usedLogin = 'Email';
    }

    return(
      <div className="account-tabs-content-container">
        <div className="account-tabs-content">
          <h2 className="account-content__title">Deactivating or Deleting Your Account</h2>
          <div className="account-content">
            <div className="account__provider">{usedLogin} Login</div>
            <FormControl component="fieldset">
              <RadioGroup aria-label="accountTurnOff" name="accountTurnOff" value={buttonChecked} onChange={this.handleChange}>
                <FormControlLabel
                  value="deactivate"
                  control={<Radio />}
                  label="Deactivate Account"
                  className="account__radio account__radio--first"
                />
                <FormControlLabel
                  value="delete"
                  control={<Radio />}
                  label="Permanently Delete Account"
                  className="account__radio"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <button
            className="account__button"
            onClick={this.onSubmit}
          >
            Continue
          </button>

          {isModalOpen && buttonChecked === 'deactivate' && (
            <Modal
              onClose={this.onCloseModal}
              onSubmit={this.onDeactivate}
              title="Are you sure you want to deactivate account?"
              subtitle="This action is temporary"
              actionTitle="DEACTIVATE"
            />
          )}

          {isModalOpen && buttonChecked === 'delete' && (
            <Modal
              onClose={this.onCloseModal}
              onSubmit={this.onDelete}
              title="Are you sure you want to delete account?"
              subtitle="This action cannot be undone"
              actionTitle="DELETE"
            />
          )}
        </div>
      </div>
    )
  }
};

export default DeactivateDelete
