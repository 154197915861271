import * as React from 'react';
import * as cx from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { firestoreConnect, ReduxFirestoreQueries } from 'react-redux-firebase';
import { compose } from 'redux';

const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

import './HeaderDashboard.scss';
import { mapStateToProps } from './HeaderDashboard.container';
import UserList from '../UserList';
import { BoardUsers } from '../../types';
import { Link } from "react-router-dom";
import SignOutModal from "../Modal/variant/SignOutModal";
import SwitchAccountModal from "../Modal/variant/SwitchAccountModal";
import { getFirebase } from "react-redux-firebase";
import { DocumentSnapshot } from '@firebase/firestore-types';

import SideModal from 'components/SideModal';
import NotificationCenter from 'components/NotificationCenter';

export interface HeaderDashboardOwnProps {
  isAccount?: boolean
}

export interface HeaderDashboardStateProps {
  userId: string;
  user: BoardUsers;
  onSignOut: () => void;
  onSwitchAccount: () => void;
  hasBoards: boolean | undefined;
  silver: boolean | undefined;
  dontShowAccount: boolean | undefined;
  showEmail: boolean | undefined;
  notifications?: any;
}

export interface HeaderDashboardState {
  isMenuOpen: boolean;
  isSignOutOpen: boolean;
  isSwitchingAccOpen: boolean;
  isSuperAdmin: boolean;
  showNotificationCenter: boolean;
}

export type HeaderDashboardProps = HeaderDashboardStateProps &
  HeaderDashboardOwnProps;

class HeaderDashboard extends Component<HeaderDashboardProps, HeaderDashboardState> {
  __isMounted: boolean;

  constructor(props: any) {
    super(props);
    this.__isMounted = true;
    this.state = {
      isMenuOpen: false,
      isSignOutOpen: false,
      isSwitchingAccOpen: false,
      isSuperAdmin: false,
      showNotificationCenter: false
    };
  }

  componentDidMount() {
    const firestore = getFirebase().firestore();

    firestore
      .collection('users')
      .doc(this.props.userId)
      .get()
      .then((doc: DocumentSnapshot) => {
        if(doc.exists && doc.data()) {
          this.setState({ isSuperAdmin: !!doc.data()?.isSuperAdmin });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  onClose = () => {
    if(this.__isMounted) {
      this.setState({ isMenuOpen: false });
    }
  };

  onToggle = () => {
    if (this.props.showEmail) {
      return
    }

    if(this.__isMounted) {
      this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }
  };

  onOpenSignOut = () => {
    if(this.__isMounted) {
      if(window.location.hash.includes('#/account')) {
        const portal: HTMLElement | null = document.getElementById('portal');
        if(portal) {
          portal.classList.add('portal-account-signout');
        }
      }
      this.setState({ isSignOutOpen: true });
    }
  };

  onOpenSwitchAccount = () => {
    if(this.__isMounted) {
      this.setState({ isSwitchingAccOpen: true });
    }
  };

  onCloseSignOut = () => {
    if(this.__isMounted) {
      if(window.location.hash.includes('#/account')) {
        const portal: HTMLElement | null = document.getElementById('portal');
        if(portal) {
          portal.classList.remove('portal-account-signout');
        }
      }
      this.setState({ isSignOutOpen: false });
    }
  };

  onCloseSwitchAccount = () => {
    if(this.__isMounted) {
      this.setState({ isSwitchingAccOpen: false });
    }
  };

  openNotificationCenter = () => {
    this.setState({showNotificationCenter: true})
  }

  closeNotificationCenter = () => {
    this.setAsRead()
    this.setState({showNotificationCenter: false})
  }

  setAsRead = () => {
    const notifications = this.props.notifications
    let mapedNotifications = []
    if (notifications && Object.keys(notifications)?.length > 0) {
      mapedNotifications = Object.keys(notifications).map((key: any) => {
        return { id: key, ...notifications[key] }
      })
    }

    const unreadNotifications = mapedNotifications.filter((n: any) => n.status === 'unread')

    if (unreadNotifications?.length > 0) {
      const notificationsForMarking = unreadNotifications.map((n: any) => n.id)

      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/markNotificationsAsRead`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: this.props.userId,
          notifications: notificationsForMarking
        })
      })
      .then(res => res.json())
      .catch((e) => {
        console.log(e);
      })
    }
  }

  render() {
    const { userId, user, onSignOut, onSwitchAccount, hasBoards, isAccount, silver, dontShowAccount, showEmail, notifications } = this.props;
    const { isMenuOpen, isSignOutOpen, isSwitchingAccOpen, isSuperAdmin } = this.state;

    let mapedNotifications = []
    if (notifications && Object.keys(notifications)?.length > 0) {
      mapedNotifications = Object.keys(notifications).map((key: any) => {
        return { id: key, ...notifications[key] }
      })
    }

    const userEmail = user[userId]?.email

    const headerCSS = cx("header-user", {"header-user--silver": silver})

    const toggleIcon = (
      <div
        onClick={this.onToggle}
      >
        <UserList
          currentUserId={userId}
          users={user}
          dontOpenMenu={this.props.showEmail}
        />
      </div>
    );

    const ddMenuProps = {
      isOpen: isMenuOpen,
      close: this.onClose,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    let linkTo = hasBoards ? '/dashboard' : '/new'
    if (dontShowAccount) {
      linkTo = '/'
    }

    const unreadNotifications = mapedNotifications.filter((n: any) => n.status === 'unread')
    const unreadNotificationsNum = unreadNotifications.length

    return (
      <header className={headerCSS}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 header__half-container">
              <Link
                to={linkTo}
                className="header__logo-link"
              >
                <h1 className="header__text-logo">Retro<span className="header__text-logo--orange">Team</span></h1>
              </Link>
            </div>
            {userId &&
              <div className="col-sm-6 header__half-container">
                <div className="header__user-icon">
                  {unreadNotificationsNum > 0 && (
                    <div className="header__notificationNumber" onClick={this.openNotificationCenter}>{unreadNotificationsNum}</div>
                  )}
                  <DropdownMenu
                    {...ddMenuProps}
                    className="header__toggle-btn"
                  >
                    {!showEmail && (
                      <>
                        {!isAccount && !dontShowAccount &&
                        <li className="header__menu-item">
                          <Link className="header__menu-link" to="/account">Account</Link>
                        </li>
                        }
                        <li className="header__menu-item">
                          <div className="header__menu-link" onClick={this.openNotificationCenter}>Notifications</div>
                        </li>
                        {isSuperAdmin &&
                        <li className="header__menu-item">
                          <Link className="header__menu-link" to="/statistics">Statistics</Link>
                        </li>
                        }
                        {dontShowAccount &&
                        <li className="header__menu-item header__menu-item--switchAcc" onClick={this.onOpenSwitchAccount}>
                          Switch Account
                        </li>
                        }
                        <li
                          className="header__menu-item"
                          onClick={this.onOpenSignOut}
                        >
                          Sign Out
                        </li>
                      </>
                    )}
                  </DropdownMenu>
                </div>
                {showEmail && <div className='header__menu-email'>{userEmail}</div>}
                <SideModal
                  title="Notifications"
                  isOpen={this.state.showNotificationCenter}
                  onClose={this.closeNotificationCenter}
                  className="item-actions-sidebar"
                >
                  <NotificationCenter userId={userId} notifications={mapedNotifications} onClose={this.closeNotificationCenter} />
                </SideModal>
              </div>
            }
          </div>
        </div>
        {
          isSignOutOpen &&
          <SignOutModal
            onClose={this.onCloseSignOut}
            onSignOut={onSignOut}
          />
        }
        {
          isSwitchingAccOpen &&
          <SwitchAccountModal
            onClose={this.onCloseSwitchAccount}
            onSignOut={onSwitchAccount}
          />
        }
      </header>
    );
  }
}


function firestoreConnector(props: RouteComponentProps<{ id: string }>): ReduxFirestoreQueries {
  const user: any | null = getFirebase().auth().currentUser;
  const uid: string = user ? user.uid : '';

  if (!uid) {
    return []
  }

  return [
    {
      collection: 'users',
      doc: uid,
      subcollections: [{ collection: 'notifications' }],
      storeAs: 'notifications'
    },
  ];
}

export default compose<any, any, any>(
  firestoreConnect(firestoreConnector),
  connect(
    mapStateToProps,
  )
) (HeaderDashboard);
