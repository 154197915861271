import * as React from 'react';
import './Deferred.scss';

export interface DeferredProps {
  value: string;
}

export interface DeferredState {
  value?: string;
}

export class Deferred extends React.PureComponent<
  DeferredProps,
  DeferredState
> {
  state = {
    value: ''
  };

  render() {
    return (
      <>
        {this.state.value}
      </>
    );
  }
}

export default Deferred;
