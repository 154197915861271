import * as cx from 'classnames';
import * as React from 'react';

import './ColumnHeader.scss';
import ColumnName from '../ColumnName';
// import OverviewCircle from '../../Icon/OverviewCircle';
import Expand from '../../Icon/Expand';

export interface ColumnHeaderProps {
  title: string;
  count: number;
  isAdmin: boolean;
  isCustomBoard: boolean;
  id?: string

  onToggleOverview?: () => void;
  changeCustomColumn: (id: string, value: string) => void;

  className?: string;
}

const ColumnHeader: React.FunctionComponent<ColumnHeaderProps> = ({
  title,
  onToggleOverview,
  count,
  className,
  isAdmin,
  isCustomBoard,
  changeCustomColumn,
  id
}) => {
  return (
    <header className={cx('column-header', className)}>
      <div className="column-header__title-wrapper">
        <ColumnName
          id={id}
          title={title}
          count={count}
          isAdmin={isAdmin}
          isCustomBoard={isCustomBoard}
          changeCustomColumn={changeCustomColumn}
        />
        {onToggleOverview && (
          <button
            aria-label="Open overview"
            className="column-header__overview-button"
            onClick={() => onToggleOverview()}
          >
            <Expand
              svgClassName="column-header__overview-icon"
              columnId={id}
            />
          </button>
        )}
      </div>
    </header>
  );
};

export default ColumnHeader;
