import * as React from 'react';
import { Component } from "react";
import * as cx from 'classnames';

import './SideModal.scss';

export interface SideModalOwnProps {
  title: string,
  isOpen: boolean,
  children: any,
  onClose: () => void,
  className?: string
}

export interface SideModalState {
  isScrolled: boolean
}

class SideModal extends Component<SideModalOwnProps, SideModalState> {
  element: any;

  constructor(props: SideModalOwnProps) {
    super(props);
    this.state = {
      isScrolled: false
    }
  }

  componentDidMount() {

    if(this.element && this.props.isOpen) {
      setTimeout(() => {
        this.element.classList.add(this.props.className)
      }, 4000)
    }

    if(this.element) {
     this.element.addEventListener('scroll', (e: any) => {
       const { isScrolled } = this.state;
       if(e.target.scrollTop > 8 && !isScrolled) {
         this.setState({ isScrolled: true });
       } else if(e.target.scrollTop <= 8 && isScrolled) {
         this.setState({ isScrolled: false });
       }
     })
    }

  }

  componentWillUnmount() {
    const { isOpen } = this.props;
    const portal = document.getElementById('portal');
    if(isOpen) {
      if(portal) {
        portal.classList.remove('portal-side-modal-active');
      }
    }
  }

  render() {
    const {
      isOpen,
      title,
      onClose,
      children,
      className
    } = this.props;

    const { isScrolled } = this.state;

    const portal = document.getElementById('portal');
    if(isOpen && this.element) {
      if(portal) {
        portal.classList.add('portal-side-modal-active');
      }
    } else if(!isOpen && this.element && this.element.classList.contains('side-modal--active')) {
      if(portal && portal.classList.contains('portal-side-modal-active')) {
        portal.classList.remove('portal-side-modal-active');
      }
    }

    const portalWithClass = document.getElementsByClassName('portal-side-modal-active')[0];
    if(portalWithClass) {
      if(isOpen) {
        portalWithClass.addEventListener('click', (e: any) => {
          if(e.target) {
            if(e.target.classList.contains('portal-side-modal-active') && isOpen) {
              onClose();
            }
          }
        })
      }
    }

    let classNames = !!className ? className : '';

    return (
      <aside className={cx('side-modal', classNames, {'side-modal--active': isOpen})} ref={a => this.element = a}>
        <div className="side-modal-wrapper">
          <div className={cx('side-modal__header', {'side-modal__header--active': isScrolled})}>
            <div className="side-modal-header-container">
              <h2 className="side-modal__title">{title}</h2>
              <div
                className="side-modal-close-wrapper"
                onClick={onClose}
              />
            </div>
          </div>

          <div className="side-modal-content">
            {children}
          </div>
        </div>
      </aside>
    );
  }
}

export default SideModal;
