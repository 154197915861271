import * as React from 'react';
import './Pricing.scss';
import { Link } from "react-router-dom";

export interface PricingProps {
  plans: any
}

const Pricing = ({ plans }: PricingProps) => {
  plans.sort((a: any, b: any) => parseInt(a.amount) - parseInt(b.amount));

  return(
    <div className="prices">
      <h2 className="prices__title">PRICING</h2>
      <h5 className="prices__subtitle">Choose your best price</h5>
      <div className="prices-list container">
        {plans.map((plan: any) =>
          <Link
            key={plan.id}
            to={`/register?price=${plan.id}`}
            className="price"
          >
            <div className="price-heading">
              <div className="price__title">
                Retro <strong>{plan.nickname && plan.nickname}</strong>
              </div>
              <div className="price__billing">
                {plan.amount === 0 ? 'Free' : plan.amount/100}
                {plan.amount !== 0 &&
                  <div className="plan__duration">
                    /{plan.interval_count !== 1 && plan.interval_count}
                    {plan.interval.charAt(0)}
                  </div>
                }
              </div>
            </div>
            <div className="price-info">
              {(plan.metadata.userLimit || plan.metadata.boardLimit) && (
                <div className="price-description">
                  <ul className="price-features">
                    {plan.metadata.userLimit && (
                      <li className="price__feature">{plan.metadata.userLimit} max users on board</li>
                    )}
                    {plan.metadata.boardLimit && (
                      <li className="price__feature">{plan.metadata.boardLimit} max boards</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </Link>
        )}
      </div>
    </div>
  )
};

export default Pricing;

