import { getVal, getFirebase } from 'react-redux-firebase';

import { StoreState, UserBoard, UserBoards, UserTeams } from '../../types';
import { StateNewBoardProps } from './NewBoard';

export function mapStateToProps(
  state: StoreState,
): StateNewBoardProps {
  const { fbState } = state;
  const firebase = getFirebase() as any;

  let uid: string | null = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null;
  const arrayBoards: UserBoard[] = [];
  const userBoards: UserBoards | undefined = getVal(fbState, `data/userBoards`, undefined);
  const userTeams: UserTeams | undefined = getVal(fbState, `data/userTeams`, undefined);

  for (let key in userBoards) {
    if(userBoards.hasOwnProperty(key) && userBoards[key])
    arrayBoards.push({ ...userBoards[key], id: key });
  }

  return {
    userTeams,
    boards: arrayBoards
      .sort((a: UserBoard, b: UserBoard) => {
        if (a.lastUpdatedAt && b.lastUpdatedAt) {
          return b.lastUpdatedAt - a.lastUpdatedAt;
        } else if (a.createdAt && b.createdAt) {
          return b.createdAt - a.createdAt;
        } else {
          return 1;
        }
      })
      .slice(0, 3),
    uid
  };
}
