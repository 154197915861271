import * as React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect, ReduxFirestoreQueries } from 'react-redux-firebase';
import { User } from '@firebase/auth-types';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { analytics } from "../../index";
import HeaderDashboard from 'components/HeaderDashboard';
import ActionItems from 'components/ActionItems/ActionItems';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import {
  Board as BoardType, BoardCards,
  BoardUsers
} from "../../types";
import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps
} from './BoardActionItems.container';
import './BoardActionItems.scss';

export interface UserPresence {
  [uid: string]: boolean
}

export interface BoardActionItemsProps extends RouteComponentProps<{ id: string }> {
  auth: User;
  board: BoardType
  cards: BoardCards;
  isDataLoaded: boolean;
  users: BoardUsers;
  uid: string; // ID of the current user
  setupCompleted: boolean;
  isBoardAdmin: boolean;
  username: any;
  email: any;
  isAnonymous: any;
  onMarkCardAsDone: (boardId: string, cardId: string) => any;
  onMarkCardAsUndone: (boardId: string, cardId: string) => any;
  onDelteCard: (boardId: string, cardId: string) => any;
  onUpdateCard: (card: any) => any;
}

export interface BoardState {
  isInnerLoading: boolean;
}

export class BoardActionItems extends React.Component<BoardActionItemsProps, BoardState> {
  constructor(props: BoardActionItemsProps) {
    super(props);
    this.state = {
      isInnerLoading: false
    };
  }

  componentDidMount() {
    // @ts-ignore
    analytics.logEvent('board_action_items');
  }

  getCards = (cards: BoardCards) => {
    const cardsObject = cards ?? this.props.cards
    const board = this.props.board

    let boardUsers : any = [] 
    if (board?.users) {
      const userKeys = Object.keys(board.users)
      boardUsers = userKeys.map((key: any) => {
        const singleUser = board.users[key]
        return { id: key, ...singleUser }
      })
    }

    if (!cardsObject || Object.values(cardsObject).length === 0) {
      return []
    }

    const cardsData: any = []
    const cardsKeys = Object.keys(cardsObject)

    cardsKeys.forEach((cardKey: any) => {
      const card = cardsObject[cardKey]
      if(card?.type === 'actions') {
        cardsData.push({ ...card, boardId: this.props.match.params.id, boardName: board.name, boardUsers, id: cardKey })
      }
    });

    return cardsData
  }

  render() {
    if (!this.props.isDataLoaded) {
      return <LoadingScreen />
    }

    const actionCards = this.getCards(this.props.cards)

    return (
      <div className='BoardActionItems'>
        <HeaderDashboard />
        <div className='BoardActionItems__content'>
          <ActionItems 
            isAnonymous={this.props.isAnonymous}
            getCards={this.getCards}
            cards={actionCards}
            liveUpdate={true}
            onMarkCardAsDone={this.props.onMarkCardAsDone}
            onMarkCardAsUndone={this.props.onMarkCardAsUndone}
            onDelteCard={this.props.onDelteCard}
            onUpdateCard={this.props.onUpdateCard}
            activeUserUid={this.props.uid}
          />
        </div>
      </div>
    );
  }
}

function firestoreConnector(props: RouteComponentProps<{ id: string }>): ReduxFirestoreQueries {
  
  return [
    {
      collection: 'boards',
      doc: props.match.params.id,
      subcollections: [{ collection: 'cards' }],
      storeAs: 'cards'
    },
    {
      collection: 'boards',
      doc: props.match.params.id,
      subcollections: [{ collection: 'cardGroups' }],
      storeAs: 'cardGroups'
    },
    {
      collection: 'boards',
      doc: props.match.params.id,
      storeAs: 'board'
    }
  ];
}

export default compose<any, any, any>(
  firestoreConnect(firestoreConnector),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(BoardActionItems) as React.ComponentClass<any>;
