export const firebase = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyDZEUOPr6TVQrRWCzeMf3PlyOWpvhKGSeU',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'retroteam-dev.firebaseapp.com',
  projectId: process.env.REACT_APP_PROJECT_ID || 'retroteam-dev',
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'retroteam-dev.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '20260863966',
  enableRedirectHandling: false,
  appId: process.env.REACT_APP_APP_ID || "1:20260863966:web:6444d7c9319b02cde7e74f",
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || "G-RWHJ0JHV29"
};

export const sentry = {
  dsn: process.env.REACT_APP_SENTRY_DSN
};

export const slack = {
  feedbackHook: process.env.REACT_APP_SLACK_FEEDBACK_HOOK
};
