import * as React from 'react';
import { Component } from 'react';
const reactDDMenu = require('react-dd-menu');
const DropdownMenu = reactDDMenu.DropdownMenu;

import './SubNavigation.scss';
import {Link} from "react-router-dom";

export interface SubNavigationState {
  isOpen: boolean;
  timeOut: any;
  isInProgress: boolean;
}

export interface SubNavigationProps {
  isFilterByTime: boolean;
  changeFilter: (value: boolean) => void;
  changeMyBoards: () => void;
  changeSharedBoards: () => void;
  changeAllBoards: () => void;
  changeTeamBoards: () => void;
  onActionItems: () => void;
  onSearch: (value: string) => Promise<any>;
  // closeSearch: () => void;
  isSearching: boolean;
  searchQuery: string;
  isMyBoards: boolean;
  isSharedBoards: boolean;
  isTeamBoards: boolean;
  isAllBoards: boolean;
  isActionItems: boolean;
  totalAllBoards: number;
  totalMyBoards: number;
  totalSharedBoards: number;
  totalTeamBoards: number;
  totalActionItems: number;
}

class SubNavigation extends Component<SubNavigationProps, SubNavigationState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      timeOut: '',
      isInProgress: false
    };
  }

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onChange = (value: string) => {
    this.setState({ isInProgress: true });
    this.props.onSearch(value).then(() => {
      this.setState({ isInProgress: false });
    });
  };

  onCloseSearch = () => {
    this.props.onSearch('');
  };

  render() {
    const {
      searchQuery,
      isAllBoards,
      isMyBoards,
      isSharedBoards,
      isTeamBoards,
      isActionItems,
      changeAllBoards,
      changeMyBoards,
      changeSharedBoards,
      totalAllBoards,
      totalMyBoards,
      totalSharedBoards,
      totalTeamBoards,
      totalActionItems,
      isFilterByTime,
      changeFilter,
      isSearching,
      changeTeamBoards,
      onActionItems
    } = this.props;

    const { isOpen, isInProgress } = this.state;

    const toggleIcon = (
      <div className={`subnav__dropdown-button ${isOpen && 'subnav__dropdown-button--active'}`} onClick={this.onToggle}>
        {isFilterByTime ? 'Last Updated' : 'Alphabetical'}
      </div>
    );

    const ddMenuProps = {
      isOpen,
      close: this.onClose,
      toggle: toggleIcon,
      align: 'right',
      closeOnInsideClick: true
    };

    let content = require(`!svg-inline-loader!../../assets/icon-24-search.svg`);

    return (
      <div className="subnav">
        <div className="container">
          <div className="row subnav-top">
            <div className="col-6">
              <div className="subnav-search">
                <input
                  type="text"
                  className="subnav-search__input"
                  placeholder="Search board"
                  onChange={(e: any) => this.onChange(e.target.value)}
                  value={searchQuery}
                />
                <span
                  className={`subnav-search__icon ${isInProgress || isSearching ? 'subnav-search__icon--hide' : ''}`}
                  dangerouslySetInnerHTML={{__html: content}}
                />
                <div className="subnav-search__loader">
                  {isInProgress ? (
                    <div className="search__loader">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  ) : (
                    <>
                      {isSearching && (
                        <span
                          className="search__close"
                          onClick={this.onCloseSearch}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 subnav-create-new">
              <Link
                to="/new"
                className="subnav__link"
              >
                Create retrospective
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 subnav-board-type subnav-board-type-container">
              <div
                className={`subnav__board-type ${isAllBoards && 'subnav__board-type--active'}`}
                onClick={changeAllBoards}
              >
                All Boards <span>({totalAllBoards})</span>
              </div>
              <div
                className={`subnav__board-type ${isMyBoards && 'subnav__board-type--active'}`}
                onClick={changeMyBoards}
              >
                My Boards <span>({totalMyBoards})</span>
              </div>
              <div
                className={`subnav__board-type subnav__board-type--last ${isSharedBoards && 'subnav__board-type--active'}`}
                onClick={changeSharedBoards}
              >
                Shared <span>({totalSharedBoards})</span>
              </div>
              <div
                className={`subnav__board-type subnav__board-type--last subnav__board-type--teamBoards ${isTeamBoards && 'subnav__board-type--active'}`}
                onClick={changeTeamBoards}
              >
                Team Boards <span>({totalTeamBoards})</span>
              </div>
              <div
                className={`subnav__board-type subnav__board-type--last subnav__board-type--lastChild ${isActionItems && 'subnav__board-type--active'}`}
                onClick={onActionItems}
              >
                Action Items <span>({totalActionItems})</span>
              </div>
            </div>
          </div>

          {!isActionItems && (
            <div className="row">
              <div className="col-6">
                <h2 className="subnav__main-title">Boards</h2>
              </div>
              <div className="col-6">
                <div className="subnav__filter">
                  <DropdownMenu {...ddMenuProps} className="subnav__dropdown">
                    <li
                      className={`subnav__dropdown-item subnav__dropdown-item--first ${isFilterByTime && 'subnav__dropdown-item--active'}`}
                      onClick={() => changeFilter(true)}
                    >
                      Last updated
                    </li>
                    <li
                      className={`subnav__dropdown-item ${!isFilterByTime && 'subnav__dropdown-item--active'}`}
                      onClick={() => changeFilter(false)}
                    >
                      Alphabetical
                    </li>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SubNavigation;
