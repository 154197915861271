import * as React from 'react';
import { Component } from 'react';

import './DeleteModal.scss';
import Portal from '../../Portal';
import { getFirebase } from "react-redux-firebase";

export interface DeleteModalProps {
  onClose: () => void;
  boardId: string | undefined;
  openLoading: () => Promise<void>;
  closeLoading: () => Promise<void>;
}

class DeleteModal extends Component<DeleteModalProps, {}> {

  onDelete = (e: any) => {
    const currentUser = getFirebase().auth().currentUser;
    const { boardId, openLoading, closeLoading } = this.props;
    if(currentUser && boardId) {
      const el = e.target;
      el.disabled = true;
      openLoading()
        .then(() => {
          fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/deleteBoard`, {
            method: 'POST',
            body: JSON.stringify({ uid: currentUser.uid, boardId: boardId })
          })
            .then(response => response.json())
            .then(() => {
              if(location.hash === '#/dashboard') {
                closeLoading();
              } else {
                location.hash = '/dashboard';
                location.reload();
              }
            })
            .catch(() => {
              closeLoading();
            })
        });
    }
  };

  render() {
    return (
      <Portal
        onClose={this.props.onClose}
        showCloseButton={false}
      >
        <div className="modal__content-wrapper">
          <div className="modal__content delete-modal">
            <div
              className="modal__close-button"
              onClick={this.props.onClose}
            />
            <div className="delete-modal__message">
              <h2 className="delete-modal__title">Are you sure you want to delete the board?</h2>
              <p className="delete-modal__warning">
                This action cannot be undone.
              </p>
            </div>
            <div className="delete-modal-button__buttons">
              <button
                className="delete-modal-button delete-modal-button__danger"
                onClick={this.onDelete}
              >
                DELETE
              </button>
              <button
                className="delete-modal-button delete-modal-button__cancel"
                onClick={this.props.onClose}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default DeleteModal;
