import * as React from 'react';
import {Component} from 'react';
import {User} from '@firebase/auth-types';
import {getFirebase} from "react-redux-firebase";
import {RouteComponentProps} from "react-router";
import {QuerySnapshot} from '@firebase/firestore-types';

import LoadingScreen from "../LoadingScreen/LoadingScreen";

export interface RouteWrapperProps extends RouteComponentProps{
  isFreePage: boolean;
  children: any;
}

export interface RouteWrapperState {
  isPaymentInProgress: boolean;
  isLoaded: boolean;
  unsubscribe: any;
}

class RouteWrapper extends Component<RouteWrapperProps, RouteWrapperState> {
  interval: number | undefined;
  constructor(props: RouteWrapperProps) {
    super(props);
    this.state = {
      isPaymentInProgress: false,
      isLoaded: false,
      unsubscribe: undefined
    };
    this.interval = undefined;
  }

  checkingUser = () => {
    const { isFreePage } = this.props;
    this.setState({ isLoaded: false });
    const unsub = getFirebase().auth().onAuthStateChanged((user: User) => {
      if(location.hash.includes('join')) {
        return;
      }
      if(user) {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/isActiveUser`, {
          method: 'POST',
          body: JSON.stringify({ uid: user.uid })
        })
          .then(response => response.json())
          .then((data) => {
            if(data) {
              const body = JSON.parse(data.body);
              if(body.value === 'payment-in-progress') {
                this.setState({ isLoaded: false, isPaymentInProgress: true })
              } else if(body.value !== true) {
                if(!isFreePage) {
                  location.hash = '#/register';
                } else {
                  this.setState({
                    isLoaded: true,
                    isPaymentInProgress: false
                  })
                }
              } else {
                if(isFreePage) {
                  const boardRef = getFirebase().firestore().collection('boards').where('authorUid', '==', user.uid).limit(1);
                  boardRef
                    .get()
                    .then((query: QuerySnapshot) => {
                      if(query.size) {
                        location.hash = '#/dashboard';
                      } else {
                        location.hash = '#/new';
                      }
                    });
                } else {
                  this.setState({
                    isLoaded: true,
                    isPaymentInProgress: false
                  })
                }
              }
            } else {
              location.hash = '#/register';
            }
          })
      } else {
        if(!isFreePage) {
          const loc = this.props.location;
          if(loc.pathname === '/account' && !!loc.search && loc.search.slice(0,6) === '?team=') {
            location.href = `#/register${loc.search}`;
          } else {
            location.href = '/';
          }
        } else {
          this.setState({
            isLoaded: true,
            isPaymentInProgress: false
          });
        }
      }
    });
    this.setState({ unsubscribe: unsub });
  };

  componentDidMount() {
    const { unsubscribe } = this.state;
    if(!unsubscribe) {
      this.checkingUser();
    }
  }

  componentDidUpdate(prevProps: RouteWrapperProps, prevState: RouteWrapperState) {
    const { unsubscribe, isPaymentInProgress } = this.state;
    if((!unsubscribe || this.props.match.path !== prevProps.match.path)) {
      this.checkingUser();
    }

    if(!this.interval && isPaymentInProgress) {
      this.interval = window.setInterval(this.checkingUser, 5000);
    } else if(!!this.interval && !isPaymentInProgress) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    const { unsubscribe } = this.state;
    if(unsubscribe) {
     unsubscribe();
    }
  }

  render() {
    const { isLoaded, isPaymentInProgress } = this.state;
    const { children } = this.props;

    if(isLoaded) {
      return(
        <div>
          {children}
        </div>
      )
    } else {
      return(
        <LoadingScreen status={isPaymentInProgress ? 'Payment in progress' : ''}/>
        )
    }
  }
}

export default RouteWrapper;
