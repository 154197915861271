import * as React from 'react';

import './Modal.scss';
import { mapDispatchToProps } from './Modal.container';
import { connect } from 'react-redux';
import Portal from '../Portal';

export interface OwnModalProps {
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  subtitle: string;
  actionTitle: string;
}

export interface DispatchModalProps {
  onStatus?: (open: boolean) => void;
}

export type ModalProps = OwnModalProps & DispatchModalProps;

export class Modal extends React.Component<ModalProps, {}> {
  constructor(props: ModalProps) {
    super(props);
  }

  componentWillMount() {
    if(this.props.onStatus) {
      this.props.onStatus(true);
    }
  }

  componentWillUnmount() {
    if(this.props.onStatus) {
      this.props.onStatus(false);
    }
  }

  onSubmitPortal = async (e: any) => {
    e.preventDefault();
    const btn = e.target;
    btn.disabled = true;
    await this.props.onSubmit();
    btn.disabled = false;
  };

  render() {
    const { onClose, title, subtitle, actionTitle } = this.props;

    return (
      <Portal
        onClose={onClose}
        showCloseButton={false}
      >
        <div className="modal__content-wrapper">
          <div className="modal__content delete-modal">
            <div
              className="modal__close-button"
              onClick={onClose}
            />
            <div className="delete-modal__message">
              <h2 className="delete-modal__title">{title}</h2>
              <p className="delete-modal__warning">
                {subtitle}
              </p>
            </div>
            <div className="delete-modal-button__buttons">
              <button
                className="delete-modal-button delete-modal-button__danger"
                onClick={this.onSubmitPortal}
              >
                {actionTitle}
              </button>
              <button
                className="delete-modal-button delete-modal-button__cancel"
                onClick={onClose}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default connect<{}, DispatchModalProps, OwnModalProps>(
  null,
  mapDispatchToProps
)(Modal);
