import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import InputAdornment from '@material-ui/core/InputAdornment';

import { mapStateToProps } from './LoginBoard.container';
import './LoginBoard.scss';
import getRandomName from '../../constants/Name';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

export interface LoginBoardProps extends RouteComponentProps<{ id: string; }> {
  uid: string | null;
  onLogin: (name: string) => void;
  onCreateNewBoard: () => void;

  [key: string]: any;
}

export interface LoginBoardState {
  email: string;
  error: boolean;
  isRegistering: boolean;
}

export class LoginBoard extends Component<LoginBoardProps, LoginBoardState> {
  defaultName: string;

  constructor(props: LoginBoardProps) {
    super(props);

    this.defaultName = getRandomName();
    this.state = {
      email: this.defaultName,
      error: false,
      isRegistering: false
    };
  }

  clearInput = () => {
    this.setState({ email: '' });
  };

  handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value;
    const limit = process.env.REACT_APP_USERNAME_LIMIT ? process.env.REACT_APP_USERNAME_LIMIT : 40;
    if(email.length <= limit) {
      this.setState(state => ({ ...state, email, error: /^\s/.test(email) }));
    }
  };

  handleClickLogin = () => {
    console.log('heyyyyyyyyyyyy')
    const input = document.querySelectorAll('.login-box__input input')[0];
    console.log('input', input)
    const button = document.getElementById('loginButton');
    if(input) {
      input.setAttribute('disabled', 'true');
    }

    if(button) {
      button.setAttribute('disabled', 'true');
    }

    if (!this.state.error) {
      if (this.state.email) {
        this.setState({ isRegistering: true }, () => {
          console.log('calling onLogin')
          this.props.onLogin(this.state.email);
        });
      } else {
        this.setState({ error: true });
        if(input) {
          input.setAttribute('disabled', 'false');
        }
        if(button) {
          button.setAttribute('disabled', 'false');
        }
      }
    }
  };

  handleBlurEmail = () => {
    const { email } = this.state;
    if (!email) {
      this.setState({ error: true });
    }
  };

  render() {
    const { uid } = this.props;
    const { email, error, isRegistering } = this.state;
    return (
      <>
        {isRegistering ?
          <LoadingScreen
            status='Registering user'
          />
          :
          <>
            <header className="login-header">
              <Link
                to='/new'
                className="login-header-link"
              >
                <h1 className="login-header__title">
                  Retro<span>Team</span>
                </h1>
              </Link>
            </header>
            <div className="login-main">
              <div className="login-box">
                <h2 className="login-box__header">
                  {!uid && <span>Hi, nice to meet you!</span>}
                  {uid && <span>Welcome back!</span>}
                </h2>
                <p className="login-box__paragraph">
                  <span>You were invited to join an online retrospective.</span>
                  {uid && <span>Some people already joined the group …</span>}
                </p>

                {!uid && (
                  <div className="login-input-container">
                    <div className="char-remaining">{email.length} / {process.env.REACT_APP_USERNAME_LIMIT}</div>
                    <TextField
                      value={email}
                      label="Your Name"
                      error={error}
                      className="login-box__input"
                      helperText={error ? 'Your name may not start with a whitespace' : 'Choose a name by which you will be recognized'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {email && (
                              <div
                                className="login-box-input-clear"
                                onClick={this.clearInput}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onChange={this.handleChangeEmail}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.keyCode === 13) {
                          this.handleClickLogin();
                        }
                      }}
                      onBlur={this.handleBlurEmail}
                    />
                    <button
                      id="loginButton"
                      onClick={this.handleClickLogin}
                      className="login__button"
                    >
                      Join
                    </button>
                  </div>
                )}
                {uid && (
                  <Link
                    to={`/board/${this.props.match.params.id}`}
                    id="loginButton"
                    className="login__link"
                  >
                    Join
                  </Link>
                )}
              </div>
            </div>
          </>
        }
      </>
    );
  }
}

export default connect<LoginBoardProps, {}, {}>(mapStateToProps)(LoginBoard) as unknown as React.ComponentClass<any>;
