import {getFirebase, getVal} from "react-redux-firebase";
import {StoreState, Team, Teams} from "../../../../types";
import { TeamManagerOwnProps, TeamManagerStateProps } from "./TeamManager";
import { DocumentSnapshot } from "@firebase/firestore-types";

export const mapStateToProps = (
  state: StoreState,
  ownProps: TeamManagerOwnProps
): TeamManagerStateProps => {
  const { fbState } = state;
  const user = getFirebase().auth().currentUser;
  const database = getFirebase().firestore();
  let uid: string = '';
  if(user) {
    uid = user.uid;
  }
  const teams: Teams = getVal(fbState, `data/teams`, undefined);

  const createTeam = (name: string) => {
    if(uid && name) {
      ownProps
        .openLoading('Creating team')
        .then(() => {
          fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createTeam`, {
            method: 'POST',
            body: JSON.stringify({ uid: uid, teamName: name })
          })
            .then(res => res.json())
            .then((data) => {
              const body = JSON.parse(data.body);
              if(body.success) {
                ownProps.onCreateTeam();
                ownProps.closeLoading();
              } else {
                ownProps.closeLoading();
                if(body.error === 'plan') {
                  ownProps.openModal();
                }
              }
            })
            .catch((e) => {
              ownProps.closeLoading();
            })
        })
    }
  };

  const toggleAdmin = (teamId: string, userId: string) => {
    if(teamId && userId && teams && uid) {
      const currentTeam: Team = teams[teamId];
      const isAdmin: boolean = currentTeam.admins.includes(userId);

      if (!currentTeam.admins.includes(uid)) {
        // only admins can toggle team members
        return
      }
      
      if(isAdmin) {
        const newAdmins = currentTeam.admins.filter((x) => x !== userId);
        database
          .collection('teams')
          .doc(teamId)
          .update({admins: newAdmins })
          .catch((e) => {
            console.log(e);
          })
      } else {
        const newAdmins = [ ...currentTeam.admins, userId ];
        database
          .collection('teams')
          .doc(teamId)
          .update({admins: newAdmins})
          .catch((e) => {
            console.log(e);
          })
      }
    }
  };

  const leaveTeam = (teamId: string, userId: string) => {
    if(teamId && uid && teams && teams[teamId]) {
      const currentTeam: Team = teams[teamId];
      const isAdmin: boolean = currentTeam.admins.includes(uid);
      const isUser: boolean = currentTeam.usersArray.includes(uid);
      if((( isAdmin ) || ( isUser && uid === userId )) &&  currentTeam.usersArray.includes(userId)) {
        ownProps
          .openLoading('Removing user from Team')
          .then(() => {
            database
              .collection('teams')
              .doc(teamId)
              .collection('users')
              .doc('users')
              .get()
              .then(async (value: DocumentSnapshot) => {
                if(value.exists && value.data()) {
                  try {
                    const members = value.data();
                    let newMembers = {};
                    await Object.keys(members!).map((memberId: string) => {
                      if(memberId !== userId) {
                        newMembers[memberId] = {
                          ...members![memberId]
                        }
                      }
                    });

                    database
                      .collection('teams')
                      .doc(teamId)
                      .collection('users')
                      .doc('users')
                      .set(newMembers)
                      .then(() => {
                        const index = currentTeam.usersArray.indexOf(userId);
                        const newUsersArray: string[] = [...currentTeam.usersArray.slice(0, index), ...currentTeam.usersArray.slice(index + 1)];
                        if(isAdmin && currentTeam.admins.includes(userId)) {
                          const indexOfAdmin = currentTeam.admins.indexOf(userId);
                          const newAdmins: string[] = [...currentTeam.admins.slice(0, indexOfAdmin), ...currentTeam.admins.slice(index + 1)];
                          database
                            .collection('teams')
                            .doc(teamId)
                            .update({ usersArray: newUsersArray, admins: newAdmins })
                            .then(() => {
                              ownProps.closeLoading();
                            })
                            .catch((e) => {
                              console.log(e);
                              ownProps.closeLoading();
                            })
                        } else {
                          database
                            .collection('teams')
                            .doc(teamId)
                            .update({ usersArray: newUsersArray })
                            .then(() => {
                              ownProps.closeLoading();
                            })
                            .catch((e) => {
                              console.log(e);
                              ownProps.closeLoading();
                            })
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        ownProps.closeLoading();
                      })
                  } catch (e) {
                    console.log(e);
                    ownProps.closeLoading();
                  }
                } else {
                  ownProps.closeLoading();
                }
              })
              .catch((e) => {
                console.log(e);
                ownProps.closeLoading();
              })
          })
      }
    }
  };

  return {
    teams,
    createTeam,
    leaveTeam,
    toggleAdmin
  }
};
