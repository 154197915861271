import * as React from 'react';
import {Component} from 'react';
import {User} from '@firebase/auth-types';
import {getFirebase} from "react-redux-firebase";
import {RouteComponentProps} from "react-router";

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { DocumentSnapshot } from '@firebase/firestore-types';

export interface RouteWrapperProps extends RouteComponentProps{
  isFreePage: boolean;
  children: any;
}

export interface RouteWrapperState {
  isPaymentInProgress: boolean;
  isLoaded: boolean;
  unsubscribe: any;
}

class RouteWrapper extends Component<RouteWrapperProps, RouteWrapperState> {
  interval: number | undefined;
  constructor(props: RouteWrapperProps) {
    super(props);
    this.state = {
      isPaymentInProgress: false,
      isLoaded: false,
      unsubscribe: undefined
    };
    this.interval = undefined;
  }

  checkingUser = () => {
    const { isFreePage } = this.props;
    this.setState({ isLoaded: false });
    const firestore = getFirebase().firestore();

    const unsub = getFirebase().auth().onAuthStateChanged((user: User) => {
      if(location.hash.includes('join')) {
        return;
      }
      if(user) {
        firestore
          .collection('users')
          .doc(user.uid)
          .get()
          .then((doc: DocumentSnapshot) => {
            if(doc.exists && doc.data()) {
              if (!doc.data()?.isSuperAdmin) {
                location.hash = '#/dashboard';
              }else {
                this.setState({
                  isLoaded: true,
                  isPaymentInProgress: false
                })
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if(!isFreePage) {
          const loc = this.props.location;
          if(loc.pathname === '/account' && !!loc.search && loc.search.slice(0,6) === '?team=') {
            location.href = `#/register${loc.search}`;
          } else {
            location.href = '/';
          }
        } else {
          this.setState({
            isLoaded: true,
            isPaymentInProgress: false
          });
        }
      }
    });
    this.setState({ unsubscribe: unsub });
  };

  componentDidMount() {
    const { unsubscribe } = this.state;
    if(!unsubscribe) {
      this.checkingUser();
    }
  }

  componentDidUpdate(prevProps: RouteWrapperProps, prevState: RouteWrapperState) {
    const { unsubscribe, isPaymentInProgress } = this.state;
    if((!unsubscribe || this.props.match.path !== prevProps.match.path)) {
      this.checkingUser();
    }

    if(!this.interval && isPaymentInProgress) {
      this.interval = window.setInterval(this.checkingUser, 5000);
    } else if(!!this.interval && !isPaymentInProgress) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    const { unsubscribe } = this.state;
    if(unsubscribe) {
     unsubscribe();
    }
  }

  render() {
    const { isLoaded, isPaymentInProgress } = this.state;
    const { children } = this.props;

    if(isLoaded) {
      return(
        <div>
          {children}
        </div>
      )
    } else {
      return(
        <LoadingScreen status={isPaymentInProgress ? 'Payment in progress' : ''}/>
        )
    }
  }
}

export default RouteWrapper;
