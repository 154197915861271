import * as cx from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import { PhaseMenu } from '../PhaseMenu';
import { UserMenu } from '../UserMenu';
import {Board, BoardUsers, ModalType} from '../../types';
import Logo from './subcomponents/Logo';
import { mapStateToProps } from './Header.container';
import MenuGeneral from '../MenuGeneral';
import BackButton from './subcomponents/Logo/BackButton';
import UserManagementIcon from "../UserManagementIcon";
import './Header.scss';

export interface OwnHeaderProps {
  boardId: string;
  board: Board;
  onSignOut: () => void;
  onOpenModal: (modal: ModalType) => void;
  className?: string;
  onChangeBoardName: (boardName: string) => void;
  showDelete?: boolean;
  onCloseModal?: () => void;
  markAsDoneDisabled: boolean | undefined;
  isReady: boolean | undefined;
  toggleReadyState: () => void;
  openLoading: () => Promise<void>;
  closeLoading: () => Promise<void>;
}

export interface StateHeaderProps {
  admin: boolean;
  hasCards: boolean;
  totalPhases: number;
  prevPhaseName: string | undefined;
  nextPhaseName: string | undefined;
  phaseNames: any;
  boardName?: string;
  onPrevPhase: () => void;
  onNextPhase: () => void;
  onSetTimer: (seconds: number) => void;
  loggedIn: boolean;
  user: string | null;
  users: BoardUsers;
  userLength: number;
}

export type HeaderProps = OwnHeaderProps & StateHeaderProps;

export class Header extends React.Component<HeaderProps, {}> {
  render() {
    const {
      admin,
      board,
      className,
      boardName,
      totalPhases,
      onPrevPhase,
      onNextPhase,
      prevPhaseName,
      nextPhaseName,
      onSetTimer,
      user,
      users,
      onChangeBoardName,
      onOpenModal,
      loggedIn,
      onSignOut,
      showDelete,
      onCloseModal,
      userLength,
      phaseNames,
      isReady,
      toggleReadyState,
      markAsDoneDisabled,
      boardId,
      hasCards,
      openLoading,
      closeLoading
    } = this.props;

    let currentUserInfo: BoardUsers = {};

    for(let key in users) {
      if(users.hasOwnProperty(key)) {
        if(key === user) {
          currentUserInfo[key] = users[key];
        }
      }
    }

    const componentClassName = cx('header', className);

    return (
      <header className={componentClassName}>
        <div className="header__control">
          <div className="header-third">
            <BackButton className="header__back-button" />
            <Logo />
          </div>

          <div className="header-third header-phase-container">
            <PhaseMenu
              admin={admin}
              guidedPhase={board.guidedPhase}
              onPrevPhase={onPrevPhase}
              onNextPhase={onNextPhase}
              prevPhaseName={prevPhaseName}
              nextPhaseName={nextPhaseName}
              phaseNames={phaseNames}
              totalPhases={totalPhases}
            />
          </div>


          <div className="header__control-users header-third">
            <MenuGeneral
              admin={admin}
              onOpenModal={onOpenModal}
              onSetTimer={onSetTimer}
              completed={board.completed}
              showDelete={showDelete}
              onCloseModal={onCloseModal}
              markAsDoneDisabled={markAsDoneDisabled}
              isReady={isReady}
              hasCards={hasCards}
              toggleReadyState={toggleReadyState}
              boardId={boardId}
              openLoading={openLoading}
              closeLoading={closeLoading}
              mobileVersion={false}
            />

            <div className="header__user-management">

              <UserManagementIcon
                onClick={() => onOpenModal('participants')}
                userCount={userLength}
              />

              {loggedIn && (
                <UserMenu
                  boardName={boardName}
                  completed={board.completed}
                  admin={admin}
                  onChangeBoardName={onChangeBoardName}
                  onSignOut={onSignOut}
                  onOpenModal={onOpenModal}
                  onSetTimer={onSetTimer}
                  isBoard={true}
                  currentUserId={user || ''}
                  users={currentUserInfo}
                />
              )}
            </div>
          </div>
        </div>
        <MenuGeneral
          admin={admin}
          onOpenModal={onOpenModal}
          onSetTimer={onSetTimer}
          completed={board.completed}
          showDelete={showDelete}
          onCloseModal={onCloseModal}
          markAsDoneDisabled={markAsDoneDisabled}
          isReady={isReady}
          hasCards={hasCards}
          toggleReadyState={toggleReadyState}
          boardId={boardId}
          openLoading={openLoading}
          closeLoading={closeLoading}
          mobileVersion
        />
      </header>
    );
  }
}

export default connect<StateHeaderProps, null, OwnHeaderProps>(mapStateToProps)(Header);
