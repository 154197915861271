import {StoreState} from "../../types";
import {TeamInviteUsersStateProps} from "./TeamInviteUsers";

export const mapStateToProps = (
  state: StoreState,
  ownProps: {}
): TeamInviteUsersStateProps => {

  function sendInviteMails(emails: string, hasAdminRights: boolean) {
    console.log(emails, hasAdminRights);
  }

  return {
    sendInviteMails
  }
};
