import * as React from "react";
import Portal from "../../Portal";

export interface LeaveTeamModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const LeaveTeamModal = (props: LeaveTeamModalProps) => {

  return(
    <Portal
      onClose={props.onClose}
      showCloseButton={false}
    >
      <div className="modal__content-wrapper">
        <div className="modal__content delete-modal">
          <div
            className="modal__close-button"
            onClick={props.onClose}
          />
          <div className="delete-modal__message">
            <h2 className="delete-modal__title">Are you sure you want to leave team?</h2>
            <p className="delete-modal__warning">
              This action cannot be undone.
            </p>
          </div>
          <div className="delete-modal-button__buttons">
            <button
              className="delete-modal-button delete-modal-button__danger"
              onClick={props.onSubmit}
            >
              LEAVE
            </button>
            <button
              className="delete-modal-button delete-modal-button__cancel"
              onClick={props.onClose}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </Portal>
  )
};

export default LeaveTeamModal;
