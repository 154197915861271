import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {firestoreConnect, getVal, isLoaded, ReduxFirestoreQueries} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { groupBy, sortBy, debounce } from 'lodash';
import { isEmpty } from 'lodash';

import {
  StoreState,
  BoardCards,
  Card,
  Board, CardGroup, BoardGroups
} from '../../types';
import './PrintViewBoard.scss';
import {
  getPhaseConfiguration,
  IndexedPhaseConfiguration
} from '../../constants/Retrospective';
import moment = require('moment');
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

export interface PrintViewBoardProps
  extends RouteComponentProps<{ id: string }> {
  cards: BoardCards;
  printingCards: BoardCards;
  cardGroups: BoardGroups;
  boardConfig: Board;
}

export interface PrintViewBoardState {
  hasShownExport: boolean;
}

const callBrowserPrintDialog = debounce(() => {
  // Wait some time so that everything has had time to render correctly.
  setTimeout(() => {
    window.print();
  }, 1000);
}, 1000);

export class PrintViewBoard extends React.Component<
  PrintViewBoardProps,
  PrintViewBoardState
> {
  constructor(props: PrintViewBoardProps) {
    super(props);
    this.state = {
      hasShownExport: false
    };
  }

  static sortCards(cards: BoardCards): { [columnName: string]: Card[] } {
    if(cards) {
      const cardsArray = Object.keys(cards).map(key => cards[key]);
      const groups = groupBy(cardsArray, 'type') as any;
      for (let key in groups) {
        const sorted = sortBy(groups[key], ['votes', 'timestamp']);
        sorted.reverse();
        groups[key] = sorted;
      }
      return groups;
    } else {
      return {};
    }
  }

  static sortGroups(groups: BoardGroups): { [columnName: string]: CardGroup[] } {
    if(groups) {
      const groupArray = Object.keys(groups).map(key => groups[key]);
      const groupedGroups = groupBy(groupArray, 'type') as any;
      for(let key in groupedGroups) {
        const sorted = sortBy(groupedGroups[key], ['order']);
        sorted.reverse();
        groupedGroups[key] = sorted;
      }

      return groupedGroups;
    } else {
      return {};
    }
  }

  componentDidMount() {
    // If data has already been loaded previously by other component (e.g. the board),
    // the browser print dialog can be called directly.
    const { hasShownExport } = this.state;

    if (!isEmpty(this.props.cards) && !hasShownExport) {
      this.setState({ hasShownExport: true }, () => {
        callBrowserPrintDialog();
      });
    }
  }

  componentDidUpdate(oldProps: PrintViewBoardProps) {
    // If data has not been available already during mounting (e.g. when reloading the page),
    // call the browser dialog when data has arrived.
    const { hasShownExport } = this.state;
    if (
      isEmpty(oldProps.cards) &&
      !isEmpty(this.props.cards) &&
      window &&
      typeof window.print === 'function' &&
      !hasShownExport
    ) {
      this.setState({ hasShownExport: true }, () => {
        callBrowserPrintDialog();
      });
    }
  }

  renderCardSection(title: string, cards: Card[] = [], groups: CardGroup[], index: number) {
    let totalCardsInGroup: number = !!cards && cards.length ? cards.length : 0;
    if(groups) {
      groups.map(group => {
        if(group.cards) {
          totalCardsInGroup += Object.keys(group.cards).length;
        }
      })
    }
    return (
      <div key={index} className="export-column">
        <div className="export-column__header">
          <h2 className="export-column-header__title">{title}</h2>
          <div className="export-column-header__count">{totalCardsInGroup}</div>
        </div>
        <div className="export-column__main">
          <ol className="card-list">
            {!!cards && !!cards.length && cards.map((card: Card, index: number) => this.renderCardItem(card, index))}
            {!!groups && !!groups.length && groups.map((group: CardGroup, index: number) => this.renderGroupItem(group, index))}
          </ol>
        </div>
      </div>
    );
  }

  renderCardItem(card: Card, index: number) {
    const { boardConfig } = this.props;

    return (
      <li className="card-list__item" key={index}>
        <blockquote className="print-view-board__card-blockquote">
          {boardConfig.showAuthor && (
            <cite className="print-view-board__cite">{card.author}</cite>
          )}
          <>
            {card.text}
          </>
        </blockquote>
        <div className="export-column-card__votes">{card.votes} votes</div>
      </li>
    );
  }

  renderGroupItem(group: CardGroup, index: number) {
    const { cards } = this.props;

    return (
      <li
        className="export-group"
        key={index}
      >
        <h4 className="export-group__name">{group.name} <div className="export-column-header__count">{Object.keys(group.cards).length}</div></h4>
        <ol className="card-list">
          {Object.keys(group.cards).map((cardId: string, index: number) => {
            const card: Card = {
              ...cards[cardId],
              id: cardId
            };
            return this.renderCardItem(card, index);
          })}
        </ol>
      </li>
    )
  }

  render() {
    const {
      printingCards,
      boardConfig,
      cardGroups
    } = this.props;
    const cardsSorted = PrintViewBoard.sortCards(printingCards);
    const groupSorted: any = PrintViewBoard.sortGroups(cardGroups);
    if (Object.keys(cardsSorted).length === 0 && Object.keys(groupSorted).length === 0) {
      return <LoadingScreen />;
    }

    let phase: IndexedPhaseConfiguration = {
      activities: [],
      columns: [],
      description: '',
      index: boardConfig.guidedPhase,
      name: ''
    };

    if (boardConfig.mode === 'custom') {
      if (boardConfig.customConfig) {
        phase = {
          index: boardConfig.guidedPhase,
          ...boardConfig.customConfig[2]
        };
      }
    } else {
      phase = getPhaseConfiguration(boardConfig.mode, 2);
    }

    const creationDate: string = moment(boardConfig.createdAt).format(
      'dddd, MMMM D, YYYY'
    );

    return (
      <div className="export">
        <header className="side-modal__box export-first-box">
          <div className="export-header">
            <h1 className="export__logo">
              Retro<span>Team</span>
            </h1>
          </div>
        </header>
        <div className="export-box">
          <h3 className="export__title">Retrospective results</h3>
          <div className="export__info">
            {boardConfig.name} &bull; {creationDate}
          </div>
        </div>

        <main>
          {phase.columns.map((column, index) => {
            if((!!cardsSorted[column.id] && !!cardsSorted[column.id].length) || (!!groupSorted[column.id] && !!groupSorted[column.id].length)) {
              return(this.renderCardSection(
                column.name,
                cardsSorted[column.id],
                groupSorted[column.id],
                index
              ))
            } else {
              return '';
            }
          }
          )}
        </main>

        <footer className="export-footer">
          <h2 className="export-footer__logo">
            Retro<span>Team</span>
          </h2>
          <div className="export-footer__text">Powered by RetroTeam</div>
        </footer>
      </div>
    );
  }
}

export function mapStateToProps(
  state: StoreState,
  ownProps: PrintViewBoardProps
) {
  const { fbState } = state;
  const boardUrl = `/boards/${ownProps.match.params.id}`;
  const cards: BoardCards = getVal(fbState, `data/cards`, {});
  const cardGroups: BoardGroups = getVal(fbState, `data/cardGroups`);
  const printingCards: BoardCards = {...cards};
  const boardConfig: Board = getVal(fbState,`data/${boardUrl}`,{});
  if(isLoaded(cardGroups) && isLoaded(cards)) {
    if(cardGroups) {
      Object.keys(cardGroups).map((groupId: string) => {
        const cardGroup: CardGroup = cardGroups[groupId];
        if(!!cardGroup && cardGroup.cards) {
          Object.keys(cardGroup.cards).map((cardId: string) => {
            if(printingCards.hasOwnProperty(cardId)) {
              delete printingCards[cardId];
            }
          })
        }
      })
    }
  }

  return {
    cards,
    boardConfig,
    printingCards,
    cardGroups
  };
}

function firebaseConnector(props: RouteComponentProps<{ id: string }>): ReduxFirestoreQueries {
  return [
    {
      collection: 'boards',
      doc: props.match.params.id,
    },
    {
      collection: 'boards',
      doc: props.match.params.id,
      subcollections: [{ collection: 'cards' }],
      storeAs: 'cards'
    },
    {
      collection: 'boards',
      doc: props.match.params.id,
      subcollections: [{ collection: 'cardGroups' }],
      storeAs: 'cardGroups'
    }
  ]
}

export default compose(
  firestoreConnect(firebaseConnector),
  connect(mapStateToProps)
)(PrintViewBoard) as React.ComponentClass<any>;
