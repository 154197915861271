import * as React from 'react';
import * as cx from 'classnames';
import Portal from "../../Portal";
import './InfoModal.scss';

export interface InfoModalProps {
  onClose: () => void;
  onSubmit: () => void;
  description: string;
  title: string;
  buttonText?: string;
  classNames?: string;
}


const InfoModal = (props: InfoModalProps) => {

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const btn = e.target;
    btn.disabled = true;
    await props.onSubmit();
    props.onClose();
  };

  const {
    onClose,
    description,
    title,
    buttonText,
    classNames = ''
  } = props;

  return(
    <Portal
      onClose={onClose}
      showCloseButton={false}
    >
      <div className={cx("modal__content-wrapper", classNames)}>
        <div className="modal__content info-modal">
          <div
            className="modal__close-button"
            onClick={onClose}
          />
          <h2 className="info-modal__title">{title}</h2>
          <div className="info-modal__description">{description}</div>
          <button
            className="info-modal__button"
            onClick={handleSubmit}
          >
            {buttonText ? buttonText : 'OK'}
          </button>
        </div>
      </div>
    </Portal>
  )
};

export default InfoModal;
