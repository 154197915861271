import * as React from "react";
import { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import './SettingsSideModal.scss'
import InfoModal from "../../Modal/variant/InfoModal";
import AddRemoveCircle from "../../AddRemoveCircle";

export interface SetingsSideModalProps {
  boardName: string | undefined;
  isTeam: boolean;
  username: string | undefined;
  onChangeBoardName: (name: string) => void;
  onChangeUsername: (name: string) => void;
  isShowAuthor: boolean;
  onToggleShowAuthor: () => void;
  isBoardCompleted: boolean;
  onLimitVotes: (limit: number) => void;
  limitVotes: number | undefined;
  onToggleMarkAsDone: () => void;
  markAsDoneDisabled: boolean | undefined;
  toggleLockBoard: () => void;
  onClose: () => void;
  boardSecurity: boolean;
  toggleBoardSecurity: () => void;
}

export interface SetingsSideModalState {
  isLimitedVotes: boolean;
  votes: number;
  valueBoardName: string | undefined;
  valueUserName: string | undefined;
  modal: 'lockBoard' | 'unlockBoard' | '';
}

class SetingsSideModal extends Component<SetingsSideModalProps, SetingsSideModalState> {
  maxLimitVotes: number;
  constructor(props: SetingsSideModalProps) {
    super(props);
    this.maxLimitVotes = 20;
    this.state = {
      isLimitedVotes: false,
      votes: 1,
      valueBoardName: '',
      valueUserName: '',
      modal: ''
    }
  }

  componentDidMount() {
    if(this.props.limitVotes) {
      this.setState({
        isLimitedVotes: true,
        votes: this.props.limitVotes
      });
    } else {
      this.setState({
        isLimitedVotes: false
      });
    }

    this.setState({
      valueBoardName: this.props.boardName,
      valueUserName: this.props.username
    })
  }

  toggleLimited = () => {
    const { isLimitedVotes } = this.state;
    if(isLimitedVotes) {
      this.props.onLimitVotes(0);
      this.setState({ votes: 1 });
    } else {
      if(!this.props.limitVotes) {
        this.props.onLimitVotes(1);
      }
    }
    this.setState({ isLimitedVotes: !isLimitedVotes });
  };

  onChangeLimited = (isPlus: boolean) => {
    const { votes } = this.state;
    if(isPlus && this.maxLimitVotes > votes) {
      const upVotes: number = votes + 1;
      this.setState({ votes: upVotes });
      this.props.onLimitVotes(upVotes);
      return true;
    } else if(!isPlus && votes > 1) {
      const downVotes: number = votes - 1;
      this.setState({ votes: downVotes });
      this.props.onLimitVotes(downVotes);
      return true;
    } else {
      return false;
    }
  };

  handleChangeBoardName = (e: any) => {
    const limit = process.env.REACT_APP_BOARD_NAME_LIMIT ? process.env.REACT_APP_BOARD_NAME_LIMIT : 66;
    if(e.target.value.length <= limit) {
      this.setState({
        valueBoardName: e.target.value
      });
      this.props.onChangeBoardName(e.target.value);
    }
  };

  handleChangeUserName = (e: any) => {
    const limit = process.env.REACT_APP_USERNAME_LIMIT ? process.env.REACT_APP_USERNAME_LIMIT : 40;
    if(e.target.value.length <= limit) {
      this.setState({
        valueUserName: e.target.value
      });
      this.props.onChangeUsername(e.target.value);
    }
  };

  handleToggleLockBoard = () => {
    this.props.onClose();
    if(this.props.isBoardCompleted) {
      this.setState({ modal: 'unlockBoard' });
    } else {
      this.setState({ modal: 'lockBoard' });
    }
  };

  onCloseModal = () => {
    this.setState({ modal: '' });
  };

  render() {
    const {
      isShowAuthor,
      onToggleShowAuthor,
      onToggleMarkAsDone,
      toggleLockBoard,
      markAsDoneDisabled,
      isBoardCompleted,
      boardSecurity,
      toggleBoardSecurity,
      isTeam
    } = this.props;

    const {
      isLimitedVotes,
      votes,
      valueBoardName,
      valueUserName,
      modal
    } = this.state;

    return(
      <div
        id="settingsSidebar"
      >
        <div className="side-modal__box">

          <div className="side-modal-input-container">
            <div className="char-remaining">{!!valueBoardName ? valueBoardName.length : '0'} / {process.env.REACT_APP_BOARD_NAME_LIMIT}</div>
            <TextField
              value={valueBoardName}
              label="Board Name"
              className='side-modal__input settings__input settings__input--first'
              onChange={this.handleChangeBoardName}
            />
          </div>

          <div className="side-modal-input-container">
            <div className="char-remaining">{!!valueUserName ? valueUserName.length : '0'} / {process.env.REACT_APP_USERNAME_LIMIT}</div>
            <TextField
              value={valueUserName}
              className='side-modal__input settings__input'
              label="Username"
              onChange={this.handleChangeUserName}
            />
          </div>
        </div>

        <div className="side-modal__box">
          <FormGroup>
            <FormControlLabel
              label="Show author of cards"
              labelPlacement='start'
              className="settings__checkbox"
              control={
                <Switch
                  size="small"
                  checked={isShowAuthor}
                  onChange={onToggleShowAuthor}
                />
              }
            />
          </FormGroup>
        </div>

        <div className="side-modal__box">
          <FormGroup>
            <FormControlLabel
              label="Limit votes per user"
              labelPlacement='start'
              className="settings__checkbox"
              control={
                <Switch
                  size="small"
                  checked={isLimitedVotes}
                  onChange={this.toggleLimited}
                />
              }
            />
          </FormGroup>

          {isLimitedVotes && <div className="limit-votes__line" />}

          {isLimitedVotes && (
            <div
              className="side-modal-limit-container"
            >
              <div className="side-modal__limit">
                <div className="side-modal__limit-label">Limit votes</div>
                <div className="side-modal__limit-value">{votes}</div>
              </div>
              <div
                className="side-modal-limit-dots"
              >
                <AddRemoveCircle
                  onClick={() => this.onChangeLimited(false)}
                  isPlus={false}
                  disableUpVotes={false}
                  disableDownVotes={votes <= 1}
                />
                <AddRemoveCircle
                  onClick={() => this.onChangeLimited(true)}
                  isPlus={true}
                  disableUpVotes={votes >= 20}
                  disableDownVotes={false}
                />
              </div>
            </div>
          )}
        </div>

        <div className="side-modal__box">
          <FormGroup>
            <FormControlLabel
              label='Remove "Mark when you are done" Icon'
              labelPlacement='start'
              className="settings__checkbox"
              control={
                <Switch
                  size="small"
                  checked={markAsDoneDisabled}
                  onChange={onToggleMarkAsDone}
                />
              }
            />
          </FormGroup>
        </div>

        <div className="side-modal__box">
          <FormGroup>
            <FormControlLabel
              label="Lock Board"
              labelPlacement='start'
              className="settings__checkbox"
              control={
                <Switch
                  size="small"
                  checked={isBoardCompleted}
                  onChange={this.handleToggleLockBoard}
                />
              }
            />
          </FormGroup>
        </div>

        {!isTeam &&
          <div className="side-modal__box">
            <FormGroup>
              <FormControlLabel
                label="Make Board private"
                labelPlacement="start"
                className="settings__checkbox"
                control={
                  <Switch
                    size="small"
                    checked={boardSecurity}
                    onChange={toggleBoardSecurity}
                  />
                }
              />
            </FormGroup>
          </div>
        }

        {modal === 'lockBoard' && (
          <InfoModal
            onClose={this.onCloseModal}
            onSubmit={toggleLockBoard}
            description="This will lock the board so no changes can be made."
            title="Lock Format"
          />
        )}

        {modal === 'unlockBoard' && (
          <InfoModal
            onClose={this.onCloseModal}
            onSubmit={toggleLockBoard}
            description="This will unlock the board for changes to be made."
            title="Unlock Format"
          />
        )}
      </div>
    )
  }
}

export default SetingsSideModal;
