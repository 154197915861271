import * as React from "react";
import { Component } from 'react';
import TextField from "@material-ui/core/TextField";

import './TeamCreate.scss'

export interface TeamCreateState {
  value: string;
  error: boolean
}

export interface TeamCreateProps {
  createTeam: (name: string) => void;
}

class TeamCreate extends Component<TeamCreateProps, TeamCreateState> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: 'Team Name',
      error: false
    }
  }

  handleTeamName = (e: any) => {
    this.setState({ value: e.target.value });
  };

  onBlurTeamName = (e: any) => {
    const { value } = this.state;
    this.setState({ error: /^\s/.test(value) || value === '' });
  };

  onFocusTeamName = (e: any) => {
    this.setState({ error: false });
  };

  onCreateTeam = (e: any) => {
    // e.target.disabled = true;
    const { value, error } = this.state;
    if(!error && value) {
      this.props.createTeam(value);
    } else {
      e.target.disabled = false;
    }
  };


  render() {
    const { value, error } = this.state;

    return(
      <div className="team-create">
        <div className="side-modal__box team-create-box">
          <div className="side-modal-input-container">
            <TextField
              label="Team Name"
              className='side-modal__input settings__input'
              value={value}
              onChange={this.handleTeamName}
              onBlur={this.onBlurTeamName}
              onFocus={this.onFocusTeamName}
              error={error}
              helperText={error && 'Please enter team name'}
            />
          </div>
        </div>

        <div className="team-create-submit">
          <button
            className="team-create-submit__button"
            onClick={this.onCreateTeam}
          >
            CREATE TEAM
          </button>
        </div>
      </div>
    )
  }
}

export default TeamCreate;
