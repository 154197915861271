import * as React from 'react';
import './UserManagementIcon.scss';
import ReactTooltip from "react-tooltip";

export interface UserManagementIcon {
  onClick: () => void;
  userCount?: number;
}

const UserManagementIcon = ({onClick, userCount}: UserManagementIcon) => {
  const icon = require('!svg-inline-loader!../../assets/icon-18-user.svg');

  return(
    <>
      <div
        onClick={onClick}
        className="clickable-icon"
        data-tip="Participants"
        data-for="participantTooltip"
      >
        <div
          className="clickable-icon__svg"
          dangerouslySetInnerHTML={{ __html: icon }}
        />
        {userCount && (
          <div className="clickable-icon__count">{userCount}</div>
        )}
      </div>
      <ReactTooltip
        id="participantTooltip"
        place="bottom"
        effect="solid"
        isCapture={false}
      />
    </>
  )
};

export default UserManagementIcon;
