import * as React from 'react';
import { Component } from 'react';
import Statistics from './Statistics';
import { getFirebase } from 'react-redux-firebase';
import { Redirect } from 'react-router';

class StatisticsWrap extends Component<{}, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const firebase = getFirebase();
    const auth = firebase.auth();

    return (
      <>
        {auth.currentUser ? (
          <Statistics />
        ) : (
          <Redirect to="/new" />
        )}
      </>
    );
  }
}

export default StatisticsWrap;
