import * as React from 'react';
import * as classNames from 'classnames';

import './Button.scss';

export interface ButtonProps {
  primary?: boolean;

  [key: string]: any;
}

export const Button: React.SFC<ButtonProps> = ({
  children,
  className,
  primary,
  initRipple,
  unbounded,
  ...other
}) => {
  const classes = classNames('button', className, {
    'button--primary': primary,
    'button--secondary': !primary
  });

  return (
    <button {...other} ref={initRipple} className={classes}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  primary: false
};

export default Button;
