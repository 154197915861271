import * as React from 'react';
import { Component } from 'react';
import Dashboard from './Dashboard';
import { getFirebase } from 'react-redux-firebase';
import { Redirect } from 'react-router';

export interface DashboardWrapState {
  search: string;
  searchInput: string;
  timeOut: any;
}

class DashboardWrap extends Component<{}, DashboardWrapState> {
  constructor(props: any) {
    super(props);
    this.state = {
      search: '',
      searchInput: '',
      timeOut: ''
    };
  }

  onSearch = (value: string) => {
    return new Promise(resolve => {
      this.setState({ searchInput: value });
      clearTimeout(this.state.timeOut);
      if (value) {
        const t = setTimeout(() => {
          this.setState({ search: this.state.searchInput });
          resolve('success');
        }, 2000);
        this.setState({ timeOut: t });
      } else {
        this.setState({ search: value });
        resolve('success');
      }
    });
  };

  render() {
    const firebase = getFirebase();
    const auth = firebase.auth();

    return (
      <>
        {auth.currentUser ? (
          <Dashboard
            search={this.state.search}
            searchInput={this.state.searchInput}
            onSearch={this.onSearch}
          />
        ) : (
          <Redirect to="/new" />
        )}
      </>
    );
  }
}

export default DashboardWrap;
