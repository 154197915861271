import * as React from 'react';
import { Component } from 'react';
import * as cx from 'classnames';

import { UserTeams } from '../../types';
import './TeamNamesList.scss'

export interface TeamNamesListProps {
  userTeams: UserTeams | undefined
  selectTeam: (teamId: string) => void;
  selectedTeam: string | undefined
}

export interface TeamNamesListState {
  isOpen: boolean
}

class TeamNamesList extends Component<TeamNamesListProps, TeamNamesListState> {
  constructor(props: TeamNamesListProps) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  changeTeam = (teamId: string) => {
    this.setState({ isOpen: false }, () => {
      this.props.selectTeam(teamId);
    })
  };

  render() {
    const { selectedTeam, userTeams } = this.props;
    const { isOpen } = this.state;
    const arrow = require('!svg-inline-loader!../../assets/icon-6-12-arrow.svg');

    return(
      <div
        className='team-names'
      >
        <div
          onClick={this.toggleOpen}
          className={cx('team-names-selected', {'team-names-selected--open': isOpen})}
        >
          <div
            className='team-names__team'
          >
            {selectedTeam ? selectedTeam : 'Select team'}
          </div>
          <div
            className={cx('team-name__arrow', {'team-name__arrow--up': isOpen})}
            dangerouslySetInnerHTML={{__html: arrow}}
          />
        </div>
        <ul
          className={cx('team-name-list', {'team-name-list--opened': isOpen})}
        >
          {!!userTeams && Object.keys(userTeams).map((team, index) =>
            <li
              key={index}
              className='team-name-list__item'
              onClick={() => this.changeTeam(team)}
            >
              {userTeams[team].name}
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default TeamNamesList;
