import leanCoffeeModeConfiguration from './LeanCoffeeMode';
import madSadGladConfiguration from './MadSadGladMode';
import kalmConfiguration from './KALM';
import simpleRetroModeConfiguration from './PositiveNegativeMode';
import startStopContinueRetroModeConfiguration from './StartStopContinueMode';
import wellImproveActionRetroModeConfiguration from './wellImproveAction';
import plusDeltaConfiguration from './PlusDeltaMode';
import fourLconfiguration from './4L';
import { PhaseConfiguration } from '../Retrospective';

interface RetroModeConfiguration {
  lean: PhaseConfiguration[];
  positiveNegative: PhaseConfiguration[];
  startStopContinue: PhaseConfiguration[];
  wellImproveAction: PhaseConfiguration[];
  madSadGlad: PhaseConfiguration[];
  kalm: PhaseConfiguration[];
  plusDelta: PhaseConfiguration[];
  fourL: PhaseConfiguration[];
  custom: PhaseConfiguration[]
}

export const retroModes: RetroModeConfiguration = {
  lean: leanCoffeeModeConfiguration,
  madSadGlad: madSadGladConfiguration,
  positiveNegative: simpleRetroModeConfiguration,
  startStopContinue: startStopContinueRetroModeConfiguration,
  wellImproveAction: wellImproveActionRetroModeConfiguration,
  kalm: kalmConfiguration,
  plusDelta: plusDeltaConfiguration,
  fourL: fourLconfiguration,
  custom: []
};

export type RetroMode = keyof RetroModeConfiguration;

export default retroModes;
