import * as React from 'react';
import { Component } from 'react';
import Portal from "../../Portal";
import { Link } from "react-router-dom";
import './PlanExceededModal.scss';
import {getFirebase} from "react-redux-firebase";
import {onPayment} from "../../../analytics";

export interface PlanExceededModalProps {
  uid: string | null | undefined;
  onClose: () => void;
  message: string;
  type: string;
  openLoading: () => Promise<void>
  closeLoading: () => Promise<void> | void
}

class PlanExceededModal extends Component<PlanExceededModalProps, {}> {
  constructor(props: PlanExceededModalProps) {
    super(props);
  }

  paymentScreen = (e: any) => {
    const { openLoading, closeLoading } = this.props;
    e.preventDefault();
    openLoading()
      .then(() => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`, {
          method: 'POST',
          body: JSON.stringify({
            uid: this.props.uid
          })
        })
          .then((response) => response.json())
          .then((res) => {
            const body = JSON.parse(res.body);
            if(body.session && body.session.url) {
              onPayment();
              window.location.replace(body.session.url);
            } else {
              closeLoading();
            }
          })
          .catch(() => {
            closeLoading();
          })
      })
  };

  componentDidUpdate() {
    const { type, uid } = this.props;
    if(!!uid && (type === 'boards' || type === 'time' || type === 'teams')) {
      const portalForm = document.getElementById('customerPortal');
      if(portalForm) {
        portalForm.addEventListener('submit', (e) => {
          e.preventDefault();
        })
      }
    }
  }

  render() {
    const { message, type } = this.props;
    const user = getFirebase().auth().currentUser;
    return(
      <Portal
        onClose={this.props.onClose}
        showCloseButton={false}
      >
        <div className="modal__content-wrapper">
          <div className="modal__content plan-exceed-modal">
            <div
              className="modal__close-button"
              onClick={this.props.onClose}
            />
            <h2 className="plan-exceed-modal__title">Plan Limit Exceeded</h2>
            <div className="plan-exceed-modal__description">{message}</div>

            {(type === 'boards' || type ==='time' || type === 'teams') ?
              <>
                {user && user.isAnonymous && (
                  <Link
                    to='/register?upgradePlan=true'
                    className="plan-exceed-modal__button"
                  >
                    UPGRADE
                  </Link>
                )}
                {user && !user.isAnonymous && (
                  <form
                    method="POST"
                    action={`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`}
                    id="customerPortal"
                  >
                    <button
                      className="plan-exceed-modal__button"
                      type='submit'
                      onClick={this.paymentScreen}
                    >
                      UPGRADE
                    </button>
                  </form>
                )}
              </>
              :
              <Link
                to='/new'
                className="plan-exceed-modal__button"
              >
                GO BACK
              </Link>
            }
          </div>
        </div>
      </Portal>
    )
  }
}

export default PlanExceededModal;
