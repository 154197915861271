import {getVal, isLoaded} from "react-redux-firebase";
import {Board, BoardCards, BoardGroups, CardGroup, StoreState} from "../../../../types";
import {ExportSideModalOwnProps, ExportSideModalStateProps} from "./ExportSideModal";
import {ColumnConfiguration, getPhaseConfiguration} from "../../../../constants/Retrospective";

export const mapStateToProps = (
  state: StoreState,
  ownProps: ExportSideModalOwnProps
): ExportSideModalStateProps => {
  const { boardId } = ownProps;
  const boardConfig: Board = getVal(state.fbState, `data/boards/${boardId}`, undefined);
  const cards: BoardCards | undefined = getVal(state.fbState, `data/cards`, undefined);
  const cardGroups: BoardGroups | undefined = getVal(state.fbState, `data/cardGroups`, undefined);
  const printingCards: BoardCards = {...cards};
  let columns: ColumnConfiguration[];
  if(isLoaded(cardGroups) && isLoaded(cards)) {
    if(cardGroups) {
      Object.keys(cardGroups).map((groupId: string) => {
        const cardGroup: CardGroup = cardGroups[groupId];
        if(!!cardGroup && cardGroup.cards) {
          Object.keys(cardGroup.cards).map((cardId: string) => {
            if(printingCards.hasOwnProperty(cardId)) {
              delete printingCards[cardId];
            }
          })
        }
      })
    }
  }
  if(boardConfig.mode === 'custom' && boardConfig.customConfig) {
    columns = boardConfig.customConfig[2].columns;
  } else {
    columns = getPhaseConfiguration(boardConfig.mode, 2).columns
  }

  return {
    cards,
    columns,
    cardGroups,
    printingCards,
    retroName: boardConfig.name,
    createdAt: boardConfig.createdAt
  }
};
