import { AnyAction, Dispatch } from 'redux';
import { isLoaded, getVal } from 'react-redux-firebase';
import * as Raven from 'raven-js';
import { debounce } from 'lodash';
import { User } from '@firebase/auth-types';
import { FirebaseFirestore } from '@firebase/firestore-types';

import { SETUP_COMPLETED } from '../../actions';
import { BoardProps, UserPresence } from './Board';
import {
  FirebaseProp,
  StoreState,
  Card,
  Optional,
  Board, BoardUsers
} from '../../types';

import { ParticipantsUser } from "../../components/SideModal/variant/ParticipantsSideModal";

export const mapStateToProps = (
  state: StoreState,
  ownProps: any
): BoardProps => {
  const { app, fbState } = state;
  const firebase = ownProps.firebase as FirebaseProp;
  const database: FirebaseFirestore = firebase.firestore();

  const boardSelector: string = `boards/${ownProps.match.params.id}`;
  const auth: User | any = firebase.auth().currentUser || {};

  const board: Board = ownProps.board;

  const cards = getVal(fbState, `data/cards`);
  const userReadyState: boolean | undefined = getVal(fbState, `data/${boardSelector}/users/${auth.uid}/ready`, undefined);
  const setupCompleted = getVal(app, `setupCompleted`, false);

  let pendingUsers: ParticipantsUser[] = [];

  if(board && board.applicants) {
    Object.keys(board.applicants)
      .map((userId: string) => {
        if(!board.applicants![userId].isAuthorized && !board.applicants![userId].isAuthorized) {
          pendingUsers.push({
            id: userId,
            name: board.applicants![userId].name,
            photoUrl: board.applicants![userId].photoUrl
          });
        }
    })
  }
  const presence: UserPresence = {};
  if(board && isLoaded(board) && !!board.users) {
    Object.keys(board.users).map((userId: string) => {
      if(board.users[userId].presence) {
        presence[userId] = true;
      }
    })
  }

  if (!isLoaded(board) || !board) {
    return { boardSelector, auth } as any;
  }

  const users = isLoaded(board) ? board.users : {};
  const isBoardAdmin = isLoaded(board)
    ? auth.uid === board.creatorUid
    : false;

  let userVotes: number | undefined = undefined;

  if(auth && board.users[auth.uid]) {
    userVotes = board.users[auth.uid].votes;
  }

  let focusedCard: Optional<Card> = undefined;
  if (board.focusedCardId) {
    focusedCard = cards[board.focusedCardId];
  }

  const timerExpiration: string | null = board.timerExpiration;

  let waitingUsers: {
    uid: string;
    name: string;
    image: string;
    time: Date;
  }[] = [];

  if(isLoaded(board) && board) {
    const { applicants } = board;

    if (applicants) {
      const waitingUserUids = Object.keys(applicants).filter((userId: string) => !applicants[userId].isAuthorized && applicants[userId].isAuthorized !== false);
      waitingUsers = waitingUserUids
        .map(uid => ({
          uid,
          ...applicants[uid],
          time: new Date(applicants[uid].time)
        }))
        .sort((a, b) => {
          if (a.time > b.time) {
            return 1;
          } else if (a.time < b.time) {
            return -1;
          }
          return 0;
        });
    }
  }

  function acceptUser(uid: string, accept: boolean) {
    let userInfo = {};
    if(board.applicants && board.applicants[uid]) {
       userInfo = {
        ...board.applicants![uid],
        isAuthorized: accept
      };
    } else if(board.users && board.users[uid]) {
      userInfo = {
        name: board.users[uid].name || '',
        email: board.users[uid].email || '',
        photoUrl: board.users[uid].photoUrl || '',
        isAuthorized: accept
      }
    }
    const userUpdate = {};
    userUpdate[`applicants.${uid}`] = userInfo;
    database
      .collection('boards')
      .doc(ownProps.match.params.id)
      .update(userUpdate)
      .then(() => {
        if(!accept) {
          let newUsers = {};
          Object.keys(board.users).map((usersId: string) => {
            if(uid !== usersId) {
              newUsers[usersId] = board.users[usersId];
            }
          });
          database
            .collection('boards')
            .doc(ownProps.match.params.id)
            .update({ users: newUsers })
            .then(() => {
              fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/deleteUserBoard`, {
                method: 'POST',
                body: JSON.stringify({ boardId: ownProps.match.params.id, userId: uid, adminUid: auth.uid })
              })
                .then((response) => response.json())
                .catch((e) => {
                  console.log(e);
                })
            })
        } else {
          const newUsers: BoardUsers = {
            ...board.users,
            [uid]: {
              name: board.applicants![uid].name || '',
              email: board.applicants![uid].email || '',
              photoUrl: board.applicants![uid].photoUrl || '',
              presence: false,
              ready: false,
              votes: 0
            }
          };

          database
            .collection('boards')
            .doc(ownProps.match.params.id)
            .update({ users: newUsers })
            .then(() => {
              fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/addUserBoard`, {
                method: 'POST',
                body: JSON.stringify({ boardId: ownProps.match.params.id, userId: uid, adminUid: auth.uid })
              })
                .then((response) => response.json())
                .catch((e) => {
                  console.log(e);
                })
            })
            .catch((e) => {
              console.log(e);
            })
        }
      })
      .catch((err: any) => {
        Raven.captureMessage('unable to accept user access', {
          extra: {
            reason: err.message,
            uid: auth.uid,
            boardId: boardSelector,
            user: uid,
            accept
          }
        });
      });
  }

  function onChangeUserAcception(uid: string, accept: boolean) {
    if(auth && auth.uid !== uid) {
      acceptUser(uid, accept);
    }
  }

  function onToggleShowAuthor() {
    database
      .collection('boards')
      .doc(ownProps.match.params.id)
      .update({
        showAuthor: !board.showAuthor
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not toggle show author state', {
          extra: { reason: err.message, uid: auth.uid, boardId: boardSelector }
        });
      });
  }

  function onToggleReadyState() {
    if(auth && auth.uid && board.users[auth.uid]) {
      const userData = {
        ...board.users[auth.uid],
        ready: !board.users[auth.uid].ready
      };
      const userUpdate = {};
      userUpdate[`users.${auth.uid}`] = userData;
      database
        .collection('boards')
        .doc(ownProps.match.params.id)
        .update(userUpdate)
        .catch((err: Error) => {
          Raven.captureMessage('Could not toggle user state', {
            extra: { reason: err.message, uid: auth.uid, boardId: boardSelector }
          });
        });
    }
  }

  const username = auth.displayName || undefined;
  const email = auth.email || undefined;
  const isAnonymous = auth.isAnonymous;

  const onChangeBoardName = debounce((boardName: string) => {
    const { focusedCardId } = board;
    database
      .collection('boards')
      .doc(ownProps.match.params.id)
      .update({ name: boardName })
      .catch((err: any) => {
        Raven.captureMessage('Could not set boardname', {
          extra: {
            reason: err.message,
            uid: auth.uid,
            boardId: boardSelector,
            focusedCardId
          }
        });
      });
  }, 2000);

  const onDeleteTimer = () => {
    database
      .collection('boards')
      .doc(ownProps.match.params.id)
      .update({ timerExpiration: null })
      .catch((err: any) => {
        Raven.captureMessage('Unable to delete timer', {
          extra: {
            reason: err.message,
            uid: auth.uid,
            boardId: boardSelector
          }
        });
      });
  };

  const onLimitVotes = debounce((limit: number) => {
    if (limit > 0) {
      database
        .collection('boards')
        .doc(ownProps.match.params.id)
        .update({ limitVotes: limit })
        .catch((err: Error) => {
          console.log(err);
        });
    } else {
      database
        .collection('boards')
        .doc(ownProps.match.params.id)
        .update({ limitVotes: null })
        .catch((err: Error) => {
          console.log(err);
        });
    }
  }, 2000);

  const onToggleMarkAsDone = () => {
    database
      .collection('boards')
      .doc(ownProps.match.params.id)
      .update({ markAsDoneDisabled: !board.markAsDoneDisabled })
      .catch((err: Error) => {
        console.log(err);
      })
  };

  const toggleLockBoard = () => {
    database
      .collection('boards')
      .doc(ownProps.match.params.id)
      .update({ completed: !board.completed })
      .catch((err: Error) => {
        console.log(err);
      })
  };

  const changeUserName = debounce((username: string) => {
    if(auth) {
      auth
        .updateProfile({
          displayName: username
        })
        .then(() => {
          database
            .collection('users')
            .doc(auth.uid)
            .update({ name: username })
            .catch((error: Error) => {
              console.log(error);
            });
        })
        .catch((error: Error) => {
          console.log(error);
        })
    }
  }, 2000);

  function onSignOut() {

    const uid = auth.uid;
    const boardId = ownProps.match.params.id;
    database
      .collection('boards')
      .doc(boardId)
      .update({[`users.${uid}.presence`]: false})
      .then(() => {
        firebase
          .auth()
          .signOut()
          .catch((e: any) => {
            console.log(e);
          })
      })
      .catch((e) => {
        console.log(e)
      });
  }

  function toggleBoardSecurity(): void {
    const boardId: string = ownProps.match.params.id;
    database
      .collection('boards')
      .doc(boardId)
      .update({ secure: !board.secure })
      .catch((e) => {
        console.log(e);
      })
  }

  return {
    setupCompleted,
    cards,
    boardSelector,
    users,
    focusedCard,
    isBoardAdmin,
    userVotes,
    username,
    email,
    timerExpiration,
    isAnonymous,
    userReadyState,
    presence,
    uid: auth.uid,
    onLimitVotes,
    onToggleReadyState,
    onSignOut,
    onChangeBoardName,
    onDeleteTimer,
    onToggleShowAuthor,
    onRegisterCurrentUser: () => null, // will be filled in mergeProps
    waitingUsers,
    acceptUser,
    onToggleMarkAsDone,
    toggleLockBoard,
    pendingUsers,
    onChangeUserAcception,
    changeUserName,
    toggleBoardSecurity,
    ...ownProps,
    // other props, only used in mergeProps
    auth
  };
};

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    dispatch
  };
}

export function mergeProps(
  stateProps: BoardProps,
  { dispatch }: { dispatch: Dispatch<any> },
  ownProps: BoardProps
): BoardProps {
  function onRegisterCurrentUser() {
    dispatch({ type: SETUP_COMPLETED });
  }

  return {
    ...ownProps,
    ...stateProps,
    onRegisterCurrentUser
  };
}
