import { getVal } from 'react-redux-firebase';

import { Board, BoardCards, StoreState } from '../../types';
import { OwnColumnViewProps, StateColumnViewProps } from './ColumnView';
import { getPhaseConfiguration, IndexedPhaseConfiguration } from '../../constants/Retrospective';

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnColumnViewProps
): StateColumnViewProps => {
  const board: Board = ownProps.board;

  let filteredCardType: string | undefined = undefined;
  const focusedCardId = board.focusedCardId;
  if (focusedCardId) {
    const boardCards: BoardCards = getVal(state.fbState, `data/cards`, {});
    const focusedCard = boardCards[focusedCardId];
    filteredCardType = focusedCard.type;
  }
  let phase: IndexedPhaseConfiguration;

  if(board.mode === 'custom' && board.customConfig) {
    phase = {
      index: board.guidedPhase,
      ...board.customConfig[board.guidedPhase]
    };
  } else {
    phase = getPhaseConfiguration(board.mode, board.guidedPhase);
  }


  return {
    phase: phase,
    filteredCardType
  };
};
