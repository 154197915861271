import * as React from 'react';
import {
  HashRouter,
  Route,
  Switch
} from 'react-router-dom';

// TODO: Types are not working with most recent version of Typescript.
// TODO: Use ES6 import if typings have been adjusted.
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BoardGuard from './routes/BoardGuard';
import NewBoard from './routes/NewBoard';
import LoginBoard from './routes/LoginBoard';
import PrintViewBoard from './routes/PrintViewBoard';
import Board from './routes/Board';
import BoardActionItems from './routes/BoardActionItems';
import Dashboard from './routes/Dashboard';
import Statistics from './routes/Statistics';
import { Component } from 'react';
import Account from './routes/Account/Account';
import Register from './routes/Register';
import RouteWrapper from './components/RouteWrapper';
import AdminRouteWrapper from './components/AdminRouteWrapper';
import Landing from "./routes/Landing";
import { Helmet } from 'react-helmet';

const CloseButton = (props: any) => (
  <div className="close-button-wrapper">
    <div className="close-button" onClick={props.closeToast} />
  </div>
);

class Router extends Component<{}, {}> {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }}>
          <Helmet>
            <script
              id="helpspace-widget-script"
              data-auto-init
              data-token="3jR6XgbQXFtjzBpaJJlIb4Rlq6lpEeL0vIoa70C4"
              data-client-id="82d1a52ed2c2460c951fd71cdc818c58"
              data-widget-id="8587de6a-5330-436c-8dde-bfb12332f948"
              src="https://cdn.helpspace.com/widget/widget-v1.js" 
              async
            />
          </Helmet>
          <HashRouter>
            <Switch>
              <Route
                exact
                path="/"
                render={routeProps =>
                  <RouteWrapper {...routeProps} isFreePage={true}>
                    <Landing />
                  </RouteWrapper>
                }
              />
              <Route
                exact
                path="/register"
                render={(routeProps) => (
                  <Register {...routeProps} />
                )}
              />
              <Route
                path="/dashboard"
                render={routeProps => (
                  <RouteWrapper {...routeProps} isFreePage={false}>
                    <Dashboard />
                  </RouteWrapper>
                )}
              />
              <Route
                path="/new"
                render={routeProps => (
                  <RouteWrapper {...routeProps} isFreePage={false}>
                    <NewBoard {...routeProps} />
                  </RouteWrapper>
                )}
              />
              <Route
                path="/statistics"
                render={routeProps => (
                  <AdminRouteWrapper {...routeProps} isFreePage={false}>
                    <Statistics {...routeProps} />
                  </AdminRouteWrapper>
                )}
              />
              <Route
                path="/board/:id/action-items"
                render={routeProps => (
                  <BoardActionItems {...routeProps} />
                )}
              />
              <Route
                path="/board/:id"
                render={routeProps => (
                  <BoardGuard {...routeProps} component={Board} />
                )}
              />
              <Route path="/join/:id" component={LoginBoard as any} />
              <Route
                path="/print/:id"
                render={routeProps => (
                  <RouteWrapper {...routeProps} isFreePage={false}>
                    <BoardGuard
                      {...routeProps}
                      component={PrintViewBoard as any}
                    />
                  </RouteWrapper>
                )}
              />
              <Route
                path="/account"
                render={routeProps => (
                  <RouteWrapper {...routeProps} isFreePage={false}>
                    <Account
                      {...routeProps}
                    />
                  </RouteWrapper>
                )}
              />
            </Switch>
          </HashRouter>
          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            autoClose={5000}
            toastClassName="dark-toast"
            progressClassName="dark-toast-progress"
            closeButton={<CloseButton />}
          />
        </div>
      </div>
    );
  }
}

export default Router;
