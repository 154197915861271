import * as React from 'react';
import Stack from '../Stack';

import './ColumnOverview.scss';
import * as ReactDOM from 'react-dom';
import ColumnName from '../Column/ColumnName';
import Portal from '../Portal';
import {CardGroup} from "../../types";

export interface ColumnOverviewProps {
  boardUrl: string;
  boardId: string;
  column: string;
  sorted: boolean;
  cardsCount: number;
  isBoardCompleted: boolean;

  cards: any;
  groups: CardGroup[];
  isVotingEnabled: boolean;
  isVoteSummaryShown: boolean;
  toggleOverview: () => void;
  columnObj: any;
  phase: any;
  isDiscussPhase: any;
  actionsColumn: any;
}

export const ColumnOverview: React.FunctionComponent<ColumnOverviewProps> = ({
  boardUrl,
  boardId,
  column,
  cardsCount,
  cards,
  isVotingEnabled,
  isVoteSummaryShown,
  toggleOverview,
  isBoardCompleted,
  groups,
  sorted,
  columnObj,
  phase,
  isDiscussPhase,
  actionsColumn
}) => {
  // mount backdrop into separate located DOM node 'portal'
  const portal: HTMLElement = document.getElementById('portal')!;
  if (!portal) {
    throw new Error('portal element does not exist');
  }

  console.log('actionsColumn', actionsColumn)

  return ReactDOM.createPortal(
    <Portal
      className="column-overview"
      fullWidth={true}
      verticallyAlignContent="start"
      onClose={() => toggleOverview()}
    >
      <div className="column-overview__header">
        <ColumnName
          title={column}
          count={cardsCount}
        />
      </div>
      <div className="column-overview__stack">
        <Stack
          boardUrl={boardUrl}
          boardId={boardId}
          cards={cards}
          groups={groups}
          isVotingAllowed={isVotingEnabled}
          isVoteSummaryShown={isVoteSummaryShown}
          isBoardCompleted={isBoardCompleted}
          sorted={sorted}
          columnObj={columnObj}
          phase={phase}
          isDiscussPhase={isDiscussPhase}
          actionsColumn={actionsColumn}
        />
      </div>
    </Portal>,
    portal
  );
};

export default ColumnOverview;
