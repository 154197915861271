import * as React from 'react';
import { Component } from 'react';
import { Link } from "react-router-dom";
import * as cx from 'classnames';
import './Banner.scss';
import moment = require("moment");

export interface BannerState {
  isShown: boolean
}

export interface BannerProps {
  createdAt: string | undefined
}

class Banner extends Component<BannerProps,BannerState> {
  constructor(props: BannerProps) {
    super(props);
    this.state = {
      isShown: true
    }
  }

  onClose = () => {
    this.setState({ isShown: false });
  };

  render() {
    const { isShown } = this.state;
    const { createdAt } = this.props;

    const now = moment(new Date().getTime());
    const created = moment(createdAt ? new Date(createdAt).getTime() : now);

    return(
      <div className={cx('banner', {'banner-shown': isShown})}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="banner-container">
                <div className="banner-left">Your data will be lost in {60 - now.diff(created, 'days')} days. Connect your email or social network account to keep your data!</div>
                <div className="banner-right">
                  <Link
                    to='/register'
                    className="banner__link"
                  >
                    SIGN UP
                  </Link>
                  <div
                    className="banner-close"
                    onClick={this.onClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Banner;
