import { getFirebase } from "react-redux-firebase";
import { FirebaseFirestore } from '@firebase/firestore-types';
import { DocumentSnapshot } from '@firebase/firestore-types';
import { UserCredential } from '@firebase/auth-types';

import { StoreState } from "../../types";
import { RegisterStateProps } from "./Register";
import {analytics} from "../../index";

export const mapStateToProps = (
  state: StoreState,
  ownProps: any
): RegisterStateProps => {

  const firebase = getFirebase();
  let uid: string = '';
  let isAnonymous: boolean = false;
  let hasBoards: boolean = false;
  if(firebase.auth().currentUser) {
    uid = firebase.auth().currentUser!.uid;
    isAnonymous = firebase.auth().currentUser!.isAnonymous;
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get()
      .then((doc: DocumentSnapshot) => {
        if(doc.exists && doc.data()) {
          hasBoards = true;
        }
      })
  }

  function subscribeToFreePlan(uid: string, name: string, email: string = '') {
    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/subscribeToFree`, {
      method: 'POST',
      body: JSON.stringify({
        uid: uid,
        name: name,
        email: email
      })
    })
      .then((data) => data.json())
      .then((res) => {
        if(res) {
          const success = JSON.parse(res.body);
          if(success) {
            analytics.logEvent('user_created');
            const search = ownProps.location.search;
            if(search) {
              if(search.slice(0, 6) === '?team=') {
                location.href = `#/account${search}`;
              }
            } else {
              ownProps.history.push('/new');
            }
          }
        }
      }).catch((e) => {
        console.log(e);
    });
  }

  function addUserToDb(authResult: UserCredential, username: string = '') {
    let isNewUser: boolean | undefined = undefined;
    if(authResult && authResult.additionalUserInfo) {
      isNewUser = authResult.additionalUserInfo.isNewUser;
    }

    const firebase = getFirebase();
    const auth = firebase.auth();
    let name = auth.currentUser && auth.currentUser.displayName ? auth.currentUser!.displayName : username;
    let email = auth.currentUser && auth.currentUser.email ? auth.currentUser!.email : '';
    const photoUrl = auth.currentUser && auth.currentUser.photoURL ? auth.currentUser.photoURL : '';
    let uid: string = auth.currentUser ? auth.currentUser.uid : '';

    if (!!email) {
      fetch(`https://cloudapi.mailercloud.com/v1/contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Kabfd-ec8f31d7407f1bef0deee2569229a6f7-79a25a2dff49f5f753d7596f4ad19473'
        },
        body: JSON.stringify({
          email: email,
          name: name,
          list_id: "vWFDc"
        })
      }).then(res => {
        console.log('res', res)
      })
    }
    
    if(isNewUser || (!authResult && auth.currentUser && auth.currentUser.isAnonymous)) {
      const database: FirebaseFirestore = firebase.firestore();
      const userRef = database.collection('users').doc(uid);
      userRef.set({
        uid: uid,
        name: name,
        email: email,
        photoUrl: photoUrl
      })
        .then(() => {
          if(auth.currentUser) {
            analytics.logEvent('user_created');
            if(email) {
              auth.currentUser
                .updateEmail(email)
                .then(() => {
                  fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendMail`, {
                    method: 'POST',
                    body: JSON.stringify({
                      email: email,
                      type: 'welcome',
                      appLink: window.location.origin
                    })
                  }).then(res => res.json())
                    .catch((e) => {
                      console.log(e);
                    })
                })
                .catch((e) => {
                  console.log(e);
                })
            }

            auth.currentUser
              .updateProfile({
                displayName: name
              })
              .then(() => {
                if(auth.currentUser && auth.currentUser.isAnonymous) {
                  subscribeToFreePlan(uid, name);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        })
    }
  }


  function upgradeAnonymousUser() {

    const database: FirebaseFirestore = firebase.firestore();
    const auth = firebase.auth();
    if(auth.currentUser) {
      const uid: string = auth.currentUser.uid ? auth.currentUser.uid : '';
      const name: string = auth.currentUser.displayName || '';
      const photoUrl: string = auth.currentUser.photoURL || '';
      const email = auth.currentUser.email ? auth.currentUser.email : '';

      if(uid) {
        database
          .collection(`users`)
          .doc(`${uid}`)
          .update({
            name: name,
            email: email,
            photoUrl: photoUrl
          })
          .then(() => {
            if(auth.currentUser) {
              auth.currentUser
                .updateProfile({
                  displayName: name
                }).then(() => {
                if(auth.currentUser) {
                  auth.currentUser
                    .updateEmail(email)
                    .then(() => {
                      fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendMail`, {
                        method: 'POST',
                        body: JSON.stringify({
                          email: email,
                          type: 'welcome',
                          appLink: window.location.origin
                        })
                      }).then(res => res.json())
                        .catch((e) => {
                          console.log(e);
                        })
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                }
              }).catch((e) => {
                console.log(e);
              })
            }
            let upgradePlan: boolean = ownProps.location.search === '?upgradePlan=true';
            location.href=`${process.env.REACT_APP_HOST}/#/new${upgradePlan ? '?upgradePlan=true' : ''}`
          })
          .catch((error: any) => {
            console.log(error);
          })
      }
    }
  }

  return {
    addUserToDb,
    upgradeAnonymousUser,
    subscribeToFreePlan,
    uid,
    isAnonymous,
    hasBoards: hasBoards
  }
};
