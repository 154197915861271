import * as React from 'react';
import { Component } from 'react';
import * as cx from 'classnames';

import './Boards.scss';
import CardBoard from '../CardBoard';
import { UserBoard } from '../../types';

export interface BoardsOwnProps {
  boards: UserBoard[];
  isFilterByTime: boolean;
  archiveBoard: (boardId: string | undefined, isOwnBoard: boolean) => void;
  unarchiveBoard: (boardId: string | undefined, isOwnBoard: boolean) => void;
  hidePagination?: boolean;
  openLoading: () => Promise<void>;
  closeLoading: () => Promise<void>;
}

export interface BoardsOwnState {
  currentPage: number;
  boardsPerPage: number;
}

class Boards extends Component<BoardsOwnProps, BoardsOwnState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPage: 1,
      boardsPerPage: 12
    };
  }

  componentDidMount() {

    this.onResize();

    window.addEventListener('resize', (e) => {
      this.onResize();
    })
  }

  onResize() {
    const { boardsPerPage } = this.state;
    if(window.innerWidth >= 768 && boardsPerPage !== 12) {
      this.setState({ boardsPerPage: 12 });
    } else if(768 > window.innerWidth && window.innerWidth > 575 && boardsPerPage !== 8) {
      this.setState({ boardsPerPage: 8});
    } else if(575 >= window.innerWidth && boardsPerPage !== 4) {
      this.setState({ boardsPerPage: 4 });
    }
  }

  changePage = (page: number) => {
    const { boards } = this.props;
    const { boardsPerPage } = this.state;
    const totalPages = boards.length ? Math.ceil(boards.length / boardsPerPage) : 0;
    if(page > 0 && page <= totalPages) {
      this.setState({ currentPage: page });
    }
  };

  render() {
    const {
      boards,
      isFilterByTime,
      archiveBoard,
      unarchiveBoard,
      hidePagination,
      openLoading,
      closeLoading
    } = this.props;

    const {
      currentPage,
      boardsPerPage,
    } = this.state;

    if (isFilterByTime) {
      boards.sort((a: UserBoard, b: UserBoard) => {
        return b.lastUpdatedAt - a.lastUpdatedAt;
      });
    } else {
      boards.sort((a: UserBoard, b: UserBoard) => {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      });
    }

    let start = (currentPage - 1) * boardsPerPage;
    let end: number;
    if(hidePagination) {
      start = 0;
      end = boards.length;
    } else {
      if(start + boardsPerPage >= boards.length) {
        end = boards.length;
      } else {
        end = start + boardsPerPage;
      }
    }

    const totalPages = boards.length ? Math.ceil(boards.length / boardsPerPage) : 0;

    let pagination: number[] = [];

    if(totalPages) {
      for(let i=0; i < totalPages; i++) {
        pagination.push(i+1);
      }
    }

    return (
      <div className="container dashboard__boards">
        <div className="row">
          {boards.slice(start, end).map((item: UserBoard) =>
            <div key={item.id} className="col-sm-6 col-md-4">
              <CardBoard
                board={item}
                archiveBoard={archiveBoard}
                unarchiveBoard={unarchiveBoard}
                openLoading={openLoading}
                closeLoading={closeLoading}
              />
            </div>
          )}
          {!hidePagination && totalPages !== 0 &&
            <div className="dashboard-pagination-wrapper">
              <div className="dashboard-pagination">
                <div
                  className={cx('dashboard-pagination__item', 'dashboard-pagination__item--prev', { 'dashboard-pagination__item--disabled': currentPage === 1 })}
                  onClick={() => this.changePage(currentPage - 1 )}
                />
                {pagination.map((page: number) =>
                  <div
                    key={page}
                    className={cx("dashboard-pagination__item", {"dashboard-pagination__item--active": page === currentPage})}
                    onClick={() => this.changePage(page)}
                  >
                    {page}
                  </div>
                )}
                <div
                  className={cx(
                    'dashboard-pagination__item',
                    'dashboard-pagination__item--next',
                    { 'dashboard-pagination__item--disabled': currentPage === totalPages },
                    )}
                  onClick={() => this.changePage(currentPage  + 1 )}
                />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Boards;
