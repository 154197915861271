import * as React from "react";
import { Component } from 'react';

import './Contact.scss';

export interface ContactState {
    email: string,
    message: string
}

class Contact extends Component<{}, ContactState> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: '',
            message: ''
        }
    }

    handleInput = (e: any) => {
        if(e.target.name === 'email') {
            this.setState({ email: e.target.value });
        } else if (e.target.name === 'message') {
            this.setState({ message: e.target.value });
        }
    };

    submitMessage = (e: any) => {
        e.preventDefault();
    //    @todo send email
    };

    render() {
        return(
            <div id="contact" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="contact__title">GET IN TOUCH</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 m-auto">
                            <p className="contact__description">Sed posuere consectetur est at lobortis. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 m-auto contact__form">
                            <input
                                type="email"
                                name="email"
                                className="contact__email-input"
                                placeholder="Your email"
                                onChange={this.handleInput}
                            />
                            <textarea
                                name="message"
                                cols={30}
                                rows={10}
                                className="contact__message-input"
                                placeholder="Message"
                                onChange={this.handleInput}
                            />
                            <button
                                className="contact__send"
                                onClick={this.submitMessage}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;