import * as cx from 'classnames';
import * as React from 'react';

import Component from '../Component';
import Stack from '../../Stack';
import {
  // Column,
  ColumnConfiguration,
  // getTheme
} from '../../../constants/Retrospective';
import {Card as CardModel, CardGroup} from '../../../types';

import './StackComponent.scss';

export interface StackComponentProps {
  boardUrl: string;
  boardId: string;
  cards: CardModel[];
  groups: CardGroup[];
  column: ColumnConfiguration;
  isVotingAllowed: boolean;
  isVoteSummaryShown: boolean;
  className?: string;
  isActive: boolean;
  isBoardCompleted: boolean;
  isDiscussPhase: any;
  actionsColumn: any;
}

class StackComponent extends React.Component<StackComponentProps, {}> {
  render() {
    const {
      className,
      boardUrl,
      boardId,
      column,
      cards,
      isVotingAllowed,
      isVoteSummaryShown,
      groups
    } = this.props;

    // const theme = getTheme(column.type);

    return (
      <Component
        className={cx(
          'stack-component',
          {'stack-component--theme-actions': column.type === 'actions' || column.name === 'Action'},
          className
        )}
      >
        <Stack
          boardUrl={boardUrl}
          boardId={boardId}
          cards={cards}
          groups={groups}
          isVotingAllowed={isVotingAllowed}
          isVoteSummaryShown={isVoteSummaryShown}
          className="stack-component__stack"
          isBoardCompleted={this.props.isBoardCompleted}
          sorted={this.props.column.sorted}
          columnObj={column}
          phase={{}}
          isDiscussPhase={this.props.isDiscussPhase}
          actionsColumn={this.props.actionsColumn}
        />
        <div />
      </Component>
    );
  }
}

export default StackComponent;
