import * as React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from './DataTable.container';
import { icons, sortArray } from './icons'
import cx from 'classnames'
import './DataTable.scss';

function getIcon(name: string) {
	return icons[name] || icons['sort_arrow_up']
}

const MAX_DISPLAYED_ROWS = 5

export interface OwnDataTableProps {
  data: Array<any>,
  columns: Array<any>,
  name: string,
  searchPlaceholder: string,
  searchableColumns: Array<any>,
}

export type DataTableProps = OwnDataTableProps;

export interface DataTableState {
  visibleData: Array<any>,
  sort: any;
  currentPage: number;
  currentPageInput: any;
}

export class DataTable extends React.Component<
  DataTableProps,
  DataTableState
> {
  constructor(props: DataTableProps) {
    super(props);

    this.state = {
      visibleData: props.data,
      sort: {},
      currentPage: 1,
      currentPageInput: 1,
    };
  }

  setSortingFunction = (columnKey: any, order: string) => {
    const visibleData = sortArray(this.props.data, columnKey, order)

    this.setState({
      sort: {
        key: columnKey,
        order,
      },
      visibleData,
      currentPage: 1,
      currentPageInput: 1,
    })
  }

  onSearchChange = (e: any) => {
    const value = e.target.value

    const visibleData = this.props.data.filter(d => {
      for (const col of this.props.searchableColumns) {
        if (d[col]?.includes(value)) {
          return true
        }
      }

      return false
    })

    this.setState({visibleData, currentPage: 1, currentPageInput: 1})
  }

  getSortComponent = (column: any) => {
		if (!column.sortable) {
			return null
		}

    const { sort } = this.state

		const asc = cx('', { asc: sort.order === 'asc' && sort.key === column.selector })
		const desc = cx('', { desc: sort.order === 'desc' && sort.key === column.selector })

    const SortArrowUp = getIcon('sort_arrow_up')
    const SortArrowDown = getIcon('sort_arrow_down')

		return (
			<div className='Sort'>
				<div className='SortArrows'>
					<a id='asc' className={asc} onClick={() => this.setSortingFunction(column.selector, 'asc')}>
            <SortArrowUp fill={sort.order === 'asc' && sort.key === column.selector ? '#022f49' : '#ACB3BC'} width={10} height={10} />
					</a>
					<a id='desc' className={desc} onClick={() => this.setSortingFunction(column.selector, 'desc')}>
						<SortArrowDown fill={sort.order === 'desc' && sort.key === column.selector ? '#022f49' : '#ACB3BC'} width={10} height={10} />
					</a>
				</div>
			</div>
		)
	}

  onPageNumberChange = (e: any) => {
    const value = e.target.value
    const newPage = parseInt(value)

    if (value === '') {
      this.setState({ currentPageInput: '' })
      return
    }

    if (!Number.isNaN(newPage)) {
      this.updatePage(newPage)
    }
  }

  onInputBlur = () => {
    if (this.state.currentPageInput === '') {
      this.setState({ currentPageInput: this.state.currentPage })
    }
  }

  updatePage = (newPage: number) => {
    const { visibleData } = this.state
    const rest = visibleData.length % MAX_DISPLAYED_ROWS
    let maxPage = parseInt("" + visibleData.length / MAX_DISPLAYED_ROWS)
    if (rest > 0) {
      maxPage++
    }

    if (newPage < 1) {
      this.setState({ currentPage: 1, currentPageInput: 1 })
    } else if (newPage > maxPage) {
      this.setState({ currentPage: maxPage, currentPageInput: maxPage })
    } else {
      this.setState({ currentPage: newPage, currentPageInput: newPage })
    }
  }

  render() {
    const { name, searchPlaceholder, columns } = this.props
    const { currentPage, currentPageInput, visibleData } = this.state

    const rest = visibleData.length % MAX_DISPLAYED_ROWS

    let maxPage = parseInt("" + visibleData.length / MAX_DISPLAYED_ROWS)
    if (rest > 0) {
      maxPage++
    }

    let firstFilterIndex = 0
    let lastFilterIndex = MAX_DISPLAYED_ROWS

    if (currentPage > 1) {
      firstFilterIndex = (currentPage - 1) * MAX_DISPLAYED_ROWS
      lastFilterIndex = firstFilterIndex + MAX_DISPLAYED_ROWS
    }

    const displayedData = visibleData.slice(firstFilterIndex, lastFilterIndex)

    return (
      <div>
        <div className='DataTable__headerTop'>
          <div>Total {name}: {this.props.data.length}</div>
          <input placeholder={searchPlaceholder} onChange={this.onSearchChange} />
        </div>
        <div className='DataTableContainer'>
          <table className='DataTable'>
            <thead className='DataTable__headerRow'>
              <tr>
                {columns.map((column: any) => {
                  const key = `column-${column.name}`
                  const headerCSS = cx('DataTable__headerColumn', { 'DataTable__headerColumn--sortable': column.sortable })

                  return (
                    <th key={key}>
                      <div className={headerCSS}>
                        <div>{column?.name}</div>
                        {this.getSortComponent(column)}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>

            <tbody>
              {displayedData.length === 0 && (
                <tr className='DataTable__empty'>
                  <td align='center' colSpan={100}>
                    No Results
                  </td>
                </tr>
              )}

              {displayedData.map((item: any, index) => {
                const singleRow = []
                
                for(const column of columns) {
                  const key = `data-${index}-${column.name}-${item[column.selector]}`

                  singleRow.push(
                    <td key={key}>
                      {column.render && column.render(item)}
                      {!column.render && item[column.selector]}
                    </td>
                  )
                }

                const key = `data-${index}-`
                return <tr className='DataTable__itemRow' key={key}>{[...singleRow]}</tr>
              })}
            </tbody>
          </table>
        </div>
        <div className='DataTable__navigation'>
          <button disabled={currentPage === 1} onClick={() => this.updatePage(currentPage - 1)}>Prev</button>
          <input pattern="[0-9]+" value={currentPageInput} onChange={this.onPageNumberChange} onBlur={this.onInputBlur} />
          <div>/ {maxPage}</div>
          <button disabled={currentPage === maxPage} onClick={() => this.updatePage(currentPage + 1)}>Next</button>
        </div>
      </div>
    )
  }
}

export default connect<null, null, OwnDataTableProps>(mapStateToProps)(DataTable);
