import * as React from 'react';
import { Link } from 'react-router-dom';

import './CreateTeamLink.scss'

const CreateTeamLink = () => {
  const plusIcon = require('!svg-inline-loader!../../../../assets/icon-14-plus.svg');
  const onClick = () => {
    const portal = document.querySelector('#portal.active');
    if(portal) {
      portal.classList.remove('active');
    }
  };
  return(
    <div className='create-team-link'>
      <div className='create-team-link__text'>There is no team associated with your account.  Go ahead and create your first one.</div>
      <Link to={{pathname: '/account', state: 'team' }} className='create-team-link__link' onClick={onClick}>
        <span className='create-team-link__icon' dangerouslySetInnerHTML={{ __html: plusIcon}} />
        Create Team
      </Link>
    </div>
  )
} ;

export default CreateTeamLink
