import * as React from "react";
import { Component, MouseEvent } from 'react';

import { Team, Teams } from "../../types";
import TeamCard from "../TeamCard/TeamCard";
import './TeamList.scss';
import SideModal from "../SideModal";
import DeleteTeamModal from "../Modal/variant/DeleteTeamModal";
import TeamUpdate from "../SideModal/variant/TeamSides/TeamUpdate";
import LeaveTeamModal from "../Modal/variant/LeaveTeamModal";
import {getFirebase} from "react-redux-firebase";

export interface TeamListProps {
  teams: Teams;
  userUID: string;
  openLoading: (status?: string) => Promise<void>;
  closeLoading: () => void;
  paymentScreen: (e: any) => void;
  leaveTeam: (teamId: string, userId: string) => void;
  toggleAdmin: (teamId: string, userId: string) => void;
}

export interface TeamListState {
  isOpenSide: boolean;
  selectedTeam: Team | undefined;
  isOpenModal: 'delete' | 'leave' | '';
  deleteTeam: Team | undefined;
  leaveTeamId: string;
}

class TeamList extends Component<TeamListProps, TeamListState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpenSide: false,
      selectedTeam: undefined,
      isOpenModal: '',
      deleteTeam: undefined,
      leaveTeamId: ''
    }
  }

  openSide = (team: Team) => {
    this.setState({ selectedTeam: team, isOpenSide: true });
  };

  closeSide = () => {
    this.setState({ selectedTeam: undefined, isOpenSide: false });
  };

  openModal = (e: MouseEvent<HTMLLIElement>,team: Team, modal: 'delete' | 'leave') => {
    e.stopPropagation();
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.add('account-portal--active');
    }
    if(modal === 'delete') {
      this.setState({ isOpenModal: modal, deleteTeam: team });
    } else if(modal === 'leave' && team.id) {
      this.setState({ isOpenModal: modal, leaveTeamId: team.id });
    }
  };

  closeModal = () => {
    const portal = document.getElementById('portal');
    if(portal) {
      portal.classList.remove('account-portal--active');
    }
    this.setState({ isOpenModal: '', deleteTeam: undefined, leaveTeamId: '' });
  };

  render() {
    const { teams, openLoading, closeLoading } = this.props;
    const { selectedTeam, isOpenSide, isOpenModal, deleteTeam, leaveTeamId } = this.state;


    const teamsArray: Team[] = Object.keys(teams).map((teamId) => {
      return { id: teamId, ...teams[teamId] }
    }).sort((a: Team, b: Team) => {
      if(a.lastUpdatedAt > b.lastUpdatedAt) {
        return -1;
      } else if(a.lastUpdatedAt < b.lastUpdatedAt){
        return 1
      } else {
        return 0;
      }
    });

    const user = getFirebase().auth().currentUser;
    let uid: string = user ? user.uid : '';

    return(
      <div
        className='team-list'
      >
        {teamsArray.map((team: Team) =>
          <TeamCard
            key={team.id}
            team={team}
            teamId={team.id!}
            openSide={this.openSide}
            openModal={this.openModal}
          />
        )}

        {isOpenModal === 'delete' && !!deleteTeam &&
          <DeleteTeamModal
            onClose={this.closeModal}
            team={deleteTeam}
            openLoading={openLoading}
            closeLoading={closeLoading}
          />
        }

        {isOpenModal === 'leave' && !!leaveTeamId &&
          <LeaveTeamModal
            onClose={this.closeModal}
            onSubmit={() => this.props.leaveTeam(leaveTeamId, uid)}

          />
        }

        <SideModal
          title={selectedTeam ? selectedTeam.name : ''}
          isOpen={isOpenSide}
          onClose={this.closeSide}
          className="teams-sidebar"
        >
          {!!selectedTeam &&
            <TeamUpdate
              team={selectedTeam}
              paymentScreen={this.props.paymentScreen}
              leaveTeam={this.props.leaveTeam}
              toggleAdmin={this.props.toggleAdmin}
              userUID={this.props.userUID}
            />
          }
        </SideModal>
      </div>
    )
  }
}

export default TeamList;
