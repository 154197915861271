import * as React from 'react';
import { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { UserCredential } from '@firebase/auth-types';

import './UserNameBox.scss';
import getRandomName from "../../../constants/Name";

export  interface UserNameBoxProps {
  onSubmit: (authResult: UserCredential | undefined, username?: string) => void;
  authResult: UserCredential | undefined;
  loading: () => void;
}

export interface UserNameBoxState {
  name: string
  error: boolean
}

class UserNameBox extends Component<UserNameBoxProps, UserNameBoxState> {
  constructor(props: UserNameBoxProps) {
    super(props);
    this.state = {
      name: getRandomName(),
      error: false,
    }
  }

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const limit = process.env.REACT_APP_USERNAME_LIMIT ? process.env.REACT_APP_USERNAME_LIMIT : 40;
    if(e.target.value.length <= limit) {
      this.setState({ name: e.target.value, error: /^\s/.test(e.target.value) });
    }
  };

  onBlurName = () => {
    const { name } = this.state;
    if(!name) {
      this.setState({ error: true });
    }
  };

  onFocusName = () => {
    this.setState({ error: false });
  };

  clearInput = () => {
    this.setState({ name: '' }, () => {
      const inputElement = document.getElementById('usernameInput');
      if(inputElement) {
        inputElement.focus();
      }
    });
  };

  onClickSubmit = (e: any) => {
    e.preventDefault();
    const { name, error } = this.state;
    const { authResult, onSubmit, loading } = this.props;
    if(name && !error) {
      const el = e.target;
      el.disabled = true;
      loading();
      onSubmit(authResult, name);
    }
  };

  render() {
    const { name, error } = this.state;

    return(
      <div className="username-box-container">
        <div className="username-box">
          <h3 className="username__title">Hi, nice to meet you!</h3>
          <div className="username__intro">Please, enter your name</div>
          <div className="username-input-container">
            <div className="char-remaining">{name.length} / {process.env.REACT_APP_USERNAME_LIMIT}</div>
            <TextField
              id='usernameInput'
              value={name}
              onChange={this.onChangeName}
              onBlur={this.onBlurName}
              onFocus={this.onFocusName}
              error={error}
              label='Your Name'
              className='login-box__input'
              helperText={error ? 'Your name may not start with a whitespace' : 'Choose a name by which you will be recognized'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!!name && (
                      <div
                        className="login-box-input-clear"
                        onClick={this.clearInput}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <button
            className="username__submit"
            onClick={this.onClickSubmit}
          >
            JOIN
          </button>
        </div>
      </div>
    )
  }
}

export default UserNameBox;
