import { User } from '@firebase/auth-types';
import { FirebaseFirestore } from '@firebase/firestore-types';
import { getVal, isLoaded, getFirebase } from 'react-redux-firebase';
import { AnyAction, Dispatch } from 'redux';
import {
  Board, FirebaseProp,
  StoreState
} from '../../types';
import { BoardActionItemsProps } from './BoardActionItems';
import * as Raven from 'raven-js';

export const mapStateToProps = (
  state: StoreState,
  ownProps: any
): BoardActionItemsProps => {
  const { app, fbState } = state;
  const firebase = ownProps.firebase as FirebaseProp;
  const database: FirebaseFirestore = firebase.firestore();

  const auth: User | any = firebase.auth().currentUser || {};

  const cards = getVal(fbState, `data/cards`);
  const board: Board = getVal(fbState, `data/board`);
  const setupCompleted = getVal(app, `setupCompleted`, false);

  const users = isLoaded(board) ? board.users : {};
  const isBoardAdmin = isLoaded(board)
    ? auth.uid === board.creatorUid
    : false;

  const isDataLoaded = isLoaded(board) && isLoaded(cards)

  const username = auth.displayName || undefined;
  const email = auth.email || undefined;
  const isAnonymous = auth.isAnonymous;

  async function onMarkCardAsDone(boardId: string, cardId: string) {
    return database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(cardId)
      .update({
        status: 'done',
      })
      .then(() => {
        return true
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not mark card as done', {
          extra: {
            reason: err.message,
            boardId,
            cardId
          }
        });
        return false
      });
  }

  async function onMarkCardAsUndone(boardId: string, cardId: string) {
    return database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(cardId)
      .update({
        status: 'active',
      })
      .then(() => {
        return true
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not undone card', {
          extra: {
            reason: err.message,
            boardId,
            cardId
          }
        });
        return false
      });
  }

  async function onDelteCard(boardId: string, cardId: string) {
    // add removing from group if is in the gruop
    try {
      const boardGroups = await database
                            .collection('boards')
                            .doc(boardId)
                            .collection('cardGroups')
                            .get()

      if (boardGroups?.size > 0) {
        let foundedGroup = ''
        let newCards = {}
        boardGroups.forEach((snapGroup: any) => {
          const snapGroupData = snapGroup.data()
          const cardsInGroup = snapGroupData.cards
          if (Object.keys(cardsInGroup).includes(cardId)) {
            foundedGroup = snapGroup.id
            delete cardsInGroup[cardId]
            newCards = cardsInGroup
          }
        });

        if (foundedGroup) {
          await database
            .collection('boards')
            .doc(boardId)
            .collection('cardGroups')
            .doc(foundedGroup)
            .update({ cards: newCards })

        }
      }
    } catch (e: any) {
      console.log('e', e)
      return false
    }

    // remove card
    return database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(cardId)
      .delete()
      .then(() => {
        return true
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not mark card as done', {
          extra: {
            reason: err.message,
            boardId,
            cardId
          }
        });
        return false
      });
  }

  async function sendUserEmailForAssignment(userEmail: any, boardId: any) {
    try {
      const board = await database.collection('boards').doc(boardId).get()
      if (!board.exists || !board.data()) {
        return false
      }

      const data = {
        userId: getFirebase().auth().currentUser?.uid,
        assignedEmail: userEmail,
        boardName: board.data()?.name,
        boardLink: `/#/board/${boardId}/action-items`
      }

      const assignedUserRes = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendEmailForAssignedItem`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
  
      if (assignedUserRes) {
        const resJSON = await assignedUserRes.json()

        if (resJSON) {
          if(resJSON.body) {
            return true
          }
        }
      }

      return false
  
    } catch (e) {
      return {}
    }
  }

  async function onUpdateCard(card: any) {
    const boardId = card.boardId
    const cardId = card.id
    let updatingFields = {
      text: card.text,
      status: card?.status || '',
      assignedUserId: card.assignedUserId || null,
      assignedUserEmail: card.assignedUserEmail || null,
      assignedUserName: card.assignedUserName || null,
      assignedUserPhotoUrl: card.assignedUserPhotoUrl || null
    }

    const oldCardRes = await database
                        .collection('boards')
                        .doc(boardId)
                        .collection('cards')
                        .doc(cardId)
                        .get()

    let oldCard: any
    if (oldCardRes?.data()) {
      oldCard = oldCardRes.data()
    }

    return database
      .collection('boards')
      .doc(boardId)
      .collection('cards')
      .doc(cardId)
      .update({
        ...updatingFields
      })
      .then(() => {
        if (updatingFields.assignedUserEmail) {
          if (oldCard?.assignedUserId !== card.assignedUserId || oldCard?.text !== card.text) {
            sendUserEmailForAssignment(updatingFields.assignedUserEmail, boardId)
          }
        }
        
        return true
      })
      .catch((err: Error) => {
        Raven.captureMessage('Could not mark card as done', {
          extra: {
            reason: err.message,
            boardId,
            cardId
          }
        });
        return false
      });
  }

  return {
    auth,
    board,
    cards,
    isDataLoaded,
    users,
    uid: auth.uid,
    setupCompleted,
    isBoardAdmin,
    username,
    email,
    isAnonymous,
    onMarkCardAsDone,
    onMarkCardAsUndone,
    onDelteCard,
    onUpdateCard,
    ...ownProps
  };
};

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    dispatch
  };
}

export function mergeProps(
  stateProps: BoardActionItemsProps,
  { dispatch }: { dispatch: Dispatch<any> },
  ownProps: BoardActionItemsProps
): BoardActionItemsProps {

  return {
    ...ownProps,
    ...stateProps
  };
}
