import * as React from 'react';
import { Component } from 'react';
import * as cx from 'classnames';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, ReduxFirestoreQueries, getFirebase } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

import { mapStateToProps } from './TeamUpdate.container';
import {Team, TeamBoards, TeamPendingUsers, Users, TeamPendingUser } from '../../../../../types';
import TeamMembers from '../../../../TeamMembers/TeamMembers';
import './TeamUpdate.scss';
import TeamInviteUsers from '../../../../TeamInviteUsers';

export interface TeamUpdateOwnProps {
  team: Team;
  userUID: string;
  paymentScreen: (e: any) => void;
  leaveTeam: (teamId: string, userId: string) => void;
  toggleAdmin: (teamId: string, userId: string) => void;
}

export interface TeamUpdateStateProps {
  teamUsers: Users;
  teamBoards: TeamBoards;
  pendingUsers: TeamPendingUsers | undefined;
  changeTeamName: (value: string) => void;
}

export interface TeamUpdateState {
  showCopyInfo: boolean;
  teamName: string;
  errorName: boolean;
  userLimitDone: boolean;
  userLimitProgress: boolean;
  userLimit: string | number;
  team: Team;
  teamUsers: Users;
}

export type TeamUpdateProps = TeamUpdateOwnProps & TeamUpdateStateProps;

class TeamUpdate extends Component<TeamUpdateProps, TeamUpdateState> {
  constructor(props: TeamUpdateProps) {
    super(props);
    this.state = {
      showCopyInfo: false,
      teamName: '',
      errorName: false,
      userLimit: '',
      userLimitDone: false,
      userLimitProgress: false,
      team: {name: '', createdAt: 0, lastUpdatedAt: 0, admins: [], usersArray: [], creatorUid: ''},
      teamUsers: {},
    }
  }

  componentDidMount() {
    this.setState({ teamName: this.props.team.name });
  }

  componentDidUpdate() {
    const { team, teamUsers } = this.props;
    const { userLimitProgress, userLimitDone } = this.state;
    if(team && team.creatorUid && !userLimitProgress && !userLimitDone) {
      this.setState({ userLimitProgress: true } , () => {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getPlanInfo`, {
          method: 'POST',
          body: JSON.stringify({ uid: team.creatorUid})
        })
          .then(response => response.json())
          .then((res) => {
            if(res.body) {
              const body = JSON.parse(res.body);
              this.setState({ userLimit: body.teamUserLimit, userLimitDone: true, userLimitProgress: false });
            } else {
              this.setState({ userLimitDone: true, userLimitProgress: false })
            }
          })
      });

      if (!this.state.team.name || team.admins.length !== this.state.team.admins.length) {
        this.setState({team: team})
      }
    }

    if (teamUsers && teamUsers !== this.state.teamUsers) {
      this.setState({teamUsers: teamUsers})
    }
  }

  handleTeamName = (e: any) => {
    const value = e.target.value;
    this.setState({ teamName: value }, () => {
      if(value && !/^\s/.test(value)) {
        this.props.changeTeamName(value);
      }
    });
  };

  onBlurTeamName = () => {
    const { teamName } = this.state;
    this.setState({ errorName: /^\s/.test(teamName) || teamName === '' });
  };

  onFocusTeamName = () => {
    this.setState({ errorName: false });
  };

  displayCopyMessage = () => {
    this.setState({ ...this.state, showCopyInfo: true }, () => {
      setTimeout(() => {
        this.setState({ showCopyInfo: false });
      }, 3000)
    });
  };

  resendInvite = (e: any, email: string, pendingUser: TeamPendingUser) => {
    const target = e.target;
    target.disabled = true;
    const { team } = this.props;
    const uid = getFirebase().auth().currentUser ? getFirebase().auth().currentUser!.uid : '';
    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendInvites` , {
      method: 'POST',
      body: JSON.stringify({ userIds: [email], uid: uid, hasAdmin: pendingUser.isAdmin, teamId: team.id })
    })
      .then(res => res.json())
      .then(() => {
        target.disabled = false;
      })
      .catch((e) => {
        console.log(e);
        target.disabled = false;
      })
  };

  toggleAdminOrMember = (teamId: string, userId: string) => {
    const { team } = this.state
    const isAdmin: boolean = team.admins.includes(userId);
    let admins = []
    if (isAdmin) {
      admins = team.admins.filter(t => t !== userId)
    } else {
      admins = [...team.admins, userId]
    }

    this.setState({team: {...team, admins}})
    this.props.toggleAdmin(teamId, userId)
  }

  copyInvitationLink(teamId: string) {
    const link = window.location.href.replace('account', `account?team=${teamId}`);

    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = link;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.setAttribute('style', 'white-space: pre; position: absolute; left: -9999px;');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);

    this.setState({ ...this.state, showCopyInfo: true }, () => {
      setTimeout(() => {
        this.setState({ showCopyInfo: false });
      }, 3000)
    });
  }

  removePendingUser = async (e: any, teamId: string | undefined, email: string) => {
    const target = e.target;
    target.disabled = true;

    try {
      const user = getFirebase().auth().currentUser;
      if(user) {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/removeInvite` , {
          method: 'POST',
          body: JSON.stringify({ teamId: teamId, uid: user.uid, email: email })
        })
          .then(res => res.json())
          .then((data) => {
            if(data.body) {
              const body = JSON.parse(data.body);
              if(body.success) {
                target.disabled = false;
              } else {
                target.disabled = false;
              }
            } else {
              target.disabled = false;
            }
          })
          .catch((e) => {
            console.log(e);
            target.disabled = false;
          })
        }
    } catch (e) {
      console.log(e);
      target.disabled = false;
    }
  };

  render() {
    const { team, teamName, errorName, userLimit, showCopyInfo, teamUsers } = this.state;
    const { pendingUsers } = this.props;
    const isFullUsers: boolean = userLimit === '' ? false : team.usersArray.length >= userLimit;
    const user = getFirebase().auth().currentUser;
    let uid: string = user ? user.uid : '';
    const isAdmin = team.admins.includes(uid);
    const isCreator = team.creatorUid === uid;
    const shareIcon = require(`!svg-inline-loader!../../../../../assets/icon-19-12-share.svg`);

    const arrowIcon = require('!svg-inline-loader!../../../../../assets/icon-6-12-arrow.svg');

    return(
      <div className="team-update">
        {isCreator &&
          <div className="side-modal__box team-create-box">
            <div className="side-modal-input-container">
              <TextField
                label="Team Name"
                className='side-modal__input settings__input'
                value={teamName}
                onChange={this.handleTeamName}
                onBlur={this.onBlurTeamName}
                onFocus={this.onFocusTeamName}
                error={errorName}
                helperText={errorName && 'Please enter team name'}
              />
            </div>
          </div>
        }

        {isAdmin &&
        <div className="side-modal__box team-update-box">
          <Link
            to={`/new?teamId=${team.id}`}
            className='team-update__link'
          >
            Create retrospective
            <span
              className="team-update__arrow"
              dangerouslySetInnerHTML={{
                __html: arrowIcon
              }}
            />
          </Link>
        </div>
        }

        <TeamMembers
          teamUsers={teamUsers}
          team={team}
          userLimit={userLimit}
          paymentScreen={this.props.paymentScreen}
          isFullUsers={isFullUsers}
          isCreator={isCreator}
          leaveTeam={this.props.leaveTeam}
          toggleAdmin={this.toggleAdminOrMember}
          userUID={this.props.userUID}
        />

        {!isFullUsers && team.id && isAdmin &&
          <TeamInviteUsers
            isCreator={isCreator}
            teamId={team.id}
          />
        }

        {!!pendingUsers && !!Object.keys(pendingUsers).length && !isFullUsers && isAdmin &&
          <div className='side-modal__box'>
            {Object.keys(pendingUsers).map((email: string) =>
              <div
                className='pending-user'
                key={email}
              >
                <div className='pending-user__email'>{email}</div>
                <div
                  className='team-member-action'
                >
                  {isAdmin && (
                    <>
                      <div
                        className="team-member-user__link"
                        onClick={this.copyInvitationLink.bind(this, team.id)}
                        dangerouslySetInnerHTML={{__html: shareIcon}}
                        data-tip='Copy invitation link'
                        data-for='copyInvitationTooltip'
                      />
                      <ReactTooltip
                        id='copyInvitationTooltip'
                        place="bottom"
                        effect="solid"
                        delayShow={500}
                      />
                    </>
                  )}
                  <button
                    className="team-member__user-action"
                    onClick={(e) => this.removePendingUser(e, team.id, email)}
                    data-tip='Remove invitation link'
                    data-for='removeInvitationTooltip'
                  />
                  <ReactTooltip
                    id='removeInvitationTooltip'
                    place="bottom"
                    effect="solid"
                    delayShow={500}
                  />
                  <button
                    className='pending-user__btn'
                    onClick={(e) => this.resendInvite(e, email, pendingUsers[email])}
                    data-tip='Resend invitation link'
                    data-for='resendInvitationTooltip'
                  >
                    RESEND
                  </button>
                  <ReactTooltip
                    id='resendInvitationTooltip'
                    place="bottom"
                    effect="solid"
                    delayShow={500}
                  />
                </div>
              </div>
            )}
            <span
              className={cx('side-share__copy-text', {
                'side-share__copy-text--hidden': !showCopyInfo
              })}
            >
              URL copied to clipboard
            </span>
          </div>
        }
      </div>
    )
  }
}

function firestoreConnector(props: TeamUpdateOwnProps): ReduxFirestoreQueries {
  const { team } = props;
  return [
    {
      collection: 'teams',
      doc: team.id,
      subcollections: [{ collection: 'users', doc: 'users' }],
      storeAs: 'teamUsers'
    },
    {
      collection: 'teams',
      doc: team.id,
      subcollections: [{ collection: 'boards', doc: 'boards' }],
      storeAs: 'teamBoards'
    },
    {
      collection: 'teams',
      doc: team.id,
      subcollections: [{ collection: 'pendingUsers', doc: 'pendingUsers' }],
      storeAs: 'pendingUsers'
    }
  ]
}

export default compose<any, any, any>(firestoreConnect(firestoreConnector), connect(mapStateToProps))(TeamUpdate);
