import { BoardUsers, StoreState } from '../../types';
import { getFirebase, getVal, isLoaded } from 'react-redux-firebase';
import { HeaderDashboardStateProps } from "./HeaderDashboard";

export const mapStateToProps = (state: StoreState, ownProps: any): HeaderDashboardStateProps => {
  const { fbState } = state;
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const userId: string = user ? user.uid : '';

  //@todo after adding dashboard fix this
  const boards = getVal(state.fbState, `data/userBoards`, undefined);
  const boar = getVal(state.fbState, `data/boards`, undefined);
  let hasBoards: boolean | undefined = undefined;

  const notifications: any = getVal(fbState, `data/notifications`, undefined);

  if(isLoaded(boards) && boards) {
    hasBoards = !!boards || !!boar;
  }

  let activeUser: BoardUsers = {};
  if(user) {
    activeUser = isLoaded(user)
      ? {
          [userId]: {
            name: user.displayName || '',
            photoUrl: user.photoURL || '',
            email: user.email || '',
            ready: false
          }
        }
      : {};
  }

  function onSignOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        location.hash = '/';
      })
  }

  return {
    userId: userId,
    notifications: notifications,
    user: activeUser,
    hasBoards: hasBoards,
    onSignOut: onSignOut,
    onSwitchAccount: ownProps.onSwitchAccount,
    silver: ownProps.silver,
    dontShowAccount: ownProps.dontShowAccount,
    showEmail: ownProps.showEmail,
  };
};
