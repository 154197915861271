import * as React from "react";
import { Component } from 'react';

import './Review.scss';

export interface ReviewState {
    interval: number | undefined,
    personsInfo: {name: string, occupation: string} [],
    activePerson: number
}

class Review extends Component<{}, ReviewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            interval: 0,
            personsInfo: [
                {
                    name: 'James A. Brannon',
                    occupation: 'CEO of Company Name'
                },
                {
                    name: 'John Doe',
                    occupation: 'Marketing Manager at Company Name'
                },
                {
                    name: 'Leanne Graham',
                    occupation: 'HR at Company Name'
                },
                {
                    name: 'Ervin Howell',
                    occupation: 'Sales Manager at Company Name'
                }
            ],
            activePerson: 0
        }
    }

    componentDidMount() {
        const interval: any = setInterval(() => {
            const activePerson = this.state.activePerson + 1;
            if(activePerson < 3) {
                this.setState({ activePerson: activePerson });
            } else {
                this.setState({ activePerson: 0});
            }
        }, 7000);

        this.setState({ interval: interval });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    showPersonInfo = (personId: number) => {
        clearInterval(this.state.interval);
        this.setState({ activePerson: personId });
        const interval: any = setInterval(() => {
            const activePerson = this.state.activePerson + 1;
            if(activePerson < 3) {
                this.setState({ activePerson: activePerson });
            } else {
                this.setState({ activePerson: 0});
            }
        }, 7000);
        this.setState({ interval: interval });
    };

    render() {
        const { personsInfo, activePerson } = this.state;
        return(
            <div id="review" className="review">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="review__title">OTHERS ABOUT US</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 m-auto">
                            <blockquote className="review__description">“Fusce tempor felis lacus, a hendrerit urna facilisis at. Sed sollicitudin bibendum tincidunt. Aenean a bibendum magna. Pellentesque feugiat urna a lectus consequat, quis vulputate nunc feugiat.“</blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="person__name">{personsInfo[activePerson].name}</h4>
                            <div className="person__info">
                                <span className="person__occupation">{personsInfo[activePerson].occupation}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div
                                className={`person__avatar ${activePerson === 0 && 'active'}`}
                                onClick={() => this.showPersonInfo(0)}
                            >
                                icon
                            </div>
                            <div
                                className={`person__avatar ${activePerson === 1 && 'active'}`}
                                onClick={() => this.showPersonInfo(1)}
                            >
                                icon
                            </div>
                            <div
                                className={`person__avatar ${activePerson === 2 && 'active'}`}
                                onClick={() => this.showPersonInfo(2)}
                            >
                                icon
                            </div>
                            <div
                                className={`person__avatar ${activePerson === 3 && 'active'}`}
                                onClick={() => this.showPersonInfo(3)}
                            >
                                icon
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Review;