import * as React from "react";
import { Component } from 'react';
import * as cx from 'classnames';
import ReactTooltip from "react-tooltip";
import { getFirebase } from 'react-redux-firebase';

import { Team, Users } from "../../types";
import Avatar from "../Avatar";
import './TeamMembers.scss'
import {onPayment} from "../../analytics";

export interface TeamMembersProps {
  teamUsers: Users;
  userUID: string;
  team: Team;
  userLimit: string | number;
  paymentScreen: (e: any) => void;
  isFullUsers: boolean;
  isCreator: boolean;
  leaveTeam: (teamId: string, userId: string) => void;
  toggleAdmin: (teamId: string, userId: string) => void;
}

export interface TeamMembersState {
  isCustomerPortalBtn: boolean;
  isToggledToAdmin: boolean | null;
}

class TeamMembers extends Component<TeamMembersProps, TeamMembersState> {
  constructor(props: TeamMembersProps) {
    super(props);
    this.state = {
      isCustomerPortalBtn: false,
      isToggledToAdmin: null,
    }
  }

  componentDidUpdate(prevProps: TeamMembersProps) {
    const { isCustomerPortalBtn } = this.state;
    const { paymentScreen } = this.props;
    if(!isCustomerPortalBtn) {
      const portalForm = document.getElementById('upgradePlan');
      if(portalForm) {
        portalForm.addEventListener('submit', (e) => {
          paymentScreen(e);
        });
        this.setState({ isCustomerPortalBtn: true });
      }
    }

    if(prevProps.team.name && prevProps.team.admins !== this.props.team.admins) {
      this.setState({isToggledToAdmin: this.props.team.admins.length > prevProps.team.admins.length})
      setTimeout(() => this.setState({ isToggledToAdmin: null }), 5000);
    }
  }

  toggleAdminOrMember = (teamId: string, userId: string) => {
    const admins = this.props.team.admins
    const isAdmin: boolean = admins.includes(this.props.userUID);

    if (!isAdmin) {
      return
    }

    this.props.toggleAdmin(teamId, userId)
  }

  leaveTeam = async (e: any, teamId: string, userId: string) => {
    const target = e.target;
    target.disabled = true;

    try {
      const user = getFirebase().auth().currentUser;
      if(user) {
        fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/removeTeam` , {
          method: 'POST',
          body: JSON.stringify({ teamId, userId, uid: user.uid })
        })
          .then(res => res.json())
          .then((data) => {
            if(data.body) {
              const body = JSON.parse(data.body);
              if(body.success) {
                target.disabled = false;
              } else {
                target.disabled = false;
              }
            } else {
              target.disabled = false;
            }
          })
          .catch((e) => {
            console.log(e);
            target.disabled = false;
          })
        }
    } catch (e) {
      console.log(e);
      target.disabled = false;
    }
  };

  render() {
    const { teamUsers, team, userLimit, isFullUsers, isCreator } = this.props;
    const usersTotal = teamUsers ? Object.keys(teamUsers).length : 1;
    const teamId: string = team.id || '';
    const admins = this.props.team.admins
    const isAdmin: boolean = admins.includes(this.props.userUID);

    if(team && teamUsers) {
      return(
        <div
          className="side-modal__box"
        >
          <h3 className="side-modal-box__title">
            <span>MEMBERS</span>
            <span>{usersTotal}/{userLimit}</span>
          </h3>
          <div className={cx("team-members-list", { 'team-members-list--full': isFullUsers })}>
            {Object.keys(teamUsers).map((userId: string) =>
              <div
                key={userId}
                className="team-member"
              >
                <div className="team-member-user">
                  <Avatar
                    user={teamUsers[userId]}
                    className="team-member-user__avatar"
                  />
                  <div className="team-member-user__name">{teamUsers[userId].name}</div>
                </div>
                <div
                  className='team-member-action'
                >
                  {isCreator && team.creatorUid !== userId &&
                    <>
                      <button
                        className="team-member__user-action"
                        onClick={(e) => this.leaveTeam(e, teamId, userId)}
                        data-tip='Remove from the team'
                        data-for={`removeTeamTooltip-${userId}`}
                      />
                      <ReactTooltip
                        id={`removeTeamTooltip-${userId}`}
                        place="bottom"
                        effect="solid"
                        delayShow={500}
                      />
                    </>
                  }
                  <button
                    className={cx("team-member__admin", { "team-member__admin--active": isAdmin })}
                    onClick={() => this.toggleAdminOrMember(teamId, userId)}
                  >
                    <span
                      data-tip={team.admins.includes(userId) ? 'Change admin to member.' : 'Change member to admin.'}
                      data-for='toggleAdminTooltip'
                    >
                        {team.admins.includes(userId) ? 'ADMIN' : 'MEMBER'}
                    </span>
                    <ReactTooltip
                      id='toggleAdminTooltip'
                      place="bottom"
                      effect="solid"
                      delayShow={500}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>

          {isFullUsers && isCreator &&
            <div
              className="team-members-upgrade"
            >
              <div className="team-members-upgrade-message">You reached maximum members for this team. Upgrade to add more members.</div>
              <form
                method="POST"
                action={`${process.env.REACT_APP_FUNCTIONS_URL}/createSession`}
                id="upgradePlan"
              >
                <button
                  className="teams-button--primary"
                  type="submit"
                  onClick={onPayment}
                >
                  UPGRADE
                </button>
              </form>
            </div>
          }

          {this.state.isToggledToAdmin !== null && (
            <div className='team-members-toggle-message'>
              You have changed {this.state.isToggledToAdmin ? ' member to admin.' : ' admin to member.'}
            </div>
          )}
        </div>
      )
    } else {
      return ''
    }
  }
}

export default TeamMembers;
