import * as React from 'react';
import { Component } from 'react';
import { getFirebase } from "react-redux-firebase";
import { connect } from 'react-redux';
import DataTable from '../../components/DataTable';
import HeaderDashboard from '../../components/HeaderDashboard';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { analytics } from "../../index";
import { mapStateToProps } from './Statistics.container';
import moment = require("moment");

import './Statistics.scss';

const usersColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Created Date',
    selector: 'createdDate',
    sortable: true,
  },
  // {
  //   name: 'Total Number of Teams',
  //   selector: 'numOfTeams',
  //   sortable: true,
  // },
  {
    name: 'Last Sign In',
    selector: 'lastSignIn',
    sortable: true,
  },
  // {
  //   name: 'Total Number of Boards',
  //   selector: 'numOfBoards',
  //   sortable: true,
  // },
  {
    name: 'Date Paid',
    selector: 'datePaid',
    sortable: true,
  },
  {
    name: 'Impersonate User',
    selector: 'impersonateUser',
    render: (item: any) => <button onClick={impersonateUser.bind(null, item?.uid)}>Impersonate User</button>,
    sortable: false,
  }
]

const teamsColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Created Date',
    selector: 'createdDate',
    sortable: true,
  },
  {
    name: 'Creator Name',
    selector: 'creatorName',
    sortable: true,
  },
  {
    name: 'Creator Email',
    selector: 'creatorEmail',
    sortable: true,
  },
  {
    name: 'Total Number of Users',
    selector: 'numOfUsers',
    sortable: true,
  },
  {
    name: 'Last Updated',
    selector: 'lastUpdated',
    sortable: true,
  }
]

const boardsColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Created Date',
    selector: 'createdDate',
    sortable: true,
  },
  {
    name: 'Creator Name',
    selector: 'creatorName',
    sortable: true,
  },
  {
    name: 'Creator Email',
    selector: 'creatorEmail',
    sortable: true,
  },
  {
    name: 'Total Number of Users',
    selector: 'numOfUsers',
    sortable: true,
  },
  {
    name: 'Last Updated',
    selector: 'lastUpdated',
    sortable: true,
  }
]

const impersonateUser = (uid: any) => {
  const user = getFirebase().auth().currentUser;

  fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/impersonateUser`, {
    method: 'POST',
    body: JSON.stringify({ uid: user?.uid, userUid: uid })
  })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const body = JSON.parse(data.body);
        if (body?.token) {
          getFirebase().auth().signInWithCustomToken(body?.token)
        }
      }
    }).catch(e =>{
      console.log(e)
    })
}

export interface StatisticState {
  users: Array<any>,
  usersLoading: boolean,
  teams: Array<any>,
  teamsLoading: boolean,
  boards: Array<any>,
  boardsLoading: boolean,
  calculationLoading: boolean,
  usersWithEmailsNumber: number,
  paidUsersNumber: number,
  usersWithBoardsAndCardsNumber: number,
  uniqueUsersWithMultipleBoardsNumber: number,
}

class Statistics extends Component<{}, StatisticState> {
  constructor(props: any) {
    super(props);
    this.state = {
      users: [],
      usersLoading: true,
      teams: [],
      teamsLoading: true,
      boards: [],
      boardsLoading: true,
      calculationLoading: true,
      usersWithEmailsNumber: 0,
      paidUsersNumber: 0,
      usersWithBoardsAndCardsNumber: 0,
      uniqueUsersWithMultipleBoardsNumber: 0,
    };
  }

  componentDidMount() {
    // @ts-ignore
    analytics.logEvent('statistics');
    this.fetchUsers()
  }

  componentDidUpdate(prevProps: any, prevState: StatisticState) {
    if ((prevState.usersLoading && !this.state.usersLoading) 
          || (prevState.teamsLoading && !this.state.teamsLoading) 
          || (prevState.boardsLoading && !this.state.boardsLoading)) {


      if (!this.state.usersLoading && !this.state.teamsLoading && !this.state.boardsLoading) {
        const usersWithEmailsNumber = this.state.users.filter(u => !!u.email).length
        const paidUsersNumber = this.state.users
                                  .filter(user => user.stripeSubscriptions?.planAmount > 0 && user.stripeSubscriptions?.currentPeriodStart)
                                  .length
        
        const uniqueUsersWithBoardsAndCards = this.state.boards.reduce((acc, curr) => {
          const creatorUid = curr.creatorUid
          const hasCards = curr.boardCards?.length
          if (creatorUid && !acc[creatorUid] && hasCards) {
            return { ...acc, [creatorUid]: true }
          }

          return acc
        }, {})

        const usersWithBoardsAndCardsNumber = uniqueUsersWithBoardsAndCards ? Object.keys(uniqueUsersWithBoardsAndCards)?.length : 0

        const uniqueUsersWithBoards = this.state.boards.reduce((acc, curr) => {
          const creatorUid = curr.creatorUid
          if (creatorUid) {
            if (!acc[creatorUid]) {
              return { ...acc, [creatorUid]: 1 }
            } else {
              return { ...acc, [creatorUid]: acc[creatorUid] + 1 }
            }
          }

          return acc
        }, {})

        let uniqueUsersWithMultipleBoardsNumber = Object.values(uniqueUsersWithBoards).filter((v: number) => v > 1).length

        this.setState({ usersWithEmailsNumber, paidUsersNumber, usersWithBoardsAndCardsNumber, uniqueUsersWithMultipleBoardsNumber, calculationLoading: false })
      }
    }
  }

  fetchUsers = async () => {
    const user = getFirebase().auth().currentUser;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getStatistics`, {
      method: 'POST',
      body: JSON.stringify({ uid: user?.uid })
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          const body = JSON.parse(data.body);

          if (body?.users) {
            const mappedUsers = body.users.map((user: any) => {
              const teams = user.teams
              const boards = user.boards
              const numOfTeams = teams ? Object.keys(teams)?.length : 0
              const numOfBoards = boards ? Object.keys(boards)?.length : 0

              const createdDate = user.creationTime ? moment(user.creationTime).format('YYYY-MM-DD hh:mm:ss') : '-';
              const lastSignIn = user.lastSignInTime ? moment(user.lastSignInTime).format('YYYY-MM-DD hh:mm:ss') : '-';

              let datePaid = '—'
              if (user.stripeSubscriptions?.planAmount > 0 && user.stripeSubscriptions?.currentPeriodStart) {
                datePaid = moment(user.stripeSubscriptions?.currentPeriodStart * 1000).format('YYYY-MM-DD hh:mm:ss');
              }
  
              return (
                { ...user, numOfTeams, numOfBoards, createdDate, lastSignIn, datePaid }
              )
            })

            console.log('mappedUsers', mappedUsers)
            this.fetchTeams(mappedUsers)
            this.fetchBoards(mappedUsers)
            this.setState({ users: mappedUsers, usersLoading: false })
          } else {
            this.setState({ usersLoading: false })
            this.fetchTeams([])
            this.fetchBoards([])
          }
        } else {
          this.setState({ usersLoading: false })
          this.fetchTeams([])
          this.fetchBoards([])
        }
      }).catch(e =>{
        console.log(e)
        this.setState({ usersLoading: false })
        this.fetchTeams([])
        this.fetchBoards([])
      })
  }

  fetchTeams = async (users: any) => {
    const user = getFirebase().auth().currentUser;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getTeamsStatistics`, {
      method: 'POST',
      body: JSON.stringify({ uid: user?.uid })
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          const body = JSON.parse(data.body);
          if (body?.teams) {
            const mappedTeams = body.teams.map((team: any) => {
              const createdDate = team.createdAt ? moment(team.createdAt).format('YYYY-MM-DD hh:mm:ss') : '-';
              const lastUpdated = team.lastUpdatedAt ? moment(team.lastUpdatedAt).format('YYYY-MM-DD hh:mm:ss') : '-';
              const numOfUsers = team.usersArray?.length || 0
              let creatorName = '—'
              let creatorEmail = '—'
              const creator = users.find((u: any) => u.uid === team.creatorUid)
              if (creator) {
                creatorName = creator.name ?? '—'
                creatorEmail = creator.email ?? '—'
              }

              return { ...team, createdDate, lastUpdated, numOfUsers, creatorName, creatorEmail }
            })


            console.log('mappedTeams', mappedTeams)
            this.setState({ teams: mappedTeams, teamsLoading: false })
          } else {
            this.setState({ teamsLoading: false })
          }
        } else {
          this.setState({ teamsLoading: false })
        }
      }).catch(e =>{
        this.setState({ teamsLoading: false })
        console.log(e)
      })
  }

  fetchBoards = async (users: any) => {
    const user = getFirebase().auth().currentUser;

    fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getBoardsStatistics`, {
      method: 'POST',
      body: JSON.stringify({ uid: user?.uid })
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          const body = JSON.parse(data.body);
          if (body?.boards) {
            const mappedBoards = body?.boards.map((board: any) => {
              const createdDate = board.createdAt ? moment(board.createdAt).format('YYYY-MM-DD hh:mm:ss') : '-';
              const lastUpdated = board.lastUpdatedAt ? moment(board.lastUpdatedAt).format('YYYY-MM-DD hh:mm:ss') : '-';
              const numOfUsers = board.users ? Object.keys(board.users)?.length || 0 : 0
              let creatorName = '—'
              let creatorEmail = '—'
              const creator = users.find((u: any) => u.uid === board.creatorUid)
              if (creator) {
                creatorName = creator.name ?? '—'
                creatorEmail = creator.email ?? '—'
              }

              return { ...board, createdDate, lastUpdated, numOfUsers, creatorName, creatorEmail }
            })

            console.log('mappedBoards', mappedBoards)
            this.setState({ boards: mappedBoards, boardsLoading: false })
          } else {
            this.setState({ boardsLoading: false })
          }
        } else {
          this.setState({ boardsLoading: false })
        }
      }).catch(e =>{
        this.setState({ boardsLoading: false })
        console.log(e)
      })
  }

  render() {
    const { usersLoading, teamsLoading, boardsLoading, calculationLoading } = this.state;

    const isLoading = usersLoading && teamsLoading && boardsLoading && calculationLoading

    console.log('this.state.teams', this.state.teams)

    if(isLoading) {
      return(
        <LoadingScreen />
        );
    } else {
      return (
        <div className="dashboard">
          <HeaderDashboard />
          <div className='StatisticsPage'>
            <h1>Statistics</h1>
            <div className='StatisticsPage__calculations'>
              <div>Number of Users with Emails: <b>{this.state.usersWithEmailsNumber}</b></div>
              <div>Number of Paid Users: <b>{this.state.paidUsersNumber}</b></div>
              <div>Number of Users with at least one Board with at least one Card: <b>{this.state.usersWithBoardsAndCardsNumber}</b></div>
              <div>Number of Unique Users with Multiple Boards: <b>{this.state.uniqueUsersWithMultipleBoardsNumber}</b></div>
            </div>
            {!usersLoading && (
              <div className='StatisticsPage__table'>
                <DataTable 
                  name='Users'
                  searchPlaceholder='Search by name and email'
                  searchableColumns={['name', 'email']}
                  data={this.state.users}
                  columns={usersColumns}
                />
              </div>
            )}
            {!teamsLoading && (
              <div className='StatisticsPage__table'>
                <DataTable 
                  name='Teams'
                  searchPlaceholder='Search by name or creator'
                  searchableColumns={['name', 'creatorName', 'creatorEmail']}
                  data={this.state.teams}
                  columns={teamsColumns}
                />
              </div>
            )}
            {!boardsLoading && (
              <div className='StatisticsPage__table'>
                <DataTable 
                  name='Boards'
                  searchPlaceholder='Search by name or creator'
                  searchableColumns={['name', 'creatorName', 'creatorEmail']}
                  data={this.state.boards}
                  columns={boardsColumns}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

  }
}

export default connect(mapStateToProps)(Statistics);
