import * as React from "react";

import './Description.scss';

const Description = () => {
    return(
        <div id="description" className="description">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="description__title">HOW IT WORKS?</h2>
                        <p className="description__text">Fusce tempor felis lacus, a hendrerit urna facilisis at. Sed sollicitudin bibendum tincidunt. Aenean a bibendum magna. Pellentesque feugiat urna a lectus consequat, quis vulputate nunc feugiat. Fusce non augue ligula. Ut sed ultrices lacus. Quisque pharetra nisl a pulvinar dictum. Quisque volutpat mi consectetur, rutrum lacus nec, porttitor nunc. Vivamus non est vitae erat feugiat ultricies eu id sapien. Duis vel magna posuere, malesuada mauris eget, tempus eros.</p>
                    </div>
                    <div className="col-md-6">
                        <div className="description-images">
                            <img
                                className="description__larger-img"
                                src="http://via.placeholder.com/320x336.jpg"
                                alt="how it works"
                            />

                            <img
                                className="description__smaller-img"
                                src="http://via.placeholder.com/160x192.jpg"
                                alt="how it works"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Description;