import { StoreState } from "../../types";
import { CardBoardOwnProps, CardBoardStateProps } from "./CardBoard";
import { getFirebase } from "react-redux-firebase";

export const mapStateToProps = (
  state: StoreState,
  ownProps: CardBoardOwnProps
): CardBoardStateProps => {
  const { board } = ownProps;
  const firebase = getFirebase();

  const toggleBoardSecure = (secure: boolean) => {
    if(board.id) {
      const database = firebase.firestore();

      database
        .collection('boards')
        .doc(board.id)
        .update({ secure: !secure })
        .catch((e) => {
          console.log(e);
        })
    }

  };

  return {
    toggleBoardSecure: toggleBoardSecure
  }
};
