import {StoreState, TeamBoards, TeamPendingUsers, Users} from "../../../../../types";
import { TeamUpdateOwnProps, TeamUpdateStateProps } from "./TeamUpdate";
import { getVal, getFirebase } from "react-redux-firebase";
import { debounce } from 'lodash';

export const mapStateToProps = (
  state: StoreState,
  ownProps: TeamUpdateOwnProps
): TeamUpdateStateProps => {
  const firestore = getFirebase().firestore();
  const { fbState } = state;
  const { team } = ownProps;
  const teamUsers: Users = getVal(fbState, `data/teamUsers`, undefined);
  const teamBoards: TeamBoards = getVal(fbState, `data/teamBoards`, undefined);
  const pendingUsers: TeamPendingUsers | undefined = getVal(fbState, `data/pendingUsers`, undefined);
  const user = getFirebase().auth().currentUser;
  let uid: string = '';
  if(user) {
    uid = user.uid;
  }

  const changeTeamName = debounce((value: string) => {
    if(team.admins.includes(uid)) {
      if(value && !/^\s/.test(value)) {
        firestore
          .collection('teams')
          .doc(team.id)
          .update({ name: value })
          .catch((e) => {
            console.log(e);
          })
      }
    }
  }, 2000);

  return {
    teamUsers,
    teamBoards,
    changeTeamName,
    pendingUsers
  }
};
