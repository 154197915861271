import { FirebaseFirestore } from '@firebase/firestore-types';
import { getFirebase, getVal } from "react-redux-firebase";
import { StoreState, UserBoard, UserBoards } from "../../types";
import { DashboardOwnProps, DashboardStateProps } from "./Dashboard";
import * as Raven from 'raven-js';

export const mapStateToProps = (
    store: StoreState,
    ownProps: DashboardOwnProps
): DashboardStateProps => {
    const { fbState } = store;
    const firebase = getFirebase();
    const database: FirebaseFirestore = firebase.firestore();
    const auth = firebase.auth();
    let uid = "";
    if(auth.currentUser) {
        uid = auth.currentUser.uid;
    }

    const createdAt: string | undefined = auth.currentUser ? auth.currentUser.metadata.creationTime : undefined;
    const boards: UserBoards = getVal( fbState, `data/userBoards`, {});
    let ownBoards: UserBoards = {};
    let sharedBoards: UserBoards = {};
    let teamBoards: UserBoards = {};
    if(boards) {
      Object.keys(boards).map((boardId: string)=> {
        if(boards[boardId].teamId) {
          teamBoards[boardId] = {
            ...boards[boardId],
          }
        } else if(boards[boardId].creatorUid === uid) {
          ownBoards[boardId] = {
            ...boards[boardId]
          }
        } else {
          sharedBoards[boardId] = {
            ...boards[boardId]
          }
        }
      })
    }

    const isAnonymous: boolean = firebase.auth().currentUser ? firebase.auth().currentUser!.isAnonymous : false;
    const arrayOwn: UserBoard[] = [];
    const arrayShared: UserBoard[] = [];
    const arrayArchived: UserBoard[] = [];
    const arrayTeams: UserBoard[] = [];
    let searchBoards: UserBoard[] = [];

    for(let key in ownBoards) {
        if(ownBoards[key].archived) {
            let item = {
                ...ownBoards[key],
                creator: true
            };
            arrayArchived.push({
                ...item,
                id: key
            });
        } else {
            arrayOwn.push({
                ...ownBoards[key],
                id: key
            });
        }
    }

    for(let key in sharedBoards) {
        if(sharedBoards[key].archived) {
            let item = {
                ...sharedBoards[key],
                creator: false
            };
            arrayArchived.push({
                ...item,
                id: key
            });
        } else {
            arrayShared.push({
                ...sharedBoards[key],
                id: key
            });
        }
    }

    for(let key in teamBoards) {
      if(teamBoards[key].archived) {
        let item = {
          ...teamBoards[key],
          creator: false
        };
        arrayArchived.push({
          ...item,
          id: key
        })
      } else {
        arrayTeams.push({
          ...teamBoards[key],
          id: key
        })
      }
    }

    if(ownProps.search) {
        searchBoards = arrayOwn.filter((item: any) => item.name.toUpperCase().includes(ownProps.search.toUpperCase()));
        searchBoards = searchBoards.concat(arrayShared.filter((item: any) => item.name.toUpperCase().includes(ownProps.search.toUpperCase())));
        searchBoards = searchBoards.concat(arrayArchived.filter((item: any) => item.name.toUpperCase().includes(ownProps.search.toUpperCase())));
        searchBoards = searchBoards.concat(arrayTeams.filter((item: any) => item.name.toUpperCase().includes(ownProps.search.toUpperCase())));
    }

    function archiveBoard(boardId: string | undefined) {
      if(boardId && uid) {
        database.collection('boards').doc(boardId).get().then(doc => {
          const boardData = doc.data();
          if (boardData) {
            const userIds = boardData.users;
            Object.keys(userIds).forEach(userId => {
              database
                .collection('users')
                .doc(userId)
                .collection('userBoards')
                .doc('userBoards')
                .update({ [`${boardId}.archived`]: true })
                .catch((err: Error) => {
                  console.log(err);
                });
            })
          }
        }).catch((err: Error) => {
          console.log(err);
        })
      }
    }

    function unarchiveBoard(boardId: string | undefined) {
      if(boardId && uid) {
        database.collection('boards').doc(boardId).get().then(doc => {
          const boardData = doc.data();
          if (boardData) {
            const userIds = boardData.users;
            Object.keys(userIds).forEach(userId => {
              database
                .collection('users')
                .doc(userId)
                .collection('userBoards')
                .doc('userBoards')
                .update({ [`${boardId}.archived`]: false })
                .catch((err: Error) => {
                  console.log(err);
                });
            })
          }
        }).catch((err: Error) => {
          console.log(err);
        })
      }
    }

    async function fetchCardsForSingleBoard(board: UserBoard, type: string) {
      let boardUsers : any = [] 
      if (board?.users) {
        const userKeys = Object.keys(board.users)
        boardUsers = userKeys.map((key: any) => {
          const singleUser = board.users[key]
          return { id: key, ...singleUser }
        })
      }

      const cardsData: any = []
      const boardId = board.id
      const cards: any= await database.collection("boards").doc(boardId).collection('cards').get()
      if (cards.size > 0) {
        cards.forEach((snapCards: any) => {
          const snapCardsData = snapCards.data()
          if(!type || snapCardsData?.type === type) {
            cardsData.push({ ...snapCardsData, boardId: boardId, boardName: board.name, boardUsers, id: snapCards.id })
          }
        });

        return cardsData
      }

      return []
    }

    async function fetchCardForBoards(boards: UserBoard[], type: string) {
      try {
        let response: any[] = []
        const allCards = await Promise.all(boards.map(async (board: UserBoard)=> {
          const cardsData = await fetchCardsForSingleBoard(board, type)
          return cardsData
        }))

        allCards.forEach((cards: any) => {
          cards.forEach((card: any) => {
            response.push(card)
          })
        })

        return response
      } catch (e) {
        console.log('e', e)
        return []
      }
    }

    async function onMarkCardAsDone(boardId: string, cardId: string) {
      return database
        .collection('boards')
        .doc(boardId)
        .collection('cards')
        .doc(cardId)
        .update({
          status: 'done',
        })
        .then(() => {
          return true
        })
        .catch((err: Error) => {
          Raven.captureMessage('Could not mark card as done', {
            extra: {
              reason: err.message,
              boardId,
              cardId
            }
          });
          return false
        });
    }

    async function onMarkCardAsUndone(boardId: string, cardId: string) {
      return database
        .collection('boards')
        .doc(boardId)
        .collection('cards')
        .doc(cardId)
        .update({
          status: 'active',
        })
        .then(() => {
          return true
        })
        .catch((err: Error) => {
          Raven.captureMessage('Could not undone card', {
            extra: {
              reason: err.message,
              boardId,
              cardId
            }
          });
          return false
        });
    }

    async function onDelteCard(boardId: string, cardId: string) {
      // add removing from group if is in the gruop
      try {
        const boardGroups = await database
                              .collection('boards')
                              .doc(boardId)
                              .collection('cardGroups')
                              .get()
  
        if (boardGroups?.size > 0) {
          let foundedGroup = ''
          let newCards = {}
          boardGroups.forEach((snapGroup: any) => {
            const snapGroupData = snapGroup.data()
            const cardsInGroup = snapGroupData.cards
            if (Object.keys(cardsInGroup).includes(cardId)) {
              foundedGroup = snapGroup.id
              delete cardsInGroup[cardId]
              newCards = cardsInGroup
            }
          });
  
          if (foundedGroup) {
            await database
              .collection('boards')
              .doc(boardId)
              .collection('cardGroups')
              .doc(foundedGroup)
              .update({ cards: newCards })

          }
        }
      } catch (e: any) {
        console.log('e', e)
        return false
      }

      // remove card
      return database
        .collection('boards')
        .doc(boardId)
        .collection('cards')
        .doc(cardId)
        .delete()
        .then(() => {
          return true
        })
        .catch((err: Error) => {
          Raven.captureMessage('Could not mark card as done', {
            extra: {
              reason: err.message,
              boardId,
              cardId
            }
          });
          return false
        });
    }

    async function sendUserEmailForAssignment(userEmail: any, boardId: any) {
      try {
        const board = await database.collection('boards').doc(boardId).get()
        if (!board.exists || !board.data()) {
          return false
        }

        const data = {
          userId: uid,
          assignedEmail: userEmail,
          boardName: board.data()?.name,
          boardLink: `/#/board/${boardId}/action-items`
        }

        const assignedUserRes = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/sendEmailForAssignedItem`, {
          method: 'POST',
          body: JSON.stringify(data)
        })
    
        if (assignedUserRes) {
          const resJSON = await assignedUserRes.json()

          if (resJSON) {
            if(resJSON.body) {
              return true
            }
          }
        }

        return false
    
      } catch (e) {
        return {}
      }
    }

    async function onUpdateCard(card: any) {
      const boardId = card.boardId
      const cardId = card.id
      let updatingFields = {
        text: card.text,
        status: card?.status || '',
        assignedUserId: card.assignedUserId || null,
        assignedUserEmail: card.assignedUserEmail || null,
        assignedUserName: card.assignedUserName || null,
        assignedUserPhotoUrl: card.assignedUserPhotoUrl || null
      }

      const oldCardRes = await database
                        .collection('boards')
                        .doc(boardId)
                        .collection('cards')
                        .doc(cardId)
                        .get()

      let oldCard: any
      if (oldCardRes?.data()) {
        oldCard = oldCardRes.data()
      }

      return database
        .collection('boards')
        .doc(boardId)
        .collection('cards')
        .doc(cardId)
        .update({
          ...updatingFields
        })
        .then(() => {
          if (updatingFields.assignedUserEmail) {
            if (oldCard?.assignedUserId !== card.assignedUserId || oldCard?.text !== card.text) {
              sendUserEmailForAssignment(updatingFields.assignedUserEmail, boardId)
            }
          }
          
          return true
        })
        .catch((err: Error) => {
          Raven.captureMessage('Could not mark card as done', {
            extra: {
              reason: err.message,
              boardId,
              cardId
            }
          });
          return false
        });
    }

    return {
        activeUserUid: uid,
        ownBoards: arrayOwn,
        sharedBoards: arrayShared,
        teamBoards: arrayTeams,
        archivedBoards: arrayArchived,
        searchBoards: searchBoards,
        isAnonymous: isAnonymous,
        createdAt: createdAt,
        archiveBoard: archiveBoard,
        unarchiveBoard: unarchiveBoard,
        fetchCardForBoards: fetchCardForBoards,
        onMarkCardAsDone: onMarkCardAsDone,
        onMarkCardAsUndone: onMarkCardAsUndone,
        onDelteCard: onDelteCard,
        onUpdateCard: onUpdateCard
    }

}
